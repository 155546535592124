import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function DiscussWithDoctor() {
  return (
    <ArticleContent>
      <Quiz
        question="If women have not had a Cervical Screening Test before they conceive they need to wait until after they have given birth to be tested. "
        answer="False"
        feedback="This is False. Cervical screening is safe at any stage during pregnancy, however it may cause spotting."
      />

      <h4 id="key-points">Key points</h4>
      <ul>
        <li>Your supplement requirements change throughout your pregnancy.</li>
        <li>Discuss what vaccinations you and your family may need during your pregnancy.</li>
        <li>If you have not had cervical screening, speak with your doctor about doing so.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Supplements and vaccinations remain important during pregnancy, and may need to be introduced or stopped at
        different times. It is important to discuss your needs with your doctor to ensure you receive the supplements
        and vaccinations you need.
      </p>
      <p>
        Cervical screening is important for protection of cervical cancer. Cervical screening can safely carried out at
        any time during pregnancy. Cervical screening during pregnancy may cause spotting so you should discuss this
        with your doctor prior to screening.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        <strong>Folic acid</strong> supplementation prevents the risk of neural tube defects, such as spina bifida.
        Ideally, women should take folic acid from before they plan to become pregnant, until the end of the first
        trimester (12 weeks or 3 months into pregnancy).
      </p>
      <p>
        <strong>Iodine</strong> is essential for brain and nervous system development. The easiest way to get the right
        amount of iodine is via an iodine supplement which should be taken during preconception and continued throughout
        pregnancy and while breastfeeding.
      </p>
      <p>
        <strong>Iron</strong> requirements increase during pregnancy. Talk to your doctor about checking your iron
        levels to assess if you need a supplement. Eating iron rich foods may help you keep your iron levels sufficient
        without a supplement. Iron rich foods include iron fortified breakfast cereals, white beans, lentils and
        soybeans, organ meats and spinach. Combining these with foods or drinks high in Vitamin C increases iron
        absorption such as, oranges or orange juice, kiwi fruit, capsicum and broccoli.
      </p>
      <p>
        <strong>Vaccinations</strong> to consider during pregnancy include Influenza and whooping cough (pertussis).
        Your partner, family members and friends or anyone that will have contact with your baby shortly after birth are
        advised to receive these vaccinations as well. Discuss the timing of these vaccinations with your doctor.
      </p>
      <p>
        <strong>Cervical screening</strong> if you are not up to date with cervical screening or are unsure when you
        last had a screening test discuss this with your doctor. Remember if your screening is not up to date your last
        tests may have been a pap smear as the test was changed in December 2017.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about these topics visit:</p>
      <ul>
        <li>
          <a
            href="https://www.health.gov.au/health-topics/immunisation/immunisation-throughout-life/immunisation-for-pregnancy"
            target="_blank"
            rel="noopener noreferrer">
            Department of Health, Immunisation for Pregnancy
          </a>{' '}
        </li>
        <li>
          <a
            href="https://www.health.qld.gov.au/news-events/news/supplements-vitamins-for-pregnant-women"
            target="_blank"
            rel="noopener noreferrer">
            Queensland Health, Supplements
          </a>
        </li>
        <li>
          <a
            href="https://www.health.act.gov.au/sites/default/files/2018-09/Good%20Nutrition%20in%20Pregnancy%20%28April%202014%29_0.pdf"
            target="_blank"
            rel="noopener noreferrer">
            ACT Health, Supplements
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/conditionsandtreatments/cervical-screening-tests"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Victoria, Cervical Screening
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
