import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { ArticleContent, Question, ResultLine, ResultLineTypes } from 'components/topics/Article';

export default function HealthyEating() {
  return (
    <ArticleContent>
      <Card>
        <CardHeader title="Myth or Fact" />
        <CardContent>
          <Question
            id="quiz"
            answer="Myth"
            label="If I take a pre-pregnancy vitamin, this will address all my needs and I can eat whatever I want."
            options={[
              {
                value: 'Myth',
                label: 'Myth',
              },
              {
                value: 'Fact',
                label: 'Fact',
              },
            ]}>
            {matched =>
              matched ? (
                <ResultLine type={ResultLineTypes.Success} message="Yes, this is a myth." />
              ) : (
                <ResultLine type={ResultLineTypes.Failure} message="No, this is a myth" />
              )
            }
          </Question>
        </CardContent>
      </Card>

      <h4>Key points</h4>

      <ul>
        <li>There is no special diet or increased energy requirements needed when planning for pregnancy.</li>
        <li>
          Having a well-balanced diet with a wide variety of vegetables, fruit, wholegrains, protein and dairy foods is
          recommended to achieve adequate levels of nutrients for optimal health.
        </li>
        <li>
          Planning ahead can improve control and reduce the likelihood of opting for convenient, energy dense foods.
        </li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        Dietary intake contributes significant to health, including weight. Weight gain is a result of an imbalance
        between energy consumed from foods and energy expended, or burnt. Even small increases in weight increase the
        chance of developing health complications. Excess weight gain in young women can lead to difficulty conceiving,
        menstrual problems and problems during pregnancy.
      </p>

      <h4>What are the recommendations?</h4>
      <p>
        There is no special diet, or increased energy requirements needed, when considering pregnancy. Having a
        well-balanced diet with a wide variety of vegetables, fruit, wholegrains, protein and dairy foods is recommended
        to achieve adequate levels of nutrients for optimal health.
      </p>
      <p>
        It is a good time to reduce your intake of processed and refined foods as these are often of poor nutritional
        value.
      </p>
      <p>
        Eating a diet with a wide variety of nutrients help to ensure adequate levels of nutrients such as zinc,
        magnesium, potassium and Vitamins C, B6 and E; all of which play a role in fertility.
      </p>

      <h6>The Australian Dietary Guidelines</h6>
      <p>
        The Australian Dietary Guidelines provide recommendations for Australian adults that are a great tool to help
        guide your food intake. For women aged 18-64 that are not pregnant or breastfeeding, the current recommendations
        for the 6 main foods groups are provided below:
      </p>

      {/* [images of 6 main foods groups...] */}

      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <img src="images/articles/preconception/1.fruit.jpg" alt="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <img src="images/articles/preconception/2.veg.jpg" alt="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <img src="images/articles/preconception/3.grain.jpg" alt="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <img src="images/articles/preconception/4.dairy.png" alt="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <img src="images/articles/preconception/5.protein.jpg" alt="" />
            </Grid>
            <Grid item xs={12} md={6}>
              <img src="images/articles/preconception/6.discretionary.jpg" alt="" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <h6>Consider wholefoods</h6>
      <p>Whole foods are foods that are natural and in their original state. Examples of whole foods:</p>
      <ul>
        <li>Vegetables</li>
        <li>Fruits</li>
        <li>Whole grains</li>
        <li>Nuts</li>
        <li>Legumes</li>
        <li>Unrefined plant oils - such as Extra Virgin Olive Oil</li>
        <li>Eggs</li>
        <li>
          Whole meat - like a piece of fish, chicken breast or steak. Not processed meat like salami, jerky, sausages,
          packaged deli slices or burgers.
        </li>
      </ul>

      <h6>Limit refined foods high in sugar and fat</h6>
      <p>
        Foods high in fat and/or sugar and low in fibre are high energy dense foods. They have lots of
        calories/kilojoules and are rapidly digested so they don’t fill you up and can lead to eating large amounts of
        them.
      </p>

      <p>Examples of energy dense foods include:</p>
      <ul>
        <li>Cookies</li>
        <li>Doughnuts</li>
        <li>Chocolate</li>
        <li>Cakes</li>
        <li>Sugary cereals</li>
        <li>Lollies</li>
        <li>Sugar sweetened beverages and soft drink.</li>
      </ul>

      <p>Examples of foods high in saturated or trans fats:</p>
      <ul>
        <li>Fries</li>
        <li>Chips</li>
        <li>Fast food burgers</li>
        <li>Deep fried foods</li>
        <li>Processed meats (such as: salami, jerky, sausages, packaged deli slices or burgers)</li>
        <li>Animal fats</li>
        <li>Cream, margarine and butter</li>
        <li>Pre-made cookies and biscuits.</li>
      </ul>

      <h6>Eating for two</h6>
      <p>
        You do not need to increase your food intake ahead of becoming pregnant. Following the Dietary guidelines above
        and eating the same amount as your normally would is recommended.
      </p>

      <h6>Food safety</h6>
      <p>
        Listeria is a bacteria found in some foods that can be ingested and make you unwell. Symptoms can take 2-30 days
        to appear so avoiding these food during preconception, as well as pregnancy, is advisable. To help prevent
        listeriosis, avoid eating the following foods:
      </p>
      <ul>
        <li>Unpasteurized milk and foods made with unpasteurized milk, including soft cheeses</li>
        <li>Hot dogs and luncheon meats, unless they are heated until steaming hot just before serving</li>
        <li>Refrigerated pâté and meat spreads</li>
        <li>Refrigerated smoked seafood</li>
        <li>Unwashed raw produce such as fruits and vegetables</li>
        <li>
          Avoid all raw and undercooked seafood, eggs, meat, and poultry while you are pregnant. Do not eat sushi made
          with raw fish (cooked sushi is safe). Cooking and pasteurization are the only ways to kill Listeria.
        </li>
      </ul>

      <h4>What can I do?</h4>
      <p>Talk to your partner about adopting a healthy diet together.</p>

      <p>
        Planning what meals you are going to eat throughout the week or over the next few days is a great way to
        improving control within your diet. Doing so will make grocery shopping easier and enable you to look ahead to
        ensure you intake will provide a variety of nutrients. Being well prepared and having food ready also means
        you’re less likely to get hungry and opt for fast-food or convenient, unhealthy options.
      </p>

      <p>
        If you think you need a little help in this area you can work with a healthcare provider such as a dietitian or
        nutritionist. They can construct a plan that is suited to your needs and considers your lifestyle and cooking
        abilities.
      </p>
    </ArticleContent>
  );
}
