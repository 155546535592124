import React from 'react';
import { Card, CardContent, Box, CardHeader } from '@material-ui/core';
import { ArticleContent, Question, ResultLine, ResultLineTypes } from 'components/topics/Article';

export default function Activity() {
  return (
    <ArticleContent>
      <Card elevation={0}>
        <CardHeader title="Knowledge Test" />
        <CardContent>
          <Question
            multiple
            id="knowledgeTest"
            answer={['B', 'D']}
            label="What is the recommended amount of physical activity for me per week? (Please select all that apply.)"
            options={[
              {
                value: 'A',
                label:
                  'A) 1 to 2 hours of moderate physical activity, or 30 minutes to 1 hour of vigorous physical activity.',
              },
              {
                value: 'B',
                label:
                  'B) 2 ½ to 5 hours of moderate physical activity, or 1 ¼  to 2 ½ hours of vigorous physical activity.',
              },
              {
                value: 'C',
                label: 'C) Muscle strengthening activities one day per week.',
              },
              {
                value: 'D',
                label: 'D) Muscle strengthening activities on at least two days per week.',
              },
            ]}>
            {matched =>
              matched ? (
                <ResultLine type={ResultLineTypes.Success} message="Yes, this is correct." />
              ) : (
                <ResultLine
                  type={ResultLineTypes.Failure}
                  message="Correct answer is option B + D. Progress to the module for more details"
                />
              )
            }
          </Question>
        </CardContent>
      </Card>

      <h4>Key points</h4>
      <ul>
        <li>Move at every opportunity – any physical activity is better than none at all</li>
        <li>Aim to complete at least 30 minutes of physical activity most days of the week</li>
        <li>Intensity should be moderate at minimum with a noticeable increase in heart rate and quicker breathing.</li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        Physical activity is anything that gets your body moving, makes your breathing become quicker and your heart
        beat faster.
      </p>
      <p>
        Regular physical activity is an important part of a healthy lifestyle, with many associated health benefits
        including:
      </p>
      <ul>
        <li>Weight gain prevention</li>
        <li>Prevention of weight regain following weight loss</li>
        <li>Improved mood and mental wellbeing</li>
        <li>Reduced likelihood of anxiety and depression</li>
        <li>Improved risk factors for cardiovascular disease, including blood pressure</li>
        <li>Reduced risk of glucose intolerance and development of type 2 diabetes</li>
        <li>Prevention of some cancers</li>
      </ul>

      <p>
        If you are active regularly, you are more likely to maintain your weight. If you include activities that
        increase strength such as weights or lifting you will maintain more muscle, which also helps to maintain weight
        long term.
      </p>

      <p>
        Despite this, over 50% of women don’t meet National recommendations for physical activity. Reports show that
        physical activity declines with increasing age through adulthood, with high risk periods for sedentary behaviour
        including pregnancy and child birth. Common barriers to completing physical activity reported by women include
        time constraints, motivation, caregiving, cost, low confidence and a low level of prioritisation above other
        competing factors.
      </p>

      <h4>What are the recommendations?</h4>
      <p>The Australian Physical Activity and Sedentary Behaviour Guidelines recommend:</p>

      <Box my={2} style={{ textAlign: 'center' }}>
        <img src="images/articles/preconception/1recommendation.jpg" alt="recomendation1" />
      </Box>

      <Box my={2} style={{ textAlign: 'center' }}>
        <img src="images/articles/preconception/2recommendation.jpg" alt="recomendation2" />
      </Box>

      <Box my={2} style={{ textAlign: 'center' }}>
        <img src="images/articles/preconception/3recommendation.jpg" alt="recomendation3" />
      </Box>

      <h4>What intensity of physical activity is best?</h4>
      <p>
        You can implement a mixture of both moderate and vigorous activities. Doing a variety of exercises that you find
        enjoyable means you’re more likely to continue. The intensity of physical activity depends on what level your
        heart rate reaches when you are exercising.
      </p>

      <Box my={2} style={{ textAlign: 'center' }}>
        <img src="images/articles/preconception/finalinfo.png" alt="finalinfo" />
      </Box>

      <p>
        <strong>Moderate Intensity Activities</strong> take some effort, but you are still able to talk while doing
        them. You should feel an increase in your heart rate. For example:
      </p>
      <ul>
        <li>A brisk walk</li>
        <li>Recreational swimming</li>
        <li>Dancing</li>
        <li>Social Tennis</li>
        <li>Golf</li>
        <li>Household tasks like vacuuming, scrubbing or raking leaves.</li>
      </ul>

      <p>
        <strong>Vigorous Intensity Activities</strong> are harder and you may not be able to talk while doing them. You
        will feel an increase in your heart rate and will breathe harder and faster. For example:
      </p>
      <ul>
        <li>Jogging or running</li>
        <li>Aerobics</li>
        <li>Fast cycling</li>
        <li>Some organised sports that involve running</li>
        <li>Tasks that involve heavy lifting, carrying or digging.</li>
      </ul>

      <h6>Walking for Weight Management</h6>
      <p>
        Compared with running, walking burns fewer kilojoules per hour so you have to do it for longer, however this may
        be more achievable and therefore more sustainable over time. Walking is enjoyable and can be a good social
        activity - you won’t even notice you’re burning kilojoules! If you enjoy walking and do it for long enough,
        walking can be as effective as running for burning extra energy.
      </p>

      <p>Try these strategies:</p>
      <ul>
        <li>
          Increase the time spent walking each session. Ideally you should be walking for 30-60 minutes in a session. If
          you prefer, can break it into 10-15 minute chunks, that’s fine. Just try to accumulate 30-60 minutes on most
          days of the week.
        </li>
        <li>
          Walk with a friend or group. You will be amazed how quickly an hour goes when walking and chatting with a
          friend.
        </li>
        <li>
          Monitor your progress, keeping tabs on the distances walked, how fast you’re walking and how long you’ve
          exercising for. A pedometer can be a helpful monitoring tool - aim for 8,000-10,000 steps every day.
        </li>
        <li>Listen to music or the radio - 5 songs is equal to around 20 minutes.</li>
      </ul>

      <h4>Useful Links</h4>
      <ul>
        <li>
          <a
            href="https://www.health.qld.gov.au/clinical-practice/guidelines-procedures/clinical-staff/maternity/nutrition/lifestyle/pre-conception"
            target="_blank"
            rel="noopener noreferrer">
            Pre-conception
          </a>
        </li>
        <li>
          <a
            href="http://www.health.gov.au/internet/main/publishing.nsf/Content/F01F92328EDADA5BCA257BF0001E720D/%24File/brochure%20PA%20Guidelines_A5_18-64yrs.pdf"
            target="_blank"
            rel="noopener noreferrer">
            Guidelines
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
