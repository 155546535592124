import React from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Redirect } from 'react-router';
import Form from 'components/form';
import { notEmpty as NotEmpty } from 'components/form/validators';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
/*import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Link from '@material-ui/core/Link';*/
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { ModuleId } from 'app/types';
import useTopicState from 'app/topics/useTopicState';

const notEmpty = NotEmpty();

export type GoalSettingData = {
  why?: string;
  goal?: string;
  obstacles?: string;
  strategies?: string;
  aim?: string;
  completed? : boolean;
  review_progress? : string;
};

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    introCard: {
      marginBottom: theme.spacing(4),
    },
    field: {
      width: '100%',
      margin: theme.spacing(1, 0),
    },
    content: {
      backgroundColor: '#F8f8f8',
    },
    actions: {
      justifyContent: 'center',
      padding: theme.spacing(3, 0),
    },
  }),
);

export type GoalSettingProps = {
  moduleId: ModuleId;
  goalId: string;
};

/**
 * For Fix #141
 * Setting up the of all the questions for referencing
 */
interface IQuestion {
  label: string;
  next: string;
  prev: string;
}

/**
   * This functions takes the list of the questions and
   * make a dictionary so that each question may know what is
   * the next questions and what was the previous quesiton.
   * Input : String[]
   * Output :
   *  {
        'GOAL' : {
          label : 'GOAL',           // label of the question for fast referencing
          next : 'IMPORTANCE',      // next question
          prev : '',                // last question
        }
   *  }
*/
const makeQuestions = (questionsList : string[]) : { [id : string] : IQuestion; } => {
  let Questions : { [id : string] : IQuestion; } = {}
  for(let i = 0; i < questionsList.length; i++){
    Questions[questionsList[i]] = {
      label : questionsList[i],
      next : i < questionsList.length - 1 ? questionsList[i+1] : '',
      prev : i > 0 ? questionsList[i-1] : ''
    }
  }
  return Questions
}

const Questions = makeQuestions(
  [
    'GOAL',
    'IMPORTANCE',
    'OBSTACLES',
    'STRATEGIES',
    'AIM'
  ] as string[]
)

export default function GoalSetting(props: GoalSettingProps) {
  const { moduleId, goalId } = props;
  const classes = useStyle();
  const history = useHistory();
  //const [showModal, setShowModal] = React.useState(false);
  const [currentQuestion, setCurrentQuestion] = React.useState(Questions['GOAL']) // Fix # 141
  const { topicState, updateTopicState } = useTopicState(moduleId);
  let topicGoalData = get(topicState, ['goalSettings', goalId],
    {
      why: '',
      goal: '',
      obstacles: '',
      strategies: '',
      aim: '',
      completed : null,
      review_progress : null
    }) as GoalSettingData
  // Find out if a goal is set or not.
  // If the goal is set well will show the
  // review my progress fields.
  // Otherwise not.
  const goalIsSet = (
    Boolean(topicGoalData.why) &&
    Boolean(topicGoalData.goal) &&
    Boolean(topicGoalData.obstacles) &&
    Boolean(topicGoalData.strategies) &&
    Boolean(topicGoalData.aim)
  )

  if (isEmpty(topicState.goals)) {
    return <Redirect to={`/topics/${moduleId}/goals/select`} />;
  }

  const handleSubmit = (data: GoalSettingData) => {
    const successPath = `/topics/${moduleId}/goals`;
    let goalData = {
          why : data.why,
          goal : data.goal,
          obstacles : data.obstacles,
          strategies : data.strategies,
          aim : data.aim,
          completed : data.completed || false,
          review_progress : data.review_progress || ""
    } as GoalSettingData
    updateTopicState({ goalSettings: { ...topicState.goalSettings, [goalId]: goalData } }, () => history.push(successPath));
  };

  const onError = (errors : any) : IQuestion => {
    if (errors.goal) return Questions.GOAL
    if (errors.why) return Questions.IMPORTANCE
    if (errors.obstacles) return Questions.OBSTACLES
    if (errors.strategies) return Questions.STRATEGIES
    return Questions.AIM
  }

  return (
    <React.Fragment>
      <Card className={classes.introCard}>
        <CardHeader title="Introducing smart goals" />
        <CardContent>
          <p>
            To be successful in changing habits you need to set small, achievable goals – smart goals. Because they are
            small, they are within your control and therefore more achievable.
          </p>
          <p>
            Goal setting is important to help you think clearly about your goals, increase awareness of the obstacles or
            problems that might stop you, working out how to overcome those problems with actions and making some clear
            plans to so. Once you start planning, you should feel more motivated, confident and rewarded with the
            success.
          </p>
          <p>
            Writing down your goals is an important step. It may be a hassle now, but you are more likely to follow
            through with your goal if you write it down. Think of writing a goal as a form of commitment to yourself.
          </p>
        </CardContent>
      </Card>

      <Form
        data={topicGoalData}
        validators={{
          goal: notEmpty,
          why: notEmpty,
          obstacles: notEmpty,
          strategies: notEmpty,
          aim: notEmpty,
        }}
        onSubmit={handleSubmit}>
        {
          ({ values, errors, updateField }) => {
            return (
            <Card>
            <CardHeader
            title="Follow these goal setting steps"
            subheader="The goals you set for yourself should be achievable and your expectations realistic."></CardHeader>
            <CardContent className={classes.content}>
            <Grid container spacing={3}>
            {
            currentQuestion.label === Questions.GOAL.label ? (
            <Grid item xs={12}>
            <Field
            name="goal"
            title="Goal"
            desc="Using the health habit you selected as most important to change, think of a smaller, achievable step that is within your reach."
            rows={2}
            label="I want to..."
            value={values.goal}
            onChange={e => updateField('goal', e.target.value)}
            error={Boolean(errors.goal)}
            helperText={errors.goal || 'Example: I want to eat more fruits and vegetables.'}/>

            </Grid>
            ) : <React.Fragment/>
                    }

                    {
            currentQuestion.label === Questions.IMPORTANCE.label ? (
            <Grid item xs={12}>
            <Field
            name="why"
            title="Why is this important to you?"
            desc="Be clear about what is important to you to change and why. If a habit is important to you to change, you are more likely to be motivated to do something about it and therefore will be more successful in positive change overall."
            rows={2}
            label="List what motivates you to change..."
            value={values.why}
            onChange={e => updateField('why', e.target.value)}
            error={Boolean(errors.why)}
            helperText={
            errors.why ||
           'Example: To feel healthier; to reduce intake of convenience foods; increased fibre.'}/>
            </Grid>) : <React.Fragment/>
                    }

                    {
             currentQuestion.label === Questions.OBSTACLES.label ? (
             <Grid item xs={12}>
             <Field
             name="obstacles"
             title="What are your obstacles?"
             desc={
             <React.Fragment>
             <span>Obstacles are things or thoughts that stop you from achieving your goal. Brainstorming solutions is key to working around these. Obstacles are very individual – what may stop you, may not be a problem for someone else. Your lifestyle is as individual as you. When you think about your goal, what are the obstacles that come to your mind?
        </span>
        <br/>
        <br/>
        <span>
        <span style={{fontWeight:'bold'}}>Obstacles</span>
        <br/>
        <span>Women commonly tell us how difficult it is to eat healthy food and be active regularly. Obstacles are things that stop you achieving healthier choices, even when your intentions are positive. Being aware and identifying obstacles that are within your control is an Important step in changing habits. In order to increase motivation for healthy behaviours, you need to increase your awareness of the obstacles in your life. Then, step by step find ways to overcoming them. Planning ahead, being prepared, thinking more positively and seeking support from family and friends are some of the many things that can help you successfully make a healthier lifestyle. </span>
                                </span>
                              </React.Fragment>
                            }
                            rows={4}
                            label="List your obstacles..."
                            value={values.obstacles}
                            onChange={e => updateField('obstacles', e.target.value)}
                            error={Boolean(errors.obstacles)}
                            helperText={
                              errors.obstacles || (
                                <React.Fragment>
                                  {
                                    /**
                                    <span>
                                      Example: I don’t have enough time to prepare fresh food; I always forget.{' '}
                                      <Link onClick={() => setShowModal(true)}>show me more</Link>
                                      </span>
                                     */
                                  }
                                  <span style={{fontWeight : 'bold'}}>Common obstacles are:</span>
                                  <br/><span>I don't have enough time.</span>
                                  <br/><span>I just don't feel motivated.</span>
                                  <br/><span>I am too tired.</span>
                                  <br/><span>I don't like getting up early.</span>
                                  <br/><span>It's too hot or cold outside.</span>
                                  <br/><span>I don't have any information or don't know where to go for information.</span>
                                  <br/><span>I don't feel confident.</span>
                                  <br/><span>I don't have anyone to join me.</span>
                                  <br/><span>Partner or kids complain when healthy changes are suggestedor made.</span>
                                  <br/><span>I don't have the right resources or equipment.</span>
                                </React.Fragment>
                              )
                            }
                          />
                        </Grid>
                      ) : <React.Fragment/>
                    }

                    {
                      currentQuestion.label === Questions.STRATEGIES.label ? (
                        <Grid item xs={12}>
                          <Field
                            name="strategies"
                            title="What are your strategies?"
                            desc="We all deal with obstacles each day and we do it naturally and easily. Sometimes it works and sometimes it doesn’t and then you will try another solution. This is called problem solving. Think about the problem solving you do each day at work or at home – if something is not working very well, we try and fix it. To put solution into practice, you need strategies. Strategies are actions that will help you address your barriers. List your own three strategies that will help you overcome the obstacles you have identified in the step above."
                            rows={2}
                            label="List your strategies..."
                            value={values.strategies}
                            onChange={e => updateField('strategies', e.target.value)}
                            error={Boolean(errors.strategies)}
                            helperText={
                              errors.strategies ||
                              'Example: Make a weekly meal plan; add fresh fruit to my breakfast; put fruit in a visible place so I remember.'
                            }
                          />
                        </Grid>
                      ) : <React.Fragment/>
                    }

                    {
                      currentQuestion.label === Questions.AIM.label ? (
                        <Grid item xs={12}>
                          <Field
                            name="aim"
                            title="When/where/how often"
                            desc="Be specific. Combine all the steps above into one overall, smart goal. To ensure your overall goal is specific it must include an action plus duration/amount, how often, when and where of the action. Summarise your strategies to make it achievable to you."
                            rows={2}
                            label="List your aim..."
                            value={values.aim}
                            onChange={e => updateField('aim', e.target.value)}
                            error={Boolean(errors.aim)}
                            helperText={
                              errors.aim ||
                              'Example: I will increase my fruit intake to one piece every day by taking fruit to work and displaying it on my desk as a reminder. On the weekends I will have chopped fruit on my cereal.'
                            }
                          />
                        </Grid>
                      ) : <React.Fragment/>
                    }
                  </Grid>
                </CardContent>
                {
                  (
                    (errors.aim || errors.why || errors.strategies || errors.goal || errors.obstacles) &&
                    onError(errors).label !== currentQuestion.label
                  ) ? (
                    <div>
                      <p style={{textAlign : 'center', color:'#f46f65'}}>
                        Field: {errors.goal || errors.why || errors.obstacles || errors.strategies || errors.aim }&nbsp;
                        <span
                        style={{textDecoration : 'underline', color: '#3182CE', cursor : 'pointer'}}
                        onClick={() => setCurrentQuestion(onError(errors))}>See error field.</span>
                      </p>
                    </div>
                  ) : <React.Fragment/>
                }
                <CardActions className={classes.actions}>
                  {
                    currentQuestion.prev ? (
                      <Button variant="contained" color="primary" onClick={() => setCurrentQuestion(Questions[currentQuestion.prev])}>
                        BACK
                      </Button>
                    ) : <Button style={{color : "#888"}}>BACK</Button>
                  }

                  {
                    !currentQuestion.next? (
                      <Button variant="contained" type="submit" color="primary">
                        Submit
                      </Button>
                    ) : <React.Fragment/>
                  }

                  {
                    currentQuestion.next ? (
                      <Button variant="contained" color="primary" onClick={() => setCurrentQuestion(Questions[currentQuestion.next])}>
                        Next
                      </Button>
                    ) : <Button style={{color : "#888"}}>NEXT</Button>
                  }
                </CardActions>
              </Card>
            )
          }
        }
      </Form>



      {
        goalIsSet ? (
          <div style={{marginTop : '36px'}}>
            <Form
              data = {topicGoalData}
              validators = {{
                review_progress : notEmpty,
              }}
              onSubmit={handleSubmit}
            >
              {
                ({ values, errors, updateField }) => {
                  return (
                    <Card>
                      <CardHeader
                        title="Review My Progress"
                        subheader="Write a review about your progress."/>
                      <CardContent className={classes.content}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                              <Field
                                name="review_progress"
                                title="Review Progress"
                                desc={
             <React.Fragment>
             <span>Reviewing goals is an important part of self-management which helps determine if your strategies were effective in overcoming your obstacles. If you find that your set goal is too hard and too big a jump from where you currently are, you can adjust your goal to make it more achievable. If you feel that the goal is achievable, but you are not quite there, you may need additional strategies in place or have to address unexpected or new obstacles. New obstacles may require us to think of new strategies.</span>
             <br/>
             <br/><span>If you have effectively reached this goal, you can adjust your goal to strive for more. Or note in your review that you feel you have conquered this health area and then set a new goal to focus on another.</span>
             <br/>
             <br/><span>Aim to review your goal every two weeks, this allows you time to determine if your strategies were effective. In your review, give yourself a score out of 10 for how you think you went with this goal (1 = not well, 10 = very well)</span>
                              </React.Fragment>
                            }
                                rows={2}
                                label="My progress has been"
                                value={values.review_progress}
                                onChange={e => updateField('review_progress', e.target.value)}
                                error={Boolean(errors.review_progress)}
                                helperText={
                                  errors.review_progress ||
                                  'If you gave yourself a score below 5, you probably need some more strategies to increase your likelihood of achieving this goal. If your score is higher than 5, this is a sign that you are on the right track.'
                                }
                              />
                            </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions className={classes.actions}>
                        <Button variant="contained" type="submit" color="primary">
                          Update
                        </Button>
                      </CardActions>
                    </Card>
                  )
                }
              }
            </Form>
          </div>
        ) : <React.Fragment/>
      }




    </React.Fragment>
  );
}

/**
 * Field
 */
type FieldProps = Omit<TextFieldProps, 'variant'> & {
  title: string | object;
  desc: string | object;
};

const Field = (props: FieldProps) => {
  const { title, desc, helperText, ...fieldProps } = props;
  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        {props.title}
      </Typography>

      <Typography variant="body1" color="textSecondary" gutterBottom>
        {desc}
      </Typography>

  <TextField variant="outlined" multiline fullWidth margin="normal" helperText={<span style={{fontSize:'1rem'}}>{helperText}</span>} {...fieldProps} />
    </div>
  );
};
