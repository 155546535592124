import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will discuss ways to optimise my fertility with my GP.';

export default function Fertility() {
  return (
    <ArticleContent>
      <Quiz
        question="Most women have about 6 days each month when they can fall pregnant."
        answer="True"
        feedback="This is true. Your most fertile days are the two days before, plus the day of, ovulation. Having sex during this window gives you the best chance of becoming pregnant."
      />

      <h4>Key points</h4>
      <ul>
        <li>There are 5 key factors that can alter your fertility</li>
        <li>There is only a three day fertility window in your monthly cycle in which you can get pregnant</li>
        <li>
          Your partner’s health and lifestyle is important to his and your fertility and to the health of your child
        </li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>Fertility is influenced by five key factors including:</p>
      <ol>
        <li>the time during your cycle that you have sex</li>
        <li>the age of both parents-to-be</li>
        <li>general health of both parents-to-be</li>
        <li>weight of both parents-to-be</li>
        <li>the lifestyle of both parents-to-be</li>
      </ol>
      <p>
        Research shows that men's and women's health, even before conception, has a big influence on fertility and on
        the health of the child at birth and throughout its lifetime.
      </p>
      <p>
        Factors including poor diet, being very overweight, smoking, exposure to some chemicals, using drugs and alcohol
        can affect your ability to conceive and negatively impact the child's long term health.
      </p>

      <h4>What can I do?</h4>
      <p>
        Both you and your partner can improve your fertility and overall health by limiting alcohol, quitting smoking or
        illegal drug use, making healthy food choices, and reducing stress. If overweight and/or not physically active
        start to work on these areas.
      </p>

      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information about fertility visit:</p>
      <ul>
        <li>
          <a
            href="https://www.yourfertility.org.au/healthy-you-healthy-baby-0"
            target="_blank"
            rel="noopener noreferrer">
            Your Fertility
          </a>
        </li>
        <li>
          <a
            href="https://www.yourfertility.org.au/general-resources/interactive-tools/healthy-conception-tool"
            target="_blank"
            rel="noopener noreferrer">
            Try the healthy conception tool
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
