import React from "react";
import { Hidden, Drawer } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: (props: NavProps) => ({
      [theme.breakpoints.up("lg")]: {
        width: props.width,
        flexShrink: 0,
      },
    }),
    drawerPaper: (props: NavProps) => ({
      width: props.width,
    }),
  })
);

export type NavProps = {
  responsive: boolean;
  width: number;
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

export default function Nav(props: NavProps) {
  const classes = useStyles(props);
  if (!props.responsive) {
    return (
      <Drawer
        variant="temporary"
        classes={{ paper: classes.drawerPaper }}
        open={props.open}
        onClose={props.onClose}
        anchor="left"
        ModalProps={{ keepMounted: true }}
      >
        {props.children}
      </Drawer>
    );
  }

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp implementation="css">
        <Drawer
          variant="temporary"
          classes={{ paper: classes.drawerPaper }}
          open={props.open}
          onClose={props.onClose}
          anchor="left"
          ModalProps={{ keepMounted: true }}
        >
          {props.children}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{ paper: classes.drawerPaper }}
          open
        >
          {props.children}
        </Drawer>
      </Hidden>
    </nav>
  );
}
