import { configureStore, getDefaultMiddleware, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import rootReducer from './rootReducer';

import {} from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const selectAuth = (state: RootState) => state.auth;

export const selectProfile = (state: RootState) => state.profile;

export const selectRegisters = (state: RootState) => state.registers;

export const selectUsers = (state: RootState) => state.users;

export const selectProfiles = (state: RootState) => state.profiles;
