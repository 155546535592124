import React from 'react';
import { styled, StyledProps, Backdrop, CircularProgress, Box } from '@material-ui/core';
import { ActionStatus } from 'app/helper';

export default styled((props: { status: ActionStatus | null } & StyledProps) => {
  const { className, status } = props;
  return (
    <Backdrop className={className} open={status?.type === 'request'} invisible>
      <Box p={1} borderRadius={5} bgcolor="rgba(0,0,0,.3)">
        <CircularProgress color="inherit" />
      </Box>
    </Backdrop>
  );
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));
