import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { AppPage } from 'components/page';
import Button from 'components/ActionStatusButton';
import { Container, Typography, Box, Paper } from '@material-ui/core';
import ProfileForm, { ProfileData } from './ProfileForm';

import { initProfile } from './profileSlice';
import { Profile } from 'app/types';
import { selectProfile } from 'app/store';

const initialData: ProfileData = {
  dob: null,
  weight: 60,
  height: 170,
  pregStatus: 'NO',
  dueDate: null,
  weightLogs: [],
  contactPreference: [],
  phone: null,
};

export default function InitProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { status } = useSelector(selectProfile);

  const handleSubmit = (data: ProfileData) => {
    const initialProfile = {
      ...data,
      dob: moment(data.dob).format('DD/MM/YYYY'),
      dueDate: data.dueDate ? moment(data.dueDate).format('DD/MM/YYYY') : null,
    } as Profile;

    dispatch(initProfile(initialProfile, () => history.push('/dashboard')));
  };

  return (
    <AppPage title="My Profile">
      <Container maxWidth="md">
        <Box my={2}>
          <Typography variant="h4" gutterBottom>
            Welcome to OptimalMe
          </Typography>
          <Typography variant="body1" paragraph>
            We look forward to guiding you along your journey before, during and after pregnancy.
          </Typography>
        </Box>
        <Paper>
          <Box p={2}>
            <Typography paragraph>Before you get started, please update your information.</Typography>
            <Typography paragraph>
              Once you enter the program you will be asked to answer some questions. These will help us to get to know
              you and tailor information to your health and wellbeing needs.
            </Typography>
            <Typography paragraph>
              Your tailored information will be used to select health goals that are important to you and we will help
              you to develop strategies to achieve them.
            </Typography>
            <Typography paragraph>
              Your personalised dashboard tracks your progress and summarises your activity and you can return there at
              any time.
            </Typography>
            <Typography paragraph>
              You will be contacted by your health coach at a convenient time who will assist in your journey to an
              optimal you.
            </Typography>
            <Typography paragraph>We hope you enjoy your experience!</Typography>
          </Box>
        </Paper>

        <ProfileForm data={initialData} onSubmit={handleSubmit}>
          <Box my={4} textAlign="center">
            <Button status={status} type="submit" variant="contained" color="primary">
              Create Profile
            </Button>
          </Box>
        </ProfileForm>
      </Container>
    </AppPage>
  );
}
