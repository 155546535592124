import React, { useState } from 'react';
import clsx from 'clsx';
import map from 'lodash/map';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  Collapse,
  Typography,
  Tooltip,
  Button,
  Hidden,
  Menu,
  MenuItem,
  Divider,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import TopicMenu from './TopicMenu';
import { useHistory } from 'react-router-dom';
import UpdateIcon from '@material-ui/icons/Update';
import useTopicState from 'app/topics/useTopicState';
import { ModuleId, Profile } from 'app/types';
import topicModules from 'app/topics/topicModules';
import get from 'lodash/get';
import { GoalSettingData } from 'components/topics/GoalSetting/GoalSetting';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginRight: theme.spacing(2),
    },
    content: {
      backgroundColor: '#EEE',
      borderRadius: 6,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
);

const goalSettingTitles: { [key: string]: string } = {
  why: 'Why is this important to you?',
  goal: 'Set your goal',
  obstacles: 'What are obstacles preventing you to improve?',
  solutions: 'What are your solutions?',
  strategies: 'What are your strategies?',
  aim: 'You aim to...',
  review_progress: 'Review the progress.',
};

export type GoalId = [ModuleId, string]; // topicId, goalId

export type GoalItem = {
  id: GoalId;
  title: string;
  data: object;
};

export type MyGoalsProps = {
  profile: Profile;
};

export default function MyGoals(props: MyGoalsProps) {
  const { profile } = props;
  const [filter, setFilter] = useState<ModuleId | null>(null);

  const items = profile.topicStates.items
    .filter(topicState => Boolean(!filter || filter === topicState.mod))
    .reduce<GoalItem[]>((goalItems, topicState) => {
      const topicModule = topicModules[topicState.mod];
      const entries = Object.entries(topicState.goalSettings || {});
      return [
        ...goalItems,
        ...entries.map<GoalItem>(([goalId, goalData]) => ({
          id: [topicState.mod, goalId] as GoalId,
          title: topicModule.goals[goalId],
          data: goalData,
        })),
      ];
    }, []);

  return (
    <Card>
      <CardHeader
        title="My Goals"
        subheader="This is an important part of the OptimalMe program. Setting smart goals and strategies can help you improve areas of your health and lifestyle. You can select areas to improve and set goals in the My Goals section of this program.
You can set, reset and adjust goals as many times as you like."
        action={<TopicMenu value={filter} onChange={value => setFilter(value)} />}
      />
      <CardContent>
        <List>
          {items.map((item, index) => (
            <GoalItem key={index} item={item} />
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

type GoalItemProps = {
  item: GoalItem;
};

const GoalItem = (props: GoalItemProps) => {
  const { item } = props;
  const classes = useStyles();
  const history = useHistory();
  const { topicState, updateTopicState } = useTopicState(item.id[0]);
  const [active, setActive] = useState<string | null>(null);
  const key = item.id.join('_');
  const expanded = key === active;

  const toggleActive = (id: string) => () => {
    setActive(id === active ? null : id);
  };

  const setGoalAsComplete = (data: GoalSettingData, goalId: string): void => {
    const goalIsSet =
      Boolean(data.why) &&
      Boolean(data.goal) &&
      Boolean(data.obstacles) &&
      Boolean(data.strategies) &&
      Boolean(data.aim);
    if (!goalIsSet) return;
    let goalData = {
      why: data.why,
      goal: data.goal,
      obstacles: data.obstacles,
      strategies: data.strategies,
      aim: data.aim,
      completed: true,
      review_progress: data.review_progress || '',
    } as GoalSettingData;
    updateTopicState({ goalSettings: { ...topicState.goalSettings, [goalId]: goalData } });
  };

  let data = get(item, ['data'], {
    why: '',
    goal: '',
    obstacles: '',
    strategies: '',
    aim: '',
    completed: null,
    review_progress: null,
  }) as GoalSettingData;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = (event: any) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment key={key}>
      <ListItem button onClick={toggleActive(key)}>
        <ListItemText>
          <Chip className={classes.chip} label={item.id[0]} size="small" variant="outlined" color="primary" />
          {item.title}
        </ListItemText>
        <ListItemSecondaryAction>
          <Hidden only="xs">
            <Tooltip title="Edit goal">
              <IconButton onClick={() => history.push(`/topics/${item.id[0]}/goals/fill/${item.id[1]}`)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Review my goal">
              <IconButton onClick={() => history.push(`/topics/${item.id[0]}/goals/fill/${item.id[1]}`)}>
                <UpdateIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={!data.completed ? 'Set this goal as complete' : 'This goal has been completed'}>
              {!data.completed ? (
                <Button onClick={() => setGoalAsComplete(data, item.id[1])}>Set as Complete</Button>
              ) : (
                <Button>Completed</Button>
              )}
            </Tooltip>
          </Hidden>
          <Hidden smUp>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={openMenu}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              id={`menu-${item.id[1]}`}
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMenu}>
              <MenuItem onClick={() => history.push(`/topics/${item.id[0]}/goals/fill/${item.id[1]}`)}>
                Edit my goal
              </MenuItem>
              <MenuItem onClick={() => history.push(`/topics/${item.id[0]}/goals/fill/${item.id[1]}`)}>
                Review my progress
              </MenuItem>
              {!data.completed ? (
                <MenuItem onClick={() => setGoalAsComplete(data, item.id[1])}>Set as completed</MenuItem>
              ) : (
                <MenuItem onClick={closeMenu}>Completed</MenuItem>
              )}
            </Menu>
          </Hidden>
          <IconButton className={clsx(classes.expand, { [classes.expandOpen]: expanded })} onClick={toggleActive(key)}>
            <ExpandMoreIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.content}>
          {map(item.data, (content, key) => {
            if (key === 'completed') return <React.Fragment key={key} />;
            return (
              <React.Fragment key={key}>
                <Typography variant="h6">{goalSettingTitles[key]}</Typography>
                <Typography paragraph>{content || '-'}</Typography>
              </React.Fragment>
            );
          })}
        </CardContent>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
};
