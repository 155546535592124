import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function FoodIntakeAndExercise() {
  return (
    <ArticleContent>
      <h4>How long do you need to walk to burn off your food?</h4>
      <p>
        Below you can see how long you need to walk briskly to burn off certain foods or ingredients. This makes it
        clear how quickly calories/energy intake can build up across the day or when we indulge in some fast food.
      </p>
      <p>
        View our <a href="/topics/preconception/articles/better_choices_intake">Swaps and Tips for Better Choices</a> resource
        for some strategies to reduce your energy intake.
        Remember to avoid energy-dense-nutrient-poor foods and beverages, these are items that are high in calories but
        that don’t provide you with beneficial nutrients to promote good health.
      </p>
      <figure>
        <img src="images/articles/preconception/resources/intake/chocolate.png" alt="timtams" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/intake/chips.png" alt="pasta" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/intake/pizza.png" alt="big plates" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/intake/carrot.png" alt="water" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/intake/fries.png" alt="skipping" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/intake/tofu.png" alt="eating" />
      </figure>
    </ArticleContent>
  );
}
