import React from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import StepsForm from 'components/stepsForm';
import { essentials } from '../content';
import about from './about';
import demographics from './demographic';
import health from './health';

import { CheckListItem } from 'app/types';
import { pickKeys } from 'app/helper';
import useTopicState from 'app/topics/useTopicState';

type ScreeningData = {
  a1_weight: number;
  a2_height: number;
  a3_freq: string | null;
  d1_country: string | null;
  d2_ethnicity: string | null;
  d3_schooling: string | null;
  d5_post_income: string | null;
  d6_working: string | null;
  d7_marital_status: string | null;
  d8_children: string | null;
  h1_chronic: string | string[] | null;
  h2_reproductive_history: string | string[] | null;
  h3_smoke: string | null;
  h4_alcohol: string | null;
  h5_drugs: string | null;
  h6_vaccines: string | string[] | null;
  h7_chickenpox: string | null;
  h8_influenza: string | null;
  h9_cervical: string | null;
  h10_genetics: string | null;
  h11_supplements: string | null;
  h12_medication: string | string[] | null;
  h13_contraception: string | null;
};

const initialData: ScreeningData = {
  a1_weight: 0,
  a2_height: 0,
  a3_freq: null,
  d1_country: null,
  d2_ethnicity: null,
  d3_schooling: null,
  d5_post_income: null,
  d6_working: null,
  d7_marital_status: null,
  d8_children: null,
  h1_chronic: null,
  h2_reproductive_history: null,
  h3_smoke: null,
  h4_alcohol: null,
  h5_drugs: null,
  h6_vaccines: null,
  h7_chickenpox: null,
  h8_influenza: null,
  h9_cervical: null,
  h10_genetics: null,
  h11_supplements: null,
  h12_medication: null,
  h13_contraception: null,
};

export const steps = [about, demographics, health];

export default function Screening() {
  const history = useHistory();
  const { topicState, updateTopicState } = useTopicState('preconception');
  const [step, setStep] = React.useState(0);
  const anchor = React.useRef(null);

  React.useEffect(() => {
    if (step > 0 && anchor.current) {
      const elm: HTMLElement = anchor.current!;
      elm.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [step, anchor]);

  const handleSubmit = (screeningData: ScreeningData) => {
    // build plan
    const defaultArticleIds = ['healthy_relationships', 'emotional_wellbeing', 'fertility', 'vaccination', 'genetic'];
    const plan = pickKeys<ScreeningData>(defaultArticleIds, pick => {
      pick('chronic_conditions', (data: ScreeningData) => !isEmpty(data['h1_chronic']));
      pick('reproductive_history', (data: ScreeningData) => !isEmpty(data['h2_reproductive_history']));
      pick(
        'reproductive_history',
        (data: ScreeningData) =>
          !!['h3_smoke', 'h4_alcohol', 'h5_drugs'].find(id => data[id as keyof ScreeningData] !== 'No'),
      );
      pick('vaccination', (data: ScreeningData) => {
        return isEmpty(data['h6_vaccines']) || data['h6_vaccines'] === 'No' || data['h8_influenza'] === 'Yes';
      });
      pick('cervical_screening', (data: ScreeningData) => {
        return data['h9_cervical'] === 'No';
      });
      pick('pcos', (data: ScreeningData) => {
        // data['h1_chronic'] is a multiple choice
        return (data['h1_chronic'] || []).indexOf('Polycystic ovary syndrome') >= 0;
      });
      pick('genetic', (data: ScreeningData) => {
        return data['h10_genetics'] !== 'No';
      });
      pick('supplements', (data: ScreeningData) => {
        return data['h11_supplements'] !== 'Both Folic Acid and Iodine';
      });
      pick('medication', (data: ScreeningData) => {
        return !isEmpty(data['h12_medication']);
      });
      pick('contraception', (data: ScreeningData) => {
        return data['h13_contraception'] === 'No';
      });
    })(screeningData);

    // build checklist
    const articleIds = new Set(plan);
    const checklist = essentials
      .filter(article => articleIds.has(article.id))
      .map<CheckListItem>(article => {
        return { articleId: article.id, description: article.action!, completed: false };
      });

    // update topic state
    updateTopicState(
      {
        screening: screeningData,
        plan,
        checklist,
      },
      () => {
        history.push('/topics/preconception/essentials');
      },
    );
  };

  return (
    <>
      <i ref={anchor} style={{ position: 'relative', display: 'block', top: '-100px' }} />
      <Stepper activeStep={step} style={{ marginBottom: 10 }}>
        {['About', 'Demographic', 'Health'].map((label, i) => (
          <Step key={i}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <StepsForm<ScreeningData>
        initialData={(topicState.screening as ScreeningData) || initialData}
        steps={steps}
        onSubmit={handleSubmit}
        onStepChanged={step => setStep(step)}
      />
    </>
  );
}
