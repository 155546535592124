import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RegisterStatus } from 'API';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Divider,
  Typography,
  Box,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Toolbar, SearchField } from 'components/toolbars';
import AttrList, { Attr } from 'components/AttrList';
import Alert from 'components/Alert';
import ActionStatusProgress from 'components/ActionStatusProgress';
import AdminPage from '../AdminPage';
import FilterMenu from './FilteMenu';
import { fetchRegisters, rejectRegister, approveRegister, deleteRegister, actions } from './registerSlice';
import { RegisterData } from 'app/admin/types';
import { selectRegisters } from 'app/store';

const yesNo = (value: boolean) => (value ? 'Yes' : 'No');

const attrs: Attr<RegisterData>[] = [
  {
    attr: 'dob',
    label: 'Date of birth',
  },
  {
    attr: 'postcode',
    label: 'Postcode',
  },
  {
    attr: 'email',
    label: 'Email',
  },
  {
    attr: 'medibank_number',
    label: 'Medibank Number',
  },
  {
    attr: 'consent',
    label: 'Consent to participate',
    format: yesNo,
  },
  {
    attr: 'consent_read',
    label: 'Read consent form',
    format: yesNo,
  },
  {
    attr: 'consent_contact',
    label: 'Agree to be contacted',
    format: yesNo,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expanded: {
      backgroundColor: theme.palette.grey[100],
    },
    content: {
      alignItems: 'center',
      '& > *': {
        flex: 0.5,
      },
      '& > *:not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },
    actions: {
      display: 'flex',
    },
  }),
);

export default function Registers() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { query, items, cursor, status } = useSelector(selectRegisters);

  React.useEffect(() => {
    dispatch(fetchRegisters({ email: null, nextToken: null, status: null }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => dispatch(fetchRegisters({ ...query, nextToken: cursor }));

  const handleSearch = (email: string) => dispatch(fetchRegisters({ ...query, email, nextToken: null }));

  const handleFilterChanged = (value: RegisterStatus | null) => {
    dispatch(fetchRegisters({ ...query, status: value, nextToken: null }));
  };

  const handleApprove = (register: RegisterData) => () => {
    dispatch(approveRegister(register));
  };

  const handleReject = (email: string) => () => {
    dispatch(rejectRegister(email));
  };

  const handleDelete = (email: string) => () => {
    dispatch(deleteRegister(email));
  };

  return (
    <AdminPage title="Registers">
      <Alert alertText={status?.error} onClose={() => dispatch(actions.idle())} variant="filled" severity="error" />

      <ActionStatusProgress status={status} />

      <Toolbar>
        <Box flex={1}>
          <FilterMenu value={query.status} onChange={handleFilterChanged} />
        </Box>
        <SearchField value={query.email} onSearch={handleSearch} />
      </Toolbar>

      {items &&
        items.map(register => (
          <ExpansionPanel key={register.email}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{ content: classes.content, expanded: classes.expanded }}>
              <div>
                <Typography variant="h6">{register.name}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {register.email}
                </Typography>
              </div>
              <Typography variant="body2">{register.status}</Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <AttrList<RegisterData> data={register} attrs={attrs} />
            </ExpansionPanelDetails>
            <Divider />
            <ExpansionPanelActions>
              {register.status === 'PENDING' ? (
                <React.Fragment>
                  <Button onClick={handleReject(register.email)}>Reject</Button>
                  <Button color="primary" onClick={handleApprove(register)}>
                    Approve
                  </Button>
                </React.Fragment>
              ) : (
                <Button color="secondary" onClick={handleDelete(register.email)}>
                  Delete
                </Button>
              )}
            </ExpansionPanelActions>
          </ExpansionPanel>
        ))}

      {cursor && (
        <Box my={4}>
          <Button onClick={() => handleLoadMore()}>Load More</Button>
        </Box>
      )}
    </AdminPage>
  );
}

export type AttrList<T> = {
  attr: keyof T;
  label: string;
  format?: (value: string) => string;
};
