import { TopicModule } from 'app/topics/types';
import * as factory from 'components/topics/factory';
import LifestyleQuestions from 'components/topics/LifestyleQuestions';
import Screening from './Screening';
import { EssentialsPage, GoalsPage, GoalsPageWithArticles, ShapingMyLifeStylePage } from './layout';
import * as content from './content';
import goals from './goals';
import kpiPreQuestions, { KpiPreData } from './kpiPreQuestions';
import evaluationGroups, { EvaluationData } from './evaluationQuestions';

const essentialsBuilder = factory.essentials(EssentialsPage);
const goalsBuilder = factory.goals(GoalsPage, goals);
const goalsBuilderWithArticles = factory.goals(GoalsPageWithArticles, goals);
const smlBuilded = factory.shapingMyLifestyle(ShapingMyLifeStylePage); // Fix #141

export default {
  content,
  goals,
  KpiPre: factory.createKpiDialog<KpiPreData>('Survey Questions', kpiPreQuestions),
  Evaluation: factory.createEvaluation<EvaluationData>('Program Evaluation', evaluationGroups),
  Lifestyle: goalsBuilder.buildContent(LifestyleQuestions),
  Screening: essentialsBuilder.buildContent(Screening),
  Essentials: essentialsBuilder.buildArticles(content.essentials),
  Goals: goalsBuilderWithArticles.buildGoals(),
  GoalSelect: goalsBuilderWithArticles.buildGoalSelect(),
  GoalSetting: goalsBuilder.buildGoalSetting(),
  ShapingMyLifestyle: smlBuilded.build(), // Fix #141
} as TopicModule;
