import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Container, Grid } from '@material-ui/core';
import { withStyles, makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2em 0',
      backgroundColor: theme.palette.background.paper,
      width: '100%',
    },
    menu: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
      '& > li': {
        fontSize: '0.9rem',
        lineHeight: 2,
        '& > a': {
          color: theme.palette.grey[700],
          textDecoration: 'none',
          '&:hover': {
            opacity: 0.5,
          },
        },
      },
    },
  }),
);

export default function GlobalFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item xs={12} md={3}>
            <ul className={classes.menu}>
              <li>
                <Typography variant="subtitle1" gutterBottom>
                  More Information
                </Typography>
              </li>
              <li>
                <Link to="/program">The Program</Link>
              </li>
              <li>
                <Link to="/about/">About Us</Link>
              </li>
              <li>
                <a href="mailto:mchri-optimalme@monash.edu">Contact Us</a>
              </li>
              <li>
                <Link to="/terms">Terms &amp; Conditions</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={3}>
            <ImageLink src="images/fl_mb.png" href="https://www.medibank.com.au/" alt="Medibank" />
          </Grid>
          <Grid item xs={12} md={3}>
            <ImageLink src="images/fl_mu.png" href="https://www.monash.edu/" alt="Monash University" />
          </Grid>
          <Grid item xs={12} md={3}>
            <ImageLink src="images/fl_mchri.png" href="https://www.monash.edu/medicine/sphpm/mchri" alt="MCHRI" />
          </Grid>
        </Grid>

        <Box textAlign="center">
          <Typography variant="caption">&copy; Monash University 2020</Typography>
        </Box>
      </Container>
    </div>
  );
}

type ImageLinkProps = {
  href: string;
  src: string;
  alt: string;
  classes: any;
};

const ImageLink = withStyles({
  root: {
    margin: '0.5rem 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > a img': {
      width: 180,
    },
  },
})((props: ImageLinkProps) => {
  const { href, src, alt, classes } = props;
  return (
    <section className={classes.root}>
      <a href={href}>
        <img src={src} alt={alt} />
      </a>
    </section>
  );
});
