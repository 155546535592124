import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Goals from './Goals';
import GoalSelect from './GoalSelect';
import GoalSetting from './GoalSetting';
import Articles from './Articles';
import EvaluationPage from './EvaluationPage';
import KpiDialog from './KpiDialog';
import PreEnterDialog from './PreEnterDialog';

import { SurveyQuestions } from 'components/surveyForm';
import { ModuleId } from 'app/types';
import { GoalOptions, Content, EssentialsCompProps, ModuleDialogProps } from 'app/topics/types';

type LayoutComponent = React.ComponentType<React.HTMLProps<HTMLElement>>;

export function createWrapper<T = any>(Layout: LayoutComponent, Comp: React.ComponentType<T>): React.ComponentType<T> {
  return (props: T) => (
    <Layout>
      <Comp {...props} />
    </Layout>
  );
}

export function createPreEnterDialog<T extends Record<string, any>>(title: string, questions: SurveyQuestions<T>) {
  return function(props: ModuleDialogProps) {
    const { open, data, updateData } = props;
    return (
      <PreEnterDialog
        title={title}
        open={open}
        questions={questions}
        data={data as T}
        onSubmit={(data: Partial<T>) => {
          updateData(data);
        }}
      />
    );
  };
}

export function createKpiDialog<T extends Record<string, any>>(title: string, questions: SurveyQuestions<T>) {
  return function(props: ModuleDialogProps) {
    const { open, data, updateData } = props;
    return (
      <KpiDialog
        open={open}
        questions={questions}
        title={title}
        data={data as T}
        onSubmit={(data: Partial<T>) => {
          updateData(data);
        }}
      />
    );
  };
}

export function createEvaluation<T extends Record<string, any>>(title: string, questions: SurveyQuestions<T>) {
  return () => {
    return <EvaluationPage questions={questions} title={title} />;
  };
}

export function goals(Layout: LayoutComponent, goalOptions: GoalOptions) {
  return {
    buildContent<T = any>(Content: React.ComponentType<T>): React.ComponentType<T> {
      return (props: T) => (
        <Layout>
          <Content {...props} />
        </Layout>
      );
    },

    buildGoals() {
      return (props: RouteComponentProps<{ moduleId: ModuleId }>) => {
        const moduleId = props.match.params.moduleId;
        return (
          <Layout>
            <Goals moduleId={moduleId} goalOptions={goalOptions} />
          </Layout>
        );
      };
    },

    buildGoalSelect() {
      return (props: RouteComponentProps<{ moduleId: ModuleId }>) => {
        const moduleId = props.match.params.moduleId;
        return (
          <Layout>
            <GoalSelect moduleId={moduleId} goalOptions={goalOptions} />
          </Layout>
        );
      };
    },

    buildGoalSetting() {
      return (props: RouteComponentProps<{ moduleId: ModuleId; goalId: string }>) => {
        const { moduleId, goalId } = props.match.params;
        return (
          <Layout>
            <GoalSetting moduleId={moduleId} goalId={goalId} />
          </Layout>
        );
      };
    },
  };
}

export function essentials(Layout: LayoutComponent) {
  return {
    buildContent<T = any>(Content: React.ComponentType<T>): React.ComponentType<T> {
      return (props: T) => (
        <Layout>
          <Content {...props} />
        </Layout>
      );
    },

    buildArticles(articles: Content[]): React.ComponentType<EssentialsCompProps> {
      return (props: EssentialsCompProps) => {
        const {
          match: {
            params: { moduleId },
          },
          topicSummary,
        } = props;
        return (
          <Layout>
            <Articles moduleId={moduleId} articles={articles} topicSummary={topicSummary} />
          </Layout>
        );
      };
    },
  };
}

//  Fix #141
export function shapingMyLifestyle(Layout : LayoutComponent){
  return {
    build<T=any>(){
      return (props : T) => <Layout/>
    }
  }
}
