import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      borderRadius: 8,
      margin: theme.spacing(1, 0),
    },
    selected: {
      color: theme.palette.primary.main,
    },
    text: {
      marginLeft: theme.spacing(1),
    },
    icon: (props: { selected: boolean }) => ({
      color: props.selected ? 'inherit' : theme.palette.grey[600],
      minWidth: 28,
    }),
  }),
);

export type LinkItemProps = {
  path: string;
  exact?: boolean;
  icon?: React.ReactElement;
  primary: string;
  secondary?: string;
} & Pick<ListItemProps, 'dense'>;

export default function LinkItem(props: LinkItemProps) {
  const { path, exact, icon, primary, secondary, ...itemProps } = props;
  const history = useHistory();
  const match = useRouteMatch({ path: path, exact: Boolean(exact) });
  const selected = match !== null;
  const classes = useStyles({ selected });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    history.push(path);
  };

  return (
    <ListItem
      classes={{ root: classes.root, selected: classes.selected }}
      href={path}
      onClick={handleClick}
      selected={selected}
      button
      {...itemProps}>
      {icon && (
        <ListItemIcon color="inherit" className={classes.icon}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText className={classes.text} primary={primary} secondary={secondary} />
    </ListItem>
  );
}
