import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will discuss with my GP the medications/supplements I am taking.';

export default function Medication() {
  return (
    <ArticleContent>
      <Quiz
        question="Medications sold over-the-counter are fine to take when pregnant."
        answer="False"
        feedback="This is false. It is important to discuss any medications with a trained professional when pregnant."
      />

      <h4>Key points</h4>
      <ul>
        <li>Stopping medicines you need during pregnancy can be harmful</li>
        <li>Talk with your doctor to review any current medications you are on in the lead up to your pregnancy</li>
        <li>Check with a pharmacist about over-the-counter medications that are safe to take when pregnant</li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        Some medicines are not safe to be taken during pregnancy. At the same time, stopping medicines you need can also
        be harmful. You should always consult your doctor prior to ceasing medications.
      </p>
      <p>Where necessary medications may be substituted for a safer option or dosages may be adjusted.</p>
      <p>
        Some over-the-counter medications are considered safe such as pain and cold and flu tablets prior to pregnancy,
        however safety may change with pregnancy. Talk with the Pharmacist before taking medications when pregnant.
      </p>
      <p>
        Your partner should also talk to his doctor about his own health, his family health history, and any medicines
        he uses.
      </p>

      <h4>What can I do?</h4>
      <p>I will discuss with my GP the medications/supplements I am taking.</p>

      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information visit:</p>

      <ul>
        <li>
          <a
            href="https://www.yourfertility.org.au/everyone/lifestyle/your-fertility-medications-and-drugs"
            target="_blank"
            rel="noopener noreferrer">
            Your Fertility
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-medication-drugs-and-alcohol"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
