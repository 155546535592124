import React from 'react';
import { TextField, Grid, Switch, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DietValue } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    textInput: {
      marginRight: theme.spacing(4),
    },
  }),
);

export type ServesInputProps = {
  value: DietValue;
  onChange: (value: DietValue) => void;
};

export default function ServesInput(props: ServesInputProps) {
  const { value, onChange } = props;
  const classes = useStyles();

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const n = parseInt(e.target.value);
    if (!isNaN(n)) {
      onChange([n, value[1]]);
    }
  };

  const handleSwitchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange([value[0], e.target.checked ? 'weekly' : 'daily']);
  };

  return (
    <div className={classes.root}>
      <TextField
        className={classes.textInput}
        variant="outlined"
        type="number"
        value={value[0]}
        margin="dense"
        label="No. of serves"
        onChange={handleTextInput}
      />
      <Grid container component="label" alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="body2">Daily</Typography>
        </Grid>
        <Grid item>
          <Switch color="primary" onChange={handleSwitchInput} checked={value[1] === 'weekly'} />
        </Grid>
        <Grid item>
          <Typography variant="body2">Weekly</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
