import React from 'react';
import { useHistory } from 'react-router-dom';
import { Parallax, ParallaxLayer, interpolation } from 'components/parallax';
import { HomePage, GlobalFooter } from 'components/page';
import Video from 'components/Video';
import { Button, Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { createStyles, makeStyles, Theme, styled } from '@material-ui/core/styles';

type ScreenProps = React.HTMLAttributes<HTMLDivElement> & {
  maskOpacity?: number;
  boxShadow?: string;
  background?: string;
  backgroundImage?: string;
  backgroundPosition?: string;
};

const Screen = styled(props => {
  const { className, maskOpacity } = props;
  return (
    <section className={className}>
      {maskOpacity && <div className="mask" style={{ opacity: maskOpacity }} />}
      {props.children}
    </section>
  );
})((props: any) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  boxShadow: props.boxShadow,
  background: props.background,
  backgroundImage: props.backgroundImage,
  backgroundPosition: props.backgroundPosition,
  '& > .mask': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000',
  },
})) as React.ComponentType<ScreenProps>;

// @ts-ignore
const Heading = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: theme.typography.fontWeightBold,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: 32,
  },
}));

const Text = styled('p')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightLight,
  // letterSpacing: `${-0.5/60}rem`,
  lineHeight: 1.2,
  textAlign: 'center',
  fontSize: 28,
  margin: theme.spacing(2, 0),
  '& > strong': {
    fontWeight: 500,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    lineHeight: 1.4,
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      color: 'white',
      '& .logo': {
        marginBottom: theme.spacing(2),
        display: 'block',
        width: 350,
        [theme.breakpoints.down('sm')]: {
          width: '70%',
        },
      },
    },
  }),
);

export default function LandingPage() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <HomePage>
      <Parallax pages={8} extra={<GlobalFooter />}>
        {/* OptimalMe */}
        <ParallaxLayer offset={0} zIndex={100}>
          <Screen backgroundImage="radial-gradient(circle, rgba(141,150,105,1) 0%, rgba(70,75,47,1) 98%)">
            <Container className={classes.container} fixed>
              <img className="logo" src="images/home/logo_xl.png" alt="logo" />
              <Text>
                <strong>OptimalMe</strong> is a highly interactive and personalised program that will guide you through
                one of the most exciting and important times in your life - before, during and after pregnancy.
              </Text>
              <Text>
                It is a tested and proven program developed with women, for women. It has helped thousands of women
                improve their knowledge, confidence and motivation to achieve positive lifestyle changes.
              </Text>
            </Container>
          </Screen>
        </ParallaxLayer>

        {/* Journey */}
        <ParallaxLayer
          offset={1}
          zIndex={90}
          speed={-0.6}
          render={pos => (
            <Screen
              backgroundImage="url(images/home/journey_bg.jpg)"
              backgroundPosition="50% 50%"
              maskOpacity={interpolation([
                [-1, 1],
                [-0.5, 0.7],
                [0, 0],
              ])(pos)}
            />
          )}
        />

        <ParallaxLayer offset={1} zIndex={91}>
          <Container className={classes.container} fixed>
            <Heading> Pregnancy is a journey</Heading>
            <Text>
              <strong>OptimalMe</strong> will support you on this journey.
            </Text>
            <Text>
              It is based on the best available evidence, providing trustworthy and accurate information to enhance
              knowledge as well as strategies and tips to guide you towards optimising your health during this time.
            </Text>
          </Container>
        </ParallaxLayer>

        {/* Change */}
        <ParallaxLayer
          offset={2}
          speed={-0.6}
          zIndex={80}
          render={pos => (
            <Screen
              backgroundImage="url(images/home/change_bg.jpg)"
              backgroundPosition="50% 50%"
              maskOpacity={interpolation([
                [-1, 1],
                [-0.5, 0.7],
                [0, 0],
              ])(pos)}
            />
          )}
        />
        <ParallaxLayer offset={2} zIndex={81}>
          <Container className={classes.container} fixed>
            <Heading> Small changes make a big difference</Heading>
            <Text>
              <strong>OptimalMe</strong> builds your self-management skills to make small and sustainable healthy
              lifestyle changes.
            </Text>
            <Text>
              Your own dedicated health coach supports you along the way, providing guidance to assist you in building
              skills.
            </Text>
            <Text>Ongoing SMS tips and reminders help to consolidate knowledge and confidence.</Text>
          </Container>
        </ParallaxLayer>

        {/* IntroCH */}
        <ParallaxLayer offset={3} speed={-0.6} zIndex={70}>
          <Screen backgroundImage="linear-gradient(to bottom, #AEBC95, #464B2F)">
            <Container className={classes.container} fixed>
              <Box width={['90%', '80%', 600]}>
                <Video id="Nt3lGcJv4Dg" />
              </Box>
              <Heading>An introduction from Dr Cheryce Harrison</Heading>
              <Text>
                <i>
                  "You will determine your own goals and develop personalised strategies to achieve your optimal
                  health."
                </i>
              </Text>
            </Container>
          </Screen>
        </ParallaxLayer>

        {/* JourneyStart */}
        <ParallaxLayer offset={4} zIndex={80}>
          <Screen
            backgroundImage="url(images/home/journey_start_bg.jpg)"
            backgroundPosition="50% top"
            maskOpacity={0.5}
          />
        </ParallaxLayer>
        <ParallaxLayer offset={4} zIndex={81} speed={0.5}>
          <Container className={classes.container} fixed>
            <Heading>Your journey starts now</Heading>
            <Text>
              <strong>OptimalMe</strong> includes three sections that follow you through your journey. The first begins
              before pregnancy, the second during pregnancy and the third following the birth of your baby.
            </Text>
            <Text>
              Each section is tailored to your specific information needs during these stages. Additionally, you will be
              provided with continued support to improve your health and lifestyle habits before and after the birth of
              your baby.
            </Text>
          </Container>
        </ParallaxLayer>

        {/* Empowering */}
        <ParallaxLayer offset={5} zIndex={80}>
          <Screen backgroundImage="url(images/home/empowering_bg.jpg)" backgroundPosition="50% top" maskOpacity={0.5} />
        </ParallaxLayer>
        <ParallaxLayer offset={5} zIndex={81} speed={0.5}>
          <Container className={classes.container} fixed>
            <Heading>Empowering you</Heading>
            <Text>
              We understand women’s lives are busy and complex.
              <strong>OptimalMe</strong> is non-prescriptive, judgement free and is designed to empower you towards your
              version of optimal.
            </Text>
            <Text>
              It can be accessed anywhere, anytime on your phone, tablet or computer and can be completed at your own
              pace.
            </Text>
          </Container>
        </ParallaxLayer>

        {/* Victoria Story */}
        <ParallaxLayer offset={6} zIndex={80}>
          <Screen backgroundImage="linear-gradient(to bottom, #AEBC95, #464B2F)">
            <Container className={classes.container} fixed>
              <Box width={['90%', '80%', 600]} bgcolor="black">
                <Video id="RKiIA9WTdvs" />
              </Box>
              <Heading>Listen to Victoria’s story</Heading>
              <Text>
                <i>"This program has everything that you would need for a happy and healthy pregnancy journey."</i>
              </Text>
            </Container>
          </Screen>
        </ParallaxLayer>

        {/**
         *  Fix1: Registering new users has been temporary blocked due to close program
         */}

        {/* Register */}
        <ParallaxLayer offset={7} zIndex={70}>
          <Screen backgroundImage="url(images/home/register_bg.jpg)" backgroundPosition="50% 50%">
            <Container className={classes.container} fixed>
              {/* <Heading>Register your interest today</Heading> */}
              <Heading>This program is now closed</Heading>
              {/* <Text>
                Register now if you think the <strong>OptimalMe</strong> program is right for you!
              </Text> */}

              <Text>The next version of the program is under development to be launched in 2024!</Text>

              {/* <Button
                // to="/auth/register"
                onClick={() => history.push('/auth/register')}
                color="primary"
                variant="contained">
                Register Your Interest
              </Button> */}
            </Container>
          </Screen>
        </ParallaxLayer>
      </Parallax>
    </HomePage>
  );
}
