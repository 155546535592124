import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import { ActionStatus, request, success, failure, idle, decode } from 'app/helper';
import { AppThunk } from 'app/store';
import * as adminApi from 'app/admin/api';
import * as queries from 'graphql/queries';
import { UserProfile } from 'app/admin/types';
import { toUserAttrs } from '../helper';

type YESNO = 'YES' | 'NO';

type ProfilesQuery = {
  pregStatus?: YESNO;
};

type NormalizeResult = {
  profiles: string[];
  entities: Record<string, UserProfile>;
};

type Entities = Record<string, UserProfile>;

type SetProfilesPayload = {
  userProfiles: UserProfile[];
  cursor?: string;
  nextToken?: string;
};

export type ProfileState = {
  query: ProfilesQuery;
  cursor?: string;
  status: ActionStatus | null;
  profiles: string[] | null;
  entities: Entities;
};

export const initialState: ProfileState = {
  query: {},
  status: null,
  profiles: null,
  entities: {},
};

const { actions, reducer } = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    request: request('status'),
    success: success('status'),
    failure: failure('status'),
    idle: idle('status'),

    setQuery(state, action: PayloadAction<ProfilesQuery>) {
      state.query = action.payload;
    },

    setProfiles(state, action: PayloadAction<SetProfilesPayload>) {
      const { userProfiles, cursor, nextToken } = action.payload;
      const { profiles, entities } = normalize(userProfiles);
      if (cursor) {
        // append to exsiting profiles
        state.profiles = [...(state.profiles || []), ...profiles];
        state.entities = { ...state.entities, ...entities };
      } else {
        // set new profiles
        state.profiles = profiles;
        state.entities = entities;
      }
      state.cursor = nextToken;
      state.status = null;
    },

    setProfile(state, action: PayloadAction<UserProfile>) {
      const profile = action.payload;
      state.entities = { ...state.entities, [profile.id]: profile };
      state.status = null;
    },
  },
});

export default reducer;

export { actions };

function normalize(userProfiles: UserProfile[]): NormalizeResult {
  const initialState: NormalizeResult = {
    profiles: [],
    entities: {},
  };
  return userProfiles.reduce<NormalizeResult>((current, userProfile) => {
    const id = userProfile.id;
    return {
      profiles: [...current.profiles, id],
      entities: { ...current.entities, [id]: userProfile },
    };
  }, initialState);
}

async function loadProfile(item: unknown): Promise<UserProfile | null> {
  const profile = decode.profile(item);

  try {
    const user = await adminApi.getUser(profile.id);
    return { ...profile, ...toUserAttrs(user) };
  } catch (err) {
    return null;
  }
}

export const loadProfiles = (query: ProfilesQuery, cursor?: string): AppThunk => async dispatch => {
  dispatch(actions.request());
  const { pregStatus } = query;
  dispatch(actions.setQuery(query));
  const profileFetchLimit = 500;

  try {
    const op = pregStatus
      ? graphqlOperation(queries.profilesByPregStatus, { limit: profileFetchLimit, pregStatus, nextToken: cursor })
      : graphqlOperation(queries.listProfiles, { limit: profileFetchLimit, nextToken: cursor });

    const { data } = await API.graphql(op);
    const { items, nextToken } = pregStatus ? data.profilesByPregStatus : data.listProfiles;

    const results = await Promise.all((items as Array<unknown>).map(loadProfile));
    const userProfiles: Array<UserProfile> = results.filter((item): item is UserProfile => item !== null);

    dispatch(actions.setProfiles({ userProfiles, cursor, nextToken }));
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to load profile'));
  }
};

export const getProfile = (uid: string): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    const { data } = await API.graphql(graphqlOperation(queries.getProfile, { id: uid }));
    if (data.getProfile === null) {
      throw new Error('profile did not exist');
    }

    const userProfile = await loadProfile(data.getProfile);
    if (!userProfile) {
      throw new Error('Profile user did not exist in cognito');
    }
    dispatch(actions.setProfile(userProfile!));
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to get user profile'));
  }
};
