import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will discuss our relationship with my partner and seek extra support if needed. ';

export default function HealthyRelationships() {
  return (
    <ArticleContent>
      <Quiz
        question="It is important that both you and your partner are ready to get pregnant."
        answer="True"
        feedback="This is true. When both partners plan for pregnancy, women are more likely to seek pre-pregnancy care and reduce risky lifestyle behaviours including drinking."
      />

      <h4>Key points</h4>
      <ul>
        <li>The decision to have a child is a big one and provides an opportunity to strengthen your relationship</li>
        <li>Tensions may also arise within your relationship at this time</li>
        <li>Seeking support to resolve areas of conflict can help.</li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>Planning a family with your partner can be a very exciting time and may even improve your relationship.</p>
      <p>
        For others, trying to conceive can cause some stress and sex may become routine or even feel like a chore. This
        can cause problems between partners, and in some cases may make you unsure about starting a family. Discuss how
        you both feel about the decision to have a child.
      </p>
      <p>
        It’s important for you and your partner to try to keep sex enjoyable by focusing on each other and your
        relationship, rather than worrying about conceiving. Try to also spend time together doing other things that are
        important to you as a couple.
      </p>
      <p>
        If you or your partner are feeling worried about conceiving, give each other time to talk about it and actively
        listen to each other’s feelings and concerns.
      </p>
      <p>
        It is beneficial to deal with any problems in the relationship before you get pregnant or have the baby.
        Remember, it’s never too late to get support.
      </p>
      <p>
        Talking with a professional about your worries together in a safe and confidential place with a trained
        counsellor may provide added support and reassurance.
      </p>
      <p>
        If you have experienced any form of domestic violence from your partner (physical, sexual, emotional,
        psychological or financial) it is important to talk with someone you trust. A time of great change including the
        time around pregnancy can sometimes worsen a difficult situation. There is a lot of help out there for you to
        discuss your concerns.
      </p>

      <h4>What can I do?</h4>
      <p>
        Allow open discussion about how you are both feeling about planning a pregnancy. You may decide to get support
        if some of the issues that arise are not easily resolved.
      </p>
      <p>
        If you are concerned about your safety seek help using the resources below or by reaching out to someone you
        trust.
      </p>

      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information about your relationship visit:</p>

      <ul>
        <li>
          <a
            href="https://www.womenshealth.gov/pregnancy/you-get-pregnant/preconception-health"
            target="_blank"
            rel="noopener noreferrer">
            U.S. Department of Health & Human Services(Preconception health)
          </a>
        </li>
        <li>
          <a href="https://www.1800respect.org.au/" target="_blank" rel="noopener noreferrer">
            1800 RESPECT
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
