import { Auth, API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

import { AppThunk } from 'app/store';
import { UserInfo, Profile, Credential, RegisterData } from 'app/types';
import { decode, ActionStatus, request, failure, idle } from 'app/helper';
import gtag from 'app/gtag';

export type SetAuthenticatedPayload = {
  userInfo: UserInfo;
  profile: Profile | null;
};

export type AuthState = {
  authenticated: boolean | null;
  userInfo: UserInfo | null;
  status: ActionStatus | null;
};

export const setAuthenticated = createAction<SetAuthenticatedPayload>('setAuthenticated');

export const setUnauthenticated = createAction('setUnauthenticated');

export const initialState: AuthState = {
  authenticated: null,
  userInfo: null,
  status: null,
};

const { reducer, actions } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    request: request('status'),
    failure: failure('status'),
    idle: idle('status'),
  },
  extraReducers: {
    setAuthenticated(state, action: PayloadAction<SetAuthenticatedPayload>) {
      return {
        ...initialState,
        authenticated: true,
        userInfo: action.payload.userInfo,
      };
    },

    setUnauthenticated(state) {
      return {
        ...initialState,
        authenticated: false,
      };
    },
  },
});

export default reducer;

export { actions };

/**
 * Fulfil the userinfo structure with Auth.currentUserInfo
 *
 * @param user an user object
 */
async function fulfilUserInfo(user: any): Promise<UserInfo> {
  const { username, attributes }: Omit<UserInfo, 'user'> = await Auth.currentUserInfo();
  const token = user.signInUserSession?.accessToken;
  const groups = token?.payload['cognito:groups'];
  return { id: username, username, attributes, groups } as UserInfo;
}

/**
 * Load user profile
 *
 * @param id
 */
async function loadProfile(id: string): Promise<Profile | null> {
  try {
    const { data } = await API.graphql(graphqlOperation(queries.getProfile, { id }));
    const profile = data.getProfile !== null ? decode.profile(data.getProfile) : null;
    return profile;
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * Init auth
 */
export const initAuth = (): AppThunk => async dispatch => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      const userInfo = await fulfilUserInfo(user);
      const profile = await loadProfile(userInfo.id);
      dispatch(setAuthenticated({ userInfo, profile }));
      gtag('event', 'login', { method: 'auto_signin' });
    } else {
      dispatch(setUnauthenticated());
    }
  } catch (err) {
    dispatch(setUnauthenticated());
  }
};

/**
 * Signin effects
 *
 * @param credential
 * @param onChallenge
 */
export const signin = (
  credential: Credential,
  onChallenge: (user: unknown) => void,
  failure?: (errorMessage: string) => void,
): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    const user = await Auth.signIn(credential.email, credential.password);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch(actions.idle());
      onChallenge(user);
    } else {
      const userInfo = await fulfilUserInfo(user);
      const profile = await loadProfile(userInfo.id);
      dispatch(setAuthenticated({ userInfo, profile }));
      gtag('event', 'login', { method: 'email' });
    }
  } catch (err) {
    if (err.code === 'UserNotConfirmedException') {
      dispatch(actions.failure('Your email address is not confirmed'));
    } else if (err.code === 'PasswordResetRequiredException') {
      dispatch(actions.failure('You need to reset your password'));
    } else {
      const errorMessage = err.message === 'Password attempts exceeded' ? err.message : 'No such username or password';
      dispatch(actions.failure(errorMessage));
      failure && failure(errorMessage);
    }
  }
};

export const completeNewPassword = (chanllegeUser: any, password: string): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    await Auth.completeNewPassword(chanllegeUser, password, {});
    const user = await Auth.currentAuthenticatedUser();
    const userInfo = await fulfilUserInfo(user);
    const profile = await loadProfile(userInfo.id);
    dispatch(setAuthenticated({ userInfo, profile }));
    gtag('event', 'login', { method: 'complete_password' });
  } catch (err) {
    dispatch(actions.failure('Failed to complete new password'));
  }
};

/**
 * Register effects
 *
 * @param register
 * @param onSuccess
 */
export const register = (register: RegisterData, onSuccess: () => void): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    await API.graphql({
      query: mutations.register,
      variables: register,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    });
    dispatch(actions.idle());
    onSuccess();
    gtag('event', 'sign_up', { method: 'register' });
  } catch (err) {
    dispatch(actions.failure('Failed to register user'));
  }
};

export const signout = (onSignedOut: () => void): AppThunk => async dispatch => {
  try {
    await Auth.signOut();
    gtag('event', 'sign_out');
  } finally {
    dispatch(setUnauthenticated());
    onSignedOut();
  }
};

export const forgotPassword = (email: string, onSuccess: () => void): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    await Auth.forgotPassword(email);
    dispatch(actions.idle());
    onSuccess();
    gtag('event', 'forgot_password');
  } catch (err) {
    dispatch(actions.failure(err.message || 'Fail to send reset password request'));
  }
};

export const forgotPasswordSubmit = (
  email: string,
  code: string,
  newPassword: string,
  onSuccess: () => void,
): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    dispatch(actions.idle());
    onSuccess();
    gtag('event', 'forgot_password_submit');
  } catch (err) {
    dispatch(actions.failure(err.message || 'Fail to reset your password'));
  }
};
