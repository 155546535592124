import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function EstimatingPortionSizes() {
  return (
    <ArticleContent>
      <h4>Use your hand to keep track of portions.</h4>
      <p>
        Familiarise yourself with these simple ways to estimate a serve. This will also help you follow the Australian
        Dietary Guidelines as you will have a better understanding of how many serves of the Five Food Groups you are
        eating.
      </p>
      <p>
        <strong>Palm</strong>
      </p>
      <figure>
        <img src="images/articles/preconception/resources/estimating/palm.png" alt="palm" />
      </figure>
      <p>The average adult palm is about the size of 2 serves of red meat/chicken or 1 serve of raw nuts.</p>
      <p>The average adult palm, plus fingers are roughly equivalent to 2 serves of fish.</p>
      <p>
        <strong>Clenched fist</strong>
      </p>
      <figure>
        <img src="images/articles/preconception/resources/estimating/fist.png" alt="fist" />
      </figure>
      <p>
        A clenched fist is approximately 1 cups, which equates to 1 serve of fruit, 2 serves of vegetables, 2 serves of
        grains.
      </p>
      <p>
        <strong>Thumb</strong>
      </p>
      <figure>
        <img src="images/articles/preconception/resources/estimating/thumb.png" alt="thumb" />
      </figure>
      <p>
        The volume of an average adult thumb is approximately the size of 1 tbsp. Use this as a guide for condiments,
        sauce and dips and limit yourself to 1-2 tbsp.
      </p>
    </ArticleContent>
  );
}
