import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will consult with my GP for a vaccination review.';

export default function Vaccination() {
  return (
    <ArticleContent>
      <Quiz
        question="I can get vaccinations related to pregnancy at any time."
        answer="False"
        feedback="This is false. Some vaccines can only be safely given prior to getting pregnant."
      />

      <h4>Key points</h4>
      <ul>
        <li>Vaccines are essential to provide you and your unborn baby protection against contagious diseases.</li>
        <li>A blood test to check your immunity is recommended prior to pregnancy.</li>
        <li>Some vaccines can only be provided safely prior to pregnancy.</li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        Vaccines prevent infectious diseases that can have serious effects on you and your unborn baby. The period
        before pregnancy is ideal for checking your vaccinations are up to date. Not all vaccinations can be safely
        provided during pregnancy.
      </p>
      <p>Both you and your partner should be protected against the following diseases before pregnancy:</p>
      <ul>
        <li>Measles, mumps, rubella</li>
        <li>Chickenpox (varicella)</li>
        <li>Hepatitis B</li>
      </ul>
      <p>
        <strong>Rubella</strong> infection during pregnancy can cause serious birth defects. The combined Measles, Mumps
        and Rubella (MMR) vaccine should be given at least 28 days prior to conception if you have not been vaccinated
        previously.
      </p>
      <p>
        <strong>Chickenpox</strong> during pregnancy can cause birth defects. It can also cause severe infection in a
        newborn baby if contracted close to birth. You should be vaccinated against the chicken pox if:
      </p>
      <ul>
        <li>You have not had the chickenpox previously</li>
        <li>
          You have not received a chickenpox vaccine previously. The chickenpox vaccine should be given at least 28 days
          prior to conception if you have not been vaccinated previously.
        </li>
      </ul>
      <p>
        <strong>Hepatitis B</strong> infection can be passed from mother to baby during delivery. The Hepatitis B
        vaccine can provide protection during pregnancy, if required.
      </p>

      <h4>Other vaccines:</h4>
      <p>
        <strong>Flu vaccination (influenza)</strong>: This can be given before, or during, pregnancy depending on if you
        become pregnant before or during the flu season. The flu season falls in the cooler months (April to October)
        and generally peaks in August. If you have a flu shot during pregnancy, you also protect your unborn baby for
        the first 6 months of life, until they are able to have their own vaccination.
      </p>
      <p>
        <strong>Travel vaccinations</strong>: Vaccination requirements vary depending on what countries you travel to.
        If you are considering traveling it is recommended to check to see what vaccinations are required at least 6 -
        12 weeks prior to travel. Some diseases, including the zika virus, can cause serious birth defects from
        infection in either parent. Therefore, it is important that you and your partner seek vaccination advice prior
        to travel.
      </p>

      <h4>What can I do?</h4>
      <p>
        Talk with your doctor who will check you vaccination history and perform a blood test to determine what
        vaccinations you may need.
      </p>

      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information about vaccinations before pregnancy visit:</p>
      <ul>
        <li>
          <a
            href="https://www.health.gov.au/health-topics/immunisation/immunisation-throughout-life/immunisation-for-pregnancy"
            target="_blank"
            rel="noopener noreferrer">
            Australian Government Department of Health
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/immunisation-and-pregnancy"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
