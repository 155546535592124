import React from 'react';
import { UserProfile } from 'app/admin/types';
import { Box, Card, CardActionArea, CardContent, createStyles, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme =>
  createStyles({
    primary: {
      display: 'block',
      ...theme.typography.body1,
    },
    secondary: {
      height: '1.2rem',
      lineHeight: '1.2rem',
      display: 'block',
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    },
  }),
);

export type ProfileCardProps = {
  profile: UserProfile;
};

export function ProfileCard(props: ProfileCardProps) {
  const { profile } = props;
  const history = useHistory();
  const classes = useStyles();

  const primary = profile.name || profile.email;
  const secondary = profile.name && profile.email;

  return (
    <Card>
      <CardActionArea onClick={() => history.push(`/admin/profiles/${profile.id}`)}>
        <CardContent>
          <span className={classes.primary}>{primary}</span>
          <span className={classes.secondary}>{secondary} </span>
          <Box mt={1} display="flex" flexDirection="column">
            <Typography variant="caption">
              Pregnancy status: <strong>{profile.pregStatus}</strong>
            </Typography>
            <Typography variant="caption">
              Due date: <strong>{profile.dueDate || '-'}</strong>
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
