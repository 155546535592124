import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will start taking folic acid and iodine one month prior to attempting to conceive.';

export default function Supplements() {
  return (
    <ArticleContent>
      <Quiz
        question="If I have a balanced diet I don’t need to take any supplements prior to conception."
        answer="False"
        feedback="This is false. In addition to a balanced diet folic acid and iodine are recommended prior to conception."
      />

      <h4>Key points</h4>
      <ul>
        <li>There are two main supplements recommended prior to and during pregnancy</li>
        <li>Male fertility may be improved with supplements</li>
        <li>
          A balanced, healthy diet is an important way to provide your body with the nutrients it needs prior to
          pregnancy
        </li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        A healthy diet is an important way to provide with the nutrients you need. In preparation of pregnancy, folic
        acid and iodine are recommended supplements to be taken in addition to your diet.
      </p>

      <h6>Folic Acid</h6>
      <p>
        Taking folic acid before pregnancy has been shown to effectively prevent neural tube defects in your baby’s
        brain and spinal cord such as spina bifida and anencephaly (absence of a major portion of the brain, skull and
        scalp). Research shows that taking folic acid can reduce the risk of developing neural tube defects by 72%.
      </p>
      <p>
        It is recommended to take at least 400mg of folic acid before getting pregnancy (at least one month), and to
        continue until the end of the first trimester (3 months into your pregnancy) as neural tube defects develop
        within the first few weeks of pregnancy.
      </p>

      <h6>Iodine</h6>
      <p>
        Iodine helps with the production of maternal thyroid hormone as well as the development of the baby’s brain and
        central nervous system.
      </p>
      <p>
        Recommendations include supplementing your diet with 150mg of iodine prior to conceiving. Iodine supplementation
        should be continued for the duration of your pregnancy and during breast feeding.
      </p>

      <h6>Other supplements</h6>
      <p>
        Zinc and Selenium supplements can improve sperm quality, and Zinc and Vitamin B6 are vital for the production of
        female sex hormones.
      </p>
      <p>
        Women with a Vitamin D deficiency should take supplements as this will reduce the risk of
        small-for-gestational-age babies and impaired fetal skeletal development.
      </p>
      <p>Vegans need to also consider Vitamin B12.</p>

      <h4>What can I do?</h4>
      <p>
        Commence folic acid and iodine supplementation at least one month prior to conception. Talk with your doctor
        about a blood test to find out if you are deficient in Zinc, Selenium, B6, B12 or Vitamin D.
      </p>

      <h4>Action</h4>

      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information about supplementations prior to pregnancy visit:</p>
      <ul>
        <li>
          <a
            href="https://www.yourfertility.org.au/everyone/lifestyle/nutrition-and-micronutrients"
            target="_blank"
            rel="noopener noreferrer">
            Your Fertility
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
