import FirstHours from './FirstHours';
import BabyBlues from './BabyBlues';
import SleepingRoutines from './SleepingRoutines';
import SleepDeprivation from './SleepDeprivation';
import FeedingBaby from './FeedingBaby';
import Depression from './Depression';
import Relationships from './Relationships';
import Support from './Support';
import PhysiologicalChanges from './PhysiologicalChanges';
import { Content } from 'app/topics/types';

export default [
  {
    id: 'first_hours',
    tag: 'Health',
    title: 'First hours after birth',
    desc: 'After birth there are a range of symptoms involved in recovery and healing.',
    cover: 'images/articles/postpartum/essentials/first_hours.jpg',
    banner: 'images/articles/postpartum/essentials/first_hours@2x.jpg',
    ArticleContent: FirstHours,
    dataKeys: ['quiz'],
  },
  {
    id: 'baby_blues',
    tag: 'Wellbeing',
    title: 'Baby blues',
    desc: 'Most mothers experience baby blues after giving birth.',
    cover: 'images/articles/postpartum/essentials/baby_blues.jpg',
    banner: 'images/articles/postpartum/essentials/baby_blues@2x.jpg',
    ArticleContent: BabyBlues,
    dataKeys: ['quiz'],
  },
  {
    id: 'sleeping_routines',
    tag: 'Essentials',
    title: 'Sleep routines and safe sleeping',
    desc: 'Managing sleep routines and sleep deprivation can be extremely challenging.',
    cover: 'images/articles/postpartum/essentials/sleeping_routines.jpg',
    banner: 'images/articles/postpartum/essentials/sleeping_routines@2x.jpg',
    ArticleContent: SleepingRoutines,
  },
  {
    id: 'sleep_deprivation',
    tag: 'Essentials',
    title: 'Sleep Deprivation',
    desc: 'Sleep deprivation can negatively impact your health and wellbeing.',
    cover: 'images/articles/postpartum/essentials/sleep_deprivation.jpg',
    banner: 'images/articles/postpartum/essentials/sleep_deprivation@2x.jpg',
    ArticleContent: SleepDeprivation,
    dataKeys: ['quiz'],
  },
  {
    id: 'feeding_baby',
    tag: 'Essentials',
    title: 'Feeding your baby',
    desc: 'Breast feeding is a learning journey',
    cover: 'images/articles/postpartum/essentials/feeding_baby.jpg',
    banner: 'images/articles/postpartum/essentials/feeding_baby@2x.jpg',
    ArticleContent: FeedingBaby,
    dataKeys: ['quiz'],
  },
  {
    id: 'depression',
    tag: 'Wellbeing',
    title: 'Anxiety and depression after the birth of your baby',
    desc: 'Postnatal anxiety or depression is quite common in mothers.',
    cover: 'images/articles/postpartum/essentials/depression.jpg',
    banner: 'images/articles/postpartum/essentials/depression@2x.jpg',
    ArticleContent: Depression,
    dataKeys: ['quiz'],
  },
  {
    id: 'relationships',
    tag: 'Wellbeing',
    title: 'Your Relationships',
    desc: 'This time may be challenging for your relationships with friends as well as your partner.',
    cover: 'images/articles/postpartum/essentials/relationships.jpg',
    banner: 'images/articles/postpartum/essentials/relationships@2x.jpg',
    ArticleContent: Relationships,
    dataKeys: ['quiz'],
  },
  {
    id: 'support',
    tag: 'Wellbeing',
    title: 'Support Networks and Care',
    desc: 'Support and care services are available to help.',
    cover: 'images/articles/postpartum/essentials/support.jpg',
    banner: 'images/articles/postpartum/essentials/support@2x.jpg',
    ArticleContent: Support,
    dataKeys: ['quiz'],
  },
  {
    id: 'physiological_changes',
    tag: 'Health',
    title: 'Physiological Changes and Postpartum Weight',
    desc: 'It takes about 6 weeks for the female body to return to a pre-pregnancy state.',
    cover: 'images/articles/postpartum/essentials/physiological_changes.jpg',
    banner: 'images/articles/postpartum/essentials/physiological_changes@2x.jpg',
    ArticleContent: PhysiologicalChanges,
    dataKeys: ['quiz'],
  },
] as Array<Content>;
