import React from 'react';
import { get } from 'lodash';
import {
  ExpansionPanelDetails,
  ExpansionPanel,
  Typography,
  Box,
  makeStyles,
  createStyles,
  Avatar,
  Badge,
  Chip,
} from '@material-ui/core';
import cyan from '@material-ui/core/colors/cyan';
import orange from '@material-ui/core/colors/orange';
import pink from '@material-ui/core/colors/pink';
import Assignment from '@material-ui/icons/Assignment';
import DoneIcon from '@material-ui/icons/Done';
import WaitIcon from '@material-ui/icons/Timer';
import PanelSummary, { Attrs } from './PanelSummary';
import { ModuleId, TopicState } from 'app/types';
import { Content } from 'app/topics/types';
import { essentials as preconceptionEssentials } from 'app/topics/preconception/content';
import { essentials as pregnancyEssentials } from 'app/topics/pregnancy/content';
import { essentials as postpartumEssentials } from 'app/topics/postpartum/content';

const essentialContents: Record<ModuleId, Content[]> = {
  preconception: preconceptionEssentials,
  pregnancy: pregnancyEssentials,
  postpartum: postpartumEssentials,
};

const useStyles = makeStyles(theme =>
  createStyles({
    cover: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },

    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      transform: 'scale(1.2)',
    },

    doneIcon: {
      color: cyan[500],
      marginLeft: 2,
    },

    waitIcon: {
      color: orange[500],
      marginLeft: 2,
    },

    visited: {
      borderColor: cyan[600],
      color: cyan[600],
      marginRight: theme.spacing(1),
    },

    quiz: {
      borderColor: pink[600],
      color: pink[600],
      marginRight: theme.spacing(1),
    },

    contentList: {
      flex: 1,
      margin: theme.spacing(2, 0),
      padding: 0,
      '& > li': {
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
        borderBottom: '1px solid #F0F0F0',
      },
    },
  }),
);
export type EssentialProps = {
  mod: ModuleId;
  topicState: TopicState;
};

export default function Essentials(props: EssentialProps) {
  const { mod, topicState } = props;
  const classes = useStyles();
  const contents = essentialContents[mod];
  const attrs = React.useMemo(() => getSummaryAttrs(topicState, contents), [contents, topicState]);

  const visited = topicState.visited || [];
  const plan = topicState.plan || [];

  return (
    <ExpansionPanel>
      <PanelSummary icon={<Assignment />} primary="Essentials" secondary={<Attrs attrs={attrs} />} />
      <ExpansionPanelDetails>
        <ul className={classes.contentList}>
          {contents.map(content => {
            const isPlaned = plan.indexOf(content.id) >= 0;
            const isVisited = visited.indexOf(content.id) >= 0;
            const quiz = get(topicState.articles, content.id);
            const checkListItem = topicState.checklist?.find(item => item.articleId === content.id);
            return (
              <li key={content.id}>
                <Badge color="secondary" variant={isPlaned ? 'dot' : 'standard'} classes={{ badge: classes.badge }}>
                  <Avatar variant="rounded" src={content.cover} className={classes.cover} />
                </Badge>
                <Box ml={2} display="flex" flexDirection="column">
                  <Typography variant="subtitle1">{content.title}</Typography>
                  {checkListItem && (
                    <Box display="flex" justifyContent="center">
                      <Typography variant="caption" gutterBottom color="textSecondary">
                        {checkListItem.description}
                      </Typography>
                      {checkListItem.completed ? (
                        <DoneIcon fontSize="small" className={classes.doneIcon} />
                      ) : (
                        <WaitIcon fontSize="small" className={classes.waitIcon} />
                      )}
                    </Box>
                  )}
                  <Box mt={1}>
                    {isVisited && <Chip label="Visited" size="small" className={classes.visited} variant="outlined" />}
                    {quiz && (
                      <Chip
                        label={Object.entries(quiz)
                          .map(entry => entry.join(':'))
                          .join(',')}
                        size="small"
                        className={classes.quiz}
                        variant="outlined"
                      />
                    )}
                  </Box>
                </Box>
              </li>
            );
          })}
        </ul>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

const getSummaryAttrs = (topicState: TopicState, contents: Content[]): Array<{ label: string; value: string }> => {
  const { plan, visited, articles, checklist } = topicState;
  const contentIds = contents.map(content => content.id);
  const contentCount = contents.length;
  const planCount = plan?.length || 0;

  const visitedCount = contentIds.reduce((count, contentId) => {
    const included = visited && visited.indexOf(contentId) >= 0;
    return count + (included ? 1 : 0);
  }, 0);
  const quizCount = articles ? Object.keys(articles).length : 0;
  const actionsCount = checklist ? Object.keys(checklist).length : 0;

  return [
    {
      label: 'Plan',
      value: `${planCount}/${contentCount}`,
    },
    {
      label: 'Visited',
      value: `${visitedCount}/${contentCount}`,
    },
    {
      label: 'Quiz',
      value: String(quizCount),
    },
    {
      label: 'Actions',
      value: String(actionsCount),
    },
  ];
};
