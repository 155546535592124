import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import pick from 'lodash/pick';
import moment from 'moment';
import { AppPage } from 'components/page';
import Button from 'components/ActionStatusButton';
import { Container, Box, Typography } from '@material-ui/core';
import ProfileForm, { ProfileData } from './ProfileForm';

import { Profile } from 'app/types';
import { selectProfile } from 'app/store';
import { updateProfile } from './profileSlice';

export type EditProfileProps = {};

export default function EditProfile(props: EditProfileProps) {
  const history = useHistory();
  const { profile, status } = useSelector(selectProfile);
  const dispatch = useDispatch();

  if (!profile) {
    return null;
  }

  const profileData: ProfileData = {
    ...pick(profile, ['weight', 'height', 'pregStatus', 'contactPreference', 'phone']),
    dob: profile.dob ? moment(profile.dob, 'DD/MM/YYYY') : null,
    dueDate: profile.dueDate ? moment(profile.dueDate, 'DD/MM/YYYY') : null,
    weightLogs: profile.weightLogs || [],
  };

  const handleSubmit = (data: ProfileData) => {
    const profile = {
      ...data,
      dob: moment(data.dob).format('DD/MM/YYYY'),
      dueDate: data.dueDate ? moment(data.dueDate).format('DD/MM/YYYY') : null,
    } as Profile;
    dispatch(updateProfile(profile, () => history.push('/dashboard')));
  };

  return (
    <AppPage title="Edit Profile">
      <Container maxWidth="md">
        <Box my={2}>
          <Typography variant="h4" gutterBottom>
            Update My Profile
          </Typography>
        </Box>
        <ProfileForm data={profileData} onSubmit={handleSubmit}>
          <Box mt={4} textAlign="center">
            <Button onClick={() => history.replace('/dashboard')} style={{ marginRight: '0.5rem' }}>
              Cancel
            </Button>
            <Button status={status} type="submit" variant="contained" color="primary">
              Update
            </Button>
          </Box>
        </ProfileForm>
      </Container>
    </AppPage>
  );
}
