import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from 'graphql/mutations';

import { AppThunk } from 'app/store';
import { Profile, TopicState } from 'app/types';
import { ActionStatus, request, failure, idle, encode, decode } from 'app/helper';
import { SetAuthenticatedPayload } from 'app/auth/authSlice';

export type ProfileState = {
  profile: Profile | null;
  status: ActionStatus | null;
};

export type UpdateTopicStatePayload = {
  topicId: string;
  topicState: TopicState;
};

export const initialState: ProfileState = {
  profile: null,
  status: null,
};

const { actions, reducer } = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<Profile>) {
      state.profile = action.payload;
      state.status = null;
    },

    updateProfile(state, action: PayloadAction<Partial<Profile>>) {
      state.profile = { ...state.profile, ...action.payload } as Profile;
      state.status = null;
    },

    updateTopicState(state, action: PayloadAction<UpdateTopicStatePayload>) {
      const { topicId, topicState } = action.payload;
      const items = state.profile?.topicStates.items.map(item => (item.id === topicId ? topicState : item));
      state.profile = {
        ...state.profile,
        topicStates: {
          items: items || [],
        },
      } as Profile;
      state.status = null;
    },

    request: request('status'),

    failure: failure('status'),

    idle: idle('status'),
  },
  extraReducers: {
    setAuthenticated(state, action: PayloadAction<SetAuthenticatedPayload>) {
      state.profile = action.payload.profile;
    },

    setUnauthenticated(state) {
      state.profile = null;
    },
  },
});

export default reducer;

export { actions };

export const initProfile = (data: Profile, onSuccess: () => void): AppThunk => async (dispatch, state) => {
  dispatch(actions.request());
  try {
    const {
      auth: { userInfo },
    } = state();
    const uid = userInfo?.id;
    if (!uid) {
      throw new Error('User was not loaded');
    }

    const input = encode.mutateProfileInput({ ...data, id: uid });
    const result = await API.graphql(graphqlOperation(createProfile, { uid, input }));
    const profile = decode.profile(result.data.initProfile);

    if (!profile) {
      throw new Error('No result');
    }
    dispatch(actions.setProfile(profile));
    onSuccess();
  } catch (err) {
    console.error(err);
    dispatch(actions.failure(err.message || 'Failed to initialize profile'));
  }
};

export const updateProfile = (data: Partial<Profile>, success?: () => void): AppThunk => async (dispatch, state) => {
  dispatch(actions.request());
  try {
    const { profile: profileState } = state();
    const id = profileState.profile?.id;
    if (!id) {
      throw new Error('no profile to update');
    }

    dispatch(actions.updateProfile(data));
    const input = encode.mutateProfileInput({ ...data, id });
    const result = await API.graphql(graphqlOperation(mutations.updateProfile, { input }));
    const profile = decode.profile(result.data.updateProfile)!;
    dispatch(actions.updateProfile(profile));
    success && success();
  } catch (err) {
    console.log(err);
    dispatch(actions.failure(err.message || 'Failed to update profile'));
  }
};

export const updateTopicState = (
  topicId: string,
  data: Partial<TopicState>,
  onSuccess?: () => void,
): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    const input = encode.updateTopicStateInput({ id: topicId, ...data });
    const result = await API.graphql(graphqlOperation(mutations.updateTopicState, { input }));
    const topicState = decode.topicState(result.data.updateTopicState);
    dispatch(actions.updateTopicState({ topicId, topicState }));
    onSuccess && onSuccess();
  } catch (err) {
    console.error(err);
    dispatch(actions.failure(err.message || 'Failed to update topic state'));
  }
};

export const createProfile = `mutation InitProfile(
  $uid: ID!
  $input: CreateProfileInput!
){
  preconception: createTopicState(input:{
    profileId:$uid
    mod:"preconception"
    lastVisited: null
    visited: [],
    kpiPre:"{}"
    kpiAfter:"{}"
    evaluation:"{}"
    screening:"{}"
    plan: [],
    articles: "{}"
    lifestyleData:"{}",
    goals: [],
    goalSettings: "{}",
    checklist:"[]"
  }){
    id
  }

  pregnancy: createTopicState(input:{
    profileId:$uid
    mod:"pregnancy"
    lastVisited: null
    visited: [],
    kpiPre:"{}"
    kpiAfter:"{}"
    evaluation:"{}"
    screening:"{}"
    plan: [],
    articles: "{}"
    lifestyleData:"{}",
    goals: [],
    goalSettings: "{}",
    checklist:"[]"
  }){
    id
  }

  postpartum: createTopicState(input:{
    profileId:$uid
    mod:"postpartum"
    kpiPre:"{}"
    kpiAfter:"{}"
    evaluation:"{}"
    lastVisited: null
    visited: []
    screening:"{}"
    plan: [],
    lifestyleData:"{}",
    goals: [],
    goalSettings: "{}",
    checklist:"[]"
    articles: "{}"
  }){
    id
  }

  initProfile: createProfile(input: $input){
    id
    owner
    dob
    weight
    height
    pregStatus
    dueDate
    contactPreference
    weightLogs
    phone
    topicStates{
      items{
        id
        profileId
        mod
        owner
        lastVisited
        visited
        kpiPre
        kpiAfter
        screening
        plan
        articles
        lifestyleData
        goals
        goalSettings
        checklist
      }
    }
  }
}`;
