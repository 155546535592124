import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function BabyBlues() {
  return (
    <ArticleContent>
      <Quiz
        question="Baby blues leads to depression."
        answer="False"
        feedback="No, baby blues are very common and a result of hormonal shifts after the baby is born. It does not lead to depression."
      />
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Baby blues are very common, affecting up to 80 per cent of new mothers.</li>
        <li>It is thought baby blues are caused by fluctuating hormones following birth.</li>
        <li>Baby blues are temporary and generally last only a few days.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Baby blues affect most women; so it is good to anticipate this, so you understand what is happening to you.
        Symptoms of baby blues tend to appear three to five days after the birth and can include feelings of distress,
        irritability, anxiety, mood swings, teariness and feeling overwhelmed. These symptoms are all very common and
        thought to be related to fluctuating hormones levels related to giving birth. Baby blues can be worse if you
        experienced a difficult labour, are very tired or have additional worries, including breastfeeding. Typically
        baby blues only last up to a few days.
      </p>
      <p>
        If your symptoms don’t go away after a few days it is important to let your health professional know as it may
        be a sign of developing depression or anxiety.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Symptoms of baby blues typically resolve within a few days and do not require any treatment. Talking with people
        close to you, including your partner, family or friends can help you to feel supported and reassured. Tell your
        support persons about this before the birth can help them to feel prepared if baby blues do occur.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about Baby blues visit:</p>
      <ul>
        <li>
          <a
            href="https://www.thewomens.org.au/health-information/pregnancy-and-birth/mental-health-pregnancy/baby-blues"
            target="_blank"
            rel="noopener noreferrer">
            The Women’s – The Royal Women’s Hospital
          </a>
        </li>
        <li>
          <a
            href="https://www.cope.org.au/preparing-for-birth/the-days-following-birth/baby-blues/"
            target="_blank"
            rel="noopener noreferrer">
            COPE
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
