import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';
import { styled } from '@material-ui/core/styles';

export default function PhysiologicalChanges() {
  return (
    <ArticleContent>
      <Quiz
        question="It is normal for women to retain weight after childbirth."
        answer="False"
        feedback="This is false. In the weeks following pregnancy your body returns to its pre-pregnancy state. To increase the likelihood of returning to your pre-pregnancy weight its important to gain within the recommendations for weight gain during pregnancy"
      />
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Your body undergoes many physical changes in the immediate hours, days and weeks following pregnancy.</li>
        <li>
          By about six weeks post-birth many of the changes have resolved to return your body to its pre-pregnancy state
          naturally.
        </li>
        <li>
          Increased body weight beyond this time can increase health risks and development of overweight or obesity.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Healthy weight gain during pregnancy is fundamental for the health of you and your baby. The recommendations for
        weight gain during pregnancy vary depending on your pre-pregnancy weight and body mass index (BMI) (refer to{' '}
        <a href="/topics/preconception/articles/weight">Gestational Weight Gain</a> fact
        sheet for more information). Recommended weight gain during pregnancy considers all the products of pregnancy,
        consisting of the fetus (baby), amniotic fluid, placenta, breast tissue, increased blood volume and uterus size.
        Additionally a small reserve of extra fat stores are used as an energy reserve for birth and breast-feeding.
      </p>
      <p>
        Excess weight gain during pregnancy above recommendations increases the risk of retaining extra weight
        post-birth, increasing the risk of future health risks including obesity development, Type II Diabetes and risk
        factors for cardiovascular disease. Excess weight gain in pregnancy also has effects on your unborn baby,
        including an increased risk of obesity development in later life. These risks are independent of a woman’s
        weight prior to pregnancy.
      </p>
      <p>
        After giving birth there are some naturally occurring physiological changes that enable your body to return back
        to its normal, pre-pregnant state by about 6 weeks post-birth. The most obvious change is the delivery of your
        baby, amniotic fluid and placenta. This is followed by a decrease in the uterus size and volume, from a large 1
        kg organ that holds 5-10 litres, to 60g organ with a capacity of just 3-5 ml. The uterus begins decreasing in
        the final stage of labour and continues, reaching its pre-pregnancy size by 5-6 weeks post-birth. Additionally,
        by this stage increased circulating blood levels reduce, bleeding should have ceased and any pregnancy related
        fluid will have decreased to normal levels.
      </p>
      <p>
        Often women discuss “baby weight” in the months (and even years!) following birth. It is important to know that
        because your body has generally returned to its pre-pregnancy state by about 6 weeks post-birth, what you weigh
        at this point is not related to carrying a baby (being pregnant), yet is more related to the amount of weight
        gained during pregnancy.
      </p>
      <p>
        <strong>What does this mean?</strong>
      </p>
      <p>
        At around six weeks post-birth, the physiological changes that occurred throughout pregnancy will have returned
        to normal. If your weight is higher than it was pre-pregnancy, this is likely to be a result of weight gain
        during pregnancy above the recommendations. For some women, they may be a similar or lower weight during
        pregnancy, likely to be due to weight gain within or lower than recommendations.
      </p>
      <p>
        This is a great time to start thinking about implementing some healthy habits to address any extra weight,
        including resumption of regular walking as generally most women have received a post-birth medical check by this
        time. For women who find they have lost weight at six weeks post-birth, maintaining healthy habits to ensure you
        stay within a healthy BMI range is recommended.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <ul>
        <li>
          Remember our 6 key lifestyle messages. These messages are easier than trying to remember how much to eat, how
          to cook, what foods you should and shouldn’t eat, what exercise to do and how much to do. They are achievable
          and relevant to the whole family.
        </li>
      </ul>

      <Message prefix="1">
        <h6>Limit High Fat Takeaway Food and Convenience Foods to Once per Week, Including Lunches</h6>
        <p>
          Foods high in fat and low in fibre are high energy dense foods. They have lots of calories/kilojoules and
          don’t make you feel full so you can eat large amounts of them. Examples are muffins, cakes, pastries,
          hamburgers, chips and pies.
        </p>
      </Message>

      <Message prefix="2">
        <h6>Eat at least 2 Serves of Fruit and 5 Serves of Vegetables Every Day</h6>
        <p>
          Fruit and vegetables help with weight maintenance by adding extra fibre, which makes you feel full. Try to
          include incorporate vegetables daily at lunch and dinner.
        </p>
      </Message>

      <Message prefix="3">
        <h6>Reduce High Fat and High Sugar Snacks Between Meals</h6>
        <p>
          Cakes, biscuits, chips and chocolates are occasional foods. They should be saved for special occasions, rather
          than being eaten every day. Snacking on fruits and vegetables between meals instead will reduce the sugar and
          fat you are eating.
        </p>
      </Message>

      <Message prefix="4">
        <h6>Drink Water in Place of Soft Drink, Juice, Cordial and Sports Drinks and Keep Alcohol to a Minimum</h6>
        <p>
          Fruit juices, cordials and soft drinks contain a lot of sugar. They can be consumed very quickly, have no
          nutritional value and don’t fill you up. Alcohol is concentrated in calories, and will lead to rapid weight
          gain. Drink no more than 2 standard drinks a day.
        </p>
      </Message>

      <Message prefix="5">
        <h6>Eat Breakfast Every Day</h6>
        <p>
          Skipping breakfast will often result in poorer food choices throughout the rest of the day. Breakfast foods
          with milk and fruit are an important part of a healthy diet. Choose whole grain varieties such as rolled oats,
          untoasted muesli, wheat based cereals and grainy breads.
        </p>
      </Message>

      <Message prefix="6">
        <h6>Take a Brisk Walk for at least 30 Minutes on Most Days of the Week</h6>
        <p>
          Walking should be brisk, rather than slow. You should feel warm and need to breathe through your mouth to get
          extra air. You heart rate should become slightly faster. For extra health and fitness some more vigorous
          exercise is also beneficial.
        </p>
      </Message>
      <ul>
        <li>
          Refer to the post-birth{' '}
          <a href="/topics/postpartum/articles/physical_activity">Physical activity</a> and{' '}
          <a href="/topics/postpartum/articles/eating">Healthy eating</a> fact sheets for some
          tips and recommendations.
        </li>
        <li>
          Practice goal setting and identify barriers and enablers. Your barriers and enablers may be vastly different
          to what they were before and during pregnancy, and identifying them is important.
        </li>
        <li>
          Monitor your weight weekly to enable quick adjustment of lifestyle behaviours in response to changes in
          weight.
        </li>
      </ul>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about these topics visit:</p>
      <ul>
        <li>
          <a
            href="http://editorial.glowm.com/%22%22?p=glowm.cml/section_view&articleid=143"
            target="_blank"
            rel="noopener noreferrer">
            Postpartum Care
          </a>
        </li>
        <li>
          <a
            href="https://jamanetwork.com/journals/jamainternalmedicine/fullarticle/2708196"
            target="_blank"
            rel="noopener noreferrer">
            Interpregnancy Interval
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}

const Message = styled('div')((props: any) => {
  return {
    position: 'relative',
    margin: props.theme.spacing(2),
    padding: '2rem 1.5rem 1.5rem 1.5rem',
    backgroundColor: '#adb4b9',
    borderRadius: 8,
    '& > h6': {
      margin: '0 0 1rem 0',
    },
    '&:before': {
      position: 'absolute',
      top: '-1.2rem',
      left: '1rem',
      fontSize: '2rem',
      fontStyle: 'italic',
      fontWeight: 400,
      color: '#AEBC95',
      content: props.prefix,
    },
  };
});
