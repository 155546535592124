import React from 'react';
import { Container, Typography, List, Divider, Box, Toolbar } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import UsersIcon from '@material-ui/icons/People';
import InvitationsIcon from '@material-ui/icons/LocalPostOffice';
import SimplePage, { SimplePageProps } from 'components/page/SimplePage';
import LinkItem from 'components/LinkItem';

const useStyles = makeStyles(theme =>
  createStyles({
    list: {
      padding: theme.spacing(1),
    },
  }),
);

export function AdminDrawer() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Toolbar>
        <Typography variant="h6" gutterBottom>
          Admin Console
        </Typography>
      </Toolbar>
      <Divider />
      <List className={classes.list}>
        <LinkItem exact path="/" icon={<HomeIcon />} primary="Home" secondary="Back to OptimalMe" />
        <LinkItem path="/admin/registers" icon={<InvitationsIcon />} primary="Registrations" secondary="Manage registrations" />
        <LinkItem path="/admin/users" icon={<UsersIcon />} primary="Users" secondary="Manage users" />
        <LinkItem path="/admin/profiles" icon={<UsersIcon />} primary="Profiles" secondary="Manage profiles" />
      </List>
    </React.Fragment>
  );
}

export default (props: Omit<SimplePageProps, 'drawerContent'>) => (
  <SimplePage title={props.title} drawerContent={<AdminDrawer />}>
    <Container>
      <Box pt={3} pb={8}>
        {props.children}
      </Box>
    </Container>
  </SimplePage>
);
