import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: RingProps) => {
      return {
        cursor: 'pointer',
        fill: 'transparent',
        strokeLinecap: 'round',
        stroke: props.trackColor,
        '& path': {
          stroke: props.color,
        },
        '&:hover': {
          '& path': {
            opacity: 0.5,
          },
        },
      };
    },
  }),
);

function partialCircle(cx: number, cy: number, r: number, start: number, end: number): string {
  const length = Math.max(0, end - start);
  const fromX = r * Math.cos(start) + cx;
  const fromY = r * Math.sin(start) + cy;
  const toX = r * Math.cos(end) + cx;
  const toY = r * Math.sin(end) + cy;
  const large = Math.abs(length) <= Math.PI ? '0' : '1';
  const sweep = length < 0 ? '0' : '1';
  return [
    ['M', fromX, fromY],
    ['A', r, r, 0, large, sweep, toX, toY],
  ]
    .flat()
    .join(' ');
}

export type RingProps = React.HTMLAttributes<SVGGElement> & {
  color: string;
  trackColor: string;
  cx: number;
  cy: number;
  r: number;
  strokeWidth: number;
  percent: number;
};

export default function Ring(props: RingProps) {
  const classes = useStyles(props);
  const { cx, cy, r, percent, strokeWidth, trackColor, color, ...others } = props;
  const end = Math.min(percent * (Math.PI * 2) - Math.PI / 2, (3 * Math.PI) / 2 - 0.0001);
  const start = -Math.PI / 2;
  return (
    <g className={classes.root} {...others}>
      <circle cx={cx} cy={cy} r={r} strokeWidth={strokeWidth} />
      <path d={partialCircle(cx, cy, r, start, end)} strokeWidth={strokeWidth} />
    </g>
  );
}

Ring.defaultProps = {
  strokeWidth: 10,
  trackColor: 'rgba(128, 128, 128, .2)',
};
