import React from 'react';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import { TopicState, ModuleId } from 'app/types';
import { TopicSummary } from './types';
import topicModules from './topicModules';

export default function useTopicsummary(topicState: TopicState, moduleId: ModuleId) {
  return React.useMemo(() => {
    const articlePoints = topicModules[moduleId].content.essentials.reduce<{ [id: string]: number }>(
      (points, article) => {
        return { ...points, [article.id]: article.dataKeys ? article.dataKeys!.length + 1 : 1 };
      },
      {},
    );

    // initial progress
    const contentState = topicState.articles || {};
    const goals = topicState.goals || [];
    const goalSettings = topicState.goalSettings || {};
    const plan = topicState.plan || [];
    const visited = topicState.visited || [];
    const goalsTotal = goals.length + 1;
    const goalsCompleted = isEmpty(goals) ? 0 : 1 + intersection(Object.keys(goalSettings), goals).length;

    // initial topic summary
    const initial: TopicSummary = {
      articles: {},
      essential: {
        total: 0,
        completed: 0,
      },
      goals: {
        total: goalsTotal,
        completed: goalsCompleted,
      },
      total: goalsTotal,
      completed: goalsCompleted,
    };

    // reduce with planned articles
    const result = plan.reduce<TopicSummary>((topicSummary, articleId) => {
      const total = articlePoints[articleId] || 1;
      const completed = visited.indexOf(articleId) < 0 ? 0 : Object.keys(contentState[articleId] || {}).length + 1;
      return {
        total: topicSummary.total + total,
        completed: topicSummary.completed + completed,
        goals: topicSummary.goals,
        articles: { ...topicSummary.articles, [articleId]: { total, completed } },
        essential: {
          total: topicSummary.essential.total + total,
          completed: topicSummary.essential.completed + completed,
        },
      };
    }, initial);

    console.log('useTopicsummary', result);
    return result;
  }, [topicState, moduleId]);
}
