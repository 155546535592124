import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will discuss any concerns about genetic risks with my GP; and seek genetic counselling if needed.';

export default function GeneticCounselling() {
  return (
    <ArticleContent>
      <Quiz
        question="Genetic screening is recommended for all couples."
        answer="True"
        feedback="This is true. Screening is recommended for all couples however the choice to proceed is yours. Screening can identify pregnancies with an increased likelihood of being affected by a genetic condition."
      />

      <h4>Key points</h4>
      <ul>
        <li>Genetic risks to your future child cannot be determined on family history alone</li>
        <li>Age increases the risk of genetic conditions such as Down’s Syndrome</li>
        <li>There are a range of circumstances in which genetic counselling is recommended</li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        You or your partner’s obstetric and/or family history and/or ethnic background may increase the risk of an
        inherited genetic condition. Age is also a factor that may increase the risk of some conditions such as Downs
        Syndrome. Consultation with genetic services enables genetic counselling, guidance on appropriate testing and,
        interpretation and explanation of results.
      </p>
      <p>It is especially important to consider genetic counselling if:</p>
      <ul>
        <li>The mother-to-be has had two or more miscarriages or babies that died in infancy.</li>
        <li>
          The mother-to-be will be 35 or older when the baby is born. Chances of having a child with Down syndrome
          increase with the mother's age.
        </li>
        <li>A standard prenatal screening test has an abnormal result.</li>
        <li>An amniocentesis (an amniotic fluid test) shows an unexpected result.</li>
        <li>Either parent or a close relative has an inherited disease or birth defect.</li>
        <li>Either parent already has children with birth defects, intellectual disabilities, or genetic disorders.</li>
        <li>There's concern about genetic defects that happen often in an ethnic or racial group.</li>
        <li>
          Either parent is concerned about the effects of exposure to radiation, medicines, illegal drugs, infections,
          or chemicals.
        </li>
      </ul>

      <h4>What can I do?</h4>
      <p>Discuss genetic testing with your GP.</p>

      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information about genetic screening visit:</p>
      <ul>
        <li>
          <a
            href="https://ranzcog.edu.au/Womens-Health/Patient-Information-Resources/Prenatal-Screening-for-Chromosomal-and-Genetic-Con"
            target="_blank"
            rel="noopener noreferrer">
            The Royal Australian New Zealand College of Obstetricians and Gynaecologists
          </a>
        </li>
        <li>
          <a
            href="https://www.thewomens.org.au/health-information/pregnancy-and-birth/now-you-are-pregnant/genetic-testing-in-pregnancy"
            target="_blank"
            rel="noopener noreferrer">
            The Royal Women’s Hospital Victoria
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
