export default {
  mercury: 'Avoiding fish high in mercury',
  coffee: 'Caffeine intake',
  softdrinks: 'Drinking soft drink or sugary drinks',
  water: 'Drinking water',
  fruit: 'Eating fruit',
  junkfood: 'Eating junk food',
  meals: 'Eating regular meals',
  exercise: 'Exercise',
  highfat: 'Foods high in fat content',
  highsugar: 'Foods high in sugar content',
  homecooked: 'Home cooked meals',
  priority: 'Making my health a priority',
  weight: 'My weight',
  worklife: 'My work/life balance',
  packagedfood: 'Packaged or processed foods',
  pelvic: 'Pelvic floor exercises',
  foodlabels: 'Reading food labels when buying',
  sleep: 'Sleep',
  snacking: 'Snacking',
  storingfood: 'Storing food safely',
  stresslevels: 'Stress levels',
  relaxing: 'Time spent relaxing',
  junkfoodinhouse: 'Having junk food in the house',
};
