import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Card as MuiCard, CardHeader, CardContent, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import { Link } from 'react-router-dom';

import { selectAuth } from 'app/store';
import { actions } from './authSlice';
import Alert from 'components/Alert';

/**
 * Default AuthPage
 */
export default styled((props: React.HTMLProps<HTMLElement>) => {
  const dispatch = useDispatch();
  const { status } = useSelector(selectAuth);
  return (
    <div className={props.className}>
      <Alert alertText={status?.error} onClose={() => dispatch(actions.idle())} variant="filled" severity="error" />
      <Box mb={4}>
        <Link to="/">
          <img src="images/logo_m@2x.png" width={300} alt="logo" />
        </Link>
      </Box>
      {props.children}
    </div>
  );
})({
  height: '100%',
  width: '100%',
  paddingTop: 70,
  color: blueGrey.A200,
  backgroundColor: blueGrey[50],
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

/**
 * Card component
 */

export type CardProps = React.HTMLProps<HTMLElement> & {
  title?: string;
};

export function Card(props: CardProps) {
  const { title, children } = props;
  return (
    <Container maxWidth="xs">
      <MuiCard>
        <CardHeader title={title} />
        <CardContent>{children}</CardContent>
      </MuiCard>
    </Container>
  );
}

/**
 * Actions
 */
export const CardActions = styled('div')((props: any) => ({
  margin: props.theme.spacing(3, 0, 2),
  display: 'flex',
  flexDirection: 'column',
}));
