import React from 'react';
import ArticleContext from './ArticleContext';
import useTopicState from 'app/topics/useTopicState';

export default function useCheckItem() {
  const { articleId, moduleId } = React.useContext(ArticleContext);
  const { topicState, updateTopicState } = useTopicState(moduleId);

  const isInCheckList = (action: string): boolean => {
    const checkList = topicState.checklist || [];
    const item = checkList.find(item => {
      return item.articleId === articleId && item.description === action;
    });
    return !!item;
  };

  const addItem = (action: string) => {
    if (!isInCheckList(action)) {
      const checklist = [
        ...(topicState.checklist || []),
        {
          articleId,
          description: action,
          completed: false,
        },
      ];
      updateTopicState({ checklist });
    }
  };

  const removeItem = (action: string) => {
    if (isInCheckList(action)) {
      const checklist = (topicState.checklist || []).filter(item => {
        return !(item.articleId === articleId && item.description === action);
      });
      updateTopicState({ checklist });
    }
  };

  return {
    isInCheckList,
    removeItem,
    addItem,
  };
}
