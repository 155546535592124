import React from 'react';
import moment from 'moment';
import { ResponsiveContainer, XAxis, YAxis, CartesianGrid, Area, ComposedChart, Line, Tooltip, Legend } from 'recharts';
import pink from '@material-ui/core/colors/pink';

import { WeightLog } from 'app/types';
import { BmiCategory, calcBmi, bmiLegends } from 'app/helper';

export type DataPoint = {
  week: number;
  range: [number, number];
  weight?: number;
};

export type WeightGainChartProps = {
  normalWeight: number;
  height: number;
  dueDate: string | null;
  weightLogs: WeightLog[] | null;
};

export default function WeightGainChart(props: WeightGainChartProps) {
  const { normalWeight, height, dueDate, weightLogs } = props;
  const category = calcBmi(normalWeight, height)[1];
  const color = bmiLegends[category].color;

  const formatValue = (value: any, name: string): React.ReactNode => {
    switch (name) {
      case 'My Weight':
        return `${(value as number).toFixed(1)}kg`;
      case 'Recommended Weight Gain Range':
        const [low, high] = value as [number, number];
        return `${low.toFixed(1)}kg - ${high.toFixed(1)}kg`;
      default:
        return value;
    }
  };

  const data: Array<DataPoint> = createDataPoints(category, normalWeight, dueDate, weightLogs);
  const [yMin, yMax] = data.reduce<[number, number]>(
    (range, { weight }) => {
      return weight ? [Math.min(weight, range[0]), Math.max(weight, range[1])] : range;
    },
    [data[0].range[0], data[data.length - 1].range[1]],
  );

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={400}>
      <ComposedChart data={data} margin={{ top: 0, right: 20, left: 0, bottom: 20 }}>
        <XAxis
          type="number"
          dataKey="week"
          label={{ value: 'Weeks of pregnancy', position: 'bottom' }}
          domain={[0, 40]}
          tickCount={10}
        />
        <YAxis
          type="number"
          dataKey="range"
          domain={[yMin - 1, yMax + 1]}
          label={{ value: 'Weight (kg)', angle: -90, position: 'insideLeft' }}
        />
        <Tooltip
          labelStyle={{ fontSize: 12 }}
          contentStyle={{ fontSize: 12 }}
          labelFormatter={label => `Week ${label}`}
          formatter={(value, name) => formatValue(value, name)}
        />
        <Legend verticalAlign="top" height={36} />
        <CartesianGrid strokeDasharray="3 3" />
        <Area
          name="Recommended Weight Gain Range"
          type="linear"
          dataKey="range"
          stroke={color}
          fill={color}
          connectNulls
        />
        <Line
          name="My Weight"
          type="linear"
          dataKey="weight"
          stroke={pink[400]}
          dot={{ r: 4 }}
          activeDot={{ stroke: 'white', strokeWidth: 2, r: 8 }}
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

const createDataPoints = (
  category: BmiCategory,
  weight: number,
  dueDate: string | null,
  weightLogs: WeightLog[] | null,
): DataPoint[] => {
  const [low, high] = bmiLegends[category].gainRange;
  const ranges: DataPoint[] = [
    {
      week: 0,
      range: [weight, weight],
    },
    {
      week: 13,
      range: [weight + 0.5, weight + 2],
    },
    {
      week: 40,
      range: [weight + low, weight + high],
    },
  ];

  if (!dueDate || !weightLogs) {
    return ranges;
  }

  const [week0, week13, week40] = ranges;
  let past13 = false;
  let ds = weightLogs
    .map(weightLog => {
      return {
        week: 40 - moment(dueDate!, 'DD/MM/YYYY').diff(moment(weightLog.date, 'DD/MM/YYYY'), 'weeks'),
        weight: Number(weightLog.weight),
      };
    })
    .sort((a, b) => a.week - b.week)
    .reduce<DataPoint[]>(
      (ds, log) => {
        const interpol = (x: number, x0: number, y0: number, x1: number, y1: number) => {
          return y0 + (y1 - y0) * ((x - x0) / (x1 - x0));
        };

        const weightAt = (week: number, last: DataPoint, next: { week: number; weight: number }) =>
          interpol(week, last.week, Number(last.weight), next.weight, Number(next.weight));

        const rangeAt = (week: number, last: DataPoint, next: DataPoint) => [
          interpol(week, last.week, last.range[0], next.week, next.range[0]),
          interpol(week, last.week, last.range[1], next.week, next.range[1]),
        ];

        const { week, weight } = log;
        if (week < 0 || week > 40) {
          return ds;
        }

        if (!past13 && week > 13) {
          let dp = week13;
          if (ds.length > 1) {
            const weight = weightAt(13, ds[ds.length - 1], log);
            dp = { ...week13, weight };
          }
          ds = [...ds, dp];
          past13 = true;
        }

        const range = week > 13 ? rangeAt(week, week13, week40) : rangeAt(week, week0, week13);
        return [...ds, { week, weight, range }] as DataPoint[];
      },
      [week0],
    );

  ds = [...ds, week40];

  return ds;
};
