import PhysicalActivity from './PhysicalActivity';
import Eating from './Eating';
import { Content } from 'app/topics/types';

export default [
  {
    id: 'physical_activity',
    tag: 'Lifestyle',
    title: 'Physical Activity',
    desc: 'Physical activity is essential for recovery and wellbeing, but your body needs to heal first.',
    cover: 'images/articles/postpartum/essentials/physical_activity.jpg',
    banner: 'images/articles/postpartum/essentials/physical_activity@2x.jpg',
    ArticleContent: PhysicalActivity,
    dataKeys: ['quiz'],
  },
  {
    id: 'eating',
    tag: 'Lifestyle',
    title: 'Healthy Eating',
    desc: 'Eating nutritious diet will enhance your wellbeing and may help you lose weight.',
    cover: 'images/articles/postpartum/essentials/eating.jpg',
    banner: 'images/articles/postpartum/essentials/eating@2x.jpg',
    ArticleContent: Eating,
    dataKeys: ['quiz'],
  },
] as Array<Content>;
