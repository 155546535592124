import React from 'react';
import { Card, CardHeader, CardContent, Box, Typography, Chip, Divider } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { UserProfile } from 'app/admin/types';
import AttrList, { Attr } from 'components/AttrList';
import indigo from '@material-ui/core/colors/indigo';

type UserInfo = {
  name: string;
  email: string;
  dob?: string;
  medibank_number?: string;
  postcode?: string;
  contactPreference?: string[];
  phone: string;
  pregStatus: 'YES' | 'NO';
  dueDate: string;
};

const attrs: Attr<UserInfo>[] = [
  {
    attr: 'name',
    label: 'Name',
  },
  {
    attr: 'email',
    label: 'Email Address',
  },
  {
    attr: 'postcode',
    label: 'Postcode',
  },
  {
    attr: 'dob',
    label: 'Date of birth',
  },
  {
    attr: 'medibank_number',
    label: 'Medibank membership',
  },

  {
    attr: 'phone',
    label: 'Phone Number',
  },
  {
    attr: 'pregStatus',
    label: 'Pregnancy status',
  },
  {
    attr: 'dueDate',
    label: 'Due date',
  },
];

const useStyles = makeStyles(theme =>
  createStyles({
    contactChip: {
      color: indigo[500],
      borderColor: indigo[500],
      marginRight: theme.spacing(1),
    },
  }),
);

export type UserSummaryProps = {
  profile: UserProfile;
};

export default function UserSummary(props: UserSummaryProps) {
  const { profile } = props;
  const classes = useStyles();
  const contactPreference = profile.contactPreference;
  const userInfo: UserInfo = {
    name: profile.name,
    email: profile.email,
    dob: profile['custom:dob'],
    medibank_number: profile['custom:medibank_number'],
    postcode: profile['custom:postcode'],
    phone: profile.phone || '-',
    pregStatus: profile.pregStatus,
    dueDate: profile.dueDate || '-',
  };

  return (
    <Card>
      <CardHeader title="User summary" />
      <CardContent>
        <AttrList data={userInfo} attrs={attrs} />
        <Divider />
        {contactPreference && (
          <Box mt={2}>
            <Typography variant="h6" gutterBottom>
              Contact Preference
            </Typography>
            <Box>
              {contactPreference.map((pref, i) => {
                const parts = pref.split('_');
                const label =
                  parts.length !== 2
                    ? 'Invalid date'
                    : [parts[0], parts[1] === 'AM' ? 'morning' : 'afternoon'].join(' ');
                return <Chip key={i} label={label} variant="outlined" className={classes.contactChip} />;
              })}
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
