import React from 'react';
import { Snackbar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import MuiAlert, { AlertProps as MuiAlertProps } from '@material-ui/lab/Alert';

const SlideTransition = (props: TransitionProps) => <Slide {...props} direction="down" />;

export type AlertProps = Omit<MuiAlertProps, 'children'> & {
  alertText?: string;
  onClose?: () => void;
};

export default function Alert(props: AlertProps) {
  const { alertText, onClose, ...alertProps } = props;
  const [message, setMessage] = React.useState('');
  const open = Boolean(alertText);

  React.useEffect(() => {
    if (alertText) {
      setMessage(alertText);
    }
  }, [alertText]);

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <MuiAlert {...alertProps} onClose={onClose}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
