import React from 'react';
import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import awsconfig from 'aws-exports';
import theme from './theme';
import { store } from './store';
import AppRoutes from './AppRoutes';

Amplify.configure(awsconfig);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      </Router>
    </ThemeProvider>
  );
}
