import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function BetterChoices() {
  return (
    <ArticleContent>
      <h4>Swaps and Tips for Better Choices</h4>
      <p>
        Below are some simple tips or easy swaps that can help you reduce your energy intake, make better food choices
        and develop healthy habits.
      </p>
      <p>
        If you save at least 210 kJ (50cals) per day, you are likely to reduce weight gain. Swap any of the following
        and you will start reducing your energy intake in no time.
      </p>
      <figure>
        <img src="images/articles/preconception/resources/better_choices_intake/timtams.png" alt="timtams" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/better_choices_intake/pasta.png" alt="pasta" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/better_choices_intake/plates.png" alt="big plates" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/better_choices_intake/water.png" alt="water" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/better_choices_intake/skipping.png" alt="skipping" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/better_choices_intake/eating.png" alt="eating" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/better_choices_intake/mayo.png" alt="mayo" />
      </figure>
      <figure>
        <img src="images/articles/preconception/resources/better_choices_intake/tempura.png" alt="tempura" />
      </figure>
    </ArticleContent>
  );
}
