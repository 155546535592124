/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      dob
      weight
      height
      isPreg
      pregStatus
      phone
      dueDate
      contactPreference
      weightLogs
      topicStates {
        items {
          id
          profileId
          mod
          kpiPre
          kpiAfter
          evaluation
          lastVisited
          visited
          screening
          plan
          lifestyleData
          goals
          goalSettings
          checklist
          articles
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles($filter: ModelProfileFilterInput, $limit: Int, $nextToken: String) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dob
        weight
        height
        isPreg
        pregStatus
        phone
        dueDate
        contactPreference
        weightLogs
        topicStates {
          items {
            id
            profileId
            mod
            kpiPre
            kpiAfter
            evaluation
            lastVisited
            visited
            screening
            plan
            lifestyleData
            goals
            goalSettings
            checklist
            articles
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTopicState = /* GraphQL */ `
  query GetTopicState($id: ID!) {
    getTopicState(id: $id) {
      id
      profileId
      mod
      kpiPre
      kpiAfter
      evaluation
      lastVisited
      visited
      screening
      plan
      lifestyleData
      goals
      goalSettings
      checklist
      articles
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTopicStates = /* GraphQL */ `
  query ListTopicStates($filter: ModelTopicStateFilterInput, $limit: Int, $nextToken: String) {
    listTopicStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileId
        mod
        kpiPre
        kpiAfter
        evaluation
        lastVisited
        visited
        screening
        plan
        lifestyleData
        goals
        goalSettings
        checklist
        articles
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRegister = /* GraphQL */ `
  query GetRegister($email: String!) {
    getRegister(email: $email) {
      email
      name
      dob
      postcode
      medibank_number
      consent
      consent_read
      consent_contact
      status
      createdAt
      updatedAt
    }
  }
`;
export const listRegisters = /* GraphQL */ `
  query ListRegisters(
    $email: String
    $filter: ModelRegisterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegisters(email: $email, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
      items {
        email
        name
        dob
        postcode
        medibank_number
        consent
        consent_read
        consent_contact
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const profilesByPregStatus = /* GraphQL */ `
  query ProfilesByPregStatus(
    $pregStatus: PregStatus
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profilesByPregStatus(
      pregStatus: $pregStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dob
        weight
        height
        isPreg
        pregStatus
        phone
        dueDate
        contactPreference
        weightLogs
        topicStates {
          items {
            id
            profileId
            mod
            kpiPre
            kpiAfter
            evaluation
            lastVisited
            visited
            screening
            plan
            lifestyleData
            goals
            goalSettings
            checklist
            articles
            owner
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
