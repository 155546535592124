import React from 'react';
import { RadioGroup, CheckboxGroup } from 'components/form';
import { TextField, FormGroup, FormLabel } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Field, OptionsInputAttr, FieldInputAttr } from '.';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formLabel: {
      display: 'flex',
      flexDirection: 'row',
      lineHeight: 1.2,
      '& > .seq': {
        alignSelf: 'flex-start',
        margin: theme.spacing(0, 1, 0, 0),
        padding: theme.spacing(0.2, 0.5),
        minWidth: '2rem',
        lineHeight: '1.5rem',
        backgroundColor: '#333',
        color: '#FFF',
        textAlign: 'center',
        textTransform: 'capitalize',
      },
    },
  }),
);

export type FieldInputProps = Pick<Field, 'type' | 'prefix' | 'desc' | 'helperText' | 'input'> & {
  value?: any;
  error?: string;
  onChange: (value: string | string[]) => void;
};

export default function FieldInput(props: FieldInputProps) {
  const classes = useStyles();
  const { input, type, prefix, desc, helperText, value, error, onChange } = props;

  const descLabel = desc && (
    <FormLabel className={classes.formLabel}>
      {prefix && <span className="seq">{prefix}</span>}
      <span>{desc}</span>
    </FormLabel>
  );

  switch (type) {
    case 'radio-group': {
      const inputAttr = input as OptionsInputAttr;
      return (
        <RadioGroup
          row={false}
          label={descLabel}
          options={inputAttr.options}
          value={value || ''}
          error={Boolean(error)}
          helperText={error || helperText}
          onChange={e => onChange(e.target.value)}
        />
      );
    }

    case 'checkbox-group': {
      const inputAttr = input as OptionsInputAttr;
      return (
        <CheckboxGroup
          row={false}
          label={descLabel}
          options={inputAttr.options}
          values={(value || []) as string[]}
          error={Boolean(error)}
          helperText={error || helperText}
          onChange={(e, values) => onChange(values)}
        />
      );
    }

    default: {
      const inputAttr = input as FieldInputAttr;
      return (
        <FormGroup>
          {descLabel}
          <TextField
            type={inputAttr.type}
            label={inputAttr.label}
            margin="normal"
            error={Boolean(error)}
            helperText={error || helperText}
            fullWidth
            value={value || ''}
            onChange={e => onChange(e.target.value)}
          />
        </FormGroup>
      );
    }
  }
}
