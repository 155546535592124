import React from 'react';
import { ArticleContent } from 'components/topics/Article';
import ArticleCard from 'components/topics/Articles/ArticleCard';
import { Box, Grid } from '@material-ui/core';
import resoruces from '../resources';
import { Content } from 'app/topics/types';

export default function Resources() {
  return (
    <ArticleContent>
      <Box my={2}>
        <Grid container spacing={2}>
          {resoruces.map((article: Content) => (
            <Grid item key={article.id} xs={12} sm={4}>
              <ArticleCard article={article} to={`/topics/preconception/articles/${article.id}`} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ArticleContent>
  );
}
