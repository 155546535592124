import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { AppPage } from 'components/page';
import ArticleContent from 'components/topics/Article/ArticleContent';

export default function Program() {
  return (
    <AppPage title="The Program">
      <ArticleContent>
        <Container fixed>
          <Typography variant="h4" gutterBottom>
            What is OptimalMe?
          </Typography>
          <p>
            OptimalMe is an online lifestyle program that aims to educate and motivate women to achieve their optimal
            level of health when considering pregnancy, during pregnancy and after birth. It is a tested and proven
            program based on the best available research that has helped 100s of women achieve a healthier lifestyle in
            the time around pregnancy. We have developed a highly interactive and engaging program that will guide you
            at this exciting and important life stage. The program also provides one-to-one health coaching to ensure
            you get the most from this program.
          </p>
          <Typography variant="h4" gutterBottom>
            Why we developed OptimalMe?
          </Typography>
          <p>
            A decade of research has shown that young women are putting on weight at a greater rate than other age
            groups. We also know that women have many barriers to prioritising their health. Therefore, we have worked
            closely with women in finding simple ways to optimise lifestyle behaviours through small changes to
            behaviour, eating patterns and physical activity. We have successfully helped women to be healthier through
            lifestyle choices, which will have positive flow on effects for the rest of their lives.
          </p>

          <Typography variant="h4" gutterBottom>
            Who should do this program?
          </Typography>
          <p>
            Any women who are considering pregnancy. We have run this program extensively with young women who are of
            reproductive age, across different geographic regions and with different cultural backgrounds and found it
            was very effective.
          </p>

          <Typography variant="h4" gutterBottom>
            Why focus on the time around pregnancy?
          </Typography>
          <p>
            About half of women going into pregnancy are overweight and we know that women put on more weight with each
            pregnancy. This is a problem for a number of reasons such as reducing the chances of getting pregnant,
            increasing the risk of complications during pregnancy and birth and, increasing the risks of longer-term
            health issues in the mother and child.
          </p>
          <p>
            Being overweight also has serious intergenerational risks, with the weight of the mother associated with
            increased weight of the child. Lastly, children born to mothers with obesity, have twice the rate of a range
            of chronic conditions such as obesity, higher insulin resistance and metabolic syndrome.
          </p>
          <p>
            Younger women of reproductive age are at increased risk of weight gain with small, but continuous increases
            in weight observed over time at a population level. The time around pregnancy (before, during and after) has
            been recognised as critical in which accelerated weight gain can occur. We have shown that women only need
            to make small lifestyle changes to optimise their weight.
          </p>
        </Container>
      </ArticleContent>
    </AppPage>
  );
}
