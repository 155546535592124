import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    infoList: {
      padding: 0,
      '& > li': {
        margin: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:last-child': {
          borderRight: 'none',
        },
        [theme.breakpoints.up('md')]: {
          borderRight: '1px solid #CCC',
        },
      },
    },
  }),
);

export type InfoItem = {
  label: string;
  value: string | number;
};

export type InfoListProps = {
  items: InfoItem[];
};

export default function InfoList(props: InfoListProps) {
  const { items } = props;
  const classes = useStyles();
  return (
    <Grid container justify="center" component="ul" className={classes.infoList}>
      {items.map((item, i) => (
        <Grid key={i} item xs={12} md={3} component="li">
          <Typography variant="body2" color="textSecondary">
            {item.label}
          </Typography>
          <Typography variant="h5">{item.value}</Typography>
        </Grid>
      ))}
    </Grid>
  );
}
