import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { RouteComponentProps, Switch, Route, Redirect, useRouteMatch, useParams } from 'react-router-dom';
import Alert from 'components/Alert';
import Article from 'components/topics/Article';
import topicModules from './topicModules';
import useTopicsummary from './useTopicSummary';
import useTopicState from './useTopicState';

import { ModuleId } from 'app/types';
import { selectProfile } from 'app/store';
import { actions } from 'app/profile/profileSlice';
import { isPregnant } from 'app/helper';

export default function Topics() {
  const { moduleId } = useParams<{ moduleId: ModuleId }>();
  const essentialsMatch = useRouteMatch<{ moduleId: string }>({ path: '/topics/:moduleId/essentials', exact: true });
  const goalsMatch = useRouteMatch<{ moduleId: string }>({ path: '/topics/:moduleId/goals', exact: true });
  const lifeStyleMatch = useRouteMatch<{ moduleId: string }>({
    path: '/topics/:moduleId/shaping_my_lifestyle',
    exact: true,
  });

  const { profile, status } = useSelector(selectProfile);
  const { topicState, updateTopicState } = useTopicState(moduleId);
  const dispatch = useDispatch();
  const topicModule = topicModules[moduleId];
  const satisfiableCompleted = 2;

  const topicSummary = useTopicsummary(topicState, moduleId);
  const {
    Essentials,
    Screening,
    Goals,
    GoalSelect,
    GoalSetting,
    Lifestyle,
    KpiPre,
    Evaluation,
    ShapingMyLifestyle, // Fix #141
  } = topicModule;

  // entry conditions
  if (essentialsMatch && Screening && isEmpty(topicState.screening)) {
    return <Redirect to={`/topics/${moduleId}/essentials/screening`} />;
  }

  if (lifeStyleMatch && Lifestyle && isEmpty(topicState.lifestyleData)) {
    return <Redirect to={`/topics/${moduleId}/shaping_my_lifestyle/lifestyle`} />;
  }

  if (goalsMatch && isEmpty(topicState.goals)) {
    return <Redirect to={`/topics/${moduleId}/goals/select`} />;
  }

  const showPre = isEmpty(topicState.kpiPre) || (moduleId !== 'preconception' && !isPregnant(profile!));

  // Show evaluation only for preconception
  const showEval =
    isEmpty(topicState.evaluation) && topicSummary.completed >= satisfiableCompleted && moduleId === 'preconception';

  return (
    <>
      <Alert alertText={status?.error} onClose={() => dispatch(actions.idle())} variant="filled" severity="error" />
      <Switch>
        <Route exact path="/topics/:moduleId/articles/:articleId" component={Article} />
        <Route
          exact
          path="/topics/:moduleId/essentials"
          render={(props: RouteComponentProps<{ moduleId: ModuleId }>) => (
            <Essentials {...props} topicSummary={topicSummary} />
          )}
        />
        <Route exact path="/topics/:moduleId/essentials/screening" component={Screening || RedirectToEntry} />
        <Route exact path="/topics/:moduleId/shaping_my_lifestyle" component={ShapingMyLifestyle} />
        <Route exact path="/topics/:moduleId/shaping_my_lifestyle/lifestyle" component={Lifestyle || RedirectToEntry} />
        <Route exact path="/topics/:moduleId/goals" component={Goals} />
        <Route exact path="/topics/:moduleId/goals/select" component={GoalSelect} />
        <Route exact path="/topics/:moduleId/goals/fill/:goalId" component={GoalSetting} />
        {moduleId === 'preconception' && (
          <Route exact path="/topics/:moduleId/evaluation" component={Evaluation || RedirectToEntry} />
        )}

        <Route component={RedirectToEntry} />
      </Switch>
      {KpiPre && (
        <KpiPre
          open={showPre}
          data={topicState.kpiPre || {}}
          updateData={data => {
            updateTopicState({ kpiPre: data });
          }}
        />
      )}
      {showEval && Evaluation && <Redirect to={`/topics/${moduleId}/evaluation`} />}
    </>
  );
}

function RedirectToEntry(props: RouteComponentProps<{ moduleId: ModuleId }>) {
  const moduleId = props.match.params.moduleId;
  const { topicState } = useTopicState(moduleId);
  const { lastVisited } = topicState;

  if (!lastVisited || lastVisited === '_essentials') {
    return <Redirect to={`/topics/${moduleId}/essentials`} />;
  }

  if (lastVisited === '_goals') {
    return <Redirect to={`/topics/${moduleId}/goals`} />;
  }

  return <Redirect to={`/topics/${moduleId}/articles/${lastVisited}`} />;
}
