import HowtoReadFoodLabel from './HowtoReadFoodLabel';
import BetterChoices from './BetterChoices';
import SnackIdeas from './SnackIdeas';
import Recipes from './Recipes';
import EstimatingPortionSizes from './EstimatingPortionSizes';
import FoodIntakeAndExercise from './FoodIntakeAndExercise';
import { Content } from 'app/topics/types';

export default [
  {
    id: 'howto_read_food_label',
    tag: 'Diet',
    title: 'How to Read a Food Label',
    desc: 'Simple guides to help you understand a food label.',
    cover: 'images/articles/preconception/resources/howto_read_food_label.jpg',
    banner: 'images/articles/preconception/resources/howto_read_food_label@2x.jpg',
    ArticleContent: HowtoReadFoodLabel,
  },
  {
    id: 'better_choices_intake',
    tag: 'Diet',
    title: 'Swaps and Tips to for Better Choices Intake',
    desc: 'Swap it, don’t stop it. Easy swaps can create much healthier habits.',
    cover: 'images/articles/preconception/resources/better_choices_intake.jpg',
    banner: 'images/articles/preconception/resources/better_choices_intake@2x.jpg',
    ArticleContent: BetterChoices,
  },
  {
    id: 'snack_ideas',
    tag: 'Diet',
    title: 'Quick Healthy Snack Ideas',
    desc: 'A collection of quick and easy snack ideas.',
    cover: 'images/articles/preconception/resources/snack_ideas.jpg',
    banner: 'images/articles/preconception/resources/snack_ideas@2x.jpg',
    ArticleContent: SnackIdeas,
  },
  {
    id: 'recipes',
    tag: 'Diet',
    title: 'Recipes',
    desc: 'A collection of healthy recipes for snacks or small meals.',
    cover: 'images/articles/preconception/resources/recipes.jpg',
    banner: 'images/articles/preconception/resources/recipes@2x.jpg',
    ArticleContent: Recipes,
  },
  {
    id: 'portion_sizes',
    tag: 'Diet',
    title: 'Estimating Portion Sizes',
    desc: 'Use your hand to keep track of portions.',
    cover: 'images/articles/preconception/resources/portion_sizes.jpg',
    banner: 'images/articles/preconception/resources/portion_sizes@2x.jpg',
    ArticleContent: EstimatingPortionSizes,
  },
  {
    id: 'exercise',
    tag: 'Diet',
    title: 'Food Intake and Exercise',
    desc: 'How long do you need to walk to burn off your food?',
    cover: 'images/articles/preconception/resources/exercise.jpg',
    banner: 'images/articles/preconception/resources/exercise@2x.jpg',
    ArticleContent: FoodIntakeAndExercise,
  },
] as Array<Content>;
