import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { RegisterStatus } from 'API';
import { MenuLabel } from 'components/toolbars';

export type FilterMenuProps = {
  value: RegisterStatus | null;
  onChange: (value: RegisterStatus | null) => void;
};

export default function FilterMenu(props: FilterMenuProps) {
  const { value, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleChange = (value: RegisterStatus | null) => () => {
    setAnchorEl(null);
    onChange(value);
  };

  return (
    <>
      <MenuLabel secondary="Status" primary={value || 'Any'} onClick={e => setAnchorEl(e.currentTarget)} />
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleChange(null)} selected={value === null}>
          All
        </MenuItem>
        <MenuItem onClick={handleChange(RegisterStatus.PENDING)} selected={value === RegisterStatus.PENDING}>
          Pending
        </MenuItem>
        <MenuItem onClick={handleChange(RegisterStatus.APPROVED)} selected={value === RegisterStatus.APPROVED}>
          Approved
        </MenuItem>
        <MenuItem onClick={handleChange(RegisterStatus.REJECTED)} selected={value === RegisterStatus.REJECTED}>
          Rejected
        </MenuItem>
      </Menu>
    </>
  );
}
