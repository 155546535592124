import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { RadioGroup } from 'components/form';

export type SelectionOpenProps = {
  value: string | null;
  options: string[];
  onChange: (value: string) => void;
};

export default function SelectionOpen(props: SelectionOpenProps) {
  const { value, options, onChange } = props;
  const [selectionValue, comment] = value?.split('|') || [];
  const isLast = selectionValue === options[options.length - 1];

  const handleChange = (e: React.ChangeEvent, value: string) => {
    onChange(value);
  };

  const handleComment = (comment: string) => {
    onChange([selectionValue, comment].join('|'));
  };

  return (
    <>
      <RadioGroup value={selectionValue || null} onChange={handleChange} options={options} />
      {isLast && (
        <Box mt={1}>
          <TextField
            variant="filled"
            label={selectionValue}
            value={comment || ''}
            onChange={e => handleComment(e.target.value)}
            fullWidth
          />
        </Box>
      )}
    </>
  );
}
