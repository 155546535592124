import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function Support() {
  return (
    <ArticleContent>
      <Quiz
        question="Most women manage fine after birth without any support."
        answer="False"
        feedback="This is false. There are different means of support available and you should not be afraid to reach out."
      />
      <h3 id="key-points">Key Points</h3>
      <ul>
        <li>There are many support networks available for women after birth</li>
        <li>
          Discuss support networks with your midwife before you leave hospital or make a list prior to giving birth
        </li>
        <li>It may be a challenging time and lots of women feel the need to engage in support networks.</li>
      </ul>
      <h3 id="why-is-this-important-to-me">Why is this important to me?</h3>
      <p>
        Pregnancy and birth can often seem easy once you return home and start navigating life as a new mum. Some
        mothers may struggle to manage breastfeeding, disrupted routines and the responsibility of caring for their
        newborn. Before you leave hospital, or even before you give birth, it is important to talk to your midwife or
        medical team about healthcare support services that you can access when you get home. This may be a challenging
        time for you and it is good to know where to turn if you need assistance. Support services can include:
      </p>
      <ul>
        <li>home-based postnatal care visit</li>
        <li>breastfeeding support</li>
        <li>lactation consultants</li>
        <li>physiotherapists</li>
        <li>maternal and child health services</li>
        <li>mental health and postnatal depression support.</li>
      </ul>
      <p>
        Most hospitals will offer a home-based postnatal care visit from a midwife within the first week of being at
        home with your new baby. The midwife will check:
      </p>
      <ul>
        <li>how you are coping</li>
        <li>how you are managing breastfeeding</li>
        <li>how any wounds are healing</li>
        <li>that your uterus is contracting well – done by examining your stomach</li>
        <li>your baby’s health and weight.</li>
      </ul>
      <h3 id="what-can-i-do">What can I do?</h3>
      <p>
        While friends and family can offer lots of valuable advice, it is common to need some outside help and support
        along the way. You can reach out to your GP or maternal and child health nurse if you have questions about yours
        and your baby’s health. There is phone support through the Maternal and Child Health Line 24/7, so you can call
        at any time of the day or night. Parent line is available 8 am till midnight, seven days a week.
      </p>
      <p>For telephone support and information, call:</p>
      <ul>
        <li>For an emergency: 000</li>
        <li>ParentLine: 13 22 89</li>
        <li>Maternal and Child Health Line: 13 22 29</li>
        <li>13 HEALTH: 13 432 584</li>
        <li>
          <a href="https://www.pregnancybirthbaby.org.au/" target="_blank" rel="noopener noreferrer">
            Pregnancy, Birth and Baby Helpline
          </a>{' '}
          with train counsellors: 1800 822 436
        </li>
        <li>
          <a href="https://www.breastfeeding.asn.au/" target="_blank" rel="noopener noreferrer">
            Australian Breastfeeding Association
          </a>
          : 1800 686 268
        </li>
      </ul>
      <h3 id="useful-links">Useful Links</h3>
      <p>For more information about support networks visit:</p>
      <ul>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/servicesandsupport/first-days-after-birth"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Victoria
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
