import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper1: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: '#A04138',
  },
  paper2: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: '#FF9D5C',
  },
  paper3: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary,
    backgroundColor: '#AEBC95',
  },
}));

export default function FoodSafety() {
  const classes = useStyles();
  return (
    <ArticleContent>
      <Quiz
        question="All meat should be avoided during pregnancy. "
        answer="False"
        feedback="This is not true. Meats are safe to eat if cooked thoroughly and prepared/stored correctly."
      />
      <h4 id="key-points">Key points</h4>
      <ul>
        <li>Remember these rules: keep it cold, keep it clean, keep it hot and check the label.</li>
        <li>The info-graphics in this section can be saved to your phone to refer to when making food choices.</li>
        <li>Proper food choices and handling will make you less likely to contract a food related illness.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>

      <p>
        The section called{' '}
        <a href="/topics/pregnancy/articles/food_poisoning">
          Listeria, Salmonella and Toxoplasmosis in Pregnancy
        </a>{' '}
        outlines information about food born bacteria that can harm you and your baby during pregnancy. Please refer to
        this section for more information.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <p>KEEP IT COLD</p>
              <ul>
                <li>Keep foods in the fridge below 5&#x2103;.</li>
                <li>
                  Put cold foods in the fridge straight away – do not leave shopping on the bench or leftovers cooling
                  at room temperature.
                </li>
                <li>Store leftovers in the fridge promptly and eat within a day.</li>
                <li>
                  Do not eat fridge that is meant to be kept cool if it has been out of the fridge for two hours or
                  more.
                </li>
                <li>Defrost and marinate foods, especially means, in the fridge – not on the bench.</li>
                <li>Shop with a cooler bag and take an Esky to picnics/parties.</li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <p>KEEP IT CLEAN</p>
              <ul>
                <li>Wash and dry hands thoroughly before preparing or eating foods, even small snacks.</li>
                <li>Keep benches, kitchen equipment and tableware clean.</li>
                <li>Separate raw and cooked food.</li>
                <li>
                  Do not use the same chopping board or knife after it has been used to for raw or unwashed foods.
                </li>
                <li>Don’t not let raw meat juice drip onto other foods.</li>
                <li>Avoiding eating food made by someone who is unwell with diarrhoea or gastro symptoms.</li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <p>KEEP IT HOT</p>
              <ul>
                <li>Cook foods until they are steaming hot.</li>
                <li>Reheat foods until they are steaming hot.</li>
                <li>Heat leftovers as soon as they come out of the fridge – don’t let them sit on the bench.</li>
                <li>Make sure there is no pink left in cooked meats such as mince, sausages and chicken.</li>
                <li>Look for clear juices before eating freshly cooked pork or chicken.</li>
                <li>Heat marinades containing raw meat juices to boiling just before serving the meal.</li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <p>KEEP IT CLEAN</p>
              <ul>
                <li>Do not eat food past the use-by date.</li>
                <li>Note the best before date.</li>
                <li>Follow storage and cooking instructions.</li>
                <li>Ask for information about unpackaged foods at cafes, restaurants etc.</li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <p>
        Modified from: NSW Food Authority. Pregnancy and food safety [Internet]. Newington: NSW Health; 2016 [updated
        2018 Oct 11; cited 2019 Jun 24]. Available from: http://www.foodauthority.nsw.gov.au/
      </p>
      <p>
        <strong>
          Use the following tables to guide your food choices during your pregnancy. It highlights some foods that
          pregnant women are recommended to avoid. Foods that should be avoided, may commonly contain harmful bacteria
          such as Listeria or Salmonella.
        </strong>
      </p>

      <p>Red = Do Not Eat</p>
      <p>Orange = Eat With Caution</p>
      <p>Green = Safe to Eat</p>

      <h4>Flesh Foods (Meat, Poultry &amp; Seafood)</h4>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper1}>
              <p>DO NOT EAT</p>
              <ul>
                <li>
                  <strong>Processed Meats</strong> (salami, ham, hot dogs,sausages, chicken nuggets,chicken slices etc.)
                  - DO NOT EAT, unless thoroughly cooked to at least 75&#x2103; and eaten soon afterwards.
                </li>
                <li>
                  <strong>Any raw meat</strong> (chicken or other poultry, beef, port etc.)
                </li>
                <li>
                  <strong>Meat paste or spread</strong>
                </li>
                <li>
                  <strong>Cold chicken or turkey</strong> (used in sandwiches or cold salads){' '}
                </li>
                <li>
                  <strong>Raw seafood</strong> (commonly found in sushi)
                </li>
                <li>
                  <strong>Chilled, peeled prawns</strong>
                </li>
                <li>
                  <strong>Pre-made / store-bought sushi</strong>
                </li>
              </ul>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper2}>
              <p>EAT WITH CAUTION</p>
              <ul>
                <li>
                  <strong>Hot takeaway chicken</strong> - Must be freshly cooked and eaten while hot. Leftovers must be
                  stored in the fridge, reheated to at least 60&#x2103; and used within a day.
                </li>
              </ul>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper3}>
              <p>GOOD TO EAT</p>
              <ul>
                <li>
                  <strong>Home cooked poultry</strong> - Ensure meat is cooked thoroughly to at least 74&#x2103; and eat
                  while hot. Leftovers must be stored in the fridge, reheated to at least 60&#x2103; and used within a
                  day.{' '}
                </li>
                <li>
                  <strong>Cooked fish and seafood</strong> - Cook thoroughly to at least 63&#x2103; and eat while hot.
                  Leftovers must be stored in the fridge, reheated to at least 60&#x2103; and used within a day.
                </li>
                <li>
                  <strong>Home-made sushi</strong> - using only vegetables or cooked meats. Do not use any raw meat or
                  seafood and eat immediately.{' '}
                </li>
                <li>
                  <strong>Whole cuts of meat or mince</strong> - Cook thoroughly to at least 71&#x2103; (medium) and eat
                  while hot.
                </li>
              </ul>
            </Paper>
          </Grid>
        </Grid>
      </div>

      <p>
        Download the overview here:{' '}
        <a href="assets/Meat_Seafood.pdf" target="_blank" rel="noopener noreferrer">
          FLESH FOODS (MEAT, POULTRY &amp; SEAFOOD)
        </a>
      </p>

      <h4>Dairy &amp; Eggs</h4>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper1}>
              <p>DO NOT EAT</p>
              <ul>
                <li>
                  <strong>Soft or semi-soft cheese</strong> (brie, camembert, ricotta, feta blue cheese etc.) - DO NOT
                  EAT, unless thoroughly cooked to at least 75&#x2103; and eaten soon afterwards.
                </li>
                <li>
                  <strong>Soft serve ice cream</strong>
                </li>
                <li>
                  <strong>Fried ice cream</strong>
                </li>
                <li>
                  <strong>Unpasteurised (raw) other dairy</strong>
                </li>
                <li>
                  <strong>Eggs</strong> raw in food (e.g. home-made mayonnaise, aioli, chocolate mousse, cake batter,
                  pancake batter.
                </li>
              </ul>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper2}>
              <p>EAT WITH CAUTION</p>
              <ul>
                <li>
                  <strong>Processed cheese</strong> (made from pasteurised milk), cheese spreads, cottage cheese, cream
                  cheese etc. (e.g. mozzarella, halloumi and goat cheese) - Store in the fridge and eat within two days
                  of opening.{' '}
                </li>
                <li>
                  <strong>Store-bought custard</strong> - Can be eaten cold if freshly opened. Must be stores in the
                  fridge, reheated to at least 60&#x2103; and used within a day of opening. Check ‘best before’ or
                  ‘use-by’ date.{' '}
                </li>
                <li>
                  <strong>Home-made custard</strong> - Cook thoroughly to at least 71&#x2103; and eat while hot. Store
                  in fridge. Always reheat to at least 60&#x2103; and use within a day.{' '}
                </li>
                <li>
                  <strong>Cooked eggs</strong> (e.g. friend eggs, scrambled eggs, quiche) - Cook thoroughly to at least
                  71&#x2103;. Do not use cracked or dirty eggs.{' '}
                </li>
              </ul>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper3}>
              <p>GOOD TO EAT</p>
              <ul>
                <li>
                  <strong>Hard cheese</strong> (cheddar, Swiss, gouda, parmesan and tasty cheese) - Safe to eat. Must be
                  stored in the fridge.
                </li>
                <li>
                  <strong>Packaged, frozen ice cream</strong> - Keep frozen and eat while frozen.
                </li>
                <li>
                  <strong>Pasteurised milk, cream, yoghurt etc.</strong> - Check ‘best before’ or ‘use-by’ date. Ensure
                  storage instructions are followed.{' '}
                </li>
                <li>
                  <strong>Eggs</strong> in non-refrigerated commercial products, e.g. mayonnaise and aioli.{' '}
                </li>
              </ul>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <p>
        Download the overview here:
        <a href="assets/Dairy.pdf" target="_blank" rel="noopener noreferrer">
          DAIRY &amp; EGGS
        </a>
      </p>

      <h4>Fruit and veg</h4>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper1}>
              <p>DO NOT EAT</p>
              <ul>
                <li>
                  <strong>
                    Alfalfa, broccoli, onion, sunflower, clover, radish, soybean and snowpea sprouts, and mung beans
                  </strong>{' '}
                  - DO NOT EAT RAW. Can be used thoroughly cooked.{' '}
                </li>
                <li>
                  <strong>Pre-prepared or pre-packed salads</strong> (includes fruit salads), such as those from salad
                  bars or cafes{' '}
                </li>
                <li>
                  <strong>Rockmelon</strong>
                </li>
              </ul>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper3}>
              <p>GOOD TO EAT</p>
              <ul>
                <li>
                  <strong>Home-made salads</strong> - Safe to eat if salad ingredients are WASHED WELL. Leftovers must
                  be stored in the fridge and eaten within a day.{' '}
                </li>
                <li>
                  <strong>Whole, fresh fruits</strong> - Wash well before eating and cutting
                </li>
                <li>
                  <strong>Fresh vegetables and herbs</strong> - Wash well just before eating or using in cooking.{' '}
                </li>
                <li>
                  <strong>Frozen vegetables</strong> - Must be cooked. Do not eat uncooked.{' '}
                </li>
              </ul>
            </Paper>
          </Grid>
        </Grid>
      </div>

      <p>
        Download the overview here:
        <a href="assets/Fruit_veg.pdf" target="_blank" rel="noopener noreferrer">
          FRUIT &amp; VEGETABLES
        </a>
      </p>

      <h4>Other foods</h4>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper1}>
              <p>DO NOT EAT</p>
              <ul>
                <li>
                  <strong>Chicken or poultry stuffing</strong> - DO NOT EAT. Unless cooked separately and eaten hot.{' '}
                </li>
              </ul>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper2}>
              <p>EAT WITH CAUTION</p>
              <ul>
                <li>
                  <strong>Cooked foods/ Leftovers</strong> - Store left overs in the fridge. Always reheat to at least
                  60&#x2103; and use within a day.
                </li>
                <li>
                  <strong>Canned fruit, vegetables, beans, lentils, fish etc.</strong> - Safe to eat. Left overs must be
                  stored in a clean, sealed container in the fridge and used within a day.{' '}
                </li>
                <li>
                  <strong>Store-bought or home-made hummus and dips</strong> - Store in fridge and eat within two days
                  of opening or making. Check ‘best before’ and ‘use-by’ on storebought options.{' '}
                </li>
              </ul>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper3}>
              <p>GOOD TO EAT</p>
              <ul>
                <li>
                  <strong>All soy products</strong> (e.g. soy milk, yoghurt and ice-cream, tofu etc.) - Check ‘best
                  before’ or ‘use-by’ date. Ensure storage instructions are followed.{' '}
                </li>
              </ul>
            </Paper>
          </Grid>
        </Grid>
      </div>

      <p>
        Download the overview here:
        <a href="assets/Other_foods.pdf" target="_blank" rel="noopener noreferrer">
          OTHER FOODS
        </a>
      </p>

      <p>
        Modified from: NSW Food Authority. Foods to avoid during pregnancy [Internet]. Newington: NSW Health; 2016
        [updated 2019 Jan 14; cited 2019 Jun 24]. Available from:{' '}
        <a href="http://www.foodauthority.nsw.gov.au/" target="_blank" rel="noopener noreferrer">
          NSW Food Authority
        </a>
      </p>
    </ArticleContent>
  );
}
