declare global {
  interface Window {
    gtag?: (key: string, value: string, config?: any) => void;
  }
}

export default function gtag(key: string, value: string, config?: any) {
  if (window.gtag) {
    window.gtag(key, value, config);
  }
}
