import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will review my health condition with my GP as part of my pregnancy planning.';

export default function ChronicConditions() {
  return (
    <ArticleContent>
      <Quiz
        question="My condition is well managed so I can start trying to conceive anytime."
        answer="False"
        feedback="This is false. Even well managed conditions require review prior to pregnancy."
      />

      <h4>Key points</h4>
      <ul>
        <li>Chronic conditions need to be well managed in the lead up to pregnancy</li>
        <li>Some medications may need to be reviewed</li>
        <li>Some conditions may need a stabilisation period prior to pregnancy</li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        If you have a medical condition or chronic disease, it is important this condition is being well managed by you
        and your doctor. A range of pre-existing medical conditions may increase risk to the mother and/or baby
        throughout pregnancy.
      </p>
      <p>
        It is important to discuss your pregnancy plans with your specialist or GP. Some chronic conditions, such as
        epilepsy, require a period of stabilisation prior to conception, and appropriate contraception must be provided
        to facilitate this. A review of medications is necessary to gauge if they may potentially cause harm.
        Medications may need to be substituted with safer options, dosage may be changed or in some cases alternatives
        to medications may be considered.
      </p>

      <h4>What can I do?</h4>
      <p>Discuss pregnancy planning with my GP prior to conceiving.</p>

      <h4>Action</h4>
      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information visit:</p>

      <ul>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-medication-drugs-and-alcohol"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel (Medications and Pregnancy)
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/chronic-illness"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel (General Information)
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
