import React, { useState } from 'react';

import { styled, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Container, Box, StyledProps } from '@material-ui/core';
import Video from 'components/Video';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
    },
    menu: {
      margin: '0 auto',
      padding: 0,
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      minHeight: '2em',
    },
  }),
);

type DotProps = {
  active: boolean;
} & Pick<React.HTMLProps<HTMLLIElement>, 'onClick'>;

const Dot = styled((props: StyledProps & DotProps) => {
  const { className, onClick } = props;
  return <li className={className} onClick={onClick} />;
})((props: DotProps) => ({
  width: 15,
  height: 15,
  borderRadius: '10%',
  border: '1px solid rgba(0, 0, 0, 0.5)',
  margin: '0 0.5rem',
  cursor: 'pointer',
  backgroundColor: props.active ? '#333' : '#FFF',
}));

export type VideoType = {
  id: string;
  title: string;
};

export type VideoHeadingProps = {
  videos: VideoType[];
};

export default function Videos(props: VideoHeadingProps) {
  const { videos } = props;
  const classes = useStyles();
  const [videoIndex, setVideoIndex] = useState(0);
  const video = videos[videoIndex];
  return (
    <Container className={classes.root} fixed>
      <Box mx="auto" width={{ xs: 360, md: 500, lg: 760 }} height={{ xs: 250, md: 325, lg: 460 }} mb={0}>
        <Video id={video.id} title={video.title} />
      </Box>
      {videos.length > 0 && (
        <ul className={classes.menu}>
          {videos.map((video: VideoType, index: number) => (
            <Dot key={video.id} onClick={() => setVideoIndex(index)} active={index === videoIndex} />
          ))}
        </ul>
      )}
    </Container>
  );
}
