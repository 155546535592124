export default {
  alcohol: 'Alcohol consumption',
  weight: 'My weight',
  exercise: 'Exercise',
  sitting: 'Time spent sitting down',
  fruit: 'Eating fruit',
  vegatables: 'Eating vegetables',
  junkfood: 'Eating junk food',
  junkfood_house: 'Having junk food in the house',
  high_sugar: 'High fat or high sugar foods',
  water: 'Drinking water',
  softdrink: 'Drinking soft drink or sugary drinks',
  cook: 'Home cooked meals',
  packaged_food: 'Packaged or pre-prepared foods',
  snacking: 'Snacking',
  regular_meals: 'Eating regular meals',
  health_priority: 'Making my health a priority',
  worklife: 'My work/life balance',
  stress: 'Stress levels',
  sleep: 'Sleep',
  relaxing: 'Time spent relaxing',
};
