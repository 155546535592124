import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function Depression() {
  return (
    <ArticleContent>
      <Quiz
        question="Mothers and fathers experience the same level of anxiety and/or depression after the birth of their child."
        answer="False"
        feedback="No, mothers are at greater risk with 1 in 7 experiencing anxiety and/or depression compared to fathers at around 1 in 10."
      />
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>
          It quite normal to be concerned for your baby’s health but constantly feeling nervous, having anxious thoughts
          or worrying that something is or will go wrong, may be an indication of antenatal anxiety.
        </li>
        <li>
          Postnatal anxiety and depression can vary in intensity from mild, moderate to severe.
          <br />
        </li>
        <li>Postnatal anxiety and depression are different to baby blues.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        This is important because around 1 in 7 mothers will experience anxiety and/or depression after having a baby.
        It is good to recognise the signs and symptoms so if needed, you can take action early.
      </p>
      <p>
        The symptoms of depression and/or anxiety post-birth can vary in intensity from mild, moderate to severe and not
        all women will experience the same symptoms. Below is a list of commonly experienced symptoms.
      </p>
      <ul>
        <li>
          Panic attacks (a racing heart, palpitations, shortness of breath, shaking or feeling physically ‘detached’
          from your surroundings)
        </li>
        <li>Persistent, generalised worry, often focused on fears for the health, well-being or safety of the baby</li>
        <li>The development of obsessive or compulsive thoughts and/or behaviours</li>
        <li>Abrupt mood swings</li>
        <li>Feeling constantly sad, low, or crying for no obvious reason</li>
        <li>Being nervous, ‘on edge’, or panicky</li>
        <li>Feeling constantly tired and lacking energy</li>
        <li>
          Having little or no interest in all the normal things that bring joy (like time with friends, exercise,
          eating, or sharing partner time)
        </li>
        <li>Sleeping too much or not sleeping very well at all</li>
        <li>Losing interest in intimacy</li>
        <li>Withdrawing from friends and family</li>
        <li>Being easily annoyed or irritated</li>
        <li>Feeling angry</li>
        <li>
          Finding it difficult to focus, concentrate or remember (people with depression often describe this as a ‘brain
          fog’)
        </li>
        <li>Engaging in more risk taking behaviour (e.g. alcohol or drug use)</li>
        <li>Having thoughts of harming your baby</li>
      </ul>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Postnatal anxiety and depression can vary in intensity from mild, moderate to severe. Symptoms can begin
        suddenly after birth or appear gradually in the weeks or months during the first year after birth.
      </p>
      <p>
        It’s important to remember that postnatal anxiety and depression are temporary and treatable. It is important to
        take action early and seek help to prevent a worsening of symptoms.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about Anxiety and depression, visit:</p>
      <ul>
        <li>
          <a
            href="https://www.cope.org.au/new-parents/postnatal-mental-health-conditions/common-mental-health-problems-after-having-a-baby/"
            target="_blank"
            rel="noopener noreferrer">
            Centre for Perinatal Excellence
          </a>
        </li>
        <li>
          <a href="https://www.panda.org.au/info-support/after-birth" target="_blank" rel="noopener noreferrer">
            PANDA
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
