import React from 'react';
import { Card, CardHeader, CardContent, Box, Typography, Divider } from '@material-ui/core';
import { UserProfile } from 'app/admin/types';
import moment from 'moment';
import InfoList from 'components/InfoList';
import { calcBmi, bmiLegends, isPregnant } from 'app/helper';
import GradiantBMI from 'components/GradiantBMI';
import WeightGainChart from 'components/WeightGainChart';

export type WeightSummaryProps = {
  profile: UserProfile;
};
export default function WeightSummary(props: WeightSummaryProps) {
  const { profile } = props;
  const { dob, weight, height, dueDate, weightLogs } = profile;
  const [bmi, category] = calcBmi(weight, height);

  return (
    <Card>
      <CardHeader title="Weight" />
      <CardContent>
        <Box>
          <GradiantBMI weight={weight} height={height} />
          <InfoList
            items={[
              {
                label: 'Age',
                value: moment().diff(moment(dob, 'DD/MM/YYYY'), 'years'),
              },
              {
                label: 'Weight',
                value: `${weight} kg`,
              },
              {
                label: 'Height',
                value: `${height} cm`,
              },
              {
                label: `BMI (${bmiLegends[category].label})`,
                value: bmi.toFixed(1),
              },
            ]}
          />

          {isPregnant(profile) && (
            <>
              <Typography variant="h6">Pregnant</Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Due Date : {dueDate}
              </Typography>
              <Divider />
              <Box mt={2}>
                <WeightGainChart normalWeight={weight} height={height} dueDate={dueDate} weightLogs={weightLogs} />
              </Box>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
