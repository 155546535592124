import React from 'react';
import NavToolbar, { Nav } from 'components/nav/NavToolbar';
//import ArticleCard from 'components/topics/Articles/ArticleCard';
import { ScrollTriggerPage } from 'components/page';
import { Typography/*, Box, Grid, CardHeader, Card, CardContent*/ } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
//import { shapingLife } from '../content';
//import { Content } from '../../types';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      backgroundColor: 'transparent',
      color: '#805D49',
    },
    filler: {
      backgroundImage: 'url(images/topics/preconception/goals_banner.jpg)',
      backgroundSize: 'cover',
    },
    header: {
      padding: theme.spacing(2, 0),
      color: '#805D49',
    },
  }),
);

const navs: Nav[] = [
  {
    label: 'Essential Information',
    path: '/topics/preconception/essentials',
    exact: false,
  },
  {
    // Fix # 141
    label: 'Shaping My Lifestyle',
    path: '/topics/preconception/shaping_my_lifestyle',
    exact: false,
  },
  {
    label: 'My Goals',
    path: '/topics/preconception/goals',
    exact: false,
  },
];

export function GoalsPage(props: React.HTMLProps<HTMLElement>) {
  const classes = useStyles();
  return (
    <ScrollTriggerPage
      title="Pre-Pregnancy Goals"
      extraToolbar={<NavToolbar navs={navs} />}
      classes={{ appBar: classes.appBar, fillerBg: classes.filler }}>
      <div className={classes.header}>
        <Typography variant="h4" color="inherit">
          My Goals
        </Typography>
      </div>
      {props.children}
    </ScrollTriggerPage>
  );
}

export function GoalsPageWithArticles(props: React.HTMLProps<HTMLElement>) {
  return (
    <GoalsPage>
      {
        /* --- Commented out by Saad Ahmad to Fix issue #141 
        <Card>
          <CardHeader title="Shaping my Lifestyle" />
          <CardContent>
            <Typography gutterBottom>
              Below information about healthy lifestyles is relevant to you. These recommendations for physical activity and diet will help optimise your lifestyle. In addition, we introduce six healthy lifestyle messages that will simplify healthy living and are a great way to start making small, achievable changes to your everyday life. They are based on many years of research and we recommend integrating these into your lifestyle to maintain your health and weight long-term.
            </Typography>
          </CardContent>
        </Card>
        <Box my={2}>
          <Grid container spacing={2}>
            {shapingLife.map((article: Content) => (
              <Grid item key={article.id} xs={12} sm={4}>
                <ArticleCard article={article} to={`/topics/preconception/articles/${article.id}`} />
              </Grid>
            ))}
          </Grid>
        </Box>
        */
      }
      {props.children}
    </GoalsPage>
  );
}
