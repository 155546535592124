import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function SexAndPregnancy() {
  return (
    <ArticleContent>
      <Quiz
        question="All women have an increased desire for sex while pregnant."
        answer="False"
        feedback="This is not true. Some women experience increased sexual desire while others experienced decreased sexual desire."
      />
      <h4 id="key-points">Key points</h4>
      <ul>
        <li>Sex is safe in pregnancy unless there are complications.</li>
        <li>
          Women react differently to hormonal changes during pregnancy with some having increased interest in sex while
          others have less interest. This level of interest may change through the pregnancy too.
        </li>
        <li>Most forms of sex, including vaginal, anal, and oral sex, are perfectly safe while pregnant.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        You and/or your partner may have concerns about the safety of your unborn child during sex and may also
        experience changes in your desire for sex during pregnancy.
      </p>
      <p>
        <strong>Is my baby affected by sex?</strong>
      </p>
      <p>
        In a pregnancy without complications sex is safe for your unborn child, who is well protected by the cervix (the
        muscles at the top of the vagina), uterus, and amniotic fluid.
      </p>
      <p>
        If you do have complications it is important to discuss with your nurse/doctor any precautions you may need to
        take.
      </p>
      <p>
        Sex may not be recommended if: - You have a risk of miscarriage - You have unexpected vaginal bleeding - Your
        water has broken, or amniotic fluid is leaking - Your placenta is unusually low and covers the cervix (called
        placenta previa) - You are leaking amniotic fluid (the fluid protecting the baby) - You have a history of, or
        show signs of, early labour.
      </p>
      <p>
        <strong>Sexual desire in pregnancy</strong>
      </p>
      <p>
        Pregnancy produces many changes that can affect your desire for sex. Higher levels of oestrogen and
        progesterone, as well as an increase in blood flow to the genitals, can lead to increased sexual desire for some
        women. However, nausea, fatigue, stress, and the many physical, social and psychological changes that occur as a
        result of pregnancy can reduce your interest in sex.
      </p>
      <p>
        <strong>Changes in sexual interest at different stages in the pregnancy</strong>
      </p>
      <p>
        Desire may change at different stages in the pregnancy also. During the first three months, some women notice a
        decrease in both sexual desire and sexual satisfaction due to surging hormone levels and uncomfortable physical
        symptoms such as: - mood swings - nausea - fatigue - stress - digestive issues - tender breasts.
      </p>
      <p>Other women find that they are more interested in sex, sometimes this interest is very intense.</p>
      <p>
        Women commonly report increased sexual interest in the 3 to 6 months stage of pregnancy as the hormones continue
        to increase and many women feel better overall.
      </p>
      <p>
        As pregnancy progresses, having sex can become more of a challenge. You will need to explore different positions
        as sex with your partner on top can become uncomfortable. It may be better to lie on your sides, either facing
        each other or with your partner behind. Experimenting until both of you are ok with your approach, is ideal.
      </p>
      <p>
        <strong>Will sex cause me to have an early birth?</strong>
      </p>
      <p>
        In a normal pregnancy, sex will not cause you to have an early birth. However, sex can assist to bring on the
        birth if you are close to your date or past it. Your health professional may suggest having sex as a means to
        bring on birth if you are growing tired and wish to commence the process. This is because semen contains
        hormones, called prostaglandins, that can cause uterine contractions and start the birth process.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Talk with your health professional (doctor, midwife) about your concerns for the safety of your unborn child
        during sex. Also, initiate discussion with your partner about how both of you feel about sex while you are
        pregnant.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about sex during pregnancy visit:</p>
      <ul>
        <li>
          <a
            href="https://www.sexualhealthaustralia.com.au/sex_during_pregnancy.html"
            target="_blank"
            rel="noopener noreferrer">
            Sexual Health Australia
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
