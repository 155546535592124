import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { styled, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Typography, Box, IconButton, Grid, Menu, MenuItem } from '@material-ui/core';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArticleCard from './ArticleCard';

import { ModuleId } from 'app/types';
import { TopicSummary, Content } from 'app/topics/types';
import useTopicState from 'app/topics/useTopicState';

//----------------------------
// Articles Component
//----------------------------
type Group = [Content[], Content[]];

/**
 * function to build article groups
 *
 * @param articles All articles
 * @param plan Current plan
 */
function buildArticleGroups(articles: Array<Content>, plan: Array<string>): Group {
  return articles.reduce<Group>(
    (group, article) => {
      const [primary, secondary] = group;
      return plan.indexOf(article.id) < 0 ? [primary, [...secondary, article]] : [[...primary, article], secondary];
    },
    [[], []],
  );
}

export type ArticlesProps = {
  moduleId: ModuleId;
  articles: Content[];
  topicSummary: TopicSummary;
};

type MenuTarget = {
  anchor: HTMLElement;
  articleId: string;
};

/**
 * Articles Component
 *
 * @param props ArticlesProps
 */
export default function Articles(props: ArticlesProps) {
  const { moduleId, articles, topicSummary } = props;
  const history = useHistory();
  const { topicState, updateTopicState } = useTopicState(moduleId);

  const [expanded, setExpanded] = React.useState(false);
  const [showPrimaryMenu, setShowPrimaryMenu] = React.useState<MenuTarget | null>(null);
  const [showSecondaryMenu, setShowSecondaryMenu] = React.useState<MenuTarget | null>(null);

  const plan = topicState.plan || [];
  const [primary, secondary] = buildArticleGroups(articles, plan);

  const updatePlan = (articleId: string, included: boolean) => {
    const planSet = new Set(plan);
    const success = included ? planSet.add(articleId) : planSet.delete(articleId);
    if (success) {
      updateTopicState({ plan: Array.from(planSet) });
    }
  };

  const addToMyPlan = (articleId: string) => {
    updatePlan(articleId, true);
    setShowSecondaryMenu(null);
  };

  const removeFromMyPlan = (articleId: string) => {
    updatePlan(articleId, false);
    setShowPrimaryMenu(null);
  };

  const visit = (target: MenuTarget | null) => {
    if (target !== null) {
      history.push(`/topics/${moduleId}/articles/${target.articleId}`);
    }
  };

  const handleArticleAction = (articleId: string, isPrimary: boolean) => (e: React.MouseEvent<HTMLElement>) => {
    const menuTarget = { anchor: e.currentTarget, articleId };
    isPrimary ? setShowPrimaryMenu(menuTarget) : setShowSecondaryMenu(menuTarget);
  };

  const getProgress = (articleId: string) => {
    const progress = topicSummary.articles[articleId];
    if (!progress) return 0;

    const { total, completed } = progress;
    return completed / total;
  };

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Essential information for you
      </Typography>

      <Typography gutterBottom>
        Based on your responses, the topics most important to you are displayed below.
      </Typography>

      <Grid container spacing={2}>
        {primary.map((article: Content) => (
          <Grid item key={article.id} xs={12} sm={6} md={4}>
            <ArticleCard
              to={`/topics/${moduleId}/articles/${article.id}`}
              article={article}
              progress={getProgress(article.id)}
              extraActions={
                <IconButton onClick={handleArticleAction(article.id, true)}>
                  <MoreVertIcon />
                </IconButton>
              }
            />
          </Grid>
        ))}
      </Grid>

      {!isEmpty(secondary) && (
        <Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={4}>
            <Typography variant="caption" gutterBottom>
              Click the button below to view other recommended reading.
            </Typography>
            <IconButton onClick={() => setExpanded(!expanded)} color="primary">
              {expanded ? <UnfoldMoreIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>

          <Expandable toggled={expanded}>
            <Typography variant="h5" gutterBottom>
              Other recommended reading
            </Typography>
            <Grid container spacing={2}>
              {secondary.map((article: Content) => (
                <Grid item key={article.id} xs={12} sm={4}>
                  <ArticleCard
                    to={`/topics/${moduleId}/articles/${article.id}`}
                    article={article}
                    extraActions={
                      <IconButton onClick={handleArticleAction(article.id, false)}>
                        <MoreVertIcon />
                      </IconButton>
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Expandable>
        </Box>
      )}

      <Menu
        id="add-menu"
        open={Boolean(showPrimaryMenu)}
        anchorEl={showPrimaryMenu && showPrimaryMenu.anchor}
        onClose={() => setShowPrimaryMenu(null)}>
        <MenuItem onClick={() => visit(showPrimaryMenu)}>Read</MenuItem>
        <MenuItem onClick={() => showPrimaryMenu && removeFromMyPlan(showPrimaryMenu.articleId)}>
          Remove from my plan
        </MenuItem>
      </Menu>

      <Menu
        id="remove-menu"
        open={Boolean(showSecondaryMenu)}
        anchorEl={showSecondaryMenu && showSecondaryMenu.anchor}
        onClose={() => setShowSecondaryMenu(null)}>
        <MenuItem onClick={() => visit(showSecondaryMenu)}>Read</MenuItem>
        <MenuItem onClick={() => showSecondaryMenu && addToMyPlan(showSecondaryMenu.articleId)}>
          Add to my plan
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

const Expandable = styled((props: React.HTMLProps<HTMLElement>) => (
  <section className={props.className}>{props.children}</section>
))((props: { theme: Theme; toggled: boolean }) => {
  return {
    margin: props.theme.spacing(4, 0),
    paddingBottom: props.theme.spacing(4),
    overflow: 'hidden',
    transition: 'all 300ms ease-in',
    ...(props.toggled
      ? {
          maxHeight: 'inherit',
          opacity: 1,
        }
      : {
          maxHeight: 0,
          opacity: 0,
        }),
  };
});
