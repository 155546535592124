import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 0),
      '& dt': {
        fontWeight: 500,
        margin: theme.spacing(0, 0, 1, 0),
      },
      '& dd': {
        margin: theme.spacing(0, 0, 1, 0),
        color: theme.palette.text.secondary,
      },
    },
  }),
);

export type Attr<T> = {
  attr: keyof T;
  label: string;
  format?: (v: any) => React.ReactNode;
};

export type AttrListProps<T> = {
  data: T;
  attrs: Attr<T>[];
};

export default function AttrList<T>(props: AttrListProps<T>) {
  const classes = useStyles();
  const { data, attrs } = props;
  return (
    <Grid container spacing={2} component="dl" className={classes.root}>
      {attrs.map(({ attr, label, format }) => {
        const value = data[attr];
        return (
          <Grid key={attr as string} item xs={6} md={4} lg={3}>
            <dt>{label}</dt>
            <dd>{value === undefined ? '-' : format ? format(value) : value}</dd>
          </Grid>
        );
      })}
    </Grid>
  );
}
