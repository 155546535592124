import React from 'react';
import clsx from 'clsx';
import { Container, useScrollTrigger, WithStyles, withStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import { NavPage, AppBar } from 'components/nav';
import AppDrawer from './AppDrawer';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingBottom: theme.spacing(4),
      overflow: 'auto',
    },

    filler: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 300,
    },

    container: {
      zIndex: 10,
      paddingTop: 150,
      paddingBottom: theme.spacing(4),
    },

    appBar: {},

    appBarTriggered: {},

    fillerBg: {},
  });

export type SimplePageProps = React.HTMLProps<HTMLElement> &
  WithStyles<typeof styles> & {
    title: string;
    extraToolbar?: React.ReactNode;
  };

export default withStyles(styles)((props: SimplePageProps) => {
  const { title, extraToolbar, classes } = props;
  const [elm, setElm] = React.useState<Node>();
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 60, target: elm });

  return (
    <NavPage drawerContent={<AppDrawer />}>
      <AppBar
        classes={{ root: trigger ? classes.appBarTriggered : classes.appBar }}
        elevation={trigger ? 4 : 0}
        title={title}
        extraToolbar={extraToolbar}
      />
      <main className={classes.content} ref={e => setElm(e as Node)}>
        <div className={clsx(classes.filler, classes.fillerBg)} />
        <Container className={classes.container} maxWidth="lg">
          <div>{props.children}</div>
        </Container>
      </main>
    </NavPage>
  );
});
