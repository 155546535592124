import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Button,
  Divider,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttrList from 'components/AttrList';
import { toUserAttrs } from 'app/admin/helper';
import { AWSUser } from 'app/admin/types';
import { disableUser, enableUser, confirmUserSignUp } from '../userSlice';

const useStyles = makeStyles(theme =>
  createStyles({
    expanded: {
      backgroundColor: theme.palette.grey[100],
    },
    content: {
      alignItems: 'center',
      '& > *': {
        flex: 0.5,
      },
      '& > *:not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },
    actions: {
      display: 'flex',
    },
  }),
);

export type UserPanelProps = {
  user: AWSUser;
};

export function UserPanel(props: UserPanelProps) {
  const { user } = props;
  const classes = useStyles();
  const userAttrs = toUserAttrs(user);
  const dispatch = useDispatch();

  const handleDisable = (username: string) => () => {
    dispatch(disableUser(username));
  };

  const handleEnable = (username: string) => () => {
    dispatch(enableUser(username));
  };

  const handleConfirm = (username: string) => () => {
    dispatch(confirmUserSignUp(username));
  };

  return (
    <ExpansionPanel key={user.Username}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          expanded: classes.expanded,
          content: classes.content,
        }}>
        <div>
          <Typography variant="body1">{user.Username}</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {userAttrs.email}
          </Typography>
        </div>
        <Typography variant="body2">{user.UserStatus}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <AttrList
            data={userAttrs}
            attrs={[
              {
                attr: 'sub',
                label: 'Subject',
              },
              {
                attr: 'name',
                label: 'Name',
              },
              {
                attr: 'email',
                label: 'Email',
              },
              {
                attr: 'custom:dob',
                label: 'Date Of Birth',
              },
              {
                attr: 'custom:postcode',
                label: 'PostCode',
              },
              {
                attr: 'custom:medibank_number',
                label: 'Medibank Number',
              },
            ]}
          />
          <Divider />
          <AttrList<AWSUser>
            data={user}
            attrs={[
              {
                attr: 'UserCreateDate',
                label: 'Created At',
                format: d => moment(d).fromNow(),
              },
              {
                attr: 'UserLastModifiedDate',
                label: 'Last Modified',
                format: d => moment(d).fromNow(),
              },
              {
                attr: 'Enabled',
                label: 'Enabled',
                format: v => (Boolean(v) ? 'Yes' : 'No'),
              },
              {
                attr: 'UserStatus',
                label: 'Current Status',
              },
            ]}
          />
        </div>
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions>
        {user.UserStatus === 'UNCONFIRMED' && (
          <Button color="primary" onClick={handleConfirm(user.Username)}>
            Confirm Signup
          </Button>
        )}

        {user.Enabled ? (
          <Button color="secondary" onClick={handleDisable(user.Username)}>
            Disable User
          </Button>
        ) : (
          <Button color="secondary" onClick={handleEnable(user.Username)}>
            Enable User
          </Button>
        )}
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
}
