import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function MorningSickness() {
  return (
    <ArticleContent>
      <Quiz
        question="Morning sickness only occurs before midday."
        answer="False"
        feedback="You can experience morning sickness at any time of the day."
      />

      <h4>Key points</h4>
      <ul>
        <li>
          Morning sickness is the nausea and/or vomiting experienced by 70-85% of pregnant women at any time during the
          day (not just in the morning).
        </li>
        <li>
          It is caused by changes in hormone levels, blood pressure fluctuations and changes in carbohydrate metabolism.
        </li>
        <li>
          Symptoms can be improved by eating a few dry crackers before you get up in the morning, avoiding foods and
          smells that make you feel unwell, drinking lots of water and eating high-carbohydrate and high-protein foods.
        </li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        Morning sickness can be quite debilitating during pregnancy. Symptoms of morning sickness often appear about 5
        or 6 weeks into your pregnancy. The symptoms may worsen at around week 9 but tend to improve by 16 to 18 weeks.
        For a small number of women they may last into the third trimester or continue through to birth.
      </p>
      <p>Morning sickness will not harm you or your child, but you must speak to your doctor if you experience:</p>
      <ul>
        <li>Signs of dehydration (dark coloured urine or dizziness)</li>
        <li>Repeated vomiting throughout the day</li>
        <li>Pain and cramping</li>
        <li>Weight loss over 3 kg</li>
      </ul>

      <h4>What can I do?</h4>
      <p>
        Making changes to your diet may improve the symptoms of morning sickness. Below are some tips to help women
        struggling with the condition:
      </p>

      <strong>Stay hydrated during times of vomiting:</strong>
      <ul>
        <li>It is very important to drink fluids, especially if you are vomiting.</li>
        <li>Drinks that contain sugar are better tolerated – sip small amounts every 15 minutes.</li>
        <li>Try lemonade, cordial, electrolyte or glucose drinks, ginger beer, mineral or soda waters.</li>
        <li>Suck on icy poles, frozen fruit juice, ice-blocks, frozen yoghurt or frozen flavoured milks.</li>
        <li>Avoid high acid drinks like orange juice as they can irritate your stomach.</li>
        <li>Try ginger in water, either ginger tea or fresh ginger – it can settle nausea and relieve indigestion.</li>
      </ul>

      <strong>Choose healthy drinks once vomiting lessens:</strong>
      <ul>
        <li>Full cream or fat reduced milk (can be) mixed with soda water</li>
        <li>Fruit juices, vegetable juices and nectars (dilute with water if needed)</li>
        <li>Milkshakes or fruit smoothies</li>
        <li>Soups – these may be better tolerated at room temperature, rather than very hot or very cold</li>
      </ul>

      <strong>Eat small amounts of food:</strong>
      <ul>
        <li>
          Eat a small amount of carbohydrates every half hour. For example: cracker biscuits; a small amount of rice,
          pasta or breakfast cereal; or a small amount of banana or other fruit.
        </li>
        <li>Eat a small amount of carbohydrates (rice, pasta, bread etc.) 10 minutes before your main meals.</li>
        <li>Keep your meals small, but eat more often - aim for 5 to 6 per day.</li>
        <li>Avoid drinking any fluids during meals.</li>
      </ul>

      <strong>Eat low fat food:</strong>

      <p>
        They may be easier to digest and will help to reduce discomfort from overeating. Include these foods in your
        diet:
      </p>
      <ul>
        <li>dry toast with honey, jam or vegemite</li>
        <li>plain salty crackers and cheese</li>
        <li>jelly and custard</li>
        <li>popcorn or dry breakfast cereal</li>
        <li>stewed, canned, fresh or dry fruit</li>
        <li>rice, pasta or noodles</li>
        <li>steamed, boiled, or fresh vegetables</li>
        <li>soups.</li>
      </ul>

      <strong>Foods to avoid:</strong>

      <p>These foods may upset your stomach:</p>
      <ul>
        <li>fatty or fried foods</li>
        <li>thick, creamy gravies or soups</li>
        <li>sweet foods like chocolate, rich desserts, cakes and pastries</li>
        <li>nuts and dry chips</li>
        <li>strong smelling vegetables</li>
        <li>coffee, tea, cocoa, cola drinks</li>
        <li>spicy or rich foods</li>
        <li>wholemeal and high-fibre bread.</li>
      </ul>

      <strong>Other helpful hints to manage morning sickness:</strong>
      <ul>
        <li>Wear loose clothing.</li>
        <li>Get fresh air, sit outside in the garden and eat.</li>
        <li>Rest after meals but avoid lying flat. Use pillows to raise your head and shoulders.</li>
        <li>Cook and freeze food on a good day so it is ready for your ‘off’ days.</li>
        <li>Get help from a friend, family member or your partner to cook and prepare food.</li>
        <li>If possible avoid being in or near the kitchen while food is prepared.</li>
        <li>
          Take vitamin B6 supplements (10 to 25 mg 3 times a day) as this can reduce symptoms of mild to moderate
          nausea. Ask your doctor or midwife for more information.
        </li>
        <li>Acupuncture, acupressure and hypnosis are useful for some women.</li>
        <li>Give up cigarettes and avoid cigarette smoke.</li>
        <li>
          Do not take iron tablets, unless prescribed by your doctor. Iron can be included in pregnancy vitamins, so
          discuss other options with your doctor.
        </li>
      </ul>

      <h4>Useful Links</h4>
      <p>For more information about morning sickness visit:</p>
      <ul>
        <li>
          <a href="https://www.healthdirect.gov.au/morning-sickness" target="_blank" rel="noopener noreferrer">
            Health Direct
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-morning-sickness"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Victoria
          </a>
        </li>
        <li>
          <a href="https://healthywa.wa.gov.au/Articles/J_M/Morning-sickness" target="_blank" rel="noopener noreferrer">
            Healthy WA
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
