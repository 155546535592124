import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function FoodPoisoning() {
  return (
    <ArticleContent>
      <Quiz
        question="Lower immunity during pregnancy makes women more susceptible to food poisoning. "
        answer="True"
        feedback="This is true. Changes in your body’s metabolism, circulation and immunity make you more vulnerable to food poisoning."
      />

      <h4 id="key-points">Key points</h4>
      <ul>
        <li>Pregnant women are at greater risk of food poisoning.</li>
        <li>Cases of food poisoning during pregnancy can be very serious and harmful to your baby.</li>
        <li>
          Being vigilant about food handling, preparation and choices will decrease the risks to you and your baby.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        There are a number of microbes that may be very harmful during pregnancy, three of the most common ones are
        Listeria, salmonella and toxoplasma.
      </p>
      <h4 id="what-is-listeria">What is Listeria?</h4>
      <p>
        Listeria is a type of bacteria found in some foods. The bacteria can cause a rare but very dangerous infection.
        During pregnancy, Listeria can be passed onto your unborn baby and lead to miscarriage, premature labour or
        still birth. It can take 30 days or more for the flu-like symptoms associated with the infection to occur.
      </p>
      <p>To reduce your risk, it is recommended you following these tips:</p>
      <ul>
        <li>Eat only freshly cooked and prepared foods that have been well washed.</li>
        <li>Leftovers must be promptly refrigerated and eaten within a day.</li>
        <li>
          Avoid consuming rock-melon (cantaloupe) – this fruit has been associated with a recent Listeria outbreak in
          Australia.
        </li>
        <li>
          Avoid any foods that may have been made more than a day ago – e.g. pre-made or pre-packages salads, sandwiches
          and wraps.
        </li>
      </ul>
      <p>The common foods we advise women to avoid during pregnancy are:</p>
      <ul>
        <li>Soft and semi-soft cheeses</li>
        <li>Cold pressed meats</li>
        <li>Cold cooked chicken</li>
        <li>Pre-prepared vegetables and salads</li>
        <li>
          Pre-cut fruit
          <br />
        </li>
        <li>Pậté</li>
        <li>Soft-serve ice-cream</li>
        <li>Raw or smoked seafood.</li>
      </ul>
      <p>
        For more information on the risks involved visit{' '}
        <a href="http://www.foodauthority.nsw.gov.au/_Documents/foodsafetyandyou/listeria_and_pregnancy.pdf">
          Listeria and pregnancy - The foods you should avoid and why.
        </a>
      </p>
      <h4 id="what-is-salmonella">What is Salmonella?</h4>
      <p>
        Salmonella is a bacteria that can cause a rare but serious infection. Symptoms can include nausea, vomiting,
        severe abdominal cramps, diarrhoea, fever and headaches. Pregnant women are not at higher risk of contracting
        salmonellosis. However, in the case that they do, it may trigger miscarriage.
      </p>
      <p>
        It is advisable to avoid foods that contain raw eggs (including: fresh mayonnaise, aioli, hollandaise, meringue
        and mousse) and to cook meat, poultry and eggs thoroughly. The NSW Food Authority recommends that pregnant women
        do not eat any type of sprout when it is raw or cooked lightly. (Sprouts include: alfalfa sprouts, broccoli
        sprouts, onion sprouts, sunflower sprouts, clover sprouts, radish sprouts, snow pea sprouts, mung beans and
        soybean sprouts.)
      </p>
      <h4 id="what-is-toxoplasmosis">What is Toxoplasmosis?</h4>
      <p>
        Toxoplasmosis is an infection caused by a parasite known as Toxoplasma gondii (T. gondii). This infection can
        cause brain damage or blindness to your unborn child. The parasite can be passed through the faeces of household
        cats or wild birds.
      </p>
      <p>
        While it is uncommon in pregnant women, toxoplasmosis can occur when you eat under-cooked meats or unwashed
        fruits and vegetables. Fruits and vegetables from home gardens with household or neighbourhood cats are
        particularly susceptible. Most commonly the infection is caused by cleaning cat litter trays or
        touching/ingesting contaminated soil.
      </p>
      <p>To avoid toxoplasmosis:</p>
      <ul>
        <li>Do not eat undercooked or raw meat.</li>
        <li>
          Wash hands immediately and thoroughly after touching raw or undercooked meat, particularly lamb, pork or
          kangaroo.
        </li>
        <li>Do not eat raw oysters, clams or mussels.</li>
        <li>Do not drink unpasteurised milk.</li>
        <li>Always thoroughly wash fruit and vegetables.</li>
        <li>Do not drink tap water when travelling overseas.</li>
        <li>Do not swallow water if swimming in a lake or river.</li>
        <li>Always wash your hands after touching animals, especially cats.</li>
        <li>Do not handle cat litter or animal faeces (always wear gloves if unavoidable).</li>
        <li>Always wear gloves when gardening.</li>
      </ul>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Proper food handling and avoiding foods/activities that put you at risk will help you avoid these infections.
        Please see the factsheet{' '}
        <a href="/topics/pregnancy/articles/food_safety">
          Preparing and Choosing Food Safely
        </a>{' '}
        for more information.
      </p>
      <h4 id="other-resources">Other Resources</h4>
      <p>For more information please visit:</p>
      <ul>
        <li>
          <a
            href="http://www.foodauthority.nsw.gov.au/_Documents/foodsafetyandyou/listeria_and_pregnancy.pdf"
            target="_blank"
            rel="noopener noreferrer">
            NSW Food Authority
          </a>
        </li>
        <li>
          <a href="https://www.healthdirect.gov.au/salmonella" target="_blank" rel="noopener noreferrer">
            Health Direct
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/conditionsandtreatments/toxoplasmosis"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Victoria
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
