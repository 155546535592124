import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function CordBlood() {
  return (
    <ArticleContent>
      <Quiz
        question="I can arrange for cord blood collection at the hospital."
        answer="False"
        feedback="This is false. You need to contact a private cord blood collection and storage company to arrange to have your child’s blood collected and stored. The exemption to this is if your chose to donate the cord blood to a public cord blood bank."
      />

      <h4 id="key-points">Key points</h4>
      <ul>
        <li>Umbilical cord blood is the blood remaining in the umbilical cord and placenta after your baby’s birth.</li>
        <li>Umbilical cord blood is a rich source of stem cells.</li>
        <li>
          Stem cells can be used in the treatment of a range of blood disorders and conditions of the immune system for
          both children and adults.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        You may wish to have the cord blood of your baby collected and stored for possible future use or to donate to a
        public cord blood bank.
      </p>
      <p>
        <strong>What is umbilical cord blood?</strong>
      </p>
      <p>
        Umbilical cord blood is the blood remaining in the umbilical cord and placenta after your baby’s birth. Cord
        blood is normally thrown away after birth; however, it can be collected at birth and stored for possible use in
        the future.
      </p>
      <p>
        <strong>What can umbilical cord blood be used for?</strong>
      </p>
      <p>
        Umbilical cord blood is a rich source of stem cells, which are the building blocks of blood cells in our bodies.
        Stem cells can be used in the treatment of a range of blood disorders and conditions of the immune system for
        both children and adults. The diseases currently most commonly treated using stem cells are: - Immune
        deficiency, when your body is unable to fight disease - Leukaemia - Blood diseases, such as aplastic anaemia -
        Metabolic disorders, which interfere with the process by which the body gets energy from food - Thalassaemia, a
        blood disorder that affects the way the body makes haemoglobin (a protein in your red blood cells that
        transports oxygen in your blood)
      </p>
      <p>
        Some commercial groups claim that cord blood can prevent or cure a range of diseases, but there is currently
        insufficient evidence to prove this. In the future, the range of diseases treated using cord blood might be
        expanded as science and technology advances.
      </p>
      <p>
        <strong>How is cord blood collected?</strong>
      </p>
      <p>
        Cord blood can be collected after the umbilical cord has been cut following either a vaginal or caesarean birth.
        The type of birth does not affect the collection of cord blood. The collection process is quick and painless for
        both mother and baby, and is performed by a trained cord blood collector, obstetrician or midwife.
      </p>
      <p>
        A needle is inserted into the umbilical cord vein attached to the placenta and the blood left in the umbilical
        cord and placenta is drained into a collection bag. The cord blood collection takes about three minutes and can
        occur either before or after the placenta has been delivered.
      </p>
      <p>Once collected, the cord blood is stored frozen for future use.</p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        You will need to contact a cord blood service to collect and store the blood. You will notify the company when
        you go into labour and they be at the hospital when the baby has arrive. It is critical that the blood is
        collected immediately after birth. There is a cost for collection and an annual cost for storage.
      </p>
      <p>
        <strong>Can I have delayed cord clamping and then bank the cord blood?</strong>
      </p>
      <p>
        Delayed cord clamping is the practice where the umbilical cord is not clamped or cut until after pulsations have
        ceased, or until after the placenta is delivered. You are not able to delay cord clamping when collecting
        umbilical cord blood as the cord must be clamped early to capture the most stem cells.
      </p>
      <p>
        <strong>What is required if I wish to donate my baby’s cord blood?</strong>
      </p>
      <p>
        Participation in donation programs is completely voluntary. There may be circumstances when cord blood
        collection cannot be guaranteed. This is as staff priority is to provide optimal care to mother and baby and due
        to the availability of collection staff. If enough stem cells are collected to bank the cord blood, you will be
        asked to complete a questionnaire about your personal and family medical history and give a blood sample which
        is tested to determine eligibility. After a period of six months, you will be contacted to check on the health
        of your baby since donation. This information is required to ensure the safety of blood and cell products for
        use in the future.
      </p>
      <p>
        All information related to your cord blood donation and your medical and family history is confidential and is
        not passed on to anyone other than you, your doctor and other healthcare professionals involved in your or your
        baby’s care.
      </p>
      <p>
        <strong>What are my options to bank my baby’s cord blood?</strong>
      </p>
      <p>In Australia, there are two options:</p>
      <ol type="1">
        <li>
          <strong>Donate to a Public cord blood bank.</strong> If an donation is chosen, donated cord blood is made
          available to all patients in need of a blood stem cell transplant in Australia or overseas. No fee is charged
          for storage. This cord blood will not be kept specifically for your family’s use. In special circumstances,
          your baby’s cord blood will be made available for use by your baby or another family member, if it is still in
          the bank.
        </li>
        <li>
          <strong>
            Storage in a Private cord blood bank for potential use only by your baby or other family members.
          </strong>{' '}
          These banks are private companies and charge a fee for their processing and storage services. The main idea
          behind storing your baby’s blood in a private cord bank is that one day, your child may become ill and you may
          be able to use those stem cells for treatment. Parents should be aware that many diseases cannot be treated
          with stem cells, especially if the disease is genetic in origin. This type of banking cannot be viewed as a
          health insurance policy.
        </li>
      </ol>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about genetic screening visit:</p>
      <ul>
        <li>
          <a
            href="https://ranzcog.edu.au/womens-health/patient-information-resources/umbilical-cord-blood-banking"
            target="_blank"
            rel="noopener noreferrer">
            The Royal Australian New Zealand College of Obstetricians and Gynaecologists
          </a>
        </li>
      </ul>
      <h4 id="action-item">Action item:</h4>
      <p>Make arrangements with a cord blood storage service prior to the birth of your baby.</p>
    </ArticleContent>
  );
}
