import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function Anxiety() {
  return (
    <ArticleContent>
      <Quiz
        question="Anxiety during pregnancy is common. "
        answer="True"
        feedback="This is true. 1 in 3 women experience anxiety and anxiety during pregnancy is common. It is important to seek treatment early during pregnancy so that your experience of pregnancy and motherhood is positive."
      />

      <h4 id="key-points">Key points</h4>
      <ul>
        <li>
          Anxiety during pregnancy is common. 1 in 3 women experience anxiety and 10-15% of women will be diagnosed with
          depression during pregnancy.
        </li>
        <li>Women in Australia are routinely assessed for depression during pregnancy.</li>
        <li>There are a range of treatments available.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        You may experience worry, anxiety and/or depression during your pregnancy.Pregnancy is a major life event that
        can trigger worry, anxiety and/or depression as that may be mixed with joy and also negative emotions.
      </p>
      <h4 id="signs-that-you-may-be-experiencing-anxiety">Signs that you may be experiencing anxiety</h4>
      <ul>
        <li>anxiety or fear that interrupts your thoughts and interferes with daily tasks</li>
        <li>
          panic attacks — outbursts of extreme fear and panic that are overwhelming and feel difficult to bring under
          control
        </li>
        <li>anxiety and worries that keep coming into your mind that are difficult to stop or control</li>
        <li>constantly feeling irritable, restless or on edge</li>
        <li>having tense muscles, a tight chest and heart palpitations</li>
        <li>finding it difficult to relax and/or taking a long time to fall asleep at night</li>
      </ul>
      <p>
        <a href="https://healthyfamilies.beyondblue.org.au/pregnancy-and-new-parents/maternal-mental-health-and-wellbeing/anxiety">
          Source: Beyond Blue
        </a>
      </p>
      <h4 id="signs-that-you-may-be-experiencing-depression">Signs that you may be experiencing depression</h4>
      <ul>
        <li>feeling depressed or miserable consistently for most of the day and for most days of the week</li>
        <li>feeling irritable, angry or anxious a lot of the time</li>
        <li>increased crying, sometimes for no apparent reason</li>
        <li>reduced interest in things that you would normally enjoy</li>
        <li>not able to sleep or sleeping more than usual</li>
        <li>reduced appetite or over-eating</li>
        <li>excessive fatigue and tiredness</li>
        <li>difficulty concentrating and being forgetful</li>
        <li>
          preoccupation with morbid thoughts or being anxious about multiple things, such as bad things happening to
          you, your pregnancy, your baby or your partner
        </li>
        <li>excessive feelings of guilt and/or failure, that you will be a ‘bad mother’</li>
        <li>thoughts of harming yourself</li>
      </ul>
      <p>
        <a href="https://www.thewomens.org.au/health-information/pregnancy-and-birth/mental-health-pregnancy/depression-pregnancy/">
          Source: The Royal Women’s Hospital
        </a>
      </p>
      <h4 id="what-causes-anxiety-and-depression">What causes anxiety and depression?</h4>
      <p>
        Worry, anxiety and depression during pregnancy may be the resurfacing of previous episodes. Your worry or
        anxiety may be focused on the baby’s health, fear of the responsibilities of parenting, fear of the birthing
        experience and/or concern about your weight gain and body shape. Some level of fear is very common but when it
        is persistent and begins to dominate your thoughts, it is time to talk with a health professional.
      </p>
      <p>
        You may be genetically predisposed to worry and anxiety or you may have been subject to a stressful event or
        both. Also, you may have been a victim/survivor of sexual assault may also have particular anxieties during
        pregnancy. For this group of women there is also likely to be anxiety in the lead up to the birth and further
        anxiety about being a parent. Women who have been sexually assaulted can find it very difficult to be touched.
        Medical check-ups can be very stressful, especially check-ups that involve the vagina. This group of women can
        also be very fearful of the birth process; they can suffer from muscle tension during the birth, which in turn
        can impact on their ability to manage the pain of labour.
      </p>
      <p>
        Women who have different kinds of pre-existing anxiety disorders can find pregnancy challenging for any number
        of reasons. Pregnancy and the birth experience can put women into situations outside their comfort zones, which
        can induce anxiety. There are also symptoms that women may have in late pregnancy such as shortness of breath,
        dizziness, an increased heart rate and feeling hot and sweaty which can be mistaken for panic attacks or, in
        some instances, can lead to a panic attack.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        It is important to seek treatment as early as possible so that your experience of pregnancy and motherhood is as
        positive as it can be.
      </p>
      <p>
        Mild to moderate depression can be improved with psychological treatment and increasing support around you,
        including family and friends. In some cases of more severe depression, you may need to take antidepressants.
        There are antidepressants that are quite safe to take during pregnancy and breastfeeding.
      </p>
      <p>
        Anxiety is effectively treated with psychological therapies. These include relaxation training, cognitive
        behavioural therapy, and mindfulness practice. Lifestyle modifications such as stress reduction and exercise are
        also helpful. Sometimes in more severe illnesses, medication may be needed, preferably in conjunction with
        psychological therapies.
      </p>
      <h4 id="useful-resources">Useful resources</h4>
      <p>For more information please visit:</p>
      <ul>
        <li>
          <a
            href="https://healthyfamilies.beyondblue.org.au/pregnancy-and-new-parents/maternal-mental-health-and-wellbeing/anxiety"
            target="_blank"
            rel="noopener noreferrer">
            Beyond Blue
          </a>
        </li>
        <li>
          <a
            href="https://www.thewomens.org.au/health-information/pregnancy-and-birth/mental-health-pregnancy/anxiety-pregnancy"
            target="_blank"
            rel="noopener noreferrer">
            The Royal Women’s Hospital
          </a>
        </li>
        <li>
          <a href="https://www.panda.org.au/info-support" target="_blank" rel="noopener noreferrer">
            Panda
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
