import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { AppPage } from 'components/page';
import ArticleContent from 'components/topics/Article/ArticleContent';

export default function Contact() {
  return (
    <AppPage title="Contact">
      <ArticleContent>
        <Container fixed>
          <Typography variant="h3" gutterBottom>
            Contact Us
          </Typography>
        </Container>
      </ArticleContent>
    </AppPage>
  );
}
