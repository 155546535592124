import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { AppPage } from 'components/page';
import ArticleContent from 'components/topics/Article/ArticleContent';

export default function Terms() {
  return (
    <AppPage title="Terms">
      <ArticleContent>
        <Container fixed>
          <Typography variant="h4" gutterBottom>
            OptimalMe Terms and Conditions
          </Typography>
          <p>
            This program is owned and operated by Monash University (ABN 12 377 614 012) of Wellington Road, Clayton,
            Victoria, 3800, Australia ("Monash University"). By accessing and/or using this program and related
            services, or by applying to register for an account on this program ("Account"), you agree to these Terms
            and Conditions ("Terms").
          </p>
          <p>
            By applying to register for an account or using this program, you will be assumed to have accepted these
            terms.  If you do not wish to accept these terms, you must not use this program or apply to register for an
            account.
          </p>
          <p>
            In these Terms, "you" and "your" means any person who registers or applies to register for an Account or
            accesses or uses this program.
          </p>
          <ol>
            <li>
              <strong>Registration:</strong> You must have successfully registered for an Account to access certain
              features of this program. You are responsible for keeping this user name and password secure and are
              responsible for all use and activity carried out under this user name or using your Account. Your Account
              is non-transferrable. By applying to register for an Account, you:
              <ul>
                <li>warrant that you are at least 18 years of age;</li>
                <li>
                  warrant that you possess the legal right and ability to enter into a legally binding agreement with
                  Monash University; and
                </li>
                <li>agree and warrant using this program in accordance with these Terms.</li>
              </ul>
            </li>

            <li>
              <strong>Account information:</strong>  You warrant that all information supplied to Monash University as
              part of your application to register for an Account is true and correct.  You acknowledge that Monash
              University may take such steps, as it is able to take, to verify the information provided by you before
              deciding whether to provide you with an Account. If Monash University considers (in its sole discretion)
              that any of that information provided by you is untrue, Monash University reserves the right to reject
              your application for an Account or to cancel your Account and terminate your access to the features of
              this program, which are only accessible by registered account holders.  You indemnify Monash University
              from and against any and all liability, loss, damage and costs (including legal costs) arising from its
              use of or reliance upon the information contained in your application to register for an Account, or
              supplied by you through your Account.
            </li>

            <li>
              <strong>Your Privacy:</strong>  You agree that Monash University may collect and deal with personal
              information about you or others in accordance with the{' '}
              <a href="https://www.monash.edu/privacy-monash">Monash University Privacy Collection Statement</a>. You
              warrant that you have obtained the consent of any person whose personal information is as a result of
              anything done by you collected and uploaded through this program.
            </li>

            <li>
              <strong>Security:</strong> You must keep your Log In Details secure and confidential.  If you believe that
              your Log In Details have been compromised, you must tell Monash University immediately or change them on
              this program through the facilities provided. Monash University is entitled to assume that you are the
              person gaining access to any part of this program which is only accessible to registered account holders
              or Monash University's computer systems through use of your Log In Details, and you will be responsible
              for what is done while using your Log In Details, unless you have given Monash University reasonable prior
              notice that your Log In Details have been compromised and new Log In Details have not yet been allocated
              to or selected by you.
            </li>

            <li>
              <strong>Intellectual Property:</strong> Unless otherwise indicated, Monash University owns or has been
              granted licences by third parties that own all rights, title and interest (including copyright, designs,
              patents, registered and unregistered trade marks and other intellectual property rights) in and to this
              program and all of the material (including all text, graphics, logos, audio and software) made available
              on or provided through this program ("Content").
            </li>

            <li>
              <p>
                Subject to your compliance with these Terms, Monash University grants you a royalty free, non-exclusive,
                non-transferrable, limited licence to use this program and the Content and view this program and the
                Content for personal non-commercial purposes only. Unless you have obtained Monash University's prior
                written permission, you must not use this program or the Content for any other purpose or do any of the
                following:
              </p>
              <ul>
                <li>copy, distribute, sell, rent or modify any part of this program or the Content;</li>
                <li>
                  reverse engineer, decompile, disassemble or otherwise derive or attempt to derive the source code of
                  any part of this program or the Content (except to the extent permitted by non-excludable applicable
                  law), or tamper with any part of this program or the Content in a way that disables, circumvents, or
                  otherwise defeats any protection mechanisms that have been applied to this program or the Content;
                </li>
                <li>
                  operate this program for the benefit of any other person, whether as an online service, outsourced
                  service, comparison service or otherwise;
                </li>
                <li>
                  remove, alter or add to any trade mark or copyright notice or similar marking applied to the Content
                  or within this program; or
                </li>
                <li>
                  extract any data from any of the databases used by or forming part of this program, or resell or
                  distribute that data in any way.
                </li>
              </ul>
            </li>

            <li>
              Monash University reserves the right to enforce its intellectual property rights in the Content or this
              program through civil proceedings.
            </li>

            <li>
              <strong>Prohibited Activities:</strong> You must not do any act that Monash University (acting reasonably)
              considers is appropriate or which is unlawful or prohibited by any laws applicable to this program,
              including but not limited to:
              <ol>
                <li>
                  any act that would constitute a breach of either the privacy (including by uploading through this
                  program private or personal information without an individual's consent) or any other of the legal
                  rights of individuals;
                </li>
                <li>using this program to defame or libel Monash University, its employees or other individuals;</li>
                <li>
                  uploading files (whether deliberately, negligently or as a result of failure to take reasonable steps)
                  that contain viruses or other software, data or routines that may cause damage to, disrupt or provide
                  unauthorised access to Monash University's computer systems, data or other property or the property of
                  other individuals;
                </li>
                <li>
                  posting on or transmitting to this program any non-authorised material including, but not limited to,
                  material that is, in Monash University’s opinion, likely to cause annoyance, or which is defamatory,
                  racist, obscene, threatening, pornographic or otherwise detrimental to or in violation of Monash
                  University’s systems or network security or a third party's systems or network security. 
                </li>
              </ol>
            </li>

            <li>
              Monash University may allow you to post information to this program ("User Content"), and retains the
              right to take down the User Content at its sole discretion and without notice. You grant Monash University
              a royalty free, non-exclusive, non-transferrable, limited licence to use, reproduce and communicate to the
              public the User Content and to sub-license other people to use and view the User Content.  You warrant
              that you are legally entitled to submit, upload, post or transmit the User Content to, on or through this
              program and have obtained all necessary licences, consents and program approvals in relation to the User
              Content (including intellectual property licences) to enable you to submit, upload, post or transmit the
              User Content to, on or through this program and to permit Monash University and other people to deal with
              the User Content in accordance with the licences granted above.
            </li>

            <li>
              <strong>Content:</strong> the information and material provided through this program is provided for
              general informational purposes only.  It should not be used or relied on as medical advice or to diagnose,
              treat, cure or prevent any medical condition. While care has been taken in creating this program, Monash
              University does not warrant or represent that any information or material provided through this program is
              complete, up to date or suitable for any purpose. Users access this program and rely on the information
              and material provided through it at their own risk.
            </li>

            <li>
              <strong>Limits on service:</strong>  Monash University reserves the right at any time to change, remove or
              discontinue any feature or function of this program, or all or any part of this program, either
              temporarily or permanently, with or without notice to you. From time to time, Monash University may issue
              updates, which add, modify and/or remove features from this program. These updates may be applied
              automatically with or without notice to you. These terms will govern any updates provided by Monash
              University, unless the update is accompanied by separate terms and conditions in which case those terms
              and conditions will apply.
            </li>

            <li>
              Access to this program depends on telecommunications and internet service providers and other external
              factors and Monash University not guarantee the availability of this program at all times or at any
              specific times.  While Monash University will take precautions to ensure this program is secure, no data
              transmission over the internet can be guaranteed as totally secure.  Accordingly, Monash university cannot
              ensure the security of any information transmitted to, from or using this program and you do so at your
              own risk.
            </li>

            <li>
              <strong>Third party websites:</strong>  This program may cause the device through which you gain access to
              this program to display URLs or links to websites not controlled by Monash University.  Those URLs or
              links are provided for convenience only and may not be current.  Providing a URL or link should not be
              construed as an endorsement or program approval of the third party program by Monash University, and
              Monash University is not responsible for the content of third party websites.
            </li>

            <li>
              Disclaimers and liability of Monash University: Monash University does not warrant nor represent that use
              of this program will not infringe the rights of third parties, or that this program will meet your
              requirements or that it will operate in an uninterrupted or error-free manner, or be compatible or
              integrate with your computer systems.  All warranties, which would otherwise be implied into this
              agreement, are excluded as far as permitted by law.  If any legislation implies or applies in these terms
              any condition, warranty or guarantee, and that legislation avoids or prohibits provisions in a contract
              excluding or modifying the application of or exercise of or liability under the condition, warranty or
              guarantee, the condition, warranty or guarantee is deemed to be included in these terms. However, to the
              extent permitted by law, Monash University's liability for failure to comply with any such condition,
              warranty or guarantee is limited (at Monash University's option, and as determined by it) to:
              <ol>
                <li>
                  In the case of goods - to the replacement of the goods or the supply of equivalent goods, the repair
                  of the goods, the payment of the cost of replacing the goods or of acquiring equivalent goods or the
                  payment of the cost of having the goods repaired; and
                </li>
                <li>
                  In the case of services - to the supplying of the services again or the payment of the cost of having
                  the services supplied again.
                </li>
              </ol>
            </li>

            <li>
              <strong>Termination:</strong>  If you fail to comply with any part of these Terms, Monash University may
              terminate this agreement, your Account and your right to use this program, by notice to you.  Termination
              of this agreement will not affect any rights or liabilities you or Monash University have accrued up to
              that time.  Upon termination of this agreement you must stop using and accessing this program.
            </li>

            <li>
              <strong>General:</strong>  You may not assign, transfer or otherwise deal with the rights under the Terms
              without the prior written consent of Monash University.  This agreement will be governed by and construed
              in accordance with the laws in force in the State of Victoria, Australia, and each party submits to the
              non-exclusive jurisdiction of the courts of that State.  If any term of this agreement is held
              unenforceable or illegal for any reason, the Terms will remain otherwise in full force apart from that
              provision.
            </li>
          </ol>
          Questions, complaints or feedback about this program, please{' '}
          <a href="mailto:mchri-optimalme@monash.edu">contact us</a>.
        </Container>
      </ArticleContent>
    </AppPage>
  );
}
