import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will discuss my contraception and planning for conception with my GP.';

export default function Contraception() {
  return (
    <ArticleContent>
      <Quiz
        question="I need to wash-out hormonal contraceptives before conceiving."
        answer="False"
        feedback="This is false. Hormones in contraceptives are not stored in your body and there is no need for a wash-out period."
      />

      <h4>Key points</h4>

      <ul>
        <li>You can get pregnant immediately after stopping contraception</li>
        <li>Short-term contraceptives (e.g. pill) are not stored in the body</li>
        <li>Stopping long-term contraceptives (e.g. Implanon) requires planning to time your conception</li>
      </ul>

      <h4>Why is this important to me?</h4>

      <p>There are two forms of contraception - short or long-term.</p>
      <p>
        Short-term examples are the oral contraceptive pill or barrier methods such as condoms. Once you stop using
        these you may conceive.
      </p>
      <p>
        It is important to know that the contraceptive pill is not retained in the body (tissues) after you stop taking
        it. You can become pregnant immediately after stopping short-term contraceptives.
      </p>
      <p>
        Long-term contraceptives such as intrauterine devices (IUDs) and implanted contraception (Implanon) will need
        longer term planning to remove.
      </p>

      <h4>What can I do?</h4>

      <p>Talk to your GP about when you desire to conceive and when and how to stop or remove contraception.</p>

      <h4>Action</h4>

      <AddToActions action={action} />

      <h4>Useful Links</h4>

      <p>For more information about contraception visit:</p>
      <ul>
        <li>
          <a
            href="https://www.fpnsw.org.au/health-information/individuals/contraception/contraception-choices"
            target="_blank"
            rel="noopener noreferrer">
            Family Planning NSW
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
