import React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Question from './Question';
import ResultLine, { ResultLineTypes } from './ResultLine';

const useStyles = makeStyles(theme =>
  createStyles({
    card: {
      backgroundColor: theme.palette.grey[50],
    },
  }),
);

export type QuizProps = {
  question: string;
  answer: 'True' | 'False';
  feedback: string;
  negativeFeedback?: string;
};

export default function Quiz(props: QuizProps) {
  const { question, answer, feedback, negativeFeedback } = props;
  const classes = useStyles();
  return (
    <Card elevation={0} className={classes.card}>
      <CardHeader title="Quiz" />
      <CardContent>
        <Question
          id="quiz"
          answer={answer}
          label={question}
          options={[
            { value: 'True', label: 'True' },
            { value: 'False', label: 'False' },
          ]}>
          {matched =>
            matched ? (
              <ResultLine type={ResultLineTypes.Success} message={feedback} />
            ) : (
              <ResultLine type={ResultLineTypes.Failure} message={negativeFeedback || feedback} />
            )
          }
        </Question>
      </CardContent>
    </Card>
  );
}
