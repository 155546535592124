import { notEmpty } from 'components/form/validators';
import { Step } from 'components/stepsForm';

const toNumber = (value: any) => Number(value);

export default {
  title: 'Tell us a little about yourself',
  fields: [
    {
      type: 'text-field',
      name: 'a1_weight',
      prefix: 'A1',
      desc: 'What is your current weight without shoes?',
      helperText: '(in kg) If you are unsure, please estimate anyway.',
      input: {
        label: 'Weight',
        to: 'float',
      },
    },
    {
      type: 'text-field',
      name: 'a2_height',
      prefix: 'A2',
      desc: 'What is your current height without shoes?',
      helperText: '(in cm) If you are unsure, please estimate anyway.',
      input: {
        label: 'Height',
        valueTo: toNumber,
      },
    },
    {
      type: 'radio-group',
      name: 'a3_freq',
      prefix: 'A3',
      desc: 'How often do you weigh yourself?',
      validator: notEmpty(),
      input: {
        options: ['Daily', 'Weekly', 'Monthly', 'Occasionally or Never'],
      },
    },
  ],
} as Step;
