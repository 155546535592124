import React from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Typography, Button, Box, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Form from 'components/form';
import { Profile } from 'app/types';

export type PregCheck = Pick<Profile, 'pregStatus' | 'dueDate'>;

export type PregnancyCheckFormProps = {
  data: PregCheck;
  onSubmit: (data: PregCheck) => void;
};

export default function PregnancyCheckForm(props: PregnancyCheckFormProps) {
  const { data, onSubmit } = props;
  const canSubmit = (data: PregCheck): boolean => {
    return data.pregStatus === 'YES' && data.dueDate !== null;
  };

  const toMoment = (value: string | null) => {
    return value ? moment(value, 'DD/MM/YYYY') : null;
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Form<PregCheck> data={data} onSubmit={onSubmit}>
        {({ updateField, values, errors }) => (
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" gutterBottom>
              This module is designed to be accessed during pregnancy. Please confirm that you are pregnant:
            </Typography>

            <Box my={2}>
              <Select
                variant="outlined"
                id="pregStatus"
                value={values.pregStatus}
                onChange={e => {
                  updateField('pregStatus', e.target.value);
                }}>
                <MenuItem value="YES">I am pregnant</MenuItem>
                <MenuItem value="NO">I am not pregnant</MenuItem>
              </Select>
            </Box>
            {values.pregStatus === 'YES' && (
              <Box display="flex" flexDirection="column">
                <Typography variant="h6" gutterBottom>
                  Please enter your estimated due date:
                </Typography>
                <Box my={2}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    label="Due Date"
                    placeholder="DD/MM/YYYY"
                    value={toMoment(values.dueDate)}
                    onChange={(date: moment.Moment | null, value?: string | null) => {
                      updateField('dueDate', value);
                    }}
                    error={Boolean(errors.dueDate)}
                    helperText={errors.dueDate}
                    fullWidth
                  />
                </Box>
              </Box>
            )}
            <Button type="submit" variant="contained" color="primary" disabled={!canSubmit(values)}>
              Update profile
            </Button>
          </Box>
        )}
      </Form>
    </MuiPickersUtilsProvider>
  );
}
