import HealthyRelationships, { action as HealthyRelationshipsAction } from './HealthyRelationships';
import EmotionalWellbeing, { action as EmotionalWellbeingAction } from './EmotionalWellbeing';
import Fertility, { action as FertilityAction } from './Fertility';
import Vaccination, { action as VaccinationAction } from './Vaccination';
import Genetic, { action as GeneticAction } from './Genetic';
import Contraception, { action as ContraceptionAction } from './Contraception';
import CervicalScreening, { action as CervicalScreeningAction } from './CervicalScreening';
import Supplements, { action as SupplementsAction } from './Supplements';
import Medication, { action as MedicationAction } from './Medication';
import ChronicConditions, { action as ChronicConditionsAction } from './ChronicConditions';
import SmokingAlcoholDrugs, { action as SmokingAlcoholDrugsAction } from './SmokingAlcoholDrugs';
import ReproductiveHistory, { action as ReproductiveHistoryAction } from './ReproductiveHistory';
import PCOS from './PCOS';
import { Content } from 'app/topics/types';

export default [

  {
    id: 'fertility',
    tag: 'Health',
    title: 'Fertility',
    desc: 'Can I boost my fertility?',
    cover: 'images/articles/preconception/essentials/fertility.jpg',
    banner: 'images/articles/preconception/essentials/fertility@2x.jpg',
    ArticleContent: Fertility,
    action: FertilityAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'vaccination',
    tag: 'Health',
    title: 'Vaccinations',
    desc: 'Vaccines not only protect you, they also safeguard your baby during pregnancy.',
    cover: 'images/articles/preconception/essentials/vaccination.jpg',
    banner: 'images/articles/preconception/essentials/vaccination@2x.jpg',
    ArticleContent: Vaccination,
    action: VaccinationAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'genetic',
    tag: 'Health',
    title: 'Genetics',
    desc: 'Will we need genetic testing?',
    cover: 'images/articles/preconception/essentials/genetic.jpg',
    banner: 'images/articles/preconception/essentials/genetic@2x.jpg',
    ArticleContent: Genetic,
    action: GeneticAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'contraception',
    title: 'Contraception',
    desc: 'How long does the contraceptive pill stay in my body after I stop taking it?',
    cover: 'images/articles/preconception/essentials/contraception.jpg',
    banner: 'images/articles/preconception/essentials/contraception@2x.jpg',
    tag: 'Health',
    ArticleContent: Contraception,
    action: ContraceptionAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'cervical_screening',
    tag: 'Health',
    title: 'Cervical (pap) Screening',
    desc: 'Do I need a screening test prior to getting pregnant?',
    cover: 'images/articles/preconception/essentials/cervical_screening.jpg',
    banner: 'images/articles/preconception/essentials/cervical_screening@2x.jpg',
    ArticleContent: CervicalScreening,
    action: CervicalScreeningAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'supplements',
    tag: 'Health',
    title: 'Supplements',
    desc: 'The supplements you should take before conceiving and why.',
    cover: 'images/articles/preconception/essentials/supplements.jpg',
    banner: 'images/articles/preconception/essentials/supplements@2x.jpg',
    ArticleContent: Supplements,
    action: SupplementsAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'medication',
    tag: 'Health',
    title: 'Medication',
    desc: 'There are a few things to consider when taking medications during pregnancy.',
    cover: 'images/articles/preconception/essentials/medications.jpg',
    banner: 'images/articles/preconception/essentials/medications@2x.jpg',
    ArticleContent: Medication,
    action: MedicationAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'chronic_conditions',
    tag: 'Health',
    title: 'Chronic Conditions and Diseases',
    desc: 'Can I have a healthy pregnancy with an existing chronic condition?',
    cover: 'images/articles/preconception/essentials/chronic_conditions.jpg',
    banner: 'images/articles/preconception/essentials/chronic_conditions@2x.jpg',
    ArticleContent: ChronicConditions,
    action: ChronicConditionsAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'healthy_relationships',
    tag: 'Wellbeing',
    title: 'Healthy Relationships',
    desc: 'Have an open and frank discussion with your partner about getting pregnant',
    cover: 'images/articles/preconception/essentials/healthy_relationships.jpg',
    banner: 'images/articles/preconception/essentials/healthy_relationships@2x.jpg',
    ArticleContent: HealthyRelationships,
    action: HealthyRelationshipsAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'emotional_wellbeing',
    tag: 'Wellbeing',
    title: 'Emotional Wellbeing',
    desc: 'Some level of worry when contemplating pregnancy is normal.',
    cover: 'images/articles/preconception/essentials/emotional_wellbeing.jpg',
    banner: 'images/articles/preconception/essentials/emotional_wellbeing@2x.jpg',
    ArticleContent: EmotionalWellbeing,
    action: EmotionalWellbeingAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'smoking_alcohol_drugs',
    tag: 'Lifestyle',
    title: 'Smoking, alcohol and other drug use',
    desc: 'Smoking, alcohol and other drugs can affect an unborn baby.',
    cover: 'images/articles/preconception/essentials/smoking_alcohol_drugs.jpg',
    banner: 'images/articles/preconception/essentials/smoking_alcohol_drugs@2x.jpg',
    ArticleContent: SmokingAlcoholDrugs,
    action: SmokingAlcoholDrugsAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'reproductive_history',
    tag: 'Health',
    title: 'Reproductive history/health',
    desc: 'Does my reproductive history affect a future pregnancy?',
    cover: 'images/articles/preconception/essentials/reproductive_history.jpg',
    banner: 'images/articles/preconception/essentials/reproductive_history@2x.jpg',
    ArticleContent: ReproductiveHistory,
    action: ReproductiveHistoryAction,
    dataKeys: ['quiz'],
  },
  {
    id: 'pcos',
    tag: 'Health',
    title: 'PCOS',
    desc: 'If you are thinking of starting a family and have PCOS it is important to know how you can optimise your fertility.',
    cover: 'images/articles/preconception/essentials/pcos.jpg',
    banner: 'images/articles/preconception/essentials/pcos@2.jpg',
    ArticleContent: PCOS,
    dataKeys: ['quiz'],
  },
] as Array<Content>;
