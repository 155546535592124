import { Auth, API } from 'aws-amplify';
import { omit } from 'lodash';
import { RegisterData, UsersQuery, AWSUser } from 'app/admin/types';

function AdminQueries() {
  const getHeaders = async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getAccessToken().getJwtToken();
    return {
      'Content-Type': 'application/json',
      Authorization: jwtToken,
    };
  };
  return {
    async get(path: string, queryStringParameters: { [key: string]: any }) {
      const headers = await getHeaders();
      try {
        return await API.get('AdminQueries', path, { headers, queryStringParameters });
      } catch (error) {
        const message = error.response?.data?.message;
        throw message ? new Error(message) : error;
      }
    },
    async post(path: string, body: { [key: string]: any }) {
      const headers = await getHeaders();
      try {
        return await API.post('AdminQueries', path, { headers, body });
      } catch (error) {
        const message = error.response?.data?.message;
        throw message ? new Error(message) : error;
      }
    },
  };
}

export async function listUsers(query: UsersQuery): Promise<{ Users: AWSUser[]; NextToken?: string }> {
  return await AdminQueries().get('/listUsers', query);
}

export async function getUser(username: string): Promise<AWSUser> {
  const response = await AdminQueries().get('/getUser', { username });
  return { ...omit(response, ['UserAttributes']), Attributes: response.UserAttributes } as AWSUser;
}

export async function createUser(
  registerData: Pick<RegisterData, 'email' | 'name' | 'dob' | 'postcode' | 'medibank_number'>,
) {
  return await AdminQueries().post('/createUser', registerData);
}

export async function disableUser(username: string) {
  return await AdminQueries().post('/disableUser', { username });
}

export async function enableUser(username: string) {
  return await AdminQueries().post('/enableUser', { username });
}

export async function confirmUserSignUp(username: string) {
  return await AdminQueries().post('/confirmUserSignUp', { username });
}
