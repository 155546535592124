import React from 'react';
import { FormGroup, FormHelperText, Typography, Slider } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    slider: {
      padding: theme.spacing(6, 4, 0, 2),
    },
  }),
);

export type SlideGroupProps = {
  name: string;
  label: string;
  error?: boolean;
  helperText: string;
  value: number;
  range: [number, number];
  onChange: (value: number) => void;
};

export default function SlideGroup(props: SlideGroupProps) {
  const { range, value, onChange, label, error, helperText } = props;
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<{}>, value: number | number[]) => {
    onChange(value as number);
  };

  return (
    <FormGroup>
      {label && <Typography>{label}</Typography>}
      <div className={classes.slider}>
        <Slider
          step={1}
          marks
          defaultValue={30}
          valueLabelDisplay="on"
          min={range[0]}
          max={range[1]}
          value={value}
          onChange={handleChange}
        />
      </div>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormGroup>
  );
}
