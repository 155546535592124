import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Landing from './Landing';
import About from './About';
import Contact from './Contact';
import Program from './Program';
import Terms from './Terms';

export default function HomeRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/program" component={Program} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/terms" component={Terms} />
    </Switch>
  );
}
