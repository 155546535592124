import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function PhysicalActivity() {
  return (
    <ArticleContent>
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Physical activity is important during your pregnancy and can improve the health of you and your baby.</li>
        <li>Aim to complete at least 30 minutes of physical activity on most days of the week.</li>
        <li>
          Physical activity during pregnancy should be of moderate intensity – consult a health professional before
          undertaking vigorous activities.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Physical activity is any body movement that involves the use of one or more large muscle groups and raises your
        heart rate. This includes sport, exercise and recreational activities, and incidental activity that accumulates
        throughout the day (e.g. house work, gardening, walking to the shops, climbing stairs).
      </p>
      <p>Physical activity during your pregnancy can have numerous health benefits which include:</p>
      <ul>
        <li>Benefits for gestational weight gain</li>
        <li>Improved mood and mental well-being</li>
        <li>Reduced likelihood of anxiety and depression</li>
        <li>
          Decreased the risk of pregnancy-related complications such as pregnancy-induced hypertension and pre-eclampsia
        </li>
        <li>Reduced risk of glucose intolerance and development gestational diabetes.</li>
        <li>Improved stress response during labour</li>
      </ul>
      <h4 id="what-are-the-recommendations">What are the recommendations?</h4>
      <p>
        The <strong>Australian Physical Activity and Sedentary Behaviour Guidelines</strong> recommend that pregnant
        women do at least 30 minutes of physical activity on most, if not all, days of the week; and accumulate 150–300
        minutes of moderate-intensity physical activity each week. Pregnant women should talk with their health
        professional regarding the best form of activity and to check with them before undertaking vigorous intensity
        physical activity or avoid contact sports.
      </p>
      <figure>
        <img src="images/articles/pregnancy/shapingLife/activity.png" alt="physical activity" />
      </figure>
      <p>
        <strong>Changes throughout pregnancy</strong>
      </p>
      <p>
        As your pregnancy progresses, your body will undergo many changes, some may affect your ability to exercise, or
        require you to modify your exercise routine, such as:
      </p>
      <ul>
        <li>
          Hormones such as relaxin loosen ligaments, which may increase your risk of joint injuries (such as sprains).
        </li>
        <li>
          Weight increases and changes in weight distribution and body shape. This results in the body’s centre of
          gravity moving forward, which can affect your balance and coordination.
        </li>
        <li>
          Your resting heart rate will increase, therefore you need to be conscious of how hard your body is working
          when exercising.
        </li>
        <li>
          During the second trimester your blood pressure drops, so it is important to avoid rapid changes of position –
          from lying to standing and vice versa – so as not to avoid dizzy spells.
        </li>
      </ul>
      <p>Activities that are generally safe during pregnancy include:</p>
      <ul>
        <li>Walking</li>
        <li>Swimming</li>
        <li>Riding on a stationary bicycle</li>
        <li>Jogging</li>
        <li>Muscle strengthen exercising, including pelvic floor exercises</li>
        <li>Exercise in water (aqua-aerobics)</li>
        <li>Yoga, stretching and other floor exercises</li>
        <li>Pilates</li>
        <li>Pregnancy exercise classes.</li>
      </ul>
      <p>
        <strong>Cautions to consider when exercising</strong>
      </p>
      <p>
        If you have been previously active and have regularly engaged in vigorous activities, including jogging or
        running, these can be continued during pregnancy provided you have no pre-existing complications. You may need
        to modify your existing exercise program throughout pregnancy and be guided by a healthcare professional.
        Additionally:
      </p>
      <ul>
        <li>Avoid raising your body temperature too high or exercising to the point of heavy sweating.</li>
        <li>Reduce your level of exercise on hot or humid days and stay well hydrated.</li>
        <li>Don’t exercise to the point of exhaustion.</li>
        <li>
          If weight training, choose light weights and medium to high repetitions – completely avoid lifting heavy
          weights.
        </li>
        <li>
          Avoid doing wide squats or lunges, contact sports, sports with hard objects or balls that could injure you,
          high speed sports or sports that can cause falls such as water skiing or down-hill skiing, horse riding
          gymnastics or skating.
        </li>
        <li>Perform controlled stretching and avoid over-extending.</li>
        <li>Avoid exercise if you are ill or feverish.</li>
        <li>
          If you feel tired or unwell don’t exercise, and avoid unnecessarily depleting your energy reserves on days
          when you’re not feeling 100%.
        </li>
        <li>
          If you develop an illness or a pregnancy complication, talk with your doctor or midwife before continuing or
          restarting your exercise program.
        </li>
        <li>
          Stop exercising immediately and consult your doctor if you experience:
          <ul>
            <li>Headache</li>
            <li>Dizziness or feel faint</li>
            <li>Heart palpitations or chest pain</li>
            <li>Swelling in your hands, feet or face</li>
            <li>Calf pain or swelling</li>
            <li>Vaginal bleeding</li>
            <li>Contractions</li>
            <li>Deep back, pelvic or pubic pain</li>
            <li>Cramping in the lower abdomen</li>
            <li>Walking difficulties</li>
            <li>An unusual change in your baby’s movements</li>
            <li>Amniotic fluid leakage</li>
            <li>Unusual shortness of breath</li>
            <li>Excessive fatigue</li>
            <li>Muscle weakness</li>
          </ul>
        </li>
      </ul>
      <h4 id="useful-resources">Useful resources</h4>
      <p>For more information about physical activity during pregnancy please visit:</p>
      <ul>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-and-exercise"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Vic
          </a>
        </li>
        <li>
          <a
            href="https://www.health.gov.au/resources/pregnancy-care-guidelines/part-c-lifestyle-considerations/nutrition-and-physical-activity"
            target="_blank"
            rel="noopener noreferrer">
            Department of Health
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
