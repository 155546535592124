import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function Resources() {
  return (
    <ArticleContent>
      <h4>Quick Healthy Snack Ideas</h4>
      <p>
        Having the ingredients for these snacks on hand or preparing them for the week ahead can help you avoid making
        poor food choices when you’re hungry. We often make poorer choices when we are hungry, having healthy snacks
        prepared will help you avoid this. Also check out our healthy snack recipes for some delicious ideas.
      </p>
      <p>
        <strong>Smaller snacks</strong>
      </p>
      <ul>
        <li>1 medium fresh fruit (e.g. banana, apple or orange)</li>
        <li>A small handful of dried fruit (e.g. 8 dried apricots)</li>
        <li>1 sliced apple with almond butter (1 tbs)</li>
        <li>Some baby cucumbers</li>
        <li>A packet or cherry tomatoes</li>
        <li>A punnet of berries</li>
        <li>1 large stalk of celery with 1/4 cup low fat cottage cheese or hummus</li>
        <li>3/4 cup low fat yogurt topped with berries</li>
        <li>2 cups air-popped or low fat microwave popcorn</li>
        <li>3-4 plain biscuits</li>
        <li>Small handful of plain crackers</li>
        <li>Sliced vegetables with low fat salad dressing or dip (e.g. 50g hummus)</li>
        <li>Two wholegrain dry biscuits (e.g. vita wheats) with slices of tomato</li>
        <li>1/3 cup nuts (e.g. 20 raw almonds)</li>
        <li>Canned tuna (in spring water) with salad greens or on celery sticks</li>
        <li>1/2 cup of unsalted edamame</li>
        <li>1/2 cup of olives</li>
      </ul>

      <p>
        <strong>Larger snacks</strong>
      </p>
      <ul>
        <li>1 medium fresh fruit (e.g. banana, apple or orange)</li>
        <li>
          1 slice of whole grain bread, flat bread or half a roll with 2-3 tbsp. hummus, peanut butter or cottage
          cheese, or smashed avocado
        </li>
        <li>1-2 boiled eggs</li>
        <li>1/2 a cantaloupe with 1/2 cup of low-fat cottage cheese</li>
        <li>A small handful of crackers and a small tin of baked beans or tuna</li>
        <li>
          250 mL (1 cup) fruit smoothie made with low fat yogurt, low fat milk (or unsweetened nut milk) and a blend of
          your favourite fruits
        </li>
        <li>1 small homemade wholemeal fruit muffin</li>
        <li>1/4 cup of homemade or pre-packaged trail mix (dry cereal, dried fruit and unsalted nuts and seeds)</li>
        <li>4 fruit and cheese skewers (berries, fresh fruit and a couple of cheese cubes on a skewer)</li>
      </ul>
    </ArticleContent>
  );
}
