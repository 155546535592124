import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function LabourStages() {
  return (
    <ArticleContent>
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>There are three stages of labour.</li>
        <li>
          The first stage is the longest, involving dilation of the cervix and regular contractions. The seconds
          involves the delivery of the baby and the third, after birth, involves delivery of the placenta.
        </li>
        <li>Understanding the stages of labour can help to reduce anxiety related to birth.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Understanding the stages of birth can help you understand what is happening when labour begins which can help
        with anxiety related to birth. Labour involves three stages. The duration labour varies, but tends to be shorter
        for women who have had more than one baby previously and longer for first time mothers.
      </p>
      <p>
        <strong>The first stage:</strong>
      </p>
      <p>
        This stage is the longest and can last hours or even days. The average duration is 8-18 hours for first time
        mothers, and 5-12 hours for mothers that have previously delivered. During this stage the cervix becomes softer
        and thinner and is complete when the cervix has fully dilated to about 10 centimetres. This is achieved by
        strong, regular contractions.
      </p>
      <p>
        In the early stages, pain is likely to come and go in an irregular pattern with contractions feeling like period
        pain. Other signs of early labour include: - A mucus ‘plug’ or ‘show’ which is a blood-stained mucus discharge -
        Period pain - Lower back pain - ‘waters breaking’ which includes a slow or fast release of fluid from the vagina
        when your membranes rupture. Fluid should be clear or be pink in colour.
        <br />- Runny bowel motions
      </p>
      <p>
        As labour progresses, pain will feel more intense and contractions will become more regular. Contractions will
        start mild, like period pain, reaching a climax and then reducing away. Contractions can last about a minute in
        length. As the first stage of labour progresses, the time between contractions reduces.
      </p>
      <p>
        Things that can help during the first stage of labour include: - Staying at home - Relaxation by taking a bath
        or shower - Resting and trying to sleep, if possible - Monitoring contraction frequency and duration - Speaking
        with a midwife for reassurance
      </p>
      <p>
        <strong>The second stage:</strong>
      </p>
      <p>
        This includes from when the cervix is fully dilated to when the baby is born. This stage typically lasts 2-3
        hours. In second stage contractions will be regular and longer with only a couple of minutes between
        contractions. Other features of second stage include: - Increased pressure in the bottom - Strong and
        overwhelming urge to push that may feel involuntary - Nausea and vomiting - Shaking - Stretching and burning in
        the vagina - Restlessness and irritability
      </p>
      <p>
        Things that can help during the second stage: - Putting energy into concentrating on contractions and resting in
        between - Deep breathing - Relaxing and working with your body to progress labour - Trying different positions
        if you feel uncomfortable – for example, walking, squatting, lying or sitting - Pain management - A bath or
        shower to help you to relax and to manage the pain
      </p>
      <p>
        <strong>The third stage:</strong>
      </p>
      <p>
        This begins after the baby is born and includes delivery of the placenta. This stage typically lasts only a few
        minutes. Delivery of the placenta can occur naturally or with the help of medication which accelerates this
        stages.
      </p>
      <h4 id="useful-links">Useful links</h4>
      <p>For more information please visit: </p>
      <ul>
        <li>
          <a
            href="https://www.thewomens.org.au/health-information/pregnancy-and-birth/labour-birth/stages-of-labour"
            target="_blank"
            rel="noopener noreferrer">
            The Royal Women’s Hospital
          </a>{' '}
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/HealthyLiving/pregnancy-labour"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
