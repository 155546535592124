import React from 'react';
import { Grid, Typography, Box, FormHelperText } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';

export type ContactPreferenceProps = {
  value: string[];
  onChange: (data: string[]) => void;
  error?: boolean;
  helperText?: string;
};

export default function ContactPreference(props: ContactPreferenceProps) {
  const { value, onChange, error, helperText } = props;
  const contactPreferences = value;

  const isSelected = (pref: string) => {
    return Boolean(contactPreferences.find(val => pref === val));
  };

  const toggle = (pref: string) => {
    const set = new Set<string>(contactPreferences);
    if (set.has(pref)) {
      set.delete(pref);
    } else {
      set.add(pref);
    }
    onChange(Array.from(set));
  };

  return (
    <Box mt={2}>
      <Typography variant="h6" gutterBottom>
        Please contact me at one of the following time slots. All times are in Australian Eastern Standard Time.
      </Typography>
      <Grid container spacing={4}>
        {['Monday', 'Tuesday', 'Wedesday', 'Thursday', 'Friday'].map(day => (
          <React.Fragment key={day}>
            <Grid item xs={6}>
              <DayBtn
                variant={isSelected(`${day}_AM`) ? 'contained' : 'outlined'}
                onClick={() => toggle(`${day}_AM`)}
                primary={day}
                secondary="Morning"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <DayBtn
                variant={isSelected(`${day}_PM`) ? 'contained' : 'outlined'}
                onClick={() => toggle(`${day}_PM`)}
                primary={day}
                secondary="Afternoon"
                fullWidth
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </Box>
  );
}

const DayBtn = styled((props: ButtonProps & { primary: string; secondary: string }) => {
  const { primary, secondary, ...buttonProps } = props;
  return (
    <Button {...buttonProps}>
      <div>
        <Typography variant="button">{primary}</Typography>
        <Typography variant="caption" className="secondary">
          {secondary}
        </Typography>
      </div>
    </Button>
  );
})({
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  '& .secondary': {
    display: 'block',
    color: '#666',
  },
});
