import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { Button, FormGroup, FormLabel, FormControlLabel, Checkbox, Box, Divider } from '@material-ui/core';
import useContentState from './useContentState';

type OptionType = {
  label: string;
  value: string;
};

export type QuestionsProps = {
  id: string;
  label: string;
  answer: string | string[];
  options: OptionType[];
  multiple?: boolean;
  children: (match: boolean) => React.ReactElement;
};

export default function Question(props: QuestionsProps) {
  const { id, label, options, multiple, answer, children } = props;
  const { getContentState, setContentState } = useContentState();
  const contentState = getContentState(id, null);
  const [data, setData] = React.useState<string | string[]>(contentState);
  const submitted = !isEmpty(contentState);
  const canSubmit = !isEmpty(data);

  const isChecked = (value: string) => {
    if (multiple) {
      const dataSet = new Set(data);
      return dataSet.has(value);
    } else {
      return isEqual(value, data);
    }
  };

  const isMatched = () => {
    const contentSet = new Set<string>(contentState);
    const answerSet = new Set<string>(answer);
    return isEqual(contentSet, answerSet);
  };

  const handleChange = (value: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (multiple) {
      const dataSet = new Set<string>(data);
      e.target.checked ? dataSet.add(value) : dataSet.delete(value);
      setData(Array.from(dataSet));
    } else {
      setData(value);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setContentState(id, data);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <FormLabel>{label}</FormLabel>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            label={option.label}
            control={
              <Checkbox
                color="primary"
                checked={isChecked(option.value)}
                disabled={submitted}
                onChange={handleChange(option.value)}
                value={option.value}
              />
            }
          />
        ))}
      </FormGroup>
      <Divider />
      {!submitted && (
        <Box display="flex" justifyContent="center" mt={1}>
          <Button type="submit" variant="contained" disabled={!canSubmit} color="primary">
            Submit
          </Button>
        </Box>
      )}

      {submitted && children(isMatched())}
    </form>
  );
}
