import { useState, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default function useMeasure(ref) {
  const [bounds, setContentRect] = useState({ x: 0, y: 0, width: 0, height: 0, top: 0, right: 0, bottom: 0, left: 0 });

  useLayoutEffect(() => {
    let animationFrameId = null;
    const ro = new ResizeObserver(([entry]) => {
      animationFrameId = window.requestAnimationFrame(() => {
        setContentRect(entry.contentRect);
      });
    });
    ro.observe(ref.current);

    return () => {
      window.cancelAnimationFrame(animationFrameId);
      ro.disconnect();
    };
  }, [ref]);

  return bounds;
}
