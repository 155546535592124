import React from 'react';
import { TextField } from '@material-ui/core';
import { QuestionVariant } from './types';
import { RadioGroup } from 'components/form';

import SelectionOpen from './SelectionOpen';
import Rating from './Rating';
import ToggleGroup from './ToggleGroup';
import SelectionMulti from './SelectionMulti';

export type SurveyQuestionProps = {
  name: string;
  variant?: QuestionVariant;
  options?: string[];
  value: any;
  onChange: (value: any) => void;
};

export default function SurveyQuestion(props: SurveyQuestionProps) {
  const { name, variant, options, value, onChange } = props;

  switch (variant) {
    case 'text':
      return (
        <TextField
          variant="outlined"
          multiline
          rows={3}
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          fullWidth
        />
      );

    case 'selection':
      return <RadioGroup value={value || null} options={options || []} onChange={e => onChange(e.target.value)} />;

    case 'selection-open':
      return <SelectionOpen value={value} options={options || []} onChange={onChange} />;

    case 'selection-multi':
      return <SelectionMulti value={value} options={options || []} onChange={onChange} />;

    case 'toggle-group':
      return <ToggleGroup value={value || null} options={options || []} onChange={onChange} />;

    default:
      return <Rating name={name} value={value || null} options={options || []} onChange={onChange} />;
  }
}
