import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { selectProfile } from './store';

export default function ensureProfile<T>(Comp: React.ComponentType<T>): React.ComponentType<T> {
  return (props: T) => {
    const { profile } = useSelector(selectProfile);
    if (profile === null) {
      return <Redirect to="/profile/init" />;
    }
    return <Comp {...props} />;
  };
}
