import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { selectAuth } from './store';
import ensureProfile from './ensureProfile';
import withAuth from './withAuth';
import { initAuth } from './auth/authSlice';

import Home from './home';
import Auth from './auth';
import Dashboard from './dashboard';
import Topics from './topics';
import Profile from './profile';
import Admin from './admin';
import gtag from './gtag';

Object.assign(window, {
  [`ga-disable-${process.env.REACT_APP_GA_MEASUREMENT_ID}`]: process.env.NODE_ENV !== 'production',
});

export default function AppRoutes() {
  const { authenticated } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const trackingId = process.env.REACT_APP_GA_MEASUREMENT_ID;
    if (trackingId) {
      return history.listen(location => gtag('config', trackingId, { page_path: location.pathname }));
    }
  }, [history]);

  React.useEffect(() => {
    dispatch(initAuth());
  }, [dispatch]);

  if (authenticated === null) {
    return null;
  }

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/(program|about|contact|terms)" component={Home} />
      <Route path="/auth" component={Auth} />
      <Route exact path="/dashboard" component={withAuth(ensureProfile(Dashboard))} />
      <Route path="/topics/:moduleId" component={withAuth(ensureProfile(Topics))} />
      <Route path="/profile" component={withAuth(Profile)} />
      <Route path="/admin" component={withAuth(Admin, true)} />
      <Route exact path="/404" render={() => <h6>Not Found...</h6>} />
      <Redirect to="/404" />
    </Switch>
  );
}
