import React from 'react';
import { useHistory, useParams } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import Form from 'components/form';
import { Typography, Grid, Card, CardHeader, CardContent, Button, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import useTopicState from 'app/topics/useTopicState';
import DietCard from './DietCard';
import SlideGroup from './SlideGroup';
import { DietValue } from './types';
import { ModuleId } from 'app/types';

export * from './types';

const defaultDietValue = [0, 'daily'];

const useStyles = makeStyles(theme =>
  createStyles({
    form: {
      margin: theme.spacing(4, 0),
    },
  }),
);

export type LifestyleData = {
  group1: DietValue;
  group2: DietValue;
  group3: DietValue;
  group4: DietValue;
  group5: DietValue;
  group6: DietValue;
  moderate: number;
  vigorous: number;
  muscle: number;
  sitting_weekday: number;
  sitting_weekend: number;
};

export const questions: Array<{ name: string; title: string }> = [
  {
    name: 'group1',
    title: 'Grains: bread, cereal, rice, pasta etc.',
  },
  {
    name: 'group2',
    title: 'Vegetable and legumes',
  },
  {
    name: 'group3',
    title: 'Fruit',
  },
  {
    name: 'group4',
    title: 'Milk, yoghurt, cheese, or alternatives',
  },
  {
    name: 'group5',
    title: 'Meat, fish, poultry, eggs, nuts, legumes',
  },
  {
    name: 'group6',
    title: 'Extras/Discretionary choices',
  },
  {
    name: 'moderate',
    title: 'How many hours of moderate physical activity do you accumulate in a week?',
  },
  {
    name: 'vigorous',
    title: 'How many hours of vigorous physical activity do you accumulate in a week?',
  },
  {
    name: 'muscle',
    title: 'How many days a week do you do muscle strengthening exercise each week?',
  },
  {
    name: 'sitting_weekday',
    title: 'How many hours do you spend sitting on average on week days?',
  },
  {
    name: 'sitting_weekend',
    title: 'How many hours do you spend sitting on average on weekend days?',
  },
];

const initialValue: LifestyleData = {
  group1: [0, 'daily'],
  group2: [0, 'daily'],
  group3: [0, 'daily'],
  group4: [0, 'daily'],
  group5: [0, 'daily'],
  group6: [0, 'daily'],
  moderate: 0,
  vigorous: 0,
  muscle: 0,
  sitting_weekday: 0,
  sitting_weekend: 0,
};

export default function LifestyleQuestions() {
  const classes = useStyles();
  const history = useHistory();
  const { moduleId } = useParams<{ moduleId: ModuleId }>();
  const { topicState, updateTopicState } = useTopicState(moduleId);
  const lifestyleData: LifestyleData = isEmpty(topicState.lifestyleData)
    ? initialValue
    : (topicState.lifestyleData as LifestyleData);

  const handleSubmit = (data: LifestyleData) => {
    updateTopicState({ lifestyleData: data }, () => history.replace(`/topics/${moduleId}/goals`));
  };

  return (
    <>
      <Card>
        <CardHeader title="Estimate your food group intake" />
        <CardContent>
          <Typography gutterBottom>
            Please record the estimated number of serves you eat from each food group. Account for all food eaten,
            including the ingredients added to recipes, eaten in mixed meals and in restaurant or take away meals. You
            can provide an estimate of the serves you eat in 1 day OR 1 week.
          </Typography>
        </CardContent>
      </Card>

      <Form<LifestyleData> className={classes.form} data={lifestyleData} onSubmit={handleSubmit}>
        {({ values, updateField }) => (
          <React.Fragment>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <DietCard
                  name="group1"
                  value={values.group1 || defaultDietValue}
                  onChange={value => updateField('group1', value)}
                  title="Grains: bread, cereal, rice, pasta etc."
                  subheader="example of 1 serve"
                  image="images/food_groups/group1.jpg">
                  <ul>
                    <li>
                      <em>1 slice (40g)</em> bread
                    </li>
                    <li>
                      <em>1/2 medium (40g)</em> bread roll or flat bread
                    </li>
                    <li>
                      <em>1/2 cup (120g)</em> cooked porridge
                    </li>
                    <li>
                      <em>2/3 cup (30g)</em> wheat breakfast cereal flakes
                    </li>
                    <li>
                      <em>1/4 cup (30g)</em> muesli
                    </li>
                    <li>
                      <em>1 (60g)</em> crumpet
                    </li>
                    <li>
                      <em>1/2 cup (75-120g)</em> cooked rice, pasta, noodles, quinoa, barley or other grains{' '}
                    </li>
                    <li>
                      <em>3-6 (35g)</em> crisp breads (3 Ryvita or 6 Vitawheats/Cruskits){' '}
                    </li>
                  </ul>
                </DietCard>
              </Grid>
              <Grid item xs={12}>
                <DietCard
                  name="group2"
                  value={values.group2 || defaultDietValue}
                  onChange={(value: DietValue) => updateField('group2', value)}
                  image="images/food_groups/group2.jpg"
                  title="Vegetable and legumes"
                  subheader="example of 1 serve">
                  <ul>
                    <li>
                      <em>1/2 cup</em> cooked green or orange vegetables (e.g. broccoli, spinach, carrot, pumpkin etc.){' '}
                    </li>
                    <li>
                      <em>1 cup</em> green leafy vegetables or raw salad vegetables
                    </li>
                    <li>
                      <em>1/2 cup</em> cooked dried or canned beans, peas or lentils
                    </li>
                    <li>
                      <em>1 small or 1/2 medium</em> potato/starchy vegetable (e.g. sweet potato, sweet corn, taro or
                      cassava)
                    </li>
                    <li>
                      <em>1 medium</em> tomato
                    </li>
                  </ul>
                </DietCard>
              </Grid>
              <Grid item xs={12}>
                <DietCard
                  name="group3"
                  value={values.group3 || defaultDietValue}
                  onChange={(value: DietValue) => updateField('group3', value)}
                  image="images/food_groups/group3.jpg"
                  title="Fruit"
                  subheader="example of 1 serve">
                  <ul>
                    <li>
                      <em>1 medium</em> apple, banana, orange or pear
                    </li>
                    <li>
                      <em>2 small</em> apricots, kiwi fruit or plum
                    </li>
                    <li>
                      <em>1 cup</em> berries, diced fruit pieces or canned fruit
                    </li>
                    <li>
                      <em>30g</em> dried fruit (e.g. 4 dried apricot halves, 1.5 tablespoons sultanas)
                    </li>
                    <li>
                      <em>1/2 cup (125ml)</em> fruit juice (not made from fruit concentrate)
                    </li>
                  </ul>
                </DietCard>
              </Grid>
              <Grid item xs={12}>
                <DietCard
                  name="group4"
                  value={values.group4 || defaultDietValue}
                  onChange={(value: DietValue) => updateField('group4', value)}
                  image="images/food_groups/group4.jpg"
                  title="Milk, yoghurt, cheese, or alternatives"
                  subheader="example of 1 serve">
                  <ul>
                    <li>
                      <em>1 cup (250mL)</em> dairy milk or calcium fortified dairy alternative milk (soy, rice, almond
                      etc.) or other cereal drink
                    </li>
                    <li>
                      <em>2 slices cheese or 4 cubes (3x2cm) (40g)</em> hard cheese (e.g. cheddar, gouda)
                    </li>
                    <li>
                      <em>1/2 cup (120g)</em> ricotta cheese
                    </li>
                    <li>
                      <em>3/4 cup (200g; roughly 1 small tub)</em> dairy yoghurt, or calcium fortified dairy alternative
                      yoghurt OR; non-dairy alternatives:
                    </li>
                    <li>
                      <em>1/2 cup (100g)</em> almonds with skin
                    </li>
                    <li>
                      <em>1/3 cup (75-80g)</em> canned pink or Australian salmon with bones
                    </li>
                    <li>
                      <em>100g</em> firm tofu
                    </li>
                  </ul>
                </DietCard>
              </Grid>
              <Grid item xs={12}>
                <DietCard
                  name="group5"
                  value={values.group5 || defaultDietValue}
                  onChange={(value: DietValue) => updateField('group5', value)}
                  image="images/food_groups/group5.jpg"
                  title="Meat, fish, poultry, eggs, nuts, legumes"
                  subheader="example of 1 serve">
                  <ul>
                    <li>
                      <em>65g</em> cooked red meat such as beef, veal, lamb, pork, kangaroo or goat (approx. 90-100g
                      raw) (e.g. 1/2 cup lean mince, 2 small chops or 2 slices roast meat)
                    </li>
                    <li>
                      <em>80g </em> cooked poultry such as chicken or turkey (approx. 100g raw weight) (e.g. 1/2 medium
                      chicken breast)
                    </li>
                    <li>
                      <em>100g</em> cooked fish fillet (approx. 115g raw weight) or 1 small can of fish
                    </li>
                    <li>
                      <em>2 large (120g)</em> eggs
                    </li>
                    <li>
                      <em>1 cup (150g)</em> cooked or canned beans, peas, lentils (e.g. chickpeas or split peas)
                    </li>
                    <li>
                      <em>170g</em> tofu
                    </li>
                    <li>
                      <em>30g</em> nuts, seeds or nut butters/seed paste (e.g. peanut butter, tahini)
                    </li>
                  </ul>
                </DietCard>
              </Grid>
              <Grid item xs={12}>
                <DietCard
                  name="group6"
                  value={values.group6 || defaultDietValue}
                  onChange={(value: DietValue) => updateField('group6', value)}
                  image="images/food_groups/group6.jpg"
                  title="Extras/Discretionary choices"
                  subheader="example of 1 serve">
                  <ul>
                    <li>
                      <em>1 tablespoon</em> butter, margarine, jam or honey
                    </li>
                    <li>
                      <em>2 scoops (75g)</em> regular ice cream{' '}
                    </li>
                    <li>
                      <em>2 slices</em> processed meat (e.g. salami, ham etc)
                    </li>
                    <li>
                      <em>2 thin</em> sausages{' '}
                    </li>
                    <li>
                      <em>1/3 (60g)</em> individual meat pie or pasty{' '}
                    </li>
                    <li>
                      <em>12</em> hot chips
                    </li>
                    <li>
                      <em>1 small</em> slice of plain cake or doughnut
                    </li>
                    <li>
                      <em>2-3 small</em> sweet biscuits{' '}
                    </li>
                    <li>
                      <em>5-6</em> lollies
                    </li>
                    <li>
                      <em>1 small (25g)</em> bar of chocolate{' '}
                    </li>
                  </ul>
                </DietCard>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title="Physical Activity"
                    subheader="Please record the estimated hours of physical activity you accumulate in 1 week."
                  />
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <SlideGroup
                          name="moderate"
                          range={[0, 8]}
                          label="How many hours of moderate physical activity do you accumulate in a week?"
                          helperText="This activity takes some effort but you can still talk while doing it, e.g. brisk waking, recreational swimming, dancing, social tennis, golf or household tasks like vacuuming, scrubbing or raking leaves."
                          value={values.moderate || 0}
                          onChange={value => updateField('moderate', value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SlideGroup
                          name="vigorous"
                          range={[0, 5]}
                          label="How many hours of vigorous physical activity do you accumulate in a week?"
                          helperText="This activity is harder and makes you breath harder and faster, e.g. jogging, aerobics, fast cycling, organised sports involving running, tasks that involve heavy lifting carrying or digging."
                          value={values.vigorous || 0}
                          onChange={value => updateField('vigorous', value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SlideGroup
                          name="muscle"
                          range={[0, 5]}
                          label="How many days a week do you do muscle strengthening exercise each week?"
                          helperText="This exercise can include body weight exercises like push-ups, squats and lunges; or involve weights or resistance exercises such as reformer Pilates."
                          value={values.muscle || 0}
                          onChange={value => updateField('muscle', value)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title="Sitting"
                    subheader="Please record the estimated hours you spend sitting on particular days of the week."
                  />
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <SlideGroup
                          name="sitting_weekday"
                          range={[0, 10]}
                          label="How many hours do you spend sitting on average on week days?"
                          helperText="This includes sitting while at work, driving or on public transport and while watching TV or on the computer."
                          value={values.sitting_weekday}
                          onChange={value => updateField('sitting_weekday', value)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <SlideGroup
                          name="sitting_weekend"
                          range={[0, 10]}
                          label="How many hours do you spend sitting on average on weekend days?"
                          helperText="This includes sitting while watching sporting events, driving or on public transport and while watching TV or on the computer."
                          value={values.sitting_weekend}
                          onChange={value => updateField('sitting_weekend', value)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="center" my={4}>
              <Button variant="contained" type="submit" color="primary">
                Submit
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Form>
    </>
  );
}
