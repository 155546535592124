import React from 'react';
import { Card, CardContent, CardHeader, styled } from '@material-ui/core';
import Link from 'components/Link';
import { ArticleContent, Question, ResultLine, ResultLineTypes } from 'components/topics/Article';

const Table = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',

  '& th': {
    color: '#fff',
    backgroundColor: '#8d9669',
    fontWeight: 500,
  },

  '& th, & td': {
    margin: 0,
    padding: 0,
    lineHeight: 3,
    textAlign: 'center',
    border: '1px solid #8d9669',
  },

  '& tr.highlight td': {
    backgroundColor: '#aebc95',
  },
});

export default function Weight() {
  return (
    <ArticleContent>
      <Card>
        <CardHeader title="Knowledge Test" />
        <CardContent>
          <Question
            id="knowledgeTest"
            answer="B"
            label="Which group gains more weight each year than any other age group?"
            options={[
              {
                label: 'Females aged 40+',
                value: 'A',
              },
              {
                label: 'Females in their 20s and 30s',
                value: 'B',
              },
              {
                label: 'Males aged 50+',
                value: 'C',
              },
              {
                label: 'Teen males',
                value: 'D',
              },
            ]}>
            {matched =>
              matched ? (
                <ResultLine type={ResultLineTypes.Success} message="Yes, this is correct." />
              ) : (
                <ResultLine
                  type={ResultLineTypes.Failure}
                  message="Incorrect. Females in their 20s and 30s gain more weight each year than any other age group."
                />
              )
            }
          </Question>
        </CardContent>
      </Card>

      <h4>Key points</h4>
      <ul>
        <li>Weight gain in women is just under 1kg every year.</li>
        <li>Younger women in their 20s and 30s gain more weight than any other sex or age group.</li>
        <li>Preventing weight gain improves health, including fertility, irrespective of current weight.</li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        Almost all Australians are gaining weight at a concerning rate. Often this weight creeps on a little each year
        without you even noticing. Sometimes you don’t notice it’s happening until you can’t fit into your clothes
        comfortably or it has affected your health.
      </p>

      <p>
        On average, women in Australia gain just under 1kg a year. Over 10 years this weight gain is equal to about 7-8
        kilograms. Younger women in their 20s and 30s are at highest risk in the population, gaining more weight than
        any other sex or age group.
      </p>

      <p>
        Even small increases in weight increase the chance of developing health complications. Excess weight in young
        women can lead to difficulty conceiving, menstrual problems and complications during pregnancy. Children born to
        mothers with excess weight also have higher risks of obesity and health complications. Later in life, excess
        weight gain can lead to high blood pressure, joint problems, type 2 diabetes, heart disease, and cancer. Heart
        disease is directly linked to lifestyle and can be prevented by making healthy choices, yet is the biggest
        killer of Australian women.
      </p>

      <p>
        <strong>
          With most women leading busy lives, juggling a household, working, looking after children and partners;
          healthy habits such as home cooked meals and regular exercise can be easily neglected.
        </strong>
      </p>

      <h4>Weight through different life-stages</h4>
      <p>
        Most women gain unwanted weight at some time in their lives. It is not ‘normal’ to gain weight as you get older;
        in fact any weight gain once you finish growing can affect your health. Different stages in a woman’s life are
        high risk times for weight gain, including:
      </p>

      <ul>
        <li>Puberty: if you are overweight at this time you are more likely to be overweight as an adult.</li>
        <li>
          Pre-pregnancy: weight gain is common when establishing a career and household. Many women may not realise they
          are at high risk for increases in weight. Physical activity typically declines in adulthood.
        </li>
        <li>
          Pregnancy: weight gain above recommendations is common, leading to weight retention. Following pregnancy is
          difficult to weight retained is difficult to lose.
        </li>
        <li>In your 40s: as you approach menopause you may also notice weight gain particularly around your tummy.</li>
      </ul>

      <h4>Weight and pregnancy</h4>
      <p>
        Being overweight or obese during pregnancy puts you at risk of complications including gestational diabetes,
        preeclampsia, stillbirth and delivery by caesarean section.
      </p>

      <p>
        While excess weight is a serious concern, being underweight can also be a significant issue for fertility and
        during pregnancy. Underweight females may have trouble conceiving. Those who become pregnant and do not gain
        enough weight during pregnancy are at risk of having a preterm birth or a small for gestational age (SGA) baby.
      </p>

      <p>
        If you are planning to become pregnant, optimising your diet and introducing (or maintaining) physical activity
        are positive changes that improve the likelihood you will have a healthy pregnancy. Even if you don’t lose
        weight, healthy lifestyle choices are powerful for prevention of weight gain which is relevant to the majority
        of women, even those of a healthy weight.
      </p>

      <h4>Should I maintain my weight or lose weight?</h4>
      <p>
        All women should aim to not gain weight, whether you are a healthy weight or overweight.{' '}
        <strong>
          To maintain your current weight most women need to reduce their food intake by just 210kJ, equal to one sweet
          biscuit per day or a 15 minute walk.
        </strong>{' '}
        Saving yourself from this weight gain will have huge benefits for your health as well.
      </p>

      <p>
        Sometimes extra weight gained has a major effect on your health and you may need to lose weight. Many of us will
        have success losing a few kilograms, but shedding more than this can be hard. If you have a lot of weight to
        lose you may benefit from supervision from your doctor or a healthcare professional. There is no easy way to
        manage your weight. Whether you want to gain weight, stay the same weight or lose some weight, you have to put
        in some effort. The OptimalMe program will make it as simple as possible for you and guide you through the steps
        needed to at least maintain your weight through achieving small, sustainable and positive lifestyle changes.
      </p>

      <p>
        Use our <Link to="/dashboard">Body Mass Index (BMI) calculator</Link> to review your BMI, referencing the table
        below:
      </p>

      <Table>
        <thead>
          <tr>
            <th>BMI</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Below 18.5</td>
            <td>Unhealthy weight</td>
          </tr>
          <tr className="highlight">
            <td>18.5 up to 24.9</td>
            <td>Healthy weight</td>
          </tr>
          <tr>
            <td>25 up to 30</td>
            <td>Unhealthy weight</td>
          </tr>
          <tr>
            <td>Over 30</td>
            <td>Very unhealthy weight</td>
          </tr>
        </tbody>
      </Table>

      <h4>Useful Links</h4>
      <ul>
        <li>
          <a
            href="https://www.health.qld.gov.au/clinical-practice/guidelines-procedures/clinical-staff/maternity/nutrition/lifestyle/pre-conception"
            target="_blank"
            rel="noopener noreferrer">
            Pre-conception
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
