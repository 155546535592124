import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { calcBmi, bmiLegends, BmiCategory } from 'app/helper';

type LegendMark = {
  at: number;
  gap: number;
};

type StyleProps = {
  bmi: number;
  category: BmiCategory;
  bmiPos: number;
  marks: LegendMark[];
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2, 4),
      display: 'flex',
      flexDirection: 'column',
    },

    chart: (props: StyleProps) => ({
      position: 'relative',
      height: 30,
      backgroundColor: '#CCC',
      borderRadius: 19,
      background: Utils.gradients(props.marks),
      marginTop: '2rem',
      '& > div': {
        position: 'absolute',
        display: 'inline-block',
        top: '-2rem',
        left: `${props.bmiPos.toFixed(1)}%`,
        '& > span': {
          position: 'relative',
          padding: '0.2rem 0.5rem',
          marginLeft: '-50%',
          backgroundColor: bmiLegends[props.category].color,
          color: '#FFF',
          borderRadius: 3,
          '&:after': {
            position: 'absolute',
            content: '""',
            top: '100%',
            left: 'calc(50% - 8px)',
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderTop: `8px solid ${bmiLegends[props.category].color}`,
          },
        },
      },
    }),

    legend: (props: StyleProps) => ({
      display: 'block',
      fontSize: '0.7rem',
      whiteSpace: 'nowrap',
      marginTop: theme.spacing(1),
      '& > span': {
        position: 'relative',
        display: 'inline-block',
        textAlign: 'center',
        padding: theme.spacing(0, 1),
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        zIndex: 10,
        '&:after': {
          position: 'absolute',
          content: '""',
          top: 0,
          right: 0,
          width: 1,
          bottom: 0,
          borderRight: '1px solid #666',
        },
        '&:last-child:after': {
          border: 'none',
        },
      },

      '& > .underWeight': {
        width: `${100 * props.marks[0].gap}%`,
      },

      '& > .normal': {
        width: `${100 * props.marks[1].gap}%`,
      },

      '& > .overWeight': {
        width: `${100 * props.marks[2].gap}%`,
      },

      '& > .obese': {
        width: `${100 * props.marks[3].gap}%`,
      },

      '& > .extremlyObese': {
        width: `${100 * props.marks[4].gap}%`,
      },
    }),
  }),
);

export type GradiantBMIProps = {
  weight: number;
  height: number;
};

export default function GradiantBMI(props: GradiantBMIProps) {
  const [bmi, category] = calcBmi(props.weight, props.height);
  const [min, max] = [15.5, 42];
  const classes = useStyles({
    bmi,
    category,
    bmiPos: (100 * (bmi - min)) / (max - min),
    marks: Utils.calcMarks(min, max),
  });

  return (
    <div className={classes.root}>
      <div className={classes.chart}>
        <div>
          <span>{bmi.toFixed(1)}</span>
        </div>
      </div>
      <div className={classes.legend}>
        <span className="underWeight"> Underweight </span>
        <span className="normal"> Healthy </span>
        <span className="overWeight"> Overweight </span>
        <span className="obese"> Obese </span>
        <span className="extremlyObese"> Extremly Obese </span>
      </div>
    </div>
  );
}

const Utils = {
  BMIRange: [
    {
      lt: 18.5,
      label: 'Underweight',
      color: '#FEE128',
    },
    {
      lt: 25,
      label: 'Healthy',
      color: '#228B22',
    },
    {
      lt: 30,
      label: 'Overweight',
      color: '#FEE128',
    },
    {
      lt: 40,
      label: 'Obese',
      color: '#805D49',
    },
    {
      lt: Number.MAX_VALUE,
      label: 'Extremly Obese',
      color: '#A0413B',
    },
  ],

  calcMarks(min: number, max: number) {
    return this.BMIRange.reduce<LegendMark[]>((result, item) => {
      const at = (Math.min(max, item.lt) - min) / (max - min);
      const gap = result.length > 0 ? at - result[result.length - 1].at : at;
      return [...result, { at, gap }];
    }, []);
  },

  gradients(marks: LegendMark[]) {
    const grad = marks
      .map((mark, index) => {
        const p = (mark.at - mark.gap / 2) * 100;
        return `${this.BMIRange[index].color} ${p.toFixed(0)}%`;
      })
      .join(',');
    return `linear-gradient(90deg, ${grad})`;
  },
};
