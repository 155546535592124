import React from 'react';
import { Paper, StyledProps, PaperProps, styled } from '@material-ui/core';

export const Toolbar = styled((props: StyledProps & PaperProps) => {
  const { children, className, ...others } = props;
  return (
    <Paper className={className} {...others}>
      {children}
    </Paper>
  );
})(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  marginBottom: theme.spacing(2),
}));
