import { notEmpty } from 'components/form/validators';
import { Step } from 'components/stepsForm';

export default {
  title: 'Your pre-pregnancy health',
  fields: [
    {
      name: 'h1_chronic',
      prefix: 'H1',
      type: 'checkbox-group',
      desc:
        'Have you ever been diagnosed, or told by your doctor, you have any of chronic disease conditions? Such as:',
      input: {
        options: [
          'Diabetes in pregnancy',
          'Type 1 or 2 diabetes',
          'Heart disease(i.e. congenital/ischaemic/rheumatic)',
          'High blood pressure',
          'Pre-eclampsia',
          'Thyroid disease (under or over active thyroid)',
          'Epilepsy',
          'Blood clots (i.e. deep vein thrombosis)',
          'Asthma',
          'Depression',
          'Anxiety',
          'Cancer',
          'Polycystic ovary syndrome',
          'None of the above',
        ],
      },
    },
    {
      name: 'h2_reproductive_history',
      prefix: 'H2',
      type: 'checkbox-group',
      desc: 'Have you had any of the following occur in a previous pregnancy? (Please select all that apply)',
      input: {
        options: [
          'This is my first pregnancy',
          'Fetal loss / infant death',
          'Birth defect(s)',
          'Low birth weight',
          'Pre-term birth',
          'None of the above',
        ],
      },
    },
    {
      name: 'h3_smoke',
      prefix: 'H3',
      type: 'radio-group',
      desc: 'Do you currently smoke?',
      validator: notEmpty(),
      input: {
        options: ['Yes', 'No', 'No, I have stopped to prepare for pregnancy'],
      },
    },
    {
      name: 'h4_alcohol',
      prefix: 'H4',
      type: 'radio-group',
      desc: 'Do you drink alcohol?',
      validator: notEmpty(),
      input: {
        options: ['Yes', 'No', 'No, I have stopped to prepare for pregnancy'],
      },
    },
    {
      name: 'h5_drugs',
      prefix: 'H5',
      type: 'radio-group',
      desc:
        'Do you use recreational drugs (e.g. Cocaine/crack, Marijuana, Methamphetamines, Methadone, Heroin, and Ecstasy)?',
      validator: notEmpty(),
      input: {
        options: ['Yes', 'No', 'No, I have stopped to prepare for pregnancy'],
      },
    },
    {
      name: 'h6_vaccines',
      prefix: 'H6',
      type: 'checkbox-group',
      desc: 'Are these immunisations up to date? (Please select all that apply)',
      input: {
        options: [
          'Measles, Mumps, Rubella (MMR)',
          'Hepatitis B',
          'Tetanus/Diphtheria/Pertussis (whooping cough)',
          'Chicken pox (Varicella)',
        ],
      },
    },
    {
      name: 'h7_chickenpox',
      prefix: 'H7',
      type: 'radio-group',
      desc: 'Have you ever been diagnosed, or told by your doctor you have the chickenpox?',
      validator: notEmpty(),
      input: {
        options: ['Yes', 'No'],
      },
    },
    {
      name: 'h8_influenza',
      prefix: 'H8',
      type: 'radio-group',
      desc: 'Have you had an influenza vaccination in the most recent flu season (flu shot)?',
      validator: notEmpty(),
      input: {
        options: ['Yes', 'No'],
      },
    },
    {
      name: 'h9_cervical',
      prefix: 'H9',
      type: 'radio-group',
      desc: 'Have you had a cervical screen (e.g. pap smear) after December 2017?',
      validator: notEmpty(),
      input: {
        options: ['Yes', 'No'],
      },
    },
    {
      name: 'h10_genetics',
      prefix: 'H10',
      type: 'radio-group',
      desc: 'Do you have a personal or family history of any genetic conditions?',
      validator: notEmpty(),
      input: {
        options: ['Yes', 'No', 'Unsure'],
      },
    },
    {
      name: 'h11_supplements',
      prefix: 'H11',
      type: 'radio-group',
      desc: 'Are you currently taking any of the following supplements?',
      validator: notEmpty(),
      input: {
        options: ['Folic Acid (Folate)', 'Iodine', 'Both Folic Acid and Iodine', 'None of the above'],
      },
    },
    {
      name: 'h12_medication',
      prefix: 'H12',
      type: 'checkbox-group',
      desc: 'Are you or have you been deficient in any of the following?',
      input: {
        options: ['Iron', 'Vitamin D', 'Unsure'],
      },
    },
    {
      name: 'h13_contraception',
      prefix: 'H13',
      type: 'radio-group',
      desc: 'Are you currently using any contraception?',
      validator: notEmpty(),
      input: {
        options: ['Yes', 'No'],
      },
    },
  ],
} as Step;
