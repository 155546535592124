import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import NavToolbar, { Nav } from 'components/nav/NavToolbar';
import { ScrollTriggerPage } from 'components/page';
//import ArticleCard from 'components/topics/Articles/ArticleCard';
import { Typography/*, Box, Grid, CardHeader, Card, CardContent*/ } from '@material-ui/core';
//import { shapingLife } from '../content';
//import { Content } from 'app/topics/types';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      backgroundColor: 'transparent',
      color: '#333',
    },
    filler: {
      backgroundImage: 'url(images/topics/postpartum/goals_banner.jpg)',
      backgroundSize: 'cover',
    },
    header: {
      padding: theme.spacing(2, 0),
      color: '#333',
    },
  }),
);

const navs: Nav[] = [
  {
    label: 'Essential Information',
    path: '/topics/postpartum/essentials',
    exact: false,
  },
  // Fix # 141
  {
    label: 'Shaping My Lifestyle',
    path: '/topics/postpartum/shaping_my_lifestyle',
    exact: false,
  },
  {
    label: 'My Goals',
    path: '/topics/postpartum/goals',
    exact: false,
  },
];

export function GoalsPage(props: React.HTMLProps<HTMLElement>) {
  const classes = useStyles();
  return (
    <ScrollTriggerPage
      title="Post-Birth Goals"
      extraToolbar={<NavToolbar navs={navs} />}
      classes={{ appBar: classes.appBar, fillerBg: classes.filler }}>
      <div className={classes.header}>
        <Typography variant="h4" color="inherit">
          My Goals
        </Typography>
      </div>
      {props.children}
    </ScrollTriggerPage>
  );
}

export function GoalsPageWithArticles(props: React.HTMLProps<HTMLElement>) {
  return (
    <GoalsPage>
      {
        /*
        // Fix #141
        <Card>
          <CardHeader title="Shaping my Lifestyle" />
          <CardContent>
            <p>Below is some information you may find useful about healthy living and forming sustainable habits. Any adapted change in behaviour requires close monitoring and self-assessment. This information is relevant to you and provides recommendations for physical activity and diet modifications to improve your health.</p>
            <p>They are based on many years of research and are considered most likely to maintain your health and weight long-term. We recommend incorporating these into your lifestyle over time and the upcoming sections will explore how you can do this, making these messages work for you.</p>
          </CardContent>
        </Card>
        <Box my={2}>
          <Grid container spacing={2}>
            {shapingLife.map((article: Content) => (
              <Grid item key={article.id} xs={12} sm={4}>
                <ArticleCard article={article} to={`/topics/postpartum/articles/${article.id}`} />
              </Grid>
            ))}
          </Grid>
        </Box>
         */
      }
      {props.children}
    </GoalsPage>
  );
}
