import { TopicModule } from 'app/topics/types';
import * as factory from 'components/topics/factory';
import Screening from './Screening';
import { EssentialsPage, GoalsPage, GoalsPageWithArticles, ShapingMyLifeStylePage } from './layouts';
import * as content from './content';
import goals from './goals';
import kpiPreQuestions, { KpiPreData } from './kpiPreQuestions';
import evaluationQuestions, { EvaluationData } from './evaluationQuestions';

const essentialsBuilder = factory.essentials(EssentialsPage);
const goalsBuilder = factory.goals(GoalsPage, goals);
const goalsBuilderWithArticles = factory.goals(GoalsPageWithArticles, goals);
const smlBuilder = factory.shapingMyLifestyle(ShapingMyLifeStylePage); // Fix #141

export default {
  content,
  goals,
  KpiPre: factory.createPreEnterDialog<KpiPreData>('Survey Questions', kpiPreQuestions),
  Evaluation: factory.createEvaluation<EvaluationData>('Program Evaluation', evaluationQuestions),
  Screening: essentialsBuilder.buildContent(Screening),
  Essentials: essentialsBuilder.buildArticles(content.essentials),
  Lifestyle: null,
  Goals: goalsBuilderWithArticles.buildGoals(),
  GoalSelect: goalsBuilderWithArticles.buildGoalSelect(),
  GoalSetting: goalsBuilder.buildGoalSetting(),
  ShapingMyLifestyle: smlBuilder.build(), // Fix #141
} as TopicModule;
