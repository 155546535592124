import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Typography, Breadcrumbs, Box } from '@material-ui/core';
import Link from 'components/Link';
import Alert from 'components/Alert';
import ActionStatusProgress from 'components/ActionStatusProgress';
import AdminPage from 'app/admin/AdminPage';
import { selectProfiles } from 'app/store';

import TopicStatesPanel from './TopicStatesPanel';
import { actions, getProfile } from '../profileSlice';
import UserSummary from './UserSummary';
import WeightSummary from './WeightSummary';

export type ProfileProps = {} & RouteComponentProps<{ uid: string }>;

export function UserProfile(props: ProfileProps) {
  const uid = props.match.params.uid;
  const { entities, status } = useSelector(selectProfiles);
  const dispatch = useDispatch();
  const profile = entities[uid];

  React.useEffect(() => {
    if (!profile) {
      dispatch(getProfile(uid));
    }
  }, [profile, uid, dispatch]);

  return (
    <AdminPage title="User profile">
      <Alert alertText={status?.error} onClose={() => dispatch(actions.idle())} variant="filled" severity="error" />

      <ActionStatusProgress status={status} />

      <Breadcrumbs>
        <Link to="/admin/profiles">Profiles</Link>
        {profile && <Typography color="textPrimary">{profile.name || profile.email}</Typography>}
      </Breadcrumbs>

      {profile && (
        <>
          <Box my={2}>
            <UserSummary profile={profile} />
          </Box>

          <Box my={2}>
            <WeightSummary profile={profile} />
          </Box>

          <Box my={2}>
            <TopicStatesPanel topicStates={profile.topicStates} />
          </Box>
        </>
      )}
    </AdminPage>
  );
}
