import React from 'react';
import ParallaxContext from './ParallaxContext';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: (props: { zIndex: number; height: number }) => ({
      position: 'absolute',
      width: '100%',
      zIndex: props.zIndex,
      height: props.height,
    }),
  }),
);

export type ParallaxLayer = {
  zIndex?: number;
  height?: number;
  offset?: number;
  speed?: number;
  sticky?: number;
  sync?: boolean;
  length?: number;
  render?: (range: number) => React.ReactNode;
  children?: React.ReactNode;
};

export default function ParallaxLayer(props: ParallaxLayer) {
  const { offset, speed, sticky, sync, zIndex, length } = props;
  const { bounds, scrollTop } = React.useContext(ParallaxContext);
  const height = bounds.height * length!;
  const revealed = scrollTop > height * offset!;
  const y =
    revealed && sync
      ? Math.min(scrollTop, (offset! + sticky!) * height)
      : height * (offset! + Math.floor(offset!) * speed!) - scrollTop * speed!;
  const classes = useStyles({ zIndex: zIndex!, height });

  return (
    <section
      className={classes.root}
      // style={{transform: `translate3d(0, ${y}px, 0)`}}
      style={{ top: y }}>
      {props.render ? props.render((scrollTop - height * offset!) / height) : props.children}
    </section>
  );
}

ParallaxLayer.defaultProps = {
  offset: 0,
  speed: 0,
  sticky: 0,
  sync: true,
  zIndex: 0,
  length: 1,
};
