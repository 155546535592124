import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function MaternityLeave() {
  return (
    <ArticleContent>
      <h4 id="key-points">Key points</h4>
      <ul>
        <li>Australian mothers and fathers have access to government-funded paid parental leave.</li>
        <li>
          The main carer of the baby is entitled to up to 18 weeks paid leave and up to 12 months of unpaid leave.
        </li>
        <li>
          During your pregnancy you can also apply for Special Maternity Leave if you have pregnancy related illness or
          a complication; this is in addition to your usual sick leave.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        It is your right to take leave when you are pregnant and after the birth of your child. You should not feel
        uncomfortable about doing so, and being organised and proactive is a good way to approach the situation.
      </p>
      <p>
        Parental leave entitlements include: - Maternity leave - Paternity and partner leave - Adoption leave - Special
        maternity leave - A safe job and no safe job leave - A right to return to old job.
      </p>
      <p>
        <strong>Government Funded Parental Leave (Maternity Leave)</strong>
      </p>
      <ul>
        <li>
          The government provides paid parental leave to eligible parents who are the main carer of child, for 18 weeks.
        </li>
        <li>You must not be working or taking paid leave to claim this payment.</li>
        <li>You must have worked 10 of the 13 months before the due date of your baby.</li>
        <li>You must have worked 330 hours, about 1 day a week, in that 10-month period.</li>
        <li>Parental Leave Pay is the equivalent to the National Minimum wage.</li>
        <li>To receive the total 18 weeks of pay you must claim within 34 weeks of your baby’s birth.</li>
        <li>
          The latest you can claim is 52 weeks after the birth of your baby, however you will not receive the full 18
          weeks you are entitled to.
        </li>
        <li>
          You must lodge your claim before you return to work which can be completed online. Doing so will take some
          time and your will need to provide supporting documents.
        </li>
      </ul>
      <p>
        <strong>Government Funded Paternity and Partner Leave</strong>
      </p>
      <ul>
        <li>The government provides paid dad and partner leave to eligible parents, for up to two weeks.</li>
        <li>
          This two-week period must be taken as unpaid leave from their place of employment.
          <br />
        </li>
        <li>Your partner must have worked 10 of the 13 months before the due date of your baby.</li>
        <li>Your partner must have worked 330 hours, about 1 day a week, in that 10-month period.</li>
        <li>Dad and Partner Pay is the equivalent to the National Minimum wage.</li>
        <li>The easiest way to claim is online - via a myGov account, or through a Centrelink online account.</li>
        <li>
          The start date of the leave can have a nominated start date up to 50 weeks after the birth of your child.
        </li>
      </ul>
      <p>
        <strong>Special Maternity Leave</strong>
      </p>
      <ul>
        <li>
          You are eligible for this leave if you have a pregnancy related illness or you experience a loss of pregnancy
          within 28 weeks of the expected due date.
        </li>
        <li>This leave is unpaid, and you must provide an expected period of time.</li>
        <li>You must give your employer notice as soon as possible (which may be after the leave has started).</li>
        <li>You may be required to provide a medical certificate to your employer.</li>
      </ul>
      <p>
        <strong>Requirements</strong>
      </p>
      <ul>
        <li>You must give 10 weeks notice before you start your parental leave.</li>
        <li>
          In the case that your child arrives early (before you have applied), you are still entitled to leave but must
          provide notice as soon as possible.
        </li>
        <li>You must specify an intended start and end date.</li>
        <li>
          At least four weeks prior to your intended start date you must: – Confirm your intended start and end dates,
          or – Advise of any changes to the intended start and end date (where possible).
        </li>
      </ul>
      <p>
        You may also be entitled to leave payments from your employer, this depends on what company and industry you
        work for. You can discuss this with your HR manager or team.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Consider the following things when applying for or maternity leave or to determine if you are eligible: - You
        must have been working continuously for at least 10 to 13 months prior to the expected birth of your child. -
        You must have worked at least 330 hours of paid work in this 10-month period (an average of about one day per
        week). - You must have an adjusted annual taxable income of $AUD 150,000 or less in the previous financial year.
        - You can submit the forms online. - Visit the Fair Work website or chat to your HR manager or team for more
        information.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about relevant leave visit:</p>
      <ul>
        <li>
          <a
            href="https://www.fairwork.gov.au/how-we-will-help/templates-and-guides/fact-sheets/minimum-workplace-entitlements/parental-leave-and-related-entitlements#notice-and-evidence-requirements"
            target="_blank"
            rel="noopener noreferrer">
            Parental Leave and Related Entitlements
          </a>
        </li>
        <li>
          <a
            href="https://www.humanservices.gov.au/individuals/services/centrelink/dad-and-partner-pay"
            target="_blank"
            rel="noopener noreferrer">
            Dad and Partner Pay
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
