import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function Diabetes() {
  return (
    <ArticleContent>
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>
          Gestational diabetes mellitus (GDM) is a common form of diabetes diagnosed during pregnancy, usually between
          24-28 weeks gestation
        </li>
        <li>
          Lifestyle management, including a healthy diet and physical activity is first line treatment. Sometimes
          medication may needed to control blood glucose levels.
        </li>
        <li>GDM typically resolves following pregnancy, however risk of diabetes later in life is increased</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Gestational diabetes (GDM) is a form of diabetes that occurs during pregnancy and affects about 15% of women.
        GDM is usually diagnosed between 24-28 weeks by an oral glucose tolerance test. This test is conducted by a
        pathology provider and involves drinking a glucose ‘load’ following an overnight fast to test the body’s
        response.
      </p>
      <p>
        GDM develops when blood glucose (sugar) levels cannot be controlled by the body, as they normally are. Insulin
        is a hormone that controls blood glucose levels by moving glucose from the bloodstream in the body’s cells.
        During pregnancy, the placenta provides nutrients to ensure growth and development of the baby. However hormones
        released by the placenta can also block the action of insulin, creating insulin resistance. Insulin resistance
        progressively rises over the course of pregnancy and in most cases, the body responds by producing more insulin.
        GDM develops when insulin production is inadequate, leading to high blood sugar levels. Increased blood sugar
        levels can cross the placenta and affect the baby’s growth, increasing the size of the baby.
      </p>
      <p>
        GDM management includes regularly monitoring blood glucose levels. GDM can be controlled through a healthy diet
        and increasing physical activity. If lifestyle change cannot control glucose levels, medication, including
        insulin may be used.
      </p>
      <p>Certain risk factors contribute to the development of GDM including:</p>
      <ul>
        <li>Age over 35 years</li>
        <li>Weight above the healthy range</li>
        <li>
          Higher risk ethnicity including Aboriginal and Torres Strait Islander, Polynesian, South-East Asian (Burmese,
          Thai and Vietnamese Filipino, Indonesian, Sudanese, Timorese, Balinese and Singaporean), Chinese Asian,
          Southern Asian (Anglo-Indian, Indian and Pakistani), African (Central and West African, Southern &amp; East
          African and Sub-Saharan African)
        </li>
        <li>Previous GDM</li>
        <li>A family history of Type 2 Diabetes</li>
        <li>Previous birth complications, including the delivery of a large baby; polycystic ovary syndrome</li>
      </ul>
      <p>
        After birth, GDM usually resolves. The baby is not born with GDM but may need extra monitoring following
        delivery. Regular monitoring is recommended with approximately 50% of women developing type 2 diabetes following
        GDM in pregnancy.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Talk to your doctor or obstetrician if you are concerned about your risk of developing GDM, especially if you
        have risk factors or a previous history of GDM.
      </p>
      <p>
        Also, monitor your weight gain in early pregnancy to ensure excessive weight gain does not occur. Maintain a
        healthy diet with regular physical activity.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about gestational diabetes please visit:</p>
      <ul>
        <li>
          <a href="https://www.diabetesaustralia.com.au/gestational-diabetes" target="_blank" rel="noopener noreferrer">
            Diabetes Australia
          </a>
        </li>
        <li>
          <a
            href="https://www.thewomens.org.au/health-information/pregnancy-and-birth/pregnancy-problems/pregnancy-problems-in-later-pregnancy/gestational-diabetes"
            target="_blank"
            rel="noopener noreferrer">
            Royal Women’s Hospital
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
