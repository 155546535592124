import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Users } from './users';
import { Registers } from './registers';
import { Profiles, UserProfile } from './profiles';

export default function Admin() {
  return (
    <Switch>
      <Route exact path="/admin/users" component={Users} />
      <Route exact path="/admin/registers" component={Registers} />
      <Route exact path="/admin/profiles" component={Profiles} />
      <Route exact path="/admin/profiles/:uid" component={UserProfile} />
      <Route render={() => <Redirect to="/admin/registers" />} />
    </Switch>
  );
}
