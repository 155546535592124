import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import Form from 'components/form';
import { notEmpty, passwordPolicy, mustBeEmail } from 'components/form/validators';
import Button from 'components/ActionStatusButton';

import AuthPage, { Card, CardActions } from './AuthPage';

import { forgotPasswordSubmit } from './authSlice';
import { useHistory } from 'react-router-dom';
import { selectAuth } from 'app/store';

type ForgotPasswordSubmitData = {
  email: string;
  code: string;
  newPassword: string;
};

const validators = {
  email: mustBeEmail(),
  code: notEmpty(),
  newPassword: passwordPolicy(),
};

const initialData = {
  email: '',
  code: '',
  newPassword: '',
};

export default function ForgotPasswordSubmit() {
  const dispatch = useDispatch();
  const { status } = useSelector(selectAuth);
  const history = useHistory();
  const handleSumbit = ({ email, code, newPassword }: ForgotPasswordSubmitData) => {
    dispatch(
      forgotPasswordSubmit(email, code, newPassword, () => {
        history.push('/auth/signin');
      }),
    );
  };

  return (
    <AuthPage>
      <Card title="Set New Password">
        <Form<ForgotPasswordSubmitData> validators={validators} data={initialData} onSubmit={handleSumbit}>
          {({ values, errors, updateField }) => (
            <>
              <TextField
                name="email"
                label="Email Address"
                margin="normal"
                variant="outlined"
                value={values.email}
                onChange={e => updateField('email', e.target.value)}
                error={Boolean(errors.email)}
                helperText={errors.email}
                fullWidth
              />
              <TextField
                name="code"
                label="Verification code "
                margin="normal"
                variant="outlined"
                value={values.code}
                onChange={e => updateField('code', e.target.value)}
                error={Boolean(errors.code)}
                helperText={errors.code}
                fullWidth
              />
              <TextField
                type="password"
                name="password"
                label="New Password"
                margin="normal"
                variant="outlined"
                value={values.newPassword}
                onChange={e => updateField('newPassword', e.target.value)}
                error={Boolean(errors.newPassword)}
                helperText={errors.newPassword}
                fullWidth
              />
              <CardActions>
                <Button status={status} type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </CardActions>
            </>
          )}
        </Form>
      </Card>
    </AuthPage>
  );
}
