import React from 'react';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import MuiToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

type ToggleGroupProps = {
  value: string | null;
  options: string[];
  onChange: (value: string) => void;
};

export default function ToggleGroup(props: ToggleGroupProps) {
  const { value, onChange, options } = props;
  return (
    <MuiToggleButtonGroup value={value} exclusive onChange={(e, value) => onChange(value)} size="small">
      {options.map(option => (
        <MuiToggleButton key={option} value={option} color="#CCD">
          {option}
        </MuiToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
}
