import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Link, TextField } from '@material-ui/core';
import Button from 'components/ActionStatusButton';
import Form from 'components/form';
import { mustBeEmail } from 'components/form/validators';
import AuthPage, { Card, CardActions } from './AuthPage';
import { forgotPassword } from './authSlice';
import { selectAuth } from 'app/store';

type FormData = {
  email: string;
};

const validators = {
  email: mustBeEmail(),
};

const initialData = {
  email: '',
};

export default function ForgotPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { status } = useSelector(selectAuth);

  const handleSubmit = (data: FormData) => {
    const { email } = data;
    dispatch(
      forgotPassword(email, () => {
        history.push('/auth/forgotPassword/submit');
      }),
    );
  };

  return (
    <AuthPage>
      <Card title="Reset password">
        <Form<FormData> validators={validators} data={initialData} onSubmit={handleSubmit}>
          {({ values, errors, updateField }) => (
            <>
              <TextField
                name="email"
                label="Email Address"
                margin="normal"
                variant="outlined"
                value={values.email}
                onChange={e => updateField('email', e.target.value)}
                error={Boolean(errors.email)}
                helperText={errors.email}
                fullWidth
              />
              <CardActions>
                <Button status={status} type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </CardActions>
              <Grid container>
                <Grid item xs>
                  <Link variant="body2" onClick={() => history.replace('/auth/Signin')}>
                    Already have an account? Please sign in
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </Form>
      </Card>
    </AuthPage>
  );
}
