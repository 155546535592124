import React from 'react';
import { useHistory } from 'react-router-dom';
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link';

export type LinkProps = MuiLinkProps & {
  to: string;
};

export default function Link(props: LinkProps) {
  const { to, ...linkProps } = props;
  const history = useHistory();
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    history.push(to);
  };
  return <MuiLink href={to} onClick={handleClick} {...linkProps} />;
}
