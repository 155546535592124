import React from 'react';
import { Typography /*,Box, Grid, CardHeader, Card, CardContent*/ } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import NavToolbar, { Nav } from 'components/nav/NavToolbar';
import { ScrollTriggerPage } from 'components/page';
//import ArticleCard from 'components/topics/Articles/ArticleCard';
//import { Content } from 'app/topics/types';
//import { shapingLife } from '../content';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      backgroundColor: 'transparent',
      color: '#FFF',
    },
    filler: {
      backgroundImage: 'url(images/topics/pregnancy/goals_banner.jpg)',
      backgroundSize: 'cover',
    },
    header: {
      padding: theme.spacing(2, 0),
      color: '#FFF',
    },
  }),
);

const navs: Nav[] = [
  {
    label: 'Essential Information',
    path: '/topics/pregnancy/essentials',
    exact: false,
  },
  // Fix #141
  {
    label: 'Shaping My Lifestyle',
    path: '/topics/pregnancy/shaping_my_lifestyle',
    exact: false,
  },
  {
    label: 'My Goals',
    path: '/topics/pregnancy/goals',
    exact: false,
  },
];

export function GoalsPage(props: React.HTMLProps<HTMLElement>) {
  const classes = useStyles();
  return (
    <ScrollTriggerPage
      title="Pregnancy Goals"
      extraToolbar={<NavToolbar navs={navs} />}
      classes={{ appBar: classes.appBar, fillerBg: classes.filler }}>
      <div className={classes.header}>
        <Typography variant="h4" color="inherit">
          My Goals
        </Typography>
      </div>
      {props.children}
    </ScrollTriggerPage>
  );
}

export function GoalsPageWithArticles(props: React.HTMLProps<HTMLElement>) {
  return (
    <GoalsPage>
      {
        /*
        // Fix # 141
        <Card>
          <CardHeader title="Shaping my Lifestyle" />
          <CardContent>
            <p>
            This information is relevant to you. It provides recommendations for physical activity and diet modifications to improve your health.</p>
            <p>Based on the highest quality research we recommend incorporating these into your lifestyle over time.</p>
          </CardContent>
        </Card>
        <Box my={2}>
          <Grid container spacing={2}>
            {shapingLife.map((article: Content) => (
              <Grid item key={article.id} xs={12} sm={4}>
                <ArticleCard article={article} to={`/topics/pregnancy/articles/${article.id}`} />
              </Grid>
            ))}
          </Grid>
        </Box>
        */
      }
      {props.children}
    </GoalsPage>
  );
}
