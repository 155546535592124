import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will cease alcohol, smoking and/or drugs use; and seek professional support if needed.';

export default function SmokingAlcoholDrugs() {
  return (
    <ArticleContent>
      <Quiz
        question="I only need to stop drinking alcohol once I fall pregnant."
        answer="False"
        feedback="This is wrong. It is ideal to stop drinking prior to pregnancy because you may not be aware you are pregnant for some time and because changing any established behaviour takes time."
      />

      <h4>Key points</h4>
      <ul>
        <li>Smoking, alcohol and drug taking by both parents-to-be effects fertility and the health of your baby</li>
        <li>Heavy drinking increases the time it takes to get pregnant and can affect a developing baby's health</li>
        <li>Heavy drinking can reduce men’s sex drive, affect the quality of sperm and cause impotence</li>
        <li>For women planning a pregnancy, not drinking alcohol is the safest option</li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        Drug taking (smoking, alcohol, illicit drugs) by both prospective parents directly impacts fertility and the
        development of the baby.
      </p>
      <p>
        Women and men should both seek support to quit smoking (Quit), and should reduce/abstain from alcohol and drug
        taking prior to pregnancy.
      </p>
      <p>
        There is no established ‘safe’ level of alcohol consumption for the developing fetus which is why no drinking is
        advised in the period before birth and during pregnancy. Reducing your alcohol intake is often a gradual process
        so give yourself time to do this before conceiving.
      </p>

      <h4>What can I do?</h4>
      <p>Make a plan to reduce and stop smoking/alcohol/drugs before getting pregnant.</p>

      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information about smoking, drugs and alcohol visit:</p>
      <ul>
        <li>
          <a href="https://www.quit.org.au/" target="_blank" rel="noopener noreferrer">
            QUIT
          </a>
        </li>
        <li>
          <a
            href="https://drinkwise.org.au/parents/how-alcohol-consumption-can-affect-your-baby"
            target="_blank"
            rel="noopener noreferrer">
            DrinkWise
          </a>
        </li>
        <li>
          <a href="https://www.nhmrc.gov.au/health-advice/alcohol" target="_blank" rel="noopener noreferrer">
            The National Health and Medical Research Council
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-medication-drugs-and-alcohol"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
