import React from 'react';
import { get, isEmpty, isArray } from 'lodash';
import { Box, Typography, Tabs, Tab, Paper } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';
import RecordVoiceOver from '@material-ui/icons/RecordVoiceOver';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import { SurveyQuestions } from 'components/surveyForm';
import { Step } from 'components/stepsForm';
import QuestionListPanel, { Section } from './QuestionListPanel';
import Essentials from './Essentials';
import Goals from './Goals';

import { TopicStates, ModuleId } from 'app/types';
import { steps as preconcetionScreeningSteps } from 'app/topics/preconception/Screening';
import { steps as pregnancyScreeningSteps } from 'app/topics/pregnancy/Screening';
import { steps as postpartumScreeningSteps } from 'app/topics/postpartum/Screening';
import preconceptionKpiPreSurvey from 'app/topics/preconception/kpiPreQuestions';
import pregnancyKpiPreSurvey from 'app/topics/pregnancy/kpiPreQuestions';
import postpartumKpiPreSurvey from 'app/topics/postpartum/kpiPreQuestions';

import preconceptionEvaluation from 'app/topics/preconception/evaluationQuestions';
import pregnancyEvaluation from 'app/topics/pregnancy/evaluationQuestions';
import postpartumEvaluation from 'app/topics/postpartum/evaluationQuestions';

import { questions as lifestyleQuestions } from 'components/topics/LifestyleQuestions';

const topicScreeningSteps: Record<ModuleId, Step[]> = {
  preconception: preconcetionScreeningSteps,
  pregnancy: pregnancyScreeningSteps,
  postpartum: postpartumScreeningSteps,
};

const kpiPreSurveyQuestions: Record<ModuleId, SurveyQuestions<any>> = {
  preconception: preconceptionKpiPreSurvey,
  pregnancy: pregnancyKpiPreSurvey,
  postpartum: postpartumKpiPreSurvey,
};

const evaluationQuestions: Record<ModuleId, SurveyQuestions<any>> = {
  preconception: preconceptionEvaluation,
  pregnancy: pregnancyEvaluation,
  postpartum: postpartumEvaluation,
};

const tabs: Array<{ label: string; moduleId: ModuleId }> = [
  {
    label: 'Preconceptions',
    moduleId: 'preconception',
  },
  {
    label: 'Pregnancy',
    moduleId: 'pregnancy',
  },
  {
    label: 'Post-pregnancy',
    moduleId: 'postpartum',
  },
];

export type TopicStatesPanelProps = {
  topicStates: TopicStates;
};

export default function TopicStatesPanel(props: TopicStatesPanelProps) {
  const { topicStates } = props;
  const [active, setActive] = React.useState<number>(0);
  const mod = tabs[active].moduleId;

  const topicState = React.useMemo(() => topicStates.items.find(topicState => topicState.mod === mod), [
    topicStates,
    mod,
  ]);

  const { screeningSections, kpiPreSections, evaluationSections, lifeStyleSections } = React.useMemo(() => {
    return {
      screeningSections: toScreeningSections(topicState?.screening, topicScreeningSteps[mod]),
      kpiPreSections: toQuestionSecitons(topicState?.kpiPre, kpiPreSurveyQuestions[mod]),
      evaluationSections: toQuestionSecitons(topicState?.evaluation, evaluationQuestions[mod]),
      lifeStyleSections: toLifestyleSections(topicState?.lifestyleData),
    };
  }, [topicState, mod]);

  return (
    <>
      <Paper>
        <Box p={2}>
          <Typography variant="h5">Topic States</Typography>
        </Box>
        <Box p={0}>
          <Tabs value={active} onChange={(e, index) => setActive(index)}>
            {tabs.map(item => (
              <Tab key={item.moduleId} id={item.moduleId} label={item.label} />
            ))}
          </Tabs>
        </Box>
      </Paper>

      {topicState && (
        <Box mt={2}>
          <Essentials mod={mod} topicState={topicState} />
          <Goals mod={mod} topicState={topicState} />
          <QuestionListPanel icon={<ListAltIcon />} primary="Screening" sections={screeningSections} />
          {mod !== 'postpartum' && (
            <QuestionListPanel icon={<DirectionsRun />} primary="Lifestyle" sections={lifeStyleSections} />
          )}
          <QuestionListPanel icon={<FlightTakeoff />} primary="Kpi Pre" sections={kpiPreSections} />
          {mod !== 'postpartum' && (
            <QuestionListPanel icon={<RecordVoiceOver />} primary="Evaluation" sections={evaluationSections} />
          )}
        </Box>
      )}
    </>
  );
}

const toScreeningSections = (screening: object | null | undefined, steps: Step[]): Section[] | undefined => {
  if (!isEmpty(screening)) {
    return steps.map<Section>(step => ({
      title: step.title,
      questions: step.fields.map(field => ({ label: field.desc, value: get(screening, field.name) })),
    }));
  }
};

const toQuestionSecitons = (
  questions: object | null | undefined,
  surveyQuestions: SurveyQuestions<unknown> | null,
): Section[] | undefined => {
  if (!isEmpty(questions) && surveyQuestions) {
    const data = questions || {};
    return surveyQuestions.groups.map<Section>(group => ({
      title: group.title,
      questions: group.questions.map(question => ({ label: question.label, value: data[question.id] })),
    }));
  }
};

const toLifestyleSections = (lifeStyleData: object | null | undefined): Section[] | undefined => {
  if (!isEmpty(lifeStyleData)) {
    return [
      {
        questions: lifestyleQuestions.map(question => {
          let value = get(lifeStyleData, question.name);
          if (isArray(value)) {
            value = value.join(' ');
          }
          return {
            label: question.title,
            value,
          };
        }),
      },
    ];
  }
};
