import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function PregnancyStages() {
  return (
    <ArticleContent>
      <Quiz
        question="Most babies cannot open their eyes until they are born."
        answer="False"
        feedback="This is not true. By the seventh month of pregnancy the baby can open and close their eyes."
      />
      <h4 id="key-points">Key points</h4>
      <ul>
        <li>
          Pregnancy takes place about two weeks after your last period when your egg is fertilised as it travels down
          the fallopian tube toward the uterus.
        </li>
        <li>
          In the first three months, you may experience a range of symptoms related to rising hormone levels such as:
          nausea, tiredness, tender breasts and a need to go to the bathroom often.
        </li>
        <li>
          Towards the end of your pregnancy, a “nesting instinct” may kick in as you and your partner prepare for your
          baby.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Understanding the changes within your body during pregnancy can assist you to understand your symptoms and how
        they relate to the development of your baby.
      </p>
      <h4 id="conception">Conception</h4>
      <p>
        Technically your doctor will count your pregnancy back to the first day of your last period. In fact, you are
        not pregnant until about two weeks later when your egg is fertilised as it travels down the fallopian tube
        toward the uterus.
      </p>
      <p>
        At fertilisation, the sex of your baby is determined, depending on whether your egg receives an X or Y
        chromosome from a sperm cell. If the egg receives an X chromosome, the baby will be a girl; a Y chromosome means
        the baby will be a boy.
      </p>
      <p>
        It takes about three to four days for the fertilised egg to move to the lining of the uterus, where it attaches
        to the uterine wall. Once the embryo is implanted, the cells start to grow, eventually becoming the fetus and
        the placenta, which is tissue that can transport oxygen, nutrients and hormones from your blood to the
        developing fetus throughout your pregnancy.
      </p>
      <h4 id="first-trimester-weeks-1-12-changes-in-your-body">First trimester (weeks 1-12) changes in your body</h4>
      <p>
        You may experience symptoms during your first trimester due to hormonal changes in pregnancy. In the early
        weeks, your pregnancy may not be showing much on the outside, but inside many changes are taking place.
      </p>
      <p>
        Once you are pregnant, the hormone human chorionic gonadotrophin (hCG) is present in your blood from the time
        conception. Levels of hCG can be detected in your urine about a week after your missed period, giving you’re a
        positive result on a home pregnancy test.
      </p>
      <p>
        Other hormonal changes can contribute to pregnancy symptoms: Rising levels of oestrogen and hCG may be
        responsible for the waves of nausea and vomiting known as morning sickness that you may experience during the
        first few months of pregnancy. Despite its name, morning sickness can occur any time of day.
      </p>
      <p>
        You may also feel more tired than usual during the first trimester, a symptom that’s linked with rising levels
        of the hormone progesterone, which increases sleepiness. Also, you may need to urinate more frequently as your
        uterus grows and presses on your bladder.
      </p>
      <p>
        Early in pregnancy, your breasts will feel more tender and swollen, a side effect of rising levels of pregnancy
        hormones. Your areolas, the skin around each nipple, will darken and enlarge.
      </p>
      <p>
        Your digestive system may slow down to increase the absorption of beneficial nutrients. But reduced mobility of
        the digestive system might also trigger such common complaints as heartburn, constipation, bloating and gas,
        according to the Office on Women’s Health (OWH).
      </p>
      <p>
        Many parts of the body will work harder during pregnancy, including your heart. Your heartbeat will increase to
        pump more blood to the uterus, to supply your baby.
      </p>
      <p>
        Besides the physical changes in your body, you may experience emotional highs and lows in the early months of
        your pregnancy and throughout it. These emotions may range from weepiness, mood swings and forgetfulness to
        fear, anxiety and excitement.
      </p>
      <h4 id="first-trimester-development-of-your-baby">First trimester development of your baby</h4>
      <p>
        A developing baby is called an embryo from the moment conception takes place until the eighth week of pregnancy.
      </p>
      <p>
        During the first month of pregnancy the heart and lungs begin to develop, and the arms, legs, brain, spinal cord
        and nerves begin to form.
      </p>
      <p>
        The embryo will be about the size of a pea around one month into pregnancy. Around the second month of
        pregnancy, the embryo has grown to the size of a kidney bean. In addition, the ankles, wrists, fingers and
        eyelids form, bones appear, and the genitals and inner ear begin to develop.
      </p>
      <p>After the eighth week of pregnancy and until birth occurs, a developing baby is called a fetus.</p>
      <p>By the end of the second month, eight to ten of the fetus’ main organs will have formed.</p>
      <p>
        During the third month of pregnancy, bones and muscles begin to grow, buds for future teeth appear, and fingers
        and toes grow. The intestines begin to form and the skin is almost transparent.
      </p>
      <h4 id="second-trimester-weeks-13-27-changes-in-your-body">
        Second trimester (weeks 13-27) changes in your body
      </h4>
      <p>
        By the second trimester, some of the unpleasant effects of early pregnancy may lessen or disappear as your body
        adjusts to its changing hormone levels. Sleeping may get easier and energy levels may increase.
      </p>
      <p>
        Nausea and vomiting usually get better but other symptoms may occur such as pelvic pressure where the pelvis
        feels heavy like something is weighing it down. A more visible baby bump appears as the uterus grows beyond your
        pelvis, and the skin on your expanding belly may itch as its stretches.
      </p>
      <p>
        Sometime between the 16th and 18th weeks of pregnancy, you may feel the first fluttering movements of your baby,
        known as quickening. If you have had a baby before, you are likely to feel this sooner because you have felt
        this sensation previously.
      </p>
      <h4 id="the-20th-week-typically-marks-the-halfway-point-of-a-womans-pregnancy.">
        <strong>The 20th week typically marks the halfway point of a woman’s pregnancy.</strong>
      </h4>
      <h4 id="second-trimester-development-of-your-baby">Second trimester development of your baby</h4>
      <p>In the second trimester, your baby is growing a lot and will be between 3 and 5 inches long.</p>
      <p>
        By the fourth month of pregnancy, eyebrows, eyelashes, fingernails and the neck all form, and the skin has a
        wrinkled appearance. In addition, during the fourth month the arms and legs can bend, the kidneys start working
        and can produce urine, and your baby can swallow and hear.
      </p>
      <p>
        In the fifth month of pregnancy, your baby is more active and you may be able to feel its movements. The baby
        also sleeps and wakes on regular cycles. A fine hair (called lanugo) and a waxy coating (called vernix) cover
        and protect the thin skin of your baby.
      </p>
      <p>
        By the sixth month of pregnancy, hair begins to grow, the eyes begin to open and the brain is rapidly
        developing. Although the lungs are completely formed, they do not yet function.
      </p>
      <h4 id="third-trimester-weeks-28-40-changes-in-your-body">Third trimester (weeks 28-40) changes in your body</h4>
      <p>
        During the third trimester, your enlarged uterus pushes against your diaphragm, a major muscle involved in
        breathing that may make you feel short of breath because the lungs have less room to expand. Your ankles, hands,
        feet and face may swell as fluids and your blood circulation slows.
      </p>
      <p>
        You will need to wee more frequently because more pressure will be placed on your bladder. Possibly, you may
        also have backaches and pain in the hips and pelvis, as these joints relax in preparation for delivery.
      </p>
      <p>
        Your face may develop dark patches of skin, and stretch marks may appear on your belly, thighs, breasts and
        backside and you also notice varicose veins on your legs.
      </p>
      <p>
        Your breasts may experience some leakage of colostrum, a yellow liquid, as they get ready for breastfeeding. The
        baby will drop lower in your abdomen.
      </p>
      <p>
        False labour, known as Braxton-Hicks contractions, may begin to occur as you get closer to your due date. A
        “nesting instinct” may kick in as you and your partner prepare for your baby.
      </p>
      <p>
        During the final weeks of pregnancy, it will become harder to find a comfortable sleeping position, so you may
        feel extremely tired.
      </p>
      <h4 id="third-trimester-development-of-your-baby">Third trimester development of your baby</h4>
      <p>
        By the seventh month of pregnancy, your baby kicks and stretches, and can even respond to light and sound, like
        music. Eyes can open and close.
      </p>
      <p>
        During the eighth month of pregnancy, your baby gains weight very quickly. Bones harden, but the skull remains
        soft and flexible to make delivery easier. Different regions of the brain are forming, and the fetus is able to
        hiccup.
      </p>
      <p>
        The ninth month is the home stretch of pregnancy, as your baby is getting ready for birth by turning into a
        head-down position in your pelvis. The lungs are now fully mature to prepare for functioning on their own and
        your baby continues to gain weight rapidly.
      </p>
      <h4 id="useful-resources">Useful resources</h4>
      <p>For more information about pregnancy stages please visit:</p>
      <ul>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-week-by-week"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
