import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography, Chip, Card, CardMedia, CardContent, CardActionArea, LinearProgress } from '@material-ui/core';

import { Content } from 'app/topics/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    media: {
      color: '#FFF',
      height: 200,
      padding: theme.spacing(2),
    },

    content: {
      flexGrow: 1,
    },

    heading: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },

    Health: {
      color: '#000000',
      backgroundColor: '#AEBC95', // me
    },

    Lifestyle: {
      color: '#FFF',
      backgroundColor: '#5B5F53', //optimal2
    },

    Wellbeing: {
      color: '#FFF',
      backgroundColor: '#31371E', // optimal3
    },

    Diet: {
      color: '#FFF',
      backgroundColor: '#00505E', // bluegreen
    },
  }),
);

export type ArticleCardProps = React.HTMLProps<HTMLElement> & {
  article: Content;
  progress?: number;
  to: string;
  extraActions?: React.ReactNode;
};

export default function ArticleCard(props: ArticleCardProps) {
  const { to, progress, article, extraActions } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    history.push(to);
  };

  return (
    <Card className={classes.card}>
      <CardActionArea href={to} onClick={handleClick}>
        <CardMedia className={classes.media} image={article.cover} title={article.title}>
          <Chip className={classes[article.tag]} size="small" label={article.tag} />
        </CardMedia>
      </CardActionArea>
      {progress !== undefined && <LinearProgress variant="determinate" value={progress * 100} />}
      <CardContent className={classes.content}>
        <div className={classes.heading}>
          <Typography variant="body1" style={{ flex: 1 }}>
            {article.title}
          </Typography>
          {extraActions}
        </div>
        <Typography variant="body2" color="textSecondary">
          {article.desc}
        </Typography>
      </CardContent>
    </Card>
  );
}
