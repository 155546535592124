import React from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';
import { Toolbar, IconButton, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { NavPageContnext } from './NavPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: (props: { drawerWidth: number }) => ({
      transition: 'all 0.5s',
      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${props.drawerWidth}px)`,
        marginLeft: props.drawerWidth,
      },
    }),
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export type AppBarProps = {
  title: string;
  extraToolbar?: React.ReactNode;
} & MuiAppBarProps;

export default function AppBar(props: AppBarProps) {
  const { title, extraToolbar, ...other } = props;
  const { setDrawerOpen, drawerWidth } = React.useContext(NavPageContnext);
  const classes = useStyles({ drawerWidth });
  return (
    <MuiAppBar className={classes.appBar} {...other}>
      <Toolbar>
        <IconButton color="inherit" edge="start" onClick={() => setDrawerOpen(true)} className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title} noWrap>
          {title}
        </Typography>
      </Toolbar>
      {extraToolbar}
    </MuiAppBar>
  );
}
