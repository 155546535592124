import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ScrollTriggerPage } from 'components/page';
import { NavToolbar } from 'components/nav';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      backgroundColor: 'transparent',
      color: '#333',
    },
    filler: {
      backgroundImage: 'url(images/topics/postpartum/essentials_banner.jpg)',
      backgroundSize: 'cover',
    },
  }),
);

const navs = [
  {
    label: 'Essential Information',
    path: '/topics/postpartum/essentials',
    exact: false,
  },
  // Fix # 141
  {
    label: 'Shaping My Lifestyle',
    path: '/topics/postpartum/shaping_my_lifestyle',
    exact: false,
  },
  {
    label: 'My Goals',
    path: '/topics/postpartum/goals',
    exact: false,
  },
];

export function EssentialsPage(props: React.HTMLProps<HTMLElement>) {
  const classes = useStyles();

  return (
    <ScrollTriggerPage
      title="Post-Birth Essentials"
      extraToolbar={<NavToolbar navs={navs} />}
      classes={{ appBar: classes.appBar, fillerBg: classes.filler }}>
      {props.children}
    </ScrollTriggerPage>
  );
}
