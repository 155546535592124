import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';
import { Card, CardContent, Grid } from '@material-ui/core';

export default function Eating() {
  return (
    <ArticleContent>
      <Quiz
        question="Skipping meals will help you lose weight you gained during pregnancy."
        answer="False"
        feedback="This is false. You need to eat a healthy well-balanced diet to give you energy to look after yourself and your baby."
      />
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>
          If you are breastfeeding, it is important to give your body sufficient amounts of healthy food to keep your
          breastmilk flowing
        </li>
        <li>
          As you are likely to be home quite a lot it is best not to have junk food in the house if you are easily
          tempted by these foods.
        </li>
        <li>
          You should eat a well-balanced healthy diet high in vegetables, fruit, wholegrains and protein, with
          sufficient amounts of calcium.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        It is important to take care of yourself after you give birth and eating a nutritious diet will help you feel
        good and may assist in losing extra weight you gained throughout your pregnancy. While some extra healthy foods
        may be required for women who are breastfeeding, after you give birth the Australian Dietary Guidelines are the
        same for you as they are for all adult females.
      </p>
      <p>
        <strong>Diet and Breastfeeding</strong>
      </p>
      <p>
        For women who breastfeed, eating the right amount of healthy foods is very important as well as drinking plenty
        of water regularly. Breastfeeding uses extra energy and requires more nutrients (see recommendations below). It
        is important that your diet supplies enough nutrients to help you breastfeed and maintain your energy and
        health, this includes protein, calcium, iron and some vitamins. Try to eat regularly and include a wide variety
        of healthy foods. Snacking between meals or eating numerous, small meals can assist you in reaching your energy
        needs. If you are keen to lose some of the weight you gained during your pregnancy this should be done slowly,
        rather than rapid weight loss which is the results of severe calorie restriction.
      </p>
      <h4 id="what-are-the-recommendations">What are the recommendations?</h4>
      <p>
        Having a well-balanced diet with a wide variety of vegetables, fruit, wholegrains, protein and dairy foods is
        recommended to achieve adequate levels of nutrients for optimal health.
      </p>
      <p>
        <strong>The Australian Dietary Guidelines</strong>
      </p>
      <p>
        The Australian Dietary Guidelines provide recommendations for Australian adults that are a great tool to help
        guide your food intake. For women aged 18-64 that are not pregnant or breastfeeding, the current recommendations
        for the 6 main foods groups are provided below:
      </p>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/vegetables.png" alt="Vegetables" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/fruit.png" alt="Fruit" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/grain.png" alt="Grain" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/dairy.png" alt="Dairy" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/protein.png" alt="Protein" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/limit.png" alt="Limit" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Talk to your partner about adopting or maintaining a healthy diet together. In the first weeks after giving
        birth it may be helpful to ask some friends or family for help with preparing and cooking food.
      </p>
      <p>
        Preparing for the first few days or weeks early will be very helpful. You can freeze meals in the lead up to
        giving birth so these are available for you when you get home.
      </p>
      <p>
        Planning what meals you are going to eat throughout the week is a great way to improve control within your diet
        and can help you feel in control when you are feeling tired. Being well prepared and having food available also
        means you’re less likely to get hungry and opt for fast-food or convenient, unhealthy options.
      </p>
      <p>
        If you think you need a little help in this area you can work with a healthcare provider such as a dietitian or
        nutritionist. They can construct a plan that is suited to your needs and considers your lifestyle and cooking
        abilities.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about healthy eating after birth visit:</p>
      <ul>
        <li>
          <a
            href="https://www.eatforhealth.gov.au/sites/default/files/content/The%20Guidelines/n55h_healthy_eating_during_pregnancy.pdf"
            target="_blank"
            rel="noopener noreferrer">
            Australian Dietary Guidelines
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/breastfeeding-and-your-diet"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Victoria – Breastfeeding
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
