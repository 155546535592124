import React from 'react';
import { Grid } from '@material-ui/core';
import ProgressChart from './ProgressChart';
import { ModuleId } from 'app/types';
export { default as Ring } from './Ring';

export default function MyProgress() {
  return (
    <Grid container spacing={2}>
      {[
        {
          modId: 'preconception',
          title: 'PRE-PREGNANCY',
        },
        {
          modId: 'pregnancy',
          title: 'PREGNANCY',
        },
        {
          modId: 'postpartum',
          title: 'POST-BIRTH',
        },
      ].map(chart => {
        return (
          <Grid item key={chart.modId} xs={12} md={4}>
            <ProgressChart moduleId={chart.modId as ModuleId} title={chart.title} />
          </Grid>
        );
      })}
    </Grid>
  );
}
