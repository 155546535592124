import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function Recipes() {
  return (
    <ArticleContent>
      <h4>Baked apple chips</h4>
      <p>
        <strong>Ingredients:</strong>
      </p>
      <p>3 large apples</p>
      <p>3/4 teaspoon ground cinnamon</p>
      <p>
        <strong>Instructions:</strong>
      </p>
      <ol>
        <li>Preheat oven to 120 degrees C. Line two baking sheets with baking paper.</li>
        <li>Wash the apples. Remove apple cores and thinly slice.</li>
        <li>Arrange the apples in a single layer on the prepared baking sheets. Sprinkle with cinnamon.</li>
        <li>
          Bake for 1 hour in the upper and lower thirds of the oven. Check after 1 hour and possible move bottom trays
          to top of oven if they are cooking at different rates. Continue baking for approximately 1 more hour. Remove
          one chip and set aside to cool for 2 minutes. If this apple chip is crisp, remove the rest from the oven, or
          continue cooking till they become crisp (the total time will vary based on the thickness of your slice, the
          type of apple and your oven).
        </li>
      </ol>

      <h4>Banana ice-cream</h4>
      <p>
        <strong>Ingredients:</strong>
      </p>
      <p>2 medium ripe bananas (peeled)</p>
      <p>1 tablespoon unsweetened cocoa powder (optional)</p>
      <p>
        <strong>Instructions:</strong>
      </p>
      <ol>
        <li>Peel and slice bananas, and place them in the freezer for at least 3 hours.</li>
        <li>
          Transfer frozen banana to your food processor. Process until smooth, stopping as needed to scrape the slides
          of the bowl. They may go grainy at first, keep processing until they become smooth.
        </li>
        <li>Add cocoa if desired and process to combine.</li>
        <li>Serve immediately as a soft serve ice-cream or freeze for 1-2 hours for firmer ice-cream.</li>
      </ol>
      <h4>Banana protein and almond milk smoothie</h4>
      <p>
        <strong>Ingredients:</strong>
      </p>
      <p>1-2 bananas, sliced and frozen</p>
      <p>1 cup unsweetened almond or soy milk</p>
      <p>1 tablespoon flax seeds</p>
      <p>1 teaspoon vanilla extract</p>
      <p>1/2 teaspoon cinnamon</p>
      <p>
        <strong>Instructions:</strong>
      </p>
      <ol>
        <li>
          Combine all ingredients in blender and pulse on high for 30 seconds to 1 minute, until mixture is completely
          smooth and thick. Serve immediately.
        </li>
      </ol>
      <h4>Cottage Cheese topped with Cucumber, Tomato, Avocado Salad</h4>
      <p>
        <strong>Ingredients:</strong>
      </p>
      <p>1/2 cup (not packed) cottage cheese - low-fat 2%</p>
      <p>1 tablespoon lemon juice</p>
      <p>1 dash salt</p>
      <p>1/4 teaspoon pepper</p>
      <p>1/2 teaspoon garlic powder</p>
      <p>1/2 cup grape tomatoes (halved)</p>
      <p>1/2 cup English seedless cucumber (chopped)</p>
      <p>1/2 small avocado (cubed)</p>
      <p>2 tablespoons cilantro (chopped)</p>
      <p>
        <strong>Instructions:</strong>
      </p>
      <ol>
        <li>
          Mix together chopped cucumber, halved tomatoes, avocado, lemon juice, cilantro, garlic and salt and pepper and
          use to top cottage cheese.
        </li>
      </ol>
      <h4>Cucumber hummus boats</h4>
      <p>
        <strong>Ingredients:</strong>
      </p>
        <p>1 whole cucumber</p>
        <p>4 baby plum tomatoes</p>
        <p>3 tablespoons hummus</p>
      
      <p>
        <strong>Instructions:</strong>
      </p>

      <ol>
        <li>
          Slice off the ends of cucumber. With a spoon, scoop out the seeds and discard in compost or set aside for a
          salad or other meal. Spread a bit of hummus inside, add your tomatoes. Sprinkle with pepper and some herbs.
        </li>
      </ol>

      <h4>Oven Baked Zucchini Chips</h4>
      <p>
        <strong>Ingredients:</strong>
      </p>
      <p>1 large zucchini</p>
      <p>2 table spoons of extra virgin olive oil</p>
      <p>A pinch of kosher salt and some dried herbs</p>
      <p>
        <strong>Instructions:</strong>
      </p>
      <ol>
        <li>Preheat oven to 100 degrees C. Line two large baking trays with silicon baking mats or parchment paper.</li>
        <li>Slice your zucchini on a mandolin, or slice thinly with a knife.</li>
        <li>Place the slices on a between two/four sheets of paper towel.</li>
        <li>Press down with a borad or your hand to draw out liquid, which will help them cook faster.</li>
        <li>
          Line up the zucchini slices on the prepared baking sheet tightly next to each other in a straight line, making
          sure not to overlap them.
        </li>
        <li>
          In a small bowl, pour your extra virgin olive oil in and take a pastry brush to brush the olive oil on each
          zucchini slice.
        </li>
        <li>
          Sprinkle salt throughout the baking sheet. Be sure not to over season as the chips will shrink and may become
          very salty.
        </li>
        <li>Bake for 2+ hours until they start to brown and are not soggy and are crisp.</li>
        <li>Let cool before removing and serving.</li>
        <li>Keep in an airtight container for no more than 3 days.</li>
      </ol>
      <h4>White bean salad</h4>
      <p>
        <strong>Ingredients:</strong>
      </p>
      <p>1 cup white beans (canned cannellini beans)</p>
      <p>1/2 cucumber (diced)</p>
      <p>1 small tomato (diced)</p>
      <p>Pinch of dill (fresh or dried)</p>
      <p>Juice of 1/2 lemon</p>
      <p>1/4 cup vinegar</p>
      <p>Salt to taste</p>
      <p>
        <strong>Instructions:</strong>
      </p>
      <ol>
        <li>Toss everything together and serve. You can also refrigerate the salad to let the flavours marinate.</li>
      </ol>
      <h4>Frozen yoghurt fruit bark</h4>
      <p>
        <strong>Ingredients:</strong>
      </p>
      <p>1 large container of plain unsweetened yogurt</p>
      <p>1 tablespoon vanilla</p>
      <p>2 cups chopped fruit</p>
      <p>
        <strong>Instructions:</strong>
      </p>
      <ol>
        <li>Line a large baking sheet with parchment paper.</li>
        <li>Mix the plain yogurt with vanilla.</li>
        <li>Pour the yogurt onto the baking sheet and spread a little to even.</li>
        <li>Sprinkle on the chopped fruit and freeze for 3-4 hours until frozen solid.</li>
        <li>Slice up and enjoy!</li>
      </ol>
      <p>This amount makes 10 servings (approx. 65 calories per serve) so remember to snack on this in moderation.</p>
      <h4>Avocado toast in a bowl</h4>
      <p>
        <strong>Ingredients:</strong>
      </p>
        <p>1/2 medium avocado, mashed</p>
        <p>Lemon juice</p>
        <p>1 tablespoon sesame seeds</p>
        <p>1/2 teaspoon coarse sea salt</p>
        <p>1/4 toasted quinoa (cook quinoa as per normal and then toast in the oven – great for storing in an airtight
        container in the fridge to include in salads or to use instead of breadcrumbs)</p>
        <p>Optional: Herbs of your choice and booster veg (grated zucchini, carrot or green peas are a good option) </p>
        <p>
        <strong>Instructions:</strong>
        </p>
        <ol>
        <li>Mix all ingredients.</li>
        </ol>
      <h4>Avocado dip</h4>
      <p>
        <strong>Ingredients:</strong>
      </p>
      <p>2 small ripe avocados</p>
      <p>2 scallions</p>
      <p>1/4 cup tightly packed parsley leaves</p>
      <p>1/4 cup tightly packed basil leaves</p>
      <p>3 tablespoons lemon juice</p>
      <p>2 tablespoons mayonnaise or non-fat Greek yogurt</p>
      <p>1/2 teaspoon sea salt</p>
      <p>
        <strong>Instructions:</strong>
      </p>
      <ol>
        <li>Combine all ingredients in a food processor and puree until smooth.</li>
        <li>Serve with carrot, zucchini or celery sticks.</li>
      </ol>
    </ArticleContent>
  );
}
