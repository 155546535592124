import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaletteProps } from '@material-ui/system';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: HeadingProps) => ({
      backgroundImage: props.backgroundImage,
      backgroundPosition: props.backgroundPosition,
      backgroundSize: 'cover',
      marginBottom: '1.5em',
      minHeight: 300,
    }),

    text: {
      height: '100%',
      paddingBottom: theme.spacing(2),
      margin: '0 auto',
      display: 'flex',
      alignItems: 'flex-end',
    },
  }),
);

export type HeadingProps = PaletteProps & {
  backgroundImage?: string;
  backgroundPosition?: string;
  text?: string;
  children?: React.ReactNode;
};

export default function Heading(props: HeadingProps) {
  const { text, color, bgcolor } = props;
  const classes = useStyles(props);
  return (
    <Box className={classes.root} color={color} bgcolor={bgcolor}>
      {text ? (
        <Container className={classes.text} fixed>
          <Typography variant="h3">{text}</Typography>
        </Container>
      ) : (
        props.children
      )}
    </Box>
  );
}

Heading.defaultProps = {
  backgroundPosition: 'center',
};
