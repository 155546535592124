import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import InfoList from 'components/InfoList';
import GradiantBMI from 'components/GradiantBMI';
import { Card, CardHeader, CardContent, Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { Profile } from 'app/types';
import { calcBmi, bmiLegends } from 'app/helper';

export default function WeightBmi(props: { profile: Profile }) {
  const { profile } = props;
  const history = useHistory();
  const { weight, height, dob } = profile;
  const [bmi, category] = calcBmi(weight, height);

  return (
    <Card>
      <CardHeader
        title="My BMI"
        subheader="Based on the information you have provided your BMI is displayed below. You can edit this information as your weight changes, by clicking the icon on the far right."
        action={
          <IconButton onClick={() => history.push('/profile/edit')}>
            <EditIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GradiantBMI weight={weight} height={height} />
          </Grid>
        </Grid>

        <InfoList
          items={[
            {
              label: 'Age',
              value: moment().diff(moment(dob, 'DD/MM/YYYY'), 'years'),
            },
            {
              label: 'Weight',
              value: `${weight} kg`,
            },
            {
              label: 'Height',
              value: `${height} cm`,
            },
            {
              label: `BMI (${bmiLegends[category].label})`,
              value: bmi.toFixed(1),
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
