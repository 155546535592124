import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemIcon,
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLessIcon from '@material-ui/icons/ArrowDropDown';
import LinkItem from './LinkItem';

import { selectAuth } from 'app/store';
import { getUserInfo } from 'app/helper';
import { signout } from 'app/auth/authSlice';

type ModuleNav = {
  title: string;
  entry: string;
};

type MenuLink = {
  icon?: React.ReactElement;
  label: string;
  to: string;
  exact?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(4, 2),
      // marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
      minHeight: 200,
    },

    list: {
      padding: theme.spacing(0, 2),
    },

    userInfo: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
    },

    item: {
      margin: theme.spacing(1, 0),
    },

    depth1: {
      margin: theme.spacing(1, 0),
      paddingLeft: theme.spacing(6),
      fontWeight: 400,
      borderRadius: 6,
      overflow: 'hidden',
    },
  }),
);

const unauthedMenuLinks: MenuLink[] = [
  {
    label: 'Home',
    to: '/',
    exact: true,
  },
  {
    label: 'Program',
    to: '/program',
    exact: true,
  },
  {
    label: 'About',
    to: '/about',
    exact: true,
  },
  {
    label: 'Contact us',
    to: '/contact',
    exact: true,
  },
  {
    label: 'Terms and Conditions',
    to: '/terms',
    exact: true,
  },
];

const authedMenuLinks: MenuLink[] = [
  {
    icon: <HomeIcon />,
    label: 'HOME',
    to: '/',
    exact: true,
  },
  {
    icon: <DashboardIcon />,
    label: 'MY DASHBOARD',
    to: '/dashboard',
    exact: false,
  },
];

const moduleNavs: ModuleNav[] = [
  {
    title: 'PRE-PREGNANCY',
    entry: '/topics/preconception',
  },
  {
    title: 'PREGNANCY',
    entry: '/topics/pregnancy',
  },
  {
    title: 'POST-BIRTH',
    entry: '/topics/postpartum',
  },
];

export default function AppDrawer() {
  const classes = useStyles();
  const { authenticated, userInfo } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const history = useHistory();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuAction = (action: Function) => () => {
    action();
    handleMenuClose();
  };

  const handleSignout = () => {
    dispatch(signout(() => history.push('/')));
  };

  if (!authenticated) {
    return (
      <React.Fragment>
        <section className={classes.heading}>
          <img src="images/logo_li@2x.png" width={200} alt="logo_l" />
          {/**
           *  Fix1: Registering new users has been temporary blocked due to close program
           */}
          {/* <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push({ pathname: '/auth/signin', state: { continuePath: '/dashboard' } })}>
              Sign In
            </Button>
          </Box> */}
        </section>

        <List className={classes.list}>
          {unauthedMenuLinks.map(({ icon, label, to, exact }) => (
            <PimaryLinkItem key={to} to={to} exact={exact}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={label} />
            </PimaryLinkItem>
          ))}
        </List>
      </React.Fragment>
    );
  }

  if (!userInfo) {
    throw new Error('Must have userinfo when authenticated');
  }

  const { initial, name, email, groups } = getUserInfo(userInfo);
  const isAdmin = groups && groups.indexOf('Admin') >= 0;

  return (
    <React.Fragment>
      <section className={classes.heading}>
        <img src="images/logo_li@2x.png" width={200} alt="logo_l" />
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={e => setAnchorEl(e.currentTarget)}>
            {name} <ExpandLessIcon />
          </Button>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            keepMounted>
            <div className={classes.userInfo}>
              <Avatar>{initial}</Avatar>
              <Box marginLeft={1}>
                <Typography variant="subtitle1">{name}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {email}
                </Typography>
              </Box>
            </div>
            <Divider />
            {isAdmin && <MenuItem onClick={handleMenuAction(() => history.push('/admin'))}>Admin Console</MenuItem>}
            <MenuItem onClick={handleMenuAction(() => history.push('/profile/edit'))}>Edit Profile</MenuItem>
            <MenuItem onClick={handleMenuAction(handleSignout)}>Sign Out</MenuItem>
          </Menu>
        </Box>
      </section>

      <List className={classes.list}>
        {authedMenuLinks.map(({ icon, label, to, exact }) => (
          <PimaryLinkItem key={label} to={to} exact={exact}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={label} />
          </PimaryLinkItem>
        ))}

        <Divider />

        {moduleNavs.map(({ title, entry }) => (
          <React.Fragment key={title}>
            <PrimaryListItem button>
              <ListItemText primary={title} />
            </PrimaryListItem>
            <SecondaryLinkItem to={`${entry}/essentials`}>
              <Typography variant="body2">Essential Information</Typography>
            </SecondaryLinkItem>
            <SecondaryLinkItem to={`${entry}/shaping_my_lifestyle`}>
              <Typography variant="body2">Shaping My Lifestyle</Typography>
            </SecondaryLinkItem>
            <SecondaryLinkItem to={`${entry}/goals`}>
              <Typography variant="body2">My Goals</Typography>
            </SecondaryLinkItem>
          </React.Fragment>
        ))}
      </List>
    </React.Fragment>
  );
}

const PrimaryListItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 0),
    },
  }),
)(ListItem);

const PimaryLinkItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 0),
    },
    selected: {
      backgroundColor: 'rgba(174, 188, 149, .3) !important',
    },
  }),
)(LinkItem);

const SecondaryLinkItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 0),
      paddingLeft: theme.spacing(6),
      fontWeight: 400,
      borderRadius: 6,
      overflow: 'hidden',
    },
    selected: {
      backgroundColor: 'rgba(174, 188, 149, .3) !important',
    },
  }),
)(LinkItem);
