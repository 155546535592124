import React from 'react';
import { Box, Typography, Button, LinearProgress } from '@material-ui/core';
import Form, { FormData } from 'components/form';
import SurveyQuestion from './SurveyQuestion';
import { SurveyQuestions } from './types';

export type SurveyFormProps<T> = {
  namePrefix?: string;
  onSubmit: (data: Partial<T>) => void;
  questions: SurveyQuestions<T>;
  data: Partial<T>;
};

export default function SurveyFrom<T extends FormData>(props: SurveyFormProps<T>) {
  const { namePrefix, onSubmit, questions, data } = props;
  const { groups, pre, post } = questions;
  const total = React.useMemo(() => groups.map(item => item.questions).flat().length, [groups]);

  const progress = (values: Partial<T>) => {
    const completed = Object.entries(values).reduce((count, [key, value]) => count + (value ? 1 : 0), 0);
    console.log(completed);
    return (100 * completed) / total;
  };

  const canSubmit = (values: Partial<T>) => {
    const incomplete = groups
      .map(item => item.questions)
      .flat()
      .find(question => {
        const required = !!question.required;
        return required && !values[question.id];
      });
    return !incomplete;
  };

  return (
    <Form<Partial<T>> data={data} onSubmit={onSubmit}>
      {({ values, updateField }) => {
        return (
          <>
            <LinearProgress variant="determinate" value={progress(values)} />
            {pre}
            {groups.map((group, index) => (
              <Box key={index} my={2}>
                {group.title && (
                  <Typography variant="h6" gutterBottom>
                    {group.title}
                  </Typography>
                )}
                {group.questions.map(question => (
                  <Box key={question.id as string} my={2}>
                    <Typography color="textSecondary" gutterBottom>
                      {question.label}
                    </Typography>
                    <SurveyQuestion
                      name={[namePrefix, question.id].join('-')}
                      variant={question.variant || group.variant}
                      options={question.options || group.options}
                      value={values[question.id]}
                      onChange={value => {
                        updateField(question.id, value);
                      }}
                    />
                  </Box>
                ))}
              </Box>
            ))}
            {post}
            <Box my={2}>
              <Button color="primary" variant="contained" type="submit" disabled={!canSubmit(values)} fullWidth>
                Submit
              </Button>
            </Box>
          </>
        );
      }}
    </Form>
  );
}

SurveyFrom.defaultProps = {
  namePrefix: 'q',
};
