import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function PreEclampsia() {
  return (
    <ArticleContent>
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>There are different kinds of high blood pressure, or hypertension, in pregnancy</li>
        <li>Pre-eclampsia is a severe form of hypertension that affects 10% of women during pregnancy</li>
        <li>Symptoms of high blood pressure are not always obvious</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Hypertension— can be present before or as a result of pregnancy. If hypertension develops as a result of
        pregnancy (pregnancy induced hypertension), it typically occurs in the second half of pregnancy after 20 weeks.
        It may not produce any symptoms and may be a once off event or may require frequent monitoring. Treatment may
        include bed rest, relaxation or medication.
      </p>
      <p>
        Pre-eclampsia—is a serious medical condition that usually develops in the later stages of pregnancy or shortly
        after birth. Pre-eclampsia involves high blood pressure, protein in the urine and also swelling in the face,
        feet and/or hands. Some women may experience blurred vision.
      </p>
      <p>
        Pre-eclampsia can develop rapidly with little symptoms. Pre-eclampsia can interrupt your circulation, which can
        reduce the supply of blood to the placenta, which limits the delivery of oxygen and nutrients to the baby.
        Pre-eclampsia can be managed medically, but may in some cases can require close monitoring in hospital and an
        early delivery.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        You blood pressure is monitored frequently throughout pregnancy. Talk to your doctor or obstetrician if you have
        a history of high blood pressure and/or are concerned about your risk of developing high blood pressure or
        pre-eclampsia.
      </p>
      <h4 id="useful-links">Useful links</h4>
      <p>For more information about pre-eclampsia please visit:</p>
      <ul>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-pre-eclampsia"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel
          </a>
        </li>
        <li>
          <a
            href="https://www.thewomens.org.au/health-information/pregnancy-and-birth/pregnancy-problems/pregnancy-problems-in-later-pregnancy/pre-eclampsia"
            target="_blank"
            rel="noopener noreferrer">
            The Royal Women’s Hospital
          </a>
        </li>
        <li>
          <a
            href="https://www.healthdirect.gov.au/pre-eclampsia-pregnancy-induced-hypertension"
            target="_blank"
            rel="noopener noreferrer">
            Health Direct
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
