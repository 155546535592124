import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  ListSubheader,
  Chip,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Launch';
import TopicMenu from './TopicMenu';
import { ModuleId, CheckListItem, Profile } from 'app/types';
import { updateTopicState } from 'app/profile/profileSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginRight: theme.spacing(1),
      padding: 9,
    },
  }),
);

export type TodoId = [ModuleId, number];

export type TodoItem = CheckListItem & {
  id: TodoId;
};

export type MyActionsProps = {
  profile: Profile;
};

export default function MyActions(props: MyActionsProps) {
  const { profile } = props;
  const classes = useStyles();
  const [filter, setFilter] = useState<ModuleId | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const { topicStates } = profile;
  const { todo, completed } = topicStates.items
    .filter(topicState => Boolean(!filter || filter === topicState.mod))
    .reduce<TodoItem[]>((todoItems, topicState) => {
      const items = (topicState.checklist || []).map<TodoItem>((item, index) => ({
        id: [topicState.mod, index],
        ...item,
      }));
      return [...todoItems, ...items];
    }, [])
    .reduce<{ todo: TodoItem[]; completed: TodoItem[] }>(
      ({ completed, todo }, todoItem) => {
        return todoItem.completed
          ? { todo, completed: [...completed, todoItem] }
          : { completed, todo: [...todo, todoItem] };
      },
      { todo: [], completed: [] },
    );

  const toggle = (item: TodoItem) => {
    const [mod, index] = item.id;
    const topicState = topicStates.items.find(topicState => topicState.mod === mod);
    const checklist = topicState?.checklist?.map((item, i) =>
      i === index ? { ...item, completed: !item.completed } : item,
    );
    const topicId = topicState?.id!;
    dispatch(updateTopicState(topicId, { checklist }));
  };

  const handleLink = (item: TodoItem) => {
    const [mod] = item.id;
    const link = `/topics/${mod}/articles/${item.articleId}`;
    history.push(link);
  };

  const buildItem = (item: TodoItem) => {
    return (
      <ListItem key={item.id.join('_')} onClick={() => toggle(item)} dense button>
        <ListItemIcon>
          <React.Fragment>
            <Checkbox checked={item.completed} />
            <div className={classes.chip}>
              <Chip label={item.id[0]} size="small" variant="outlined" color="primary" />
            </div>
          </React.Fragment>
        </ListItemIcon>
        <ListItemText>{item.description}</ListItemText>
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => handleLink(item)}>
            <LinkIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <Card>
      <CardHeader
        title="My Pre-pregnancy checklist"
        subheader="The checklist displayed below has been generated from your responses to the questionnaires. This checklist also relates to the information provided in the Essential Information section, which can be accessed from the side menu or through the icons on the right. Please tick these off once you have completed each action."
        action={<TopicMenu value={filter} onChange={filter => setFilter(filter)} />}
      />
      <CardContent>
        {isEmpty(todo) && isEmpty(completed) ? (
          <Typography>
            There are no actions to complete yet. Please view the ‘Essential Information’ and ‘My Goals’ sections of
            the site to develop an action plan.
          </Typography>
        ) : (
          <List>
            <ListSubheader>My tasks</ListSubheader>
            {todo.map(buildItem)}
            <ListSubheader>Completed tasks</ListSubheader>
            {completed.map(buildItem)}
          </List>
        )}
      </CardContent>
    </Card>
  );
}
