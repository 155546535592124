import React from 'react';
import { Button, Typography, Paper } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import useCheckItem from './useCheckItem';

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.grey[50],
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  }),
);

export default function AddToActions(props: any) {
  const { action } = props;
  const classes = useStyles();
  const { isInCheckList, removeItem, addItem } = useCheckItem();
  const active = isInCheckList(action);

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.content}>
        <Typography variant="h6" paragraph>
          {action}
        </Typography>

        {!active && (
          <Button color="primary" variant="contained" onClick={() => addItem(action)}>
            Add to action list
          </Button>
        )}
        {active && (
          <Button color="secondary" variant="contained" onClick={() => removeItem(action)}>
            Remove from action list
          </Button>
        )}
      </div>
    </Paper>
  );
}
