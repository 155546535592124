import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useHistory, RouteChildrenProps } from 'react-router-dom';
import Signin from './Signin';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordSubmit from './ForgotPasswordSubmit';
import Register from './Register';
import RegisterSuccess from './RegisterSuccess';
import { selectAuth } from 'app/store';

export type AuthRoutesProps = RouteChildrenProps<any, { continuePath: string }>;

export default function AuthRoutes(props: Partial<AuthRoutesProps>) {
  const { location } = props;
  const history = useHistory();
  const { authenticated } = useSelector(selectAuth);

  // Authenticated effects
  React.useEffect(() => {
    if (authenticated) {
      const continuePath = location?.state?.continuePath || '/dashboard';
      history.replace(continuePath);
    }
  }, [authenticated, history, location]);

  return (
    <Switch>
      <Route exact path="/auth/signin" component={Signin} />
      <Route exact path="/auth/forgotPassword" component={ForgotPassword} />
      <Route exact path="/auth/forgotPassword/submit" component={ForgotPasswordSubmit} />

      {/**
       *  Fix1: Registering new users has been temporary blocked due to close program
       */}

      {/* {<Route exact path="/auth/register" component={Register} />
      <Route exact path="/auth/register/success" component={RegisterSuccess} />} */}
    </Switch>
  );
}
