import reduce from 'lodash/reduce';

type PickFunction<T> = (key: string, cond: (data: T) => boolean) => void;

export function pickKeys<T extends object>(initialKeys: string[], buildConditions: (pick: PickFunction<T>) => void) {
  type Condition = (data: T) => boolean;
  type Conditions = { [id: string]: Condition };
  let conditions: Conditions = {};
  buildConditions((key: string, cond: Condition) => {
    conditions = { ...conditions, [key]: cond };
  });
  return (data: T) => {
    return reduce<Conditions, string[]>(
      conditions,
      (keys, cond, id) => (cond(data) ? [...keys, id] : keys),
      initialKeys,
    );
  };
}
