import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function PelvicFloor() {
  return (
    <ArticleContent>
      <Quiz
        question="You only need to do pelvic floor exercises if you are unfit."
        answer="False"
        feedback="This is not true. All women should strengthen their pelvic floor muscles during pregnancy and throughout their life."
      />

      <h4 id="key-points">Key points</h4>
      <ul>
        <li>Pelvic floor exercises help to strengthen the muscles of the pelvic floor.</li>
        <li>These muscles come under great strain in pregnancy and childbirth.</li>
        <li>
          If your pelvic floor muscles are weakened, you may find that you leak urine or pass wind when you cough,
          sneeze or strain.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Your pelvic floor muscles are weakened during pregnancy and during birth, so it is important to start
        conditioning these muscles from the beginning of your pregnancy. You can strengthen the muscles by performing
        pelvic floor exercises. Doing so will help your body cope with the growing weight of the baby.
      </p>
      <p>
        Having healthy, strong muscles before birth will help your pelvic floor to recover more quickly after the birth
        and may assist you in avoiding or reducing incontinence after pregnancy. All pregnant women should do pelvic
        floor exercises, even if you’re young, fit and not suffering from incontinence now.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Your pelvic floor muscles will not become strong on their own – you will need to work to improve their strength
        during your pregnancy, and then you will also need to help them become strong again after birth. Pelvic floor
        muscles tend to weaken with subsequent pregnancies and age, and menopause can worsen incontinence. Therefore,
        implementing regular strengthening during your younger years and first pregnancies is crucial.
      </p>
      <p>
        While pelvic floor exercises sound simple, they are not necessarily easy to do correctly as these muscles can be
        difficult to isolate. When done correctly, they are very effective, but the wrong technique can make a problem
        worse. If you are not sure that you are doing the exercises correctly, ask for help from your doctor, a
        physiotherapist or a qualified women’s health specialist. Also, if you are experiencing incontinence or
        discomfort during your pregnancy, consult with a qualified health professional. These are common issues and you
        should not feel embarrassed about discussing them with a health professional.
      </p>
      <p>
        <strong>Where are my pelvic floor muscles?</strong>
      </p>
      <p>
        The first thing to do is to find out which muscles you need to train. Sit or lie down with the muscles of your
        thighs, buttocks and stomach relaxed. Squeeze the ring of muscle around the back passage as if you are trying to
        stop passing wind. Now relax this muscle. Squeeze and let go a couple of times until you are sure you have found
        the right muscles. Try not to squeeze your buttocks.
      </p>
      <p>
        When sitting on the toilet to empty your bladder, try to stop the stream of urine, then start it again. Do this
        to learn which muscles are the right ones to use – but only once a week. Your bladder may not empty the way it
        should if you stop and start your stream more often than that.
      </p>
      <p>
        If you don’t feel a distinct “squeeze and lift” of your pelvic floor muscles, or if you can’t slow your stream
        of urine as talked about in Point 3, ask for help from your doctor, physiotherapist, or continence nurse. They
        will help you to get your pelvic floor muscles working right. Women with very weak pelvic floor muscles can
        benefit from pelvic floor muscle training.
      </p>
      <p>
        <strong>How do I do my pelvic floor muscle training?</strong>
      </p>
      <p>
        Now that you can feel the muscles working, you can:
        Squeeze and draw in the muscles around your back passage and your vagina at the same time. Lift them UP inside.
        You should have a sense of “lift” each time you squeeze your pelvic floor muscles. Try to hold them strong and
        tight as you count to 8. Now, let them go and relax. You should have a distinct feeling of “letting go”. *
        Repeat “squeeze and lift” and let go. It is best to rest for about 8 seconds in between each lift up of the
        muscles. If you can’t hold for 8, just hold for as long as you can. * Repeat this “squeeze and lift” as many
        times as you can, up to a limit of 8 to 12 squeezes. * Try to do three sets of 8 to 12 squeezes each, with a
        rest in between. * Do this whole training plan (three sets of 8 to 12 squeezes) each day while lying down,
        sitting or standing.
      </p>
      
      <p><strong>While doing pelvic floor muscle training:</strong></p> 
        <ul>
        <li>keep breathing</li>
        <li>only squeeze and lift</li>
        <li>do NOT tighten your buttocks; and</li> 
        <li>keep your thighs relaxed</li>
        </ul>
      
      <p>
        Please view this video by the Continence Foundation of Australia for a visual representation and overview of the
        female pelvic floor:
      </p>
      <p>
        <a href="https://www.youtube.com/watch?v=q0_JAoaM6pU">Click here for video.</a>
      </p>
      <p>
        Use these tips as a guide, but seek personalised advice when needed. Information about locating your pelvic
        floor muscles and exercises/training sourced from:{' '}
        <a href="https://www.continence.org.au/pages/pelvic-floor-women.html">The Continence Foundation</a>
      </p>
      <h4 id="useful-links">Useful Links</h4>

      <p>For more information about the pelvic floor visit: </p>
      <ul>
        <li>
          <a href="https://www.continence.org.au/" target="_blank" rel="noopener noreferrer">
            Continence Foundation
          </a>
        </li>
        <li>
          <a
            href="https://www.health.qld.gov.au/news-events/news/pelvic-floor-pre-during-pregnancy-birth-exercises-physiotherapist"
            target="_blank"
            rel="noopener noreferrer">
            Queensland Health
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
