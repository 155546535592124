import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';

export type LinkItemProps = {
  to: string;
  exact?: boolean;
  replace?: boolean;
} & Pick<ListItemProps, 'classes' | 'children'>;

export default function LinkItem(props: LinkItemProps) {
  const { to, exact, replace, ...other } = props;
  const history = useHistory();
  const match = useRouteMatch({ path: to, exact });
  const handleLink = () => {
    replace ? history.replace(to) : history.push(to);
  };
  return <ListItem button href={to} onClick={handleLink} selected={!!match} {...other} />;
}

LinkItem.defaultProps = {
  replace: false,
  exact: false,
};
