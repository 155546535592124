import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ButtonBase, ButtonBaseProps, Typography } from '@material-ui/core';
import DownArrowIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    labelBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: theme.spacing(1),
    },
  }),
);

export type MenuProps = {
  primary: string;
  secondary: string;
} & ButtonBaseProps;

export function MenuLabel(props: MenuProps) {
  const { primary, secondary, children, ...buttonProps } = props;
  const classes = useStyles();
  return (
    <ButtonBase className={classes.root} {...buttonProps}>
      <div className={classes.labelBox}>
        {secondary && (
          <Typography variant="caption" color="primary">
            {secondary}
          </Typography>
        )}
        <Typography variant="subtitle1">{primary}</Typography>
      </div>
      <DownArrowIcon />
    </ButtonBase>
  );
}
