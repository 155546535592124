import React from 'react';
import { CircularProgress, makeStyles, createStyles } from '@material-ui/core';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import { ActionStatus } from 'app/helper';

const useStyles = makeStyles(theme =>
  createStyles({
    progress: {
      margin: theme.spacing(0, 1, 0, 0),
    },
  }),
);

export type ActionStatusButtonProps = ButtonProps & {
  status?: ActionStatus | null;
};

export default function ActionStatusButton(props: ActionStatusButtonProps) {
  const { children, status, disabled, ...btnProps } = props;
  const classes = useStyles();
  const isRequesting = Boolean(status && status.type === 'request');
  return (
    <MuiButton {...btnProps} disabled={disabled || isRequesting}>
      {isRequesting && <CircularProgress className={classes.progress} size={16} />}
      {children}
    </MuiButton>
  );
}
