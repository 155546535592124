import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function HealthyEating() {
  return (
    <ArticleContent>
      <Quiz
        question="You need a large amount of extra food each day when you are pregnant."
        answer="False"
        feedback="This is not true. The amount of extra food you need is small, you do not need to eat for two."
      />

      <h4 id="key-points">Key points</h4>
      <ul>
        <li>Good nutrition during pregnancy will help keep you and your baby healthy.</li>
        <li>You should aim for a varied diet with the right amount of healthy foods from the five main food groups.</li>
        <li>
          You need to eat a little more of some foods to ensure key nutrients are obtained, but there is no need to eat
          for two.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Healthy eating during pregnancy can seem complex and confusing to all of us. You can keep it fairly simple by
        continuing to use the Australian Dietary Guidelines and focusing your diet around whole foods, while remembering
        the food safety considerations.
      </p>
      <p>
        During pregnancy, the quality rather than quantity of food is most important. Choosing a wide variety of healthy
        meals and minimising sugary and highly processed foods is important to ensure the nutritional needs of both
        mother and baby are met.
      </p>
      <p>
        Pregnancy is a not a time for strict dieting, just sensible and nourishing eating. If you are overweight you
        should not attempt a weight reduction diet during pregnancy. To help prevent excess weight gain you should limit
        high fat and high sugar, energy dense foods and drinks. While there is increased need for particular nutrients,
        the extra energy requirements from food (kilojoules) change very little, equivalent to about one extra glass of
        milk per day.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>Try to:</p>
      <ul>
        <li>Eat lots of fruits, vegetables, wholegrain breads and cereals</li>
        <li>Consume moderate amounts of dairy food and lean meats</li>
        <li>Swap water in place of soft drinks; and</li>
        <li>Eat less high energy snack foods such as chocolate, lollies, cakes, health bars, biscuits, crisps, etc.</li>
      </ul>
      <h4>The Australian Dietary Guidelines</h4>
      <p>
        The Australian Dietary Guidelines provide recommendations for Australian adults that are a great tool to help
        guide your food intake. For women aged 19-50 who are pregnant, the current recommendations for the 6 main foods
        groups are provided below:
      </p>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/vegetables.png" alt="Vegetables" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/fruit.png" alt="Fruit" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/grain.png" alt="Grain" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/dairy.png" alt="Dairy" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/protein.png" alt="Protein" />
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/articles/pregnancy/shapingLife/limit.png" alt="Limit" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <p>
        It is also important to consider the tips from the other food related fact sheets when choosing foods from these
        food groups:
      </p>
      <ul>
        <li>
          <strong>
            <a
              href="/topics/pregnancy/articles/food_poisoning">
              Listeria, Salmonella and Toxoplasmosis in Pregnancy
            </a>
          </strong>
        </li>
        <li>
          <strong>
            <a
              href="/topics/pregnancy/articles/food_safety">
              Preparing and Choosing Food Safely
            </a>
          </strong>
        </li>
        <li>
          <strong>
            <a
              href="/topics/pregnancy/articles/eating_seafood">
              Eating Seafood
            </a>
          </strong>
        </li>
      </ul>
      <h4 id="useful-resources">Useful Resources</h4>
      <p>For more information about healthy eating during pregnancy please visit:</p>
      <ul>
        <li>
          <a
            href="https://www.eatforhealth.gov.au/sites/default/files/content/The%20Guidelines/n55h_healthy_eating_during_pregnancy.pdf"
            target="_blank"
            rel="noopener noreferrer">
            Eat For Health
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-and-diet"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Vic
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
