import React from 'react';
import { styled } from '@material-ui/core/styles';
import { ArticleContent } from 'components/topics/Article';

export default function Alcohol() {
  return (
    <ArticleContent>
      <p>
        Remembering these messages may be easier than trying to remember how much to eat, how to cook, what foods you
        should and shouldn’t eat, what exercise to do and how much to do.
      </p>
      <p>These messages are relevant to everyone and can be applied at home to your family.</p>

      <Message prefix="1">
        <h6>Limit High Fat Takeaway Food and Convenience Foods to Once per Week, Including Lunches</h6>
        <p>
          Foods high in fat and low in fibre are high energy dense foods. They have lots of calories/kilojoules and
          don’t make you feel full so you can eat large amounts of them. Examples are muffins, cakes, pastries,
          hamburgers, chips and pies.
        </p>
      </Message>

      <Message prefix="2">
        <h6>Eat at least 2 Serves of Fruit and 5 Serves of Vegetables Every Day</h6>
        <p>
          Fruit and vegetables help with weight maintenance by adding extra fibre, which makes you feel full. Try to
          include incorporate vegetables daily at lunch and dinner.
        </p>
      </Message>

      <Message prefix="3">
        <h6>Reduce High Fat and High Sugar Snacks Between Meals</h6>
        <p>
          Cakes, biscuits, chips and chocolates are occasional foods. They should be saved for special occasions, rather
          than being eaten every day. Snacking on fruits and vegetables between meals instead will reduce the sugar and
          fat you are eating.
        </p>
      </Message>

      <Message prefix="4">
        <h6>Drink Water in Place of Soft Drink, Juice, Cordial and Sports Drinks and Keep Alcohol to a Minimum</h6>
        <p>
          Fruit juices, cordials and soft drinks contain a lot of sugar. They can be consumed very quickly, have no
          nutritional value and don’t fill you up. Alcohol is concentrated in calories, and will lead to rapid weight
          gain. Drink no more than 2 standard drinks a day.
        </p>
      </Message>

      <Message prefix="5">
        <h6>Eat Breakfast Every Day</h6>
        <p>
          Skipping breakfast will often result in poorer food choices throughout the rest of the day. Breakfast foods
          with milk and fruit are an important part of a healthy diet. Choose whole grain varieties such as rolled oats,
          untoasted muesli, wheat based cereals and grainy breads.
        </p>
      </Message>

      <Message prefix="6">
        <h6>Take a Brisk Walk for at least 30 Minutes on Most Days of the Week</h6>
        <p>
          Walking should be brisk, rather than slow. You should feel warm and need to breathe through your mouth to get
          extra air. You heart rate should become slightly faster. For extra health and fitness some more vigorous
          exercise is also beneficial.
        </p>
      </Message>
    </ArticleContent>
  );
}

const Message = styled('div')((props: any) => {
  return {
    position: 'relative',
    margin: props.theme.spacing(2),
    padding: '2rem 1.5rem 1.5rem 1.5rem',
    backgroundColor: '#adb4b9',
    borderRadius: 8,
    '& > h6': {
      margin: '0 0 1rem 0',
    },
    '&:before': {
      position: 'absolute',
      top: '-1.2rem',
      left: '1rem',
      fontSize: '2rem',
      fontStyle: 'italic',
      fontWeight: 400,
      color: '#AEBC95',
      content: props.prefix,
    },
  };
});
