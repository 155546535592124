import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function FirstHours() {
  return (
    <ArticleContent>
      <Quiz
        question="Post-birth bleeding will stop after 2 to 3 days."
        answer="False"
        feedback="This is not correct. Bleeding can continue for a few weeks but heavy bleeding occurs in the first few days after birth"
      />
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Your physical recovery begins very quickly after the birth of your baby</li>
        <li>Heavy bleeding is normal in the days after giving birth</li>
        <li>
          After birth it is normal to experience a range of symptoms such as cramping, swelling in your legs and feet
          and breast tenderness.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        A lot happens in the first few days after birth. Your physical recovery begins and it good to know what to
        expect. After a birth without complications your body starts to recover quickly. You will experience a vaginal
        discharge, called lochia that is normal in the first days after giving birth. This tissue and blood from the
        uterus will be heavy and bright red to start and then become lighter in flow and colour. It will stop after a
        few weeks.
      </p>
      <p>
        Cramping is also a common symptom as your uterus contracts down to its normal size. Cramps can be more
        noticeable when your baby is breastfeeding. Swelling in the legs and feet can happen in some cases. Keeping your
        feet elevated can help. Your first bowel movement may be a few days after delivery, especially after a caesarean
        section. Some women may have haemorrhoids, sore muscles or an episiotomy (cut to the skin) that will make going
        to the toilet painful. Constipation can happen. Drinking plenty of water and eating fresh fruit and vegetables
        will help.
      </p>
      <p>
        After pregnancy and birth, the muscles in your urinary tract are stretched. You may find some incontinence when
        you cough, laugh or strain, especially if there was a lengthy labour.
      </p>
      <p>
        Your breasts and nipples will be full and sometimes sore as the milk comes in three to six days after your baby
        arrives. If you had a caesarean section, you have had major abdominal surgery. It will take time for your body
        to recover and you will be given strong pain relief for the first few days. Your stitches will need dressings
        changed and monitoring for infection. Stitches will be removed when needed.
      </p>
      <p>
        If you had a vaginal birth, sitting down can be uncomfortable, especially if you have had stitches. Your doctor
        or midwife will advise you on remedies, pain relief and care of the wound.
      </p>
      <p>
        The days after giving birth can be an emotional roller-coaster. See section on{' '}
        <a href="/topics/postpartum/articles/baby_blues">Baby blues</a>.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        To manage post-birth bleeding it is best to use maternity sanitary pads that are large enough to absorb heavy
        bleeding and clots. As the bleeding slows you will be able to use regular sanitary pads. Avoid using tampons for
        up to six weeks after birth as this can increase the likelihood of infection. If the bleeding does not start to
        decrease and you are concerned, check in with your visiting midwife of contact your doctor.
      </p>
      <p>
        Pain relief can help with post-birth cramping and your midwife or doctor can advise on types safe for
        breastfeeding.
      </p>
      <p>
        To reduce swelling keep your legs elevated when you can. To assist with your bowel movement drink plenty of
        water and eat fresh fruit and vegetables to help.
      </p>
      <p>
        To relieve some of your breast tenderness your midwife will show you how to self-express and encourage milk
        supply.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about first hours after birth, visit:</p>
      <ul>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/servicesandsupport/first-days-after-birth"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
