import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import Alert from 'components/Alert';
import EditProfile from './EditProfile';
import InitProfile from './InitProfile';

import ensureProfile from 'app/ensureProfile';
import { selectProfile } from 'app/store';
import { actions } from './profileSlice';

export default function Profile() {
  const { status } = useSelector(selectProfile);
  const dispatch = useDispatch();
  return (
    <>
      <Alert alertText={status?.error} onClose={() => dispatch(actions.idle())} variant="filled" severity="error" />
      <Switch>
        <Route path="/profile/edit" component={ensureProfile(EditProfile)} />
        <Route path="/profile/init" component={InitProfile} />
        <Route render={() => <Redirect to="/profile/edit" />} />
      </Switch>
    </>
  );
}
