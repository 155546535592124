import React from 'react';
import Form from 'components/form';
import Button from 'components/ActionStatusButton';
import { passwordPolicy, matchField } from 'components/form/validators';
import { TextField } from '@material-ui/core';
import { Card, CardActions } from './AuthPage';

import { ActionStatus } from 'app/helper';

type NewPasswordData = {
  password: string;
  confirmPassword: string;
};

const initialData: NewPasswordData = {
  password: '',
  confirmPassword: '',
};

const validators = {
  password: passwordPolicy(),
  confirmPassword: matchField('password'),
};

export type ChallengeCardProps = {
  status: ActionStatus | null;
  onChallenge: (newPassword: string) => void;
};

export default function ChallengeCard(props: ChallengeCardProps) {
  const { status, onChallenge } = props;
  return (
    <Card title="Set New Password">
      <Form<NewPasswordData>
        data={initialData}
        validators={validators}
        onSubmit={(data: NewPasswordData) => {
          onChallenge(data.password);
        }}>
        {({ values, errors, updateField }) => (
          <>
            <TextField
              type="password"
              name="password"
              label="Password"
              margin="normal"
              variant="outlined"
              value={values.password}
              onChange={e => updateField('password', e.target.value)}
              error={Boolean(errors.password)}
              helperText={errors.password}
              fullWidth
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              margin="normal"
              variant="outlined"
              value={values.confirmPassword}
              onChange={e => updateField('confirmPassword', e.target.value)}
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword}
              fullWidth
            />
            <CardActions>
              <Button status={status} type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </CardActions>
          </>
        )}
      </Form>
    </Card>
  );
}
