import React from 'react';
import { Input, InputAdornment, InputLabel, FormControl, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import isEmpty from 'lodash/isEmpty';

export type SearchFieldProps = {
  value: string | null;
  onSearch: (search: string) => void;
};

export function SearchField(props: SearchFieldProps) {
  const { value, onSearch } = props;

  const [search, setSearch] = React.useState<string>('');

  React.useEffect(() => {
    if (value && value !== search) {
      setSearch(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, setSearch]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch(search);
  };

  const handleClear = () => {
    setSearch('');
    onSearch('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl style={{ minWidth: 250 }}>
        <InputLabel>Search by email</InputLabel>
        <Input
          id="search"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            !isEmpty(search) && (
              <IconButton edge="end" size="small" onClick={handleClear}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }
          title="Search"
          placeholder="Email"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </FormControl>
    </form>
  );
}
