/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const register = /* GraphQL */ `
  mutation Register(
    $email: String!
    $name: String!
    $dob: String!
    $postcode: String!
    $medibank_number: String!
    $consent: Boolean!
    $consent_read: Boolean!
    $consent_contact: Boolean!
  ) {
    register(
      email: $email
      name: $name
      dob: $dob
      postcode: $postcode
      medibank_number: $medibank_number
      consent: $consent
      consent_read: $consent_read
      consent_contact: $consent_contact
    )
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      dob
      weight
      height
      isPreg
      pregStatus
      phone
      dueDate
      contactPreference
      weightLogs
      topicStates {
        items {
          id
          profileId
          mod
          kpiPre
          kpiAfter
          evaluation
          lastVisited
          visited
          screening
          plan
          lifestyleData
          goals
          goalSettings
          checklist
          articles
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      dob
      weight
      height
      isPreg
      pregStatus
      phone
      dueDate
      contactPreference
      weightLogs
      topicStates {
        items {
          id
          profileId
          mod
          kpiPre
          kpiAfter
          evaluation
          lastVisited
          visited
          screening
          plan
          lifestyleData
          goals
          goalSettings
          checklist
          articles
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      dob
      weight
      height
      isPreg
      pregStatus
      phone
      dueDate
      contactPreference
      weightLogs
      topicStates {
        items {
          id
          profileId
          mod
          kpiPre
          kpiAfter
          evaluation
          lastVisited
          visited
          screening
          plan
          lifestyleData
          goals
          goalSettings
          checklist
          articles
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTopicState = /* GraphQL */ `
  mutation CreateTopicState(
    $input: CreateTopicStateInput!
    $condition: ModelTopicStateConditionInput
  ) {
    createTopicState(input: $input, condition: $condition) {
      id
      profileId
      mod
      kpiPre
      kpiAfter
      evaluation
      lastVisited
      visited
      screening
      plan
      lifestyleData
      goals
      goalSettings
      checklist
      articles
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTopicState = /* GraphQL */ `
  mutation UpdateTopicState(
    $input: UpdateTopicStateInput!
    $condition: ModelTopicStateConditionInput
  ) {
    updateTopicState(input: $input, condition: $condition) {
      id
      profileId
      mod
      kpiPre
      kpiAfter
      evaluation
      lastVisited
      visited
      screening
      plan
      lifestyleData
      goals
      goalSettings
      checklist
      articles
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTopicState = /* GraphQL */ `
  mutation DeleteTopicState(
    $input: DeleteTopicStateInput!
    $condition: ModelTopicStateConditionInput
  ) {
    deleteTopicState(input: $input, condition: $condition) {
      id
      profileId
      mod
      kpiPre
      kpiAfter
      evaluation
      lastVisited
      visited
      screening
      plan
      lifestyleData
      goals
      goalSettings
      checklist
      articles
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRegister = /* GraphQL */ `
  mutation UpdateRegister(
    $input: UpdateRegisterInput!
    $condition: ModelRegisterConditionInput
  ) {
    updateRegister(input: $input, condition: $condition) {
      email
      name
      dob
      postcode
      medibank_number
      consent
      consent_read
      consent_contact
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteRegister = /* GraphQL */ `
  mutation DeleteRegister(
    $input: DeleteRegisterInput!
    $condition: ModelRegisterConditionInput
  ) {
    deleteRegister(input: $input, condition: $condition) {
      email
      name
      dob
      postcode
      medibank_number
      consent
      consent_read
      consent_contact
      status
      createdAt
      updatedAt
    }
  }
`;
export const createRegister = /* GraphQL */ `
  mutation CreateRegister(
    $input: CreateRegisterInput!
    $condition: ModelRegisterConditionInput
  ) {
    createRegister(input: $input, condition: $condition) {
      email
      name
      dob
      postcode
      medibank_number
      consent
      consent_read
      consent_contact
      status
      createdAt
      updatedAt
    }
  }
`;
