import React from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import { ModuleId } from 'app/types';

export type TopicFilterProps = {
  value: ModuleId | null;
  onChange: (value: ModuleId | null) => void;
};

export default function TopicMenu(props: TopicFilterProps) {
  const { value, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleChange = (topicId: ModuleId | null) => () => {
    onChange(topicId);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <FilterIcon />
      </IconButton>
      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl} keepMounted>
        {[
          ['All', null],
          ['Pre-pregnancy', 'preconception'],
          ['Pregnancy', 'pregnancy'],
          ['Post-birth', 'postpartum'],
        ].map(([label, topicId], index) => (
          <MenuItem key={index} onClick={handleChange(topicId as ModuleId | null)}>
            <Typography color={value === topicId ? 'textPrimary' : 'textSecondary'}>{label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
