import React, { useState } from 'react';
import Ring from './Ring';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { Paper, Box, Button, Fade } from '@material-ui/core';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { ModuleId } from 'app/types';
import { TopicSummary } from 'app/topics/types';

import useTopicState from 'app/topics/useTopicState';
import useTopicsummary from 'app/topics/useTopicSummary';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      alignItems: 'center',
      //backgroundColor: "#8D9669",
      // color: "#AEBC95",
      marginBottom: theme.spacing(2),
    },
    main: {
      position: 'relative',
      width: 200,
      height: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    svg: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: 200,
      height: 200,
    },

    content: {
      position: 'relative',
      width: 90,
      height: 90,
      overflow: 'hidden',
      borderRadius: '50%',
      backgroundColor: '#AEBC95',
      zIndex: 100,
      '& > section': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        zIndex: 100,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        fontSize: '0.8rem',
      },
    },
  }),
);

// const toPercent = (value: number) => `${(value * 100).toFixed(0)}%`;

const toPercent = (value: number) => {
  if (value > 1) {
    value = 1;
    return `${(value * 100).toFixed(0)}%`;
  } else {
    return `${(value * 100).toFixed(0)}%`;
  }
};

export enum Status {
  New = 0,
  Progress,
  Completed,
}
export type ProgressChartProps = {
  moduleId: ModuleId;
  title: string;
};

export type DataItem = {
  label: string;
  value: number;
  color: string;
};

export default function ProgressChart(props: ProgressChartProps) {
  const classes = useStyles(props);
  const { moduleId, title } = props;
  const [active, setActive] = useState(-1);
  const { topicState } = useTopicState(moduleId);
  const topicSummary = useTopicsummary(topicState, moduleId);
  const history = useHistory();
  const status =
    topicSummary.completed === 0
      ? Status.New
      : topicSummary.completed >= topicSummary.total
      ? Status.Completed
      : Status.Progress;
  const entryPath = `/topics/${moduleId}`;
  const progresses = [
    {
      label: 'Essential Information',
      color: '#00505E',
      toValue: (topicSummary: TopicSummary) =>
        topicSummary.essential.completed / Math.max(topicSummary.essential.total, 1),
    },
    {
      label: 'My Goals',
      color: '#805D49',
      toValue: (topicSummary: TopicSummary) => topicSummary.goals.completed / Math.max(topicSummary.goals.total, 1),
    },
    {
      label: 'Overall Progress',
      color: '#5B5F53',
      toValue: (topicSummary: TopicSummary) => topicSummary.completed / Math.max(topicSummary.total, 1),
    },
  ].map(item => {
    return { label: item.label, color: item.color, value: item.toValue(topicSummary) } as DataItem;
  });

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" gutterBottom>
        {' '}
        {title}
      </Typography>
      <main className={classes.main}>
        <svg className={classes.svg}>
          {progresses.map((p, index) => (
            <Ring
              key={index}
              cx={100}
              cy={100}
              r={93 - index * 18}
              strokeWidth={12}
              percent={p.value}
              color={p.color}
              onMouseLeave={() => setActive(-1)}
              onMouseEnter={() => setActive(index)}
            />
          ))}
        </svg>
        <div className={classes.content}>
          <Fade in={active === -1}>
            <section>
              <Typography variant="h6">{toPercent(progresses[0].value)}</Typography>
            </section>
          </Fade>
          {[0, 1, 2].map(i => (
            <Fade key={i} in={active === i}>
              <section>
                <Typography variant="body2" color="textSecondary" align="center">
                  {progresses[i].label}
                </Typography>
                <Typography variant="h6">{toPercent(progresses[i].value)}</Typography>
              </section>
            </Fade>
          ))}
        </div>
      </main>

      <Box my={2}>
        {progresses.map((p, i) => (
          <Legend key={i} legendColor={p.color} label={p.label} />
        ))}
      </Box>

      <Box minHeight={36}>
        {status !== Status.Completed && (
          <Button variant="contained" onClick={() => history.push(entryPath)} color="primary">
            {status === Status.New ? 'Start' : 'Continue'}
          </Button>
        )}
      </Box>
    </Paper>
  );
}
console.log(Status.Completed);

type LegendProps = TypographyProps & {
  legendColor: string;
  label: string;
};

const Legend = styled(({ className, label }: LegendProps) => (
  <Typography noWrap variant="caption" align="center" className={className}>
    {label}
  </Typography>
))((props: any) => ({
  position: 'relative',
  margin: props.theme.spacing(0, 1),
  lineHeight: 1.5,
  '&:before': {
    display: 'inline-block',
    width: 10,
    height: 10,
    marginRight: 10,
    content: "''",
    backgroundColor: props.legendColor,
  },
}));
