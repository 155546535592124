/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateProfileInput = {
  id?: string | null,
  dob: string,
  weight?: number | null,
  height: number,
  isPreg?: boolean | null,
  pregStatus: PregStatus,
  phone?: string | null,
  dueDate?: string | null,
  contactPreference: Array< string | null >,
  weightLogs?: string | null,
};

export enum PregStatus {
  YES = "YES",
  NO = "NO",
}


export type ModelProfileConditionInput = {
  dob?: ModelStringInput | null,
  weight?: ModelIntInput | null,
  height?: ModelIntInput | null,
  isPreg?: ModelBooleanInput | null,
  pregStatus?: ModelPregStatusInput | null,
  phone?: ModelStringInput | null,
  dueDate?: ModelStringInput | null,
  contactPreference?: ModelStringInput | null,
  weightLogs?: ModelStringInput | null,
  and?: Array< ModelProfileConditionInput | null > | null,
  or?: Array< ModelProfileConditionInput | null > | null,
  not?: ModelProfileConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPregStatusInput = {
  eq?: PregStatus | null,
  ne?: PregStatus | null,
};

export type UpdateProfileInput = {
  id: string,
  dob?: string | null,
  weight?: number | null,
  height?: number | null,
  isPreg?: boolean | null,
  pregStatus?: PregStatus | null,
  phone?: string | null,
  dueDate?: string | null,
  contactPreference?: Array< string | null > | null,
  weightLogs?: string | null,
};

export type DeleteProfileInput = {
  id?: string | null,
};

export type CreateTopicStateInput = {
  id?: string | null,
  profileId: string,
  mod: string,
  kpiPre?: string | null,
  kpiAfter?: string | null,
  evaluation?: string | null,
  lastVisited?: string | null,
  visited?: Array< string | null > | null,
  screening?: string | null,
  plan?: Array< string | null > | null,
  lifestyleData?: string | null,
  goals?: Array< string | null > | null,
  goalSettings?: string | null,
  checklist?: string | null,
  articles?: string | null,
};

export type ModelTopicStateConditionInput = {
  profileId?: ModelIDInput | null,
  mod?: ModelStringInput | null,
  kpiPre?: ModelStringInput | null,
  kpiAfter?: ModelStringInput | null,
  evaluation?: ModelStringInput | null,
  lastVisited?: ModelStringInput | null,
  visited?: ModelStringInput | null,
  screening?: ModelStringInput | null,
  plan?: ModelStringInput | null,
  lifestyleData?: ModelStringInput | null,
  goals?: ModelStringInput | null,
  goalSettings?: ModelStringInput | null,
  checklist?: ModelStringInput | null,
  articles?: ModelStringInput | null,
  and?: Array< ModelTopicStateConditionInput | null > | null,
  or?: Array< ModelTopicStateConditionInput | null > | null,
  not?: ModelTopicStateConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateTopicStateInput = {
  id: string,
  profileId?: string | null,
  mod?: string | null,
  kpiPre?: string | null,
  kpiAfter?: string | null,
  evaluation?: string | null,
  lastVisited?: string | null,
  visited?: Array< string | null > | null,
  screening?: string | null,
  plan?: Array< string | null > | null,
  lifestyleData?: string | null,
  goals?: Array< string | null > | null,
  goalSettings?: string | null,
  checklist?: string | null,
  articles?: string | null,
};

export type DeleteTopicStateInput = {
  id?: string | null,
};

export type UpdateRegisterInput = {
  email: string,
  name?: string | null,
  dob?: string | null,
  postcode?: string | null,
  medibank_number?: string | null,
  consent?: boolean | null,
  consent_read?: boolean | null,
  consent_contact?: boolean | null,
  status?: RegisterStatus | null,
  createdAt?: string | null,
};

export enum RegisterStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export type ModelRegisterConditionInput = {
  name?: ModelStringInput | null,
  dob?: ModelStringInput | null,
  postcode?: ModelStringInput | null,
  medibank_number?: ModelStringInput | null,
  consent?: ModelBooleanInput | null,
  consent_read?: ModelBooleanInput | null,
  consent_contact?: ModelBooleanInput | null,
  status?: ModelRegisterStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelRegisterConditionInput | null > | null,
  or?: Array< ModelRegisterConditionInput | null > | null,
  not?: ModelRegisterConditionInput | null,
};

export type ModelRegisterStatusInput = {
  eq?: RegisterStatus | null,
  ne?: RegisterStatus | null,
};

export type DeleteRegisterInput = {
  email: string,
};

export type CreateRegisterInput = {
  email: string,
  name: string,
  dob: string,
  postcode: string,
  medibank_number: string,
  consent: boolean,
  consent_read: boolean,
  consent_contact: boolean,
  status: RegisterStatus,
  createdAt?: string | null,
};

export type ModelProfileFilterInput = {
  id?: ModelIDInput | null,
  dob?: ModelStringInput | null,
  weight?: ModelIntInput | null,
  height?: ModelIntInput | null,
  isPreg?: ModelBooleanInput | null,
  pregStatus?: ModelPregStatusInput | null,
  phone?: ModelStringInput | null,
  dueDate?: ModelStringInput | null,
  contactPreference?: ModelStringInput | null,
  weightLogs?: ModelStringInput | null,
  and?: Array< ModelProfileFilterInput | null > | null,
  or?: Array< ModelProfileFilterInput | null > | null,
  not?: ModelProfileFilterInput | null,
};

export type ModelTopicStateFilterInput = {
  id?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  mod?: ModelStringInput | null,
  kpiPre?: ModelStringInput | null,
  kpiAfter?: ModelStringInput | null,
  evaluation?: ModelStringInput | null,
  lastVisited?: ModelStringInput | null,
  visited?: ModelStringInput | null,
  screening?: ModelStringInput | null,
  plan?: ModelStringInput | null,
  lifestyleData?: ModelStringInput | null,
  goals?: ModelStringInput | null,
  goalSettings?: ModelStringInput | null,
  checklist?: ModelStringInput | null,
  articles?: ModelStringInput | null,
  and?: Array< ModelTopicStateFilterInput | null > | null,
  or?: Array< ModelTopicStateFilterInput | null > | null,
  not?: ModelTopicStateFilterInput | null,
};

export type ModelRegisterFilterInput = {
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  dob?: ModelStringInput | null,
  postcode?: ModelStringInput | null,
  medibank_number?: ModelStringInput | null,
  consent?: ModelBooleanInput | null,
  consent_read?: ModelBooleanInput | null,
  consent_contact?: ModelBooleanInput | null,
  status?: ModelRegisterStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelRegisterFilterInput | null > | null,
  or?: Array< ModelRegisterFilterInput | null > | null,
  not?: ModelRegisterFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type RegisterMutationVariables = {
  email: string,
  name: string,
  dob: string,
  postcode: string,
  medibank_number: string,
  consent: boolean,
  consent_read: boolean,
  consent_contact: boolean,
};

export type RegisterMutation = {
  register: string,
};

export type CreateProfileMutationVariables = {
  input: CreateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type CreateProfileMutation = {
  createProfile:  {
    __typename: "Profile",
    id: string,
    dob: string,
    weight: number | null,
    height: number,
    isPreg: boolean | null,
    pregStatus: PregStatus,
    phone: string | null,
    dueDate: string | null,
    contactPreference: Array< string | null >,
    weightLogs: string | null,
    topicStates:  {
      __typename: "ModelTopicStateConnection",
      items:  Array< {
        __typename: "TopicState",
        id: string,
        profileId: string,
        mod: string,
        kpiPre: string | null,
        kpiAfter: string | null,
        evaluation: string | null,
        lastVisited: string | null,
        visited: Array< string | null > | null,
        screening: string | null,
        plan: Array< string | null > | null,
        lifestyleData: string | null,
        goals: Array< string | null > | null,
        goalSettings: string | null,
        checklist: string | null,
        articles: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdateProfileMutationVariables = {
  input: UpdateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type UpdateProfileMutation = {
  updateProfile:  {
    __typename: "Profile",
    id: string,
    dob: string,
    weight: number | null,
    height: number,
    isPreg: boolean | null,
    pregStatus: PregStatus,
    phone: string | null,
    dueDate: string | null,
    contactPreference: Array< string | null >,
    weightLogs: string | null,
    topicStates:  {
      __typename: "ModelTopicStateConnection",
      items:  Array< {
        __typename: "TopicState",
        id: string,
        profileId: string,
        mod: string,
        kpiPre: string | null,
        kpiAfter: string | null,
        evaluation: string | null,
        lastVisited: string | null,
        visited: Array< string | null > | null,
        screening: string | null,
        plan: Array< string | null > | null,
        lifestyleData: string | null,
        goals: Array< string | null > | null,
        goalSettings: string | null,
        checklist: string | null,
        articles: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type DeleteProfileMutationVariables = {
  input: DeleteProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type DeleteProfileMutation = {
  deleteProfile:  {
    __typename: "Profile",
    id: string,
    dob: string,
    weight: number | null,
    height: number,
    isPreg: boolean | null,
    pregStatus: PregStatus,
    phone: string | null,
    dueDate: string | null,
    contactPreference: Array< string | null >,
    weightLogs: string | null,
    topicStates:  {
      __typename: "ModelTopicStateConnection",
      items:  Array< {
        __typename: "TopicState",
        id: string,
        profileId: string,
        mod: string,
        kpiPre: string | null,
        kpiAfter: string | null,
        evaluation: string | null,
        lastVisited: string | null,
        visited: Array< string | null > | null,
        screening: string | null,
        plan: Array< string | null > | null,
        lifestyleData: string | null,
        goals: Array< string | null > | null,
        goalSettings: string | null,
        checklist: string | null,
        articles: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type CreateTopicStateMutationVariables = {
  input: CreateTopicStateInput,
  condition?: ModelTopicStateConditionInput | null,
};

export type CreateTopicStateMutation = {
  createTopicState:  {
    __typename: "TopicState",
    id: string,
    profileId: string,
    mod: string,
    kpiPre: string | null,
    kpiAfter: string | null,
    evaluation: string | null,
    lastVisited: string | null,
    visited: Array< string | null > | null,
    screening: string | null,
    plan: Array< string | null > | null,
    lifestyleData: string | null,
    goals: Array< string | null > | null,
    goalSettings: string | null,
    checklist: string | null,
    articles: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdateTopicStateMutationVariables = {
  input: UpdateTopicStateInput,
  condition?: ModelTopicStateConditionInput | null,
};

export type UpdateTopicStateMutation = {
  updateTopicState:  {
    __typename: "TopicState",
    id: string,
    profileId: string,
    mod: string,
    kpiPre: string | null,
    kpiAfter: string | null,
    evaluation: string | null,
    lastVisited: string | null,
    visited: Array< string | null > | null,
    screening: string | null,
    plan: Array< string | null > | null,
    lifestyleData: string | null,
    goals: Array< string | null > | null,
    goalSettings: string | null,
    checklist: string | null,
    articles: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type DeleteTopicStateMutationVariables = {
  input: DeleteTopicStateInput,
  condition?: ModelTopicStateConditionInput | null,
};

export type DeleteTopicStateMutation = {
  deleteTopicState:  {
    __typename: "TopicState",
    id: string,
    profileId: string,
    mod: string,
    kpiPre: string | null,
    kpiAfter: string | null,
    evaluation: string | null,
    lastVisited: string | null,
    visited: Array< string | null > | null,
    screening: string | null,
    plan: Array< string | null > | null,
    lifestyleData: string | null,
    goals: Array< string | null > | null,
    goalSettings: string | null,
    checklist: string | null,
    articles: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdateRegisterMutationVariables = {
  input: UpdateRegisterInput,
  condition?: ModelRegisterConditionInput | null,
};

export type UpdateRegisterMutation = {
  updateRegister:  {
    __typename: "Register",
    email: string,
    name: string,
    dob: string,
    postcode: string,
    medibank_number: string,
    consent: boolean,
    consent_read: boolean,
    consent_contact: boolean,
    status: RegisterStatus,
    createdAt: string | null,
    updatedAt: string,
  } | null,
};

export type DeleteRegisterMutationVariables = {
  input: DeleteRegisterInput,
  condition?: ModelRegisterConditionInput | null,
};

export type DeleteRegisterMutation = {
  deleteRegister:  {
    __typename: "Register",
    email: string,
    name: string,
    dob: string,
    postcode: string,
    medibank_number: string,
    consent: boolean,
    consent_read: boolean,
    consent_contact: boolean,
    status: RegisterStatus,
    createdAt: string | null,
    updatedAt: string,
  } | null,
};

export type CreateRegisterMutationVariables = {
  input: CreateRegisterInput,
  condition?: ModelRegisterConditionInput | null,
};

export type CreateRegisterMutation = {
  createRegister:  {
    __typename: "Register",
    email: string,
    name: string,
    dob: string,
    postcode: string,
    medibank_number: string,
    consent: boolean,
    consent_read: boolean,
    consent_contact: boolean,
    status: RegisterStatus,
    createdAt: string | null,
    updatedAt: string,
  } | null,
};

export type GetProfileQueryVariables = {
  id: string,
};

export type GetProfileQuery = {
  getProfile:  {
    __typename: "Profile",
    id: string,
    dob: string,
    weight: number | null,
    height: number,
    isPreg: boolean | null,
    pregStatus: PregStatus,
    phone: string | null,
    dueDate: string | null,
    contactPreference: Array< string | null >,
    weightLogs: string | null,
    topicStates:  {
      __typename: "ModelTopicStateConnection",
      items:  Array< {
        __typename: "TopicState",
        id: string,
        profileId: string,
        mod: string,
        kpiPre: string | null,
        kpiAfter: string | null,
        evaluation: string | null,
        lastVisited: string | null,
        visited: Array< string | null > | null,
        screening: string | null,
        plan: Array< string | null > | null,
        lifestyleData: string | null,
        goals: Array< string | null > | null,
        goalSettings: string | null,
        checklist: string | null,
        articles: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type ListProfilesQueryVariables = {
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesQuery = {
  listProfiles:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      dob: string,
      weight: number | null,
      height: number,
      isPreg: boolean | null,
      pregStatus: PregStatus,
      phone: string | null,
      dueDate: string | null,
      contactPreference: Array< string | null >,
      weightLogs: string | null,
      topicStates:  {
        __typename: "ModelTopicStateConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetTopicStateQueryVariables = {
  id: string,
};

export type GetTopicStateQuery = {
  getTopicState:  {
    __typename: "TopicState",
    id: string,
    profileId: string,
    mod: string,
    kpiPre: string | null,
    kpiAfter: string | null,
    evaluation: string | null,
    lastVisited: string | null,
    visited: Array< string | null > | null,
    screening: string | null,
    plan: Array< string | null > | null,
    lifestyleData: string | null,
    goals: Array< string | null > | null,
    goalSettings: string | null,
    checklist: string | null,
    articles: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type ListTopicStatesQueryVariables = {
  filter?: ModelTopicStateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTopicStatesQuery = {
  listTopicStates:  {
    __typename: "ModelTopicStateConnection",
    items:  Array< {
      __typename: "TopicState",
      id: string,
      profileId: string,
      mod: string,
      kpiPre: string | null,
      kpiAfter: string | null,
      evaluation: string | null,
      lastVisited: string | null,
      visited: Array< string | null > | null,
      screening: string | null,
      plan: Array< string | null > | null,
      lifestyleData: string | null,
      goals: Array< string | null > | null,
      goalSettings: string | null,
      checklist: string | null,
      articles: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetRegisterQueryVariables = {
  email: string,
};

export type GetRegisterQuery = {
  getRegister:  {
    __typename: "Register",
    email: string,
    name: string,
    dob: string,
    postcode: string,
    medibank_number: string,
    consent: boolean,
    consent_read: boolean,
    consent_contact: boolean,
    status: RegisterStatus,
    createdAt: string | null,
    updatedAt: string,
  } | null,
};

export type ListRegistersQueryVariables = {
  email?: string | null,
  filter?: ModelRegisterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListRegistersQuery = {
  listRegisters:  {
    __typename: "ModelRegisterConnection",
    items:  Array< {
      __typename: "Register",
      email: string,
      name: string,
      dob: string,
      postcode: string,
      medibank_number: string,
      consent: boolean,
      consent_read: boolean,
      consent_contact: boolean,
      status: RegisterStatus,
      createdAt: string | null,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ProfilesByPregStatusQueryVariables = {
  pregStatus?: PregStatus | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProfilesByPregStatusQuery = {
  profilesByPregStatus:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      dob: string,
      weight: number | null,
      height: number,
      isPreg: boolean | null,
      pregStatus: PregStatus,
      phone: string | null,
      dueDate: string | null,
      contactPreference: Array< string | null >,
      weightLogs: string | null,
      topicStates:  {
        __typename: "ModelTopicStateConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OnCreateProfileSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateProfileSubscription = {
  onCreateProfile:  {
    __typename: "Profile",
    id: string,
    dob: string,
    weight: number | null,
    height: number,
    isPreg: boolean | null,
    pregStatus: PregStatus,
    phone: string | null,
    dueDate: string | null,
    contactPreference: Array< string | null >,
    weightLogs: string | null,
    topicStates:  {
      __typename: "ModelTopicStateConnection",
      items:  Array< {
        __typename: "TopicState",
        id: string,
        profileId: string,
        mod: string,
        kpiPre: string | null,
        kpiAfter: string | null,
        evaluation: string | null,
        lastVisited: string | null,
        visited: Array< string | null > | null,
        screening: string | null,
        plan: Array< string | null > | null,
        lifestyleData: string | null,
        goals: Array< string | null > | null,
        goalSettings: string | null,
        checklist: string | null,
        articles: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnUpdateProfileSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateProfileSubscription = {
  onUpdateProfile:  {
    __typename: "Profile",
    id: string,
    dob: string,
    weight: number | null,
    height: number,
    isPreg: boolean | null,
    pregStatus: PregStatus,
    phone: string | null,
    dueDate: string | null,
    contactPreference: Array< string | null >,
    weightLogs: string | null,
    topicStates:  {
      __typename: "ModelTopicStateConnection",
      items:  Array< {
        __typename: "TopicState",
        id: string,
        profileId: string,
        mod: string,
        kpiPre: string | null,
        kpiAfter: string | null,
        evaluation: string | null,
        lastVisited: string | null,
        visited: Array< string | null > | null,
        screening: string | null,
        plan: Array< string | null > | null,
        lifestyleData: string | null,
        goals: Array< string | null > | null,
        goalSettings: string | null,
        checklist: string | null,
        articles: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnDeleteProfileSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteProfileSubscription = {
  onDeleteProfile:  {
    __typename: "Profile",
    id: string,
    dob: string,
    weight: number | null,
    height: number,
    isPreg: boolean | null,
    pregStatus: PregStatus,
    phone: string | null,
    dueDate: string | null,
    contactPreference: Array< string | null >,
    weightLogs: string | null,
    topicStates:  {
      __typename: "ModelTopicStateConnection",
      items:  Array< {
        __typename: "TopicState",
        id: string,
        profileId: string,
        mod: string,
        kpiPre: string | null,
        kpiAfter: string | null,
        evaluation: string | null,
        lastVisited: string | null,
        visited: Array< string | null > | null,
        screening: string | null,
        plan: Array< string | null > | null,
        lifestyleData: string | null,
        goals: Array< string | null > | null,
        goalSettings: string | null,
        checklist: string | null,
        articles: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnCreateTopicStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTopicStateSubscription = {
  onCreateTopicState:  {
    __typename: "TopicState",
    id: string,
    profileId: string,
    mod: string,
    kpiPre: string | null,
    kpiAfter: string | null,
    evaluation: string | null,
    lastVisited: string | null,
    visited: Array< string | null > | null,
    screening: string | null,
    plan: Array< string | null > | null,
    lifestyleData: string | null,
    goals: Array< string | null > | null,
    goalSettings: string | null,
    checklist: string | null,
    articles: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnUpdateTopicStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTopicStateSubscription = {
  onUpdateTopicState:  {
    __typename: "TopicState",
    id: string,
    profileId: string,
    mod: string,
    kpiPre: string | null,
    kpiAfter: string | null,
    evaluation: string | null,
    lastVisited: string | null,
    visited: Array< string | null > | null,
    screening: string | null,
    plan: Array< string | null > | null,
    lifestyleData: string | null,
    goals: Array< string | null > | null,
    goalSettings: string | null,
    checklist: string | null,
    articles: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnDeleteTopicStateSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTopicStateSubscription = {
  onDeleteTopicState:  {
    __typename: "TopicState",
    id: string,
    profileId: string,
    mod: string,
    kpiPre: string | null,
    kpiAfter: string | null,
    evaluation: string | null,
    lastVisited: string | null,
    visited: Array< string | null > | null,
    screening: string | null,
    plan: Array< string | null > | null,
    lifestyleData: string | null,
    goals: Array< string | null > | null,
    goalSettings: string | null,
    checklist: string | null,
    articles: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnCreateRegisterSubscription = {
  onCreateRegister:  {
    __typename: "Register",
    email: string,
    name: string,
    dob: string,
    postcode: string,
    medibank_number: string,
    consent: boolean,
    consent_read: boolean,
    consent_contact: boolean,
    status: RegisterStatus,
    createdAt: string | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateRegisterSubscription = {
  onUpdateRegister:  {
    __typename: "Register",
    email: string,
    name: string,
    dob: string,
    postcode: string,
    medibank_number: string,
    consent: boolean,
    consent_read: boolean,
    consent_contact: boolean,
    status: RegisterStatus,
    createdAt: string | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteRegisterSubscription = {
  onDeleteRegister:  {
    __typename: "Register",
    email: string,
    name: string,
    dob: string,
    postcode: string,
    medibank_number: string,
    consent: boolean,
    consent_read: boolean,
    consent_contact: boolean,
    status: RegisterStatus,
    createdAt: string | null,
    updatedAt: string,
  } | null,
};
