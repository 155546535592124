import HealthyEating from './HealthyEating';
import Activity from './Activity';
import Weight from './Weight';
import KeyMessages from './KeyMessages';
import Resources from './Resources';
import { Content } from 'app/topics/types';

export default [
  {
    id: 'food',
    tag: 'Lifestyle',
    title: 'Healthy Eating',
    desc:
      'The foods you eat play an important role in your ability to conceive and can impact the long term health of your child.',
    cover: 'images/articles/preconception/shapingLife/food.jpg',
    banner: 'images/articles/preconception/shapingLife/food@2x.jpg',
    ArticleContent: HealthyEating,
  },
  {
    id: 'activity',
    tag: 'Lifestyle',
    title: 'Physical Activity',
    desc: 'Physical activity plays an important role in helping you become pregnant.',
    cover: 'images/articles/preconception/shapingLife/activity.jpg',
    banner: 'images/articles/preconception/shapingLife/activity@2x.jpg',
    ArticleContent: Activity,
  },
  {
    id: 'weight',
    tag: 'Lifestyle',
    title: 'Weight Gain Prevention',
    desc: 'Almost all Australians are gaining weight at a rate that is causing health concerns.',
    cover: 'images/articles/preconception/shapingLife/weight.jpg',
    banner: 'images/articles/preconception/shapingLife/weight@2x.jpg',
    ArticleContent: Weight,
  },
  {
    id: 'key_message',
    tag: 'Lifestyle',
    title: 'Our 6 key lifestyle messages',
    desc: 'These messages have been shown to be important in preventing weight gain at any life stage.',
    cover: 'images/articles/preconception/shapingLife/key_message.jpg',
    banner: 'images/articles/preconception/shapingLife/key_message@2x.jpg',
    ArticleContent: KeyMessages,
  },
  {
    id: 'resources',
    tag: 'Lifestyle',
    title: 'Resources',
    desc:
      'Here is a collection of resources developed by our health professional team that can help you make positive changes.',
    cover: 'images/articles/preconception/shapingLife/resources.jpg',
    banner: 'images/articles/preconception/shapingLife/resources@2x.jpg',
    ArticleContent: Resources,
  },
] as Array<Content>;
