import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'Discuss my previous pregnancy problems with my GP or specialist.';

export default function ReproductiveHistory() {
  return (
    <ArticleContent>
      <Quiz
        question="Past pregnancy problems are likely to occur again."
        answer="False"
        feedback="This is false. There may be a number of interventions that can reduce the risk of the reoccurrence of past problems."
      />

      <h4>Key points</h4>
      <ul>
        <li>If you have a history of pregnancy problems there may be ways to reduce the risk of reoccurrence</li>
        <li>Developing a pregnancy plan may optimise pregnancy outcomes</li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        If you have had any problems with previous pregnancies you should discuss you pregnancy plans with your doctor.
        Previous pregnancy complications may include:
      </p>
      <ul>
        <li>Infant death</li>
        <li>Fetal loss (miscarriage)</li>
        <li>Birth defects (particularly neural tube defects [NTD])</li>
        <li>Low birth weight babies</li>
        <li>Preterm birth</li>
        <li>Gestational diabetes.</li>
      </ul>

      <p>There may be interventions that can reduce the risk of reoccurrence of any problems.</p>

      <h4>Environmental exposure</h4>
      <p>
        People who work with chemicals or other toxins can be careful not to expose women to them. For example, people
        who work with fertilizers or pesticides should change out of dirty clothes before coming near women. They should
        handle and wash soiled clothes separately.
      </p>

      <h4>What can I do?</h4>
      <p>Discuss previous pregnancy problems with my GP or specialist.</p>

      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information please visit:</p>
      <ul>
        <li>
          <a
            href="https://www.thewomens.org.au/health-information/pregnancy-and-birth/pregnancy-problems"
            target="_blank"
            rel="noopener noreferrer">
            The Royal Women's Hospital
          </a>
        </li>
        <li>
          <a href="https://www.sands.org.au" target="_blank" rel="noopener noreferrer">
            Miscarriage, stillbirth and neonatal death support (sands)
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/conditionsandtreatments/birth-defects"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
