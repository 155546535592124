import React from 'react';
import MuiRating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { Chip } from '@material-ui/core';

type RatingProps = {
  name: string;
  value: string | null;
  options: string[];
  onChange: (value: string | null) => void;
};

export default function Rating(props: RatingProps) {
  const { name, options, value, onChange } = props;
  const [hover, setHover] = React.useState<number>(-1);
  const rating = value ? options.indexOf(value) + 1 : null;

  const getText = () => {
    if (hover > 0) {
      return options[hover - 1];
    }

    if (rating && rating > 0) {
      return options[rating - 1];
    }
    return null;
  };

  const text = getText();

  const handleChange = (v: number | null) => {
    const value = v ? options[v - 1] : null;
    onChange(value);
  };

  return (
    <Box display="flex" alignItems="center">
      <MuiRating
        name={name}
        value={rating}
        precision={1}
        max={options.length}
        onChange={(e, v) => handleChange(v)}
        onChangeActive={(e, newHover) => setHover(newHover)}
      />
      <Box ml={2}>{text && <Chip label={getText()} size="small" />}</Box>
    </Box>
  );
}
