import React from 'react';
import clsx from 'clsx';
import useMeasure from './useMeasure';
import ParallaxContext from './ParallaxContext';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
      '-webkit-overflow-scrolling': 'touch',
      opacity: 0,
      transition: 'opacity 300ms ease-in',
      '&.reveal': {
        opacity: 1,
      },
    },

    main: (props: { height: number }) => ({
      position: 'relative',
      backgroundColor: 'transparent',
      width: '100%',
      height: props.height,
    }),
  }),
);

/**
 * Parallax
 */
export type ParallaxProps = {
  pages: number;
  extra?: React.ReactNode;
  children?: React.ReactNode;
  onPageScroll?: Function;
};

export default function Parallax(props: ParallaxProps) {
  const { pages, extra, children, onPageScroll } = props;
  const parallaxRoot = React.useRef(null);
  const bounds = useMeasure(parallaxRoot);
  const classes = useStyles({ height: pages * bounds.height });
  const [scrollTop, setScrollTop] = React.useState(0);
  const contextValue = { bounds, scrollTop };
  const active = Boolean(parallaxRoot.current);

  const handleScroll = (e: any) => {
    const scrollTop = e.target.scrollTop;
    setScrollTop(scrollTop);
    bounds.height && onPageScroll && onPageScroll(scrollTop / bounds.height);
  };

  return (
    <div className={clsx(classes.root, { reveal: active })} ref={parallaxRoot} onScroll={handleScroll}>
      <main className={classes.main}>
        <ParallaxContext.Provider value={contextValue}>{active && children}</ParallaxContext.Provider>
      </main>
      {extra}
    </div>
  );
}
