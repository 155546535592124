import React from 'react';
import MomentUtils from '@date-io/moment';
import Form, { FormProps } from 'components/form/Form';
import { mustBeDate, mustBetween, notEmpty, conditional, mustBePhone_AU } from 'components/form/validators';
import {
  Typography,
  Box,
  TextField,
  Grid,
  InputAdornment,
  Paper,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import WeightLogsView from 'components/WeightLogsView';
import ContactPreference from './ContactPreference';
import { WeightLog } from 'app/types';

export type ProfileData = {
  dob: any;
  weight: number | null;
  height: number | null;
  pregStatus: 'YES' | 'NO';
  // isPreg: boolean;
  dueDate: any;
  weightLogs: WeightLog[];
  contactPreference: string[];
  phone: string | null;
};

const validators = {
  dob: mustBeDate({ allowEmpty: false }),
  weight: mustBetween({ from: 30, to: 300 }),
  height: mustBetween({ from: 100, to: 250 }),
  dueDate: conditional((data: ProfileData) => data.pregStatus === 'YES', mustBeDate({ allowEmpty: false })),
  weightLogs: conditional((data: ProfileData) => data.pregStatus === 'YES', notEmpty()),
  contactPreference: notEmpty(),
  phone: mustBePhone_AU(),
};

export type ProfileFormProps = Omit<FormProps<ProfileData>, 'children' | 'validators'> & {
  children: React.ReactNode;
};

export default function ProfileForm(props: ProfileFormProps) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Form<ProfileData> validators={validators} {...props}>
        {({ values, errors, updateField }) => (
          <React.Fragment>
            <Box>
              <Typography variant="h6" gutterBottom>
                My information
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="DD/MM/YYYY"
                    label="Date of Birth"
                    placeholder="DD/MM/YYYY"
                    value={values.dob}
                    onChange={(date: any) => updateField('dob', date)}
                    error={Boolean(errors.dob)}
                    helperText={errors.dob}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Select
                      variant="outlined"
                      id="pregStatus"
                      value={values.pregStatus}
                      onChange={e => updateField('pregStatus', e.target.value)}>
                      <MenuItem value="YES">I am pregnant</MenuItem>
                      <MenuItem value="NO">I am not pregnant</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label="Current Weight"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    }}
                    value={values.weight}
                    onChange={e => {
                      updateField('weight', e.target.value);
                    }}
                    error={Boolean(errors.weight)}
                    helperText={errors.weight}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label="Height"
                    variant="outlined"
                    InputProps={{
                      endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                    }}
                    value={values.height}
                    onChange={e => {
                      updateField('height', e.target.value);
                    }}
                    error={Boolean(errors.height)}
                    helperText={errors.height}
                    fullWidth
                  />
                </Grid>

                {values.pregStatus === 'YES' && (
                  <Grid item xs={12} sm={6}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="DD/MM/YYYY"
                      label="Due Date"
                      placeholder="DD/MM/YYYY"
                      value={values.dueDate}
                      onChange={(date: any) => {
                        updateField('dueDate', date);
                      }}
                      error={Boolean(errors.dueDate)}
                      helperText={errors.dueDate}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            {values.pregStatus === 'YES' && (
              <Box mt={2}>
                <Paper>
                  <WeightLogsView
                    weightLogs={values.weightLogs}
                    onChange={(weightLogs: WeightLog[]) => {
                      updateField('weightLogs', weightLogs);
                    }}
                  />
                </Paper>
                <FormHelperText error={Boolean(errors.weightLogs)}>{errors.weightLogs}</FormHelperText>
              </Box>
            )}

            <ContactPreference
              value={values.contactPreference}
              onChange={(pref: string[]) => {
                updateField('contactPreference', pref);
              }}
              error={Boolean(errors.contactPreference)}
              helperText={errors.contactPreference}
            />

            <Box style={{ marginTop: '12px' }}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder="+61xxxxxxxxx"
                    type="text"
                    label="Phone Number (for phone coaching)"
                    variant="outlined"
                    value={values.phone || ''}
                    onChange={e => {
                      updateField('phone', e.target.value);
                    }}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>

            {props.children}

            <Box mt={4}>
              <Typography variant="body2" paragraph>
                <strong>Important notice: </strong>You can access your{' '}
                <Link
                  href="assets/PICF_Version_3_December_2019_OptimalMe.pdf"
                  target="_blank"
                  rel="noopener noreferrer">
                  Participant information consent form
                </Link>{' '}
                here. If you wish to withdraw from the study please fill out the{' '}
                <Link
                  href="assets/Withdrawal_form_Version_3_December_2019_OptimalMe.pdf"
                  target="_blank"
                  rel="noopener noreferrer">
                  Participant Withdrawal form
                </Link>{' '}
                and email this to <Link href="mailto:mchri-optimalme@monash.edu">mchri-optimalme@monash.edu</Link>.
              </Typography>
            </Box>
          </React.Fragment>
        )}
      </Form>
    </MuiPickersUtilsProvider>
  );
}
