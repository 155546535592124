import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ThumUpIcon from '@material-ui/icons/ThumbUp';
import ThumDownIcon from '@material-ui/icons/ThumbDown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#F0F0F0',
    },
    icon: {
      marginRight: theme.spacing(2),
    },
  }),
);

export enum ResultLineTypes {
  Success,
  Failure,
}

export type ResultLineProps = {
  type?: ResultLineTypes;
  message: React.ReactNode;
};

export default function Resultline(props: ResultLineProps) {
  const { type, message } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root} p={3} mt={2} borderRadius={5}>
      {type === ResultLineTypes.Success && <ThumUpIcon className={classes.icon} color="primary" />}
      {type === ResultLineTypes.Failure && <ThumDownIcon className={classes.icon} color="error" />}
      {message}
    </Box>
  );
}
