import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import {
  Card,
  Button,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography,
  IconButton,
  Hidden,
  Tooltip,
  Menu,
  MenuItem
} from '@material-ui/core';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ModuleId } from 'app/types';
import { GoalOptions } from 'app/topics/types';
import useTopicState from 'app/topics/useTopicState';
import { GoalSettingData } from '../GoalSetting/GoalSetting';

/**
 * Goals
 */
export type GoalsProps = {
  moduleId: ModuleId;
  goalOptions: GoalOptions;
};

interface iGoalItemsProps {
  goalId: string;
  topicState: object;
  isChecked: any;
  setGoalAsComplete: any;
  goalOptions: GoalOptions;
  moduleId: string;
}

const GoalItem = (props: iGoalItemsProps): any => {
  const { goalId,
    topicState,
    isChecked,
    goalOptions,
    moduleId,
    setGoalAsComplete } = props
  const history = useHistory();
  const data = get(topicState, ['goalSettings', goalId],
    {
      why: '',
      goal: '',
      obstacles: '',
      strategies: '',
      aim: '',
      completed: null,
      review_progress: null
    }) as GoalSettingData

  // Find out if a goal is set or not.
  // If the goal is set we will show the
  // review my progress fields.
  // Otherwise not.
  const goalIsSet = (
    Boolean(data.why) &&
    Boolean(data.goal) &&
    Boolean(data.obstacles) &&
    Boolean(data.strategies) &&
    Boolean(data.aim)
  )

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = (event:any) => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  return (
    <React.Fragment key={goalId}>
      <ListItem>
        <ListItemIcon>{isChecked(goalId) ? <AddAlertIcon /> : <AddAlertIcon />}</ListItemIcon>
        <ListItemText primary={goalOptions![goalId]} />
        <Hidden only="xs">
          <Tooltip title="Edit goal">
            <IconButton
              onClick={() => history.push(`/topics/${moduleId}/goals/fill/${goalId}`)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Review my goal">
            <IconButton
              onClick={() => history.push(`/topics/${moduleId}/goals/fill/${goalId}`)}>
              <UpdateIcon />
            </IconButton>
          </Tooltip>
          {
            goalIsSet ? (
              <Tooltip title={!data.completed ? "Set this goal as complete" : "This goal has been completed"}>
                {
                  !data.completed ? (
                    <Button
                      onClick={() => setGoalAsComplete(data, goalId)}>
                      Set as Complete
                    </Button>
                  ) : (
                      <Button>
                        Completed
                      </Button>
                    )
                }
              </Tooltip>
            ) : <React.Fragment />
          }
        </Hidden>

        <Hidden smUp>
          <IconButton aria-controls="simple-menu" aria-haspopup="true"
            onClick={openMenu}>
            <MoreVertIcon/>
          </IconButton>

          <Menu
            id={`menu-${goalId}`}
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
          >
            <MenuItem onClick={() => history.push(`/topics/${moduleId}/goals/fill/${goalId}`)}>
              Edit my goal
            </MenuItem>
            <MenuItem onClick={() => history.push(`/topics/${moduleId}/goals/fill/${goalId}`)}>Review my progress</MenuItem>
            {
              goalIsSet ? (
                !data.completed ? (<MenuItem onClick={() => setGoalAsComplete(data, goalId)}>Set as completed</MenuItem>)
                : (<MenuItem onClick={closeMenu}>Completed</MenuItem>)
              ) : <div/>
            }
          </Menu>
        </Hidden>
      </ListItem>
      <Divider />
    </React.Fragment>
  )
}

export default function Goals(props: GoalsProps) {
  const { moduleId, goalOptions } = props;
  const { topicState, updateTopicState } = useTopicState(moduleId);
  const { goals = [], goalSettings = {} } = topicState;
  const isChecked = (goalId: string): boolean => {
    return get(goalSettings, goalId, null) !== null;
  };

  const setGoalAsComplete = (data: GoalSettingData, goalId: string): void => {
    const goalIsSet = (
      Boolean(data.why) &&
      Boolean(data.goal) &&
      Boolean(data.obstacles) &&
      Boolean(data.strategies) &&
      Boolean(data.aim)
    )
    if (!goalIsSet) return
    let goalData = {
      why: data.why,
      goal: data.goal,
      obstacles: data.obstacles,
      strategies: data.strategies,
      aim: data.aim,
      completed: true,
      review_progress: data.review_progress || ""
    } as GoalSettingData
    updateTopicState({ goalSettings: { ...topicState.goalSettings, [goalId]: goalData } });
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          My Goals
        </Typography>
        <Typography variant="body1" gutterBottom>
          The list below includes the areas that you would like to work on. Please click each area and then follow the
          steps to set some smart goals and strategies to improve these areas. You can also <Link to={`/topics/${moduleId}/goals/select`}>add more items</Link>.
           Once you have set these goals, you can
          view these in the dashboard and assess your progress. You can set, reset or adjust as many goals as you like.
        </Typography>
        <List>
          {goals!.map((goalId: string) => <GoalItem
            key={goalId}
            goalId={goalId}
            topicState={topicState}
            isChecked={isChecked}
            moduleId={moduleId}
            goalOptions={goalOptions}
            setGoalAsComplete={setGoalAsComplete}
          />)}
        </List>
      </CardContent>
    </Card>
  );
}
