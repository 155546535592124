import HealthyEating from './HealthyEating';
import PhysicalActivity from './PhysicalActivity';
import FoodPoisoning from './FoodPoisoning';
import FoodSafety from './FoodSafety';
import EatingSeafood from './EatingSeafood';

import { Content } from 'app/topics/types';
import Resources from 'app/topics/preconception/content/shapingLife/Resources';

export default [
  {
    id: 'diet',
    tag: 'Lifestyle',
    title: 'Healthy Eating during Pregnancy',
    desc: 'Eating a variety of healthy foods will provide you and your baby the nutrients you need.',
    cover: 'images/articles/pregnancy/shapingLife/diet.jpg',
    banner: 'images/articles/pregnancy/shapingLife/diet@2x.jpg',
    ArticleContent: HealthyEating,
    dataKeys: ['quiz'],
  },
  {
    id: 'physical_activity',
    tag: 'Lifestyle',
    title: 'Physical Activity during Pregnancy',
    desc: 'Physical activity is important during your pregnancy and can improve the health of you and your baby',
    cover: 'images/articles/pregnancy/shapingLife/physical_activity.jpg',
    banner: 'images/articles/pregnancy/shapingLife/physical_activity@2x.jpg',
    ArticleContent: PhysicalActivity,
  },
  {
    id: 'food_poisoning',
    tag: 'Essentials',
    title: 'Listeria, Salmonella and Toxoplasmosis in Pregnancy',
    desc: 'Factsheets - Listeria, Salmonella and Toxoplasmosis in Pregnancy',
    cover: 'images/articles/pregnancy/shapingLife/food_poisoning.jpg',
    banner: 'images/articles/pregnancy/shapingLife/food_poisoning@2x.jpg',
    ArticleContent: FoodPoisoning,
    dataKeys: ['quiz'],
  },
  {
    id: 'food_safety',
    tag: 'Essentials',
    title: 'Preparing and Choosing Food Safely',
    desc: 'Factsheets - Preparing and Choosing Food Safely',
    cover: 'images/articles/pregnancy/shapingLife/food_safety.jpg',
    banner: 'images/articles/pregnancy/shapingLife/food_safety@2x.jpg',
    ArticleContent: FoodSafety,
    dataKeys: ['quiz'],
  },
  {
    id: 'eating_seafood',
    tag: 'Essentials',
    title: 'Eating Seafood',
    desc: 'Factsheets - Eating Seafood',
    cover: 'images/articles/pregnancy/shapingLife/eating_seafood.jpg',
    banner: 'images/articles/pregnancy/shapingLife/eating_seafood@2x.jpg',
    ArticleContent: EatingSeafood,
    dataKeys: ['quiz'],
  },
  {
    id: 'resources',
    tag: 'Lifestyle',
    title: 'Resources',
    desc:
      'Here is a collection of resources developed by our health professional team that can help you make positive changes.',
    cover: 'images/articles/preconception/shapingLife/resources.jpg',
    banner: 'images/articles/preconception/shapingLife/resources@2x.jpg',
    ArticleContent: Resources,
  },
] as Array<Content>;
