import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function Relationships() {
  return (
    <ArticleContent>
      <Quiz
        question="This time may be challenging for your relationships with friends as well as your partner."
        answer="True"
        feedback="This is true."
      />
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Discussing roles and realistic expectations before you give birth is important</li>
        <li>Your partner returning to work may be a challenging and overwhelming time</li>
        <li>
          Relationships with friends may feel like they have changed and engaging in parent groups may help you develop
          a network.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        As with each stage of your journey to parenthood - from planning a pregnancy, becoming pregnant, pregnancy and
        birth - the postpartum period can also be challenging for your relationships. Early parenting can be very
        overwhelming as you learn to care for your newborn baby and establish new routines for your family. You may find
        you are quite emotional during this time and this can put a strain on relationships with friends, family, other
        children and your partner. Sleep deprivation and additional concerns can also impact on your emotional
        stability.
      </p>
      <p>
        You may feel especially challenged if and when your partner returns to work. It is completely normal to feel
        overwhelmed at this time and while you adjust it may be very helpful to ask for some additional support from
        friends and family. If there is no one available to help, you can use the phone numbers provided in the{' '}
        <a href="/topics/postpartum/articles/support">Support Networks and Care</a>{' '}
        fact sheet for some counselling or help with any concerns.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Having open and honest discussions with people will help you navigate any problems you are having with
        relationships. Women can feel frustrated with their partner and sometimes feel like they are doing all the work.
        These are common feelings and when they are compounded by lack of sleep they can lead to the whole household
        feeling stressed and overwhelmed. Having an open discussion and working through your concerns may be helpful. It
        is important to consider your partner and their adjustment to parenthood during this time. Delegating tasks and
        having realistic expectations may help you avoid or address these situations.
      </p>
      <p>
        You may need extra support during this time and you might need to lean on people you wouldn’t usually, or find
        new communities to engage in. While you may feel that you have a strong, expansive group of friends and
        supportive people around you, engaging with a parent’s group can help you meet people who are experiencing the
        same things you are.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about relationships please visit:</p>
      <ul>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/parenthood-and-your-relationship"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel
          </a>
        </li>
        <li>
          <a
            href="https://www.panda.org.au/info-support/after-birth/adjusting-to-parenthood/relationship-with-your-partner"
            target="_blank"
            rel="noopener noreferrer">
            PANDA
          </a>
        </li>
        <li>
          <a
            href="https://raisingchildren.net.au/grown-ups/looking-after-yourself/your-relationship/healthy-relationships-parents"
            target="_blank"
            rel="noopener noreferrer">
            Raising Children
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
