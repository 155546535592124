import React from 'react';
import { Checkbox, FormGroup, FormLabel, FormControlLabel, FormHelperText } from '@material-ui/core';

type OptionType = {
  label: string;
  value: string;
};

export type CheckboxGroupProps = {
  row: boolean;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, values: string[]) => void;
  values: string[];
  label: React.ReactNode;
  error?: boolean;
  helperText?: React.ReactNode;
  options: Array<string | OptionType>;
};

export default function CheckboxGroup(props: CheckboxGroupProps) {
  const { onChange, values, label, options, error, helperText } = props;

  const isChecked = (value: string): boolean => {
    return Boolean(values && values!.indexOf(value) >= 0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.target.value
    const checkboxVal = e.target.value;
    const nextValues = isChecked(checkboxVal) ? values.filter(v => v !== checkboxVal) : [...values, checkboxVal];
    onChange(e, nextValues);
  };

  return (
    <React.Fragment>
      <FormGroup>
        <FormLabel>{label}</FormLabel>
        {options.map(option => {
          const { label, value } = typeof option === 'string' ? { label: option, value: option } : option;
          return (
            <FormControlLabel
              key={value}
              label={label}
              control={<Checkbox color="primary" checked={isChecked(value)} onChange={handleChange} value={value} />}
            />
          );
        })}
      </FormGroup>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </React.Fragment>
  );
}
