import React from 'react';
import { ExpansionPanelSummary, Typography } from '@material-ui/core';
import { makeStyles, createStyles, styled, StyledProps } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import WaitIcon from '@material-ui/icons/HourglassEmpty';
import orange from '@material-ui/core/colors/orange';
import cyan from '@material-ui/core/colors/cyan';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },

    heading: {
      flexBasis: '33.33%',
      flexShrink: 0,
      marginLeft: '0.3rem',
    },
  }),
);

export type PanleSummaryProps = {
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  icon?: React.ReactNode;
};

export default function PanelSummary(props: PanleSummaryProps) {
  const { primary, secondary, icon } = props;
  const classes = useStyles();
  return (
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.root }}>
      {icon}
      <Typography variant="subtitle1" className={classes.heading}>
        {primary}
      </Typography>

      {secondary}
    </ExpansionPanelSummary>
  );
}

export const Status = styled((props: StyledProps & { completed: boolean }) => {
  const { completed, className } = props;
  if (completed) {
    return (
      <Typography className={className} color="textSecondary">
        Completed <DoneIcon fontSize="small" className="done" />
      </Typography>
    );
  } else {
    return (
      <Typography className={className} color="textSecondary">
        In progress <WaitIcon fontSize="small" className="wait" />
      </Typography>
    );
  }
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .done': {
    marginLeft: theme.spacing(1),
    color: cyan[500],
  },
  '& .wait': {
    marginLeft: theme.spacing(1),
    color: orange[500],
  },
}));

export const Attrs = styled((props: StyledProps & { attrs: Array<{ label: string; value: string }> }) => {
  const { attrs, className } = props;
  return (
    <ol className={className}>
      {attrs.map(attr => (
        <li key={attr.label}>
          <label htmlFor={attr.label}>{attr.label}</label>: <span>{attr.value}</span>
        </li>
      ))}
    </ol>
  );
})(({ theme }) => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  flex: 1,
  color: theme.palette.text.secondary,
  '& > li': {
    flex: 1,
    listStyle: 'none',
  },
}));
