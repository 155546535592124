import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API, graphqlOperation } from 'aws-amplify';
import { AppThunk, RootState } from 'app/store';
import { ActionStatus, request, failure, idle, decode } from 'app/helper';
import * as api from 'app/admin/api';
import * as queries from 'graphql/queries';
import { AWSUser, UsersQuery, UserProfile } from 'app/admin/types';
import { toUserAttrs } from '../helper';

type Entities = {
  users: Record<string, AWSUser>;
  profiles: Record<string, UserProfile>;
};

type UpdateUserPayload = {
  id: string;
  user: AWSUser;
};

type SetUsersPayload = {
  users: string[];
  entities: Record<string, AWSUser>;
  cursor: string | null;
};

export type UsersState = {
  query: UsersQuery;
  cursor: string | null;
  status: ActionStatus | null;
  users: string[] | null;
  entities: Entities;
};

export const initialState: UsersState = {
  query: {
    email: null,
    limit: 50,
    nextToken: null,
  },
  cursor: null,
  status: null,
  users: null,
  entities: {
    users: {},
    profiles: {},
  },
};

const { actions, reducer } = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setQuery(state, action: PayloadAction<UsersQuery>) {
      state.query = action.payload;
    },

    setUsers(state, action: PayloadAction<SetUsersPayload>) {
      const { users, entities, cursor } = action.payload;

      state.users = users;
      state.entities = { ...state.entities, users: entities };
      state.cursor = cursor;
      state.status = null;
    },

    appendUsers(state, action: PayloadAction<SetUsersPayload>) {
      const { users, entities, cursor } = action.payload;

      state.users = [...(state.users || []), ...users];
      state.entities = { ...state.entities, users: { ...state.entities.users, ...entities } };
      state.cursor = cursor;
      state.status = null;
    },

    updateUser(state, action: PayloadAction<UpdateUserPayload>) {
      const { id, user } = action.payload;
      const current = state.entities.users[id];
      const userEntities = { ...state.entities.users, [id]: { ...current, ...user } };

      state.entities = { ...state.entities, users: userEntities };
      state.status = null;
    },

    setProfile(state, action: PayloadAction<UserProfile>) {
      const profile = action.payload;
      state.entities = { ...state.entities, profiles: { ...state.entities.profiles, [profile.id]: profile } };
      state.status = null;
    },

    request: request('status'),

    failure: failure('status'),

    idle: idle('status'),
  },
});

export default reducer;

export { actions };

const selectUser = (state: RootState, uid: string) => {
  const user = state.users.entities.users[uid];
  if (!user) throw new Error('User not found');
  return user;
};

const normalize = <T extends Record<string, unknown>>(items: T[], idField: string) => {
  return items.reduce<{ ids: string[]; entities: Record<string, T> }>(
    (memo, item) => {
      const id = item[idField] as string;
      return {
        ids: [...memo.ids, id],
        entities: { ...memo.entities, [id]: item },
      };
    },
    {
      ids: [],
      entities: {},
    },
  );
};

export const fetchUsers = (query: UsersQuery): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    dispatch(actions.setQuery(query));
    const result = await api.listUsers(query);
    const cursor = result.NextToken || null;
    const { ids: users, entities } = normalize(result.Users, 'Username');

    if (query.nextToken === null) {
      dispatch(actions.setUsers({ users, entities, cursor }));
    } else {
      dispatch(actions.appendUsers({ users, entities, cursor }));
    }
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to fetch users'));
  }
};

export const disableUser = (username: string): AppThunk => async (dispatch, state) => {
  dispatch(actions.request());
  try {
    const user = selectUser(state(), username);
    await api.disableUser(username);
    dispatch(actions.updateUser({ id: username, user: { ...user, Enabled: false } }));
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to disable user'));
  }
};

export const enableUser = (username: string): AppThunk => async (dispatch, state) => {
  dispatch(actions.request());
  try {
    const user = selectUser(state(), username);
    await api.enableUser(username);
    dispatch(actions.updateUser({ id: username, user: { ...user, Enabled: true } }));
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to enable user'));
  }
};

export const confirmUserSignUp = (username: string): AppThunk => async (dispatch, state) => {
  dispatch(actions.request());
  try {
    const user = selectUser(state(), username);
    await api.confirmUserSignUp(username);
    dispatch(actions.updateUser({ id: username, user: { ...user, UserStatus: 'Confirmed' } }));
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to confirm user status'));
  }
};

export const getUserProfile = (uid: string): AppThunk => async (dispatch, state) => {
  dispatch(actions.request());
  try {
    const { data } = await API.graphql(graphqlOperation(queries.getProfile, { id: uid }));
    if (data.getProfile === null) {
      throw new Error('profile did not exist');
    }
    const profile = decode.profile(data.getProfile);

    let user = state().users.entities.users[uid];
    if (!user) {
      user = await api.getUser(uid);
    }

    dispatch(actions.setProfile({ ...profile, ...toUserAttrs(user) }));
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to confirm user status'));
  }
};
