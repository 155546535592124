import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { FormData } from 'components/form';
import { Card, CardHeader, CardActions, CardContent, Grid, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Step } from '.';
import FieldInput from './FieldInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: theme.spacing(2, 2),
    },
  }),
);

type Errors<T> = Partial<{ [id in keyof T]: string }>;

export type StepProps<T extends Record<string, any>> = {
  steps: Array<Step>;
  initialData: T;
  onSubmit: (data: T) => void;
  onStepChanged: (step: number) => void;
};

export default function StepForm<T>(props: StepProps<T>) {
  const classes = useStyles();
  const { steps, initialData, onSubmit } = props;

  const [current, setCurrent] = React.useState<number>(0);
  const [data, setData] = React.useState<T>(initialData);
  const [errors, setErrors] = React.useState<Errors<T>>({});

  const { title, fields } = steps[current];
  const canBack = current > 0;
  const isLast = current >= steps.length - 1;

  const updateField = (field: keyof T, value: string | string[]) => {
    setData({ ...data, [field]: value });
  };

  const validate = (): Errors<T> => {
    return fields.reduce((errors, field) => {
      const error = field.validator && field.validator(field.name, data as FormData);
      return error ? { ...errors, [field.name]: error } : errors;
    }, {});
  };

  const updateStep = (nextStep: number) => {
    props.onStepChanged(nextStep);
    setCurrent(nextStep);
  };

  const handleNext = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate();
    if (!isEmpty(errors)) {
      setErrors(errors);
    } else {
      setErrors(errors);
      if (current < steps.length - 1) {
        updateStep(current + 1);
      } else {
        onSubmit(data);
      }
    }
  };

  const handlePrev = () => {
    if (current > 0) {
      updateStep(current - 1);
    }
  };

  return (
    <Card>
      <CardHeader title={title} />
      <form onSubmit={handleNext} noValidate>
        <CardContent>
          <Grid container spacing={4}>
            {fields.map((field, i) => {
              const name = field.name as keyof T;
              return (
                <Grid key={field.name} item xs={12} sm={6}>
                  <FieldInput
                    type={field.type}
                    prefix={field.prefix}
                    desc={field.desc}
                    helperText={field.helperText}
                    input={field.input}
                    value={data[name]}
                    error={errors[name]}
                    onChange={(value: string | string[]) => updateField(name, value)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          {canBack && (
            <Button type="submit" onClick={() => handlePrev()}>
              Back
            </Button>
          )}
          <Button type="submit" color="primary" variant="contained">
            {isLast ? 'Submit' : 'Next'}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
}
