import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function PhysicalActivity() {
  return (
    <ArticleContent>
      <Quiz
        question="All women can start exercising at the same stage after birth."
        answer="False"
        feedback="This is false. Many individual factors influence your ability to exercise and the time it takes to reach this stage."
      />
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>
          In an uncomplicated birth, you can begin light exercise, such as walking when you feel ready. If you have a
          caesarean section, exercise is not recommended until you consult a health professional post-birth.
        </li>
        <li>Postnatal exercise has many benefits for pregnancy and birth recovery, as well as your overall health.</li>
        <li>Try and accumulate 150 minutes per week of moderate intensity physical activity.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Your ability to start exercising depends on individual factors that may be unique to your situation. You may be
        advised to wait until after your six-week postnatal check-up or you may be able to return to exercise before
        this appointment – potentially even in the first week or two. Your ability to return to exercising will depend
        on your birth experience, but is also influenced by your fitness and level of physical activity undertaken
        throughout your pregnancy.
      </p>
      <p>
        Managing your exercise routine may be challenging initially, especially while you and your baby settle into a
        predictable routine. Don’t be hard on yourself if things go awry for some time while you get used to life as a
        mum. However once things do settle, the health benefits of exercise that applied before your pregnancy are just
        as important for you now.
      </p>
      <p>The benefits of postnatal exercise include:</p>
      <ul>
        <li>improved mood, stress relief and prevention of postpartum depression</li>
        <li>improved sleep patterns and decreased fatigue</li>
        <li>exposure to daytime light for your newborn</li>
        <li>help to restore muscle strength and firm up your body post-birth</li>
        <li>weight loss</li>
        <li>improved cardiovascular fitness</li>
        <li>conditioning of your abdominal muscles</li>
        <li>social interaction.</li>
      </ul>
      <p>Be guided by your doctor or midwife, but remember these general safety suggestions:</p>
      <ul>
        <li>
          Wear an appropriate bra that offers good support. Don’t rely the sports bra you used before pregnancy because
          your back and cup size are likely to have changed. It is best to get measured for a new one.
        </li>
        <li>
          Your exercises should not hurt. If you experience pain or any other unexplained symptoms stop the exercise and
          consult your doctor if necessary.
        </li>
      </ul>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Walking and gentle exercise can generally be started as soon as you are comfortable. Let your body (and medical
        team) guide you and start when you feel up to it. Some women will feel able to start exercising early.
      </p>
      <p>
        Six weeks after giving birth, most of the changes that occur during pregnancy and birth will have resolved. For
        women who have a caesarean birth, a difficult birth, or complications, it may take a little longer to feel ready
        to start exercising; and your medical team may insist you wait longer. If you did not exercise during pregnancy,
        start with light exercises like walking which is easy, free and is beneficial for both you and your baby.
      </p>
      <p>It is important to realise your body is in a different condition to how it was before birth and pregnancy:</p>
      <ul>
        <li>your lower back and core abdominal muscles have weakened</li>
        <li>
          your ligaments and joints are suppler and more pliable, making it easier to injure yourself by stretching or
          twisting too much.
        </li>
      </ul>
      <p>
        For this reason, you should avoid any high-impact exercise or sports that require rapid changes in direction.
      </p>
      <p>
        <strong>Breast Feeding and Exercise</strong>
      </p>
      <p>
        Vigorous or regular exercise does not have adverse effects on a mother’s ability to successfully breastfeed as
        long as you maintain sufficient fluid and caloric intake.
      </p>
      <p>
        <strong>The Pelvic Floor</strong>
      </p>
      <p>
        Pregnancy and childbirth can adversely affect most women’s pelvic floor. You should continue pelvic floor
        exercises that we discussed in the{' '}
        <a href="/topics/pregnancy/articles/pelvic_floor_exercises">pregnancy module</a>. These can be
        resumed immediately after birth in most cases.
      </p>
      <p>
        Remember to ask for help from a physiotherapist, continence nurse, your doctor or midwife if you are unsure
        whether you are doing the exercises correctly. You may also want to visit a women’s health physiotherapist 12-16
        weeks post-birth or before you engage in high-intensity activities to check your abdominal muscles and test your
        pelvic floor.
      </p>
      <p>
        <strong>Creating Time for Exercise</strong>
      </p>
      <p>
        Finding time to be physical active when you’re caring for a newborn can be challenging. Suggestions include:
      </p>
      <ul>
        <li>
          Try and accumulate 150 minutes of moderate intensity physical activity each week. This may sound like a lot,
          but you can break it up into small amounts. You can spread your activity out during the week, and also break
          it up into smaller doses throughout the day. If you do 15 minutes morning and night, five days a week, you
          will meet the recommendations.
        </li>
        <li>
          Exercising with someone else can help you feel motivated. Also, if you schedule in a time for someone to come
          and look after your baby so you can go for a walk or do some exercise this may help motivate you and develop a
          routine.
        </li>
        <li>Use apps on your smart phone to help motivate you and track your progress.</li>
        <li>You can walk your baby in the pram rather than use the car for short trips.</li>
        <li>You can do abdominal exercises on the floor and include your baby as they lay near you.</li>
        <li>
          You can do pelvic floor and stomach exercises while you’re doing other tasks, either sitting or standing. To
          help you remember, try these exercises whenever you do certain things, such as breastfeeding or driving the
          car.
        </li>
        <li>
          Do the best you can, and don’t be hard on yourself. Everyone’s journey is different and you will get more time
          and may feel more motivated once you and your baby settle into a routine.
        </li>
      </ul>
      <p>
        <strong>Types of Post-birth Exercise</strong>
      </p>
      <ul>
        <li>mums and bubs classes (also great for socialising)</li>
        <li>brisk walking</li>
        <li>swimming</li>
        <li>water aerobics</li>
        <li>yoga</li>
        <li>Pilates</li>
        <li>low impact aerobic workouts</li>
        <li>light weight training</li>
        <li>cycling or riding an exercise bike.</li>
      </ul>
      <p>
        Talk to your doctor or midwife for more recommendations or cautions. Reach out to a physiotherapist or exercise
        physiologist if you need more support.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about exercise and physical activity after birth visit:</p>
      <ul>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/postnatal-exercise"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Victoria
          </a>
        </li>
        <li>
          <a
            href="https://www.acog.org/Patients/FAQs/Exercise-After-Pregnancy?IsMobileSet=false"
            target="_blank"
            rel="noopener noreferrer">
            American College of Obstetricians and Gynaecologists
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
