import React from 'react';
import { useLocation, useHistory, matchPath } from 'react-router-dom';
import { Toolbar, Tabs, Tab } from '@material-ui/core';

export type Nav = {
  label: string;
  path: string;
  replace?: boolean;
  exact?: boolean;
};

export type NavToolbarProps = {
  navs: Nav[];
};

export default function NavToolbar(props: NavToolbarProps) {
  const { navs } = props;
  const location = useLocation();
  const history = useHistory();
  const active = navs.findIndex((nav: Nav) => {
    return Boolean(matchPath(location.pathname, { path: nav.path, exact: nav.exact }));
  });

  const handleClick = (path: string, replace: boolean) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (replace) {
      history.replace(path);
    } else {
      history.push(path);
    }
  };
  return (
    <Toolbar variant="dense" disableGutters>
      <Tabs value={active}>
        {navs.map(nav => (
          <Tab key={nav.path} label={nav.label} onClick={handleClick(nav.path, Boolean(nav.replace))} />
        ))}
      </Tabs>
    </Toolbar>
  );
}

NavToolbar.defaultProps = {
  replace: false,
  exact: true,
};
