import React from 'react';
import { Box } from '@material-ui/core';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function EatingSeafood() {
  return (
    <ArticleContent>
      <Quiz
        question="You should avoid all fish while pregnant."
        answer="False"
        feedback="This is false. Seafood is a valuable food source and can be beneficial to you and your baby if eaten properly."
      />

      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Omega-3 fatty acids are important for the development of your baby.</li>
        <li>Seafood is a rich source of these fatty acids, as well as other beneficial nutrients.</li>
        <li>
          Fish that are high in mercury can harm your baby, so considering what fish is the right choice for you is
          important at this time.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Omega-3 fatty acids are important for the development of a baby’s central nervous system, before and after
        birth. Fish is a rich source of omega-3 fatty acids, as well as protein, minerals, iodine and vitamin B12.
      </p>
      <p>
        Eating fish is a good way of achieving this, but you need to be careful about which fish you eat. Mercury
        (methyl mercury) levels from fish are important to consider by women planning pregnancy, pregnant women,
        breastfeeding women and children up to 6 years. Fish containing high amounts of mercury can harm an unborn baby
        or affect the development of a young child’s nervous system.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        It is best to know the mercury levels of different types of fish and how often you should eat each type. Fish
        containing high levels of mercury should not be eaten regularly. Fish containing high levels of mercury are
        generally larger fish, with one serve (150 g) recommended once every fortnight with no other seafood consumed
        during this time.
      </p>
      <strong>
        The seafood listed below has low mercury levels and can be safely eaten up to 2 to 3 times a week:
      </strong>
      <p>
        <b>Low in mercury and high in omega-3</b>
      </p>
      <ul>
        <li>Mackerel</li>
        <li>Silver warehou</li>
        <li>Atlantic salmon</li>
        <li>Canned salmon and canned tuna in oil</li>
        <li>Herrings</li>
        <li>Sardines</li>
      </ul>
      <p>
        <b>Low in mercury</b>
      </p>
      <ul>
        <li>All prawns, lobsters and bugs</li>
        <li>All squids and octopus (calamari)</li>
        <li>Snapper</li>
        <li>Salmon and trout</li>
        <li>Trevally</li>
        <li>Sardines</li>
        <li>Whiting</li>
        <li>Herrings</li>
        <li>Anchovy</li>
        <li>Bream</li>
        <li>Mullet</li>
        <li>Garfish</li>
      </ul>
      <p>
        <strong>
          Ready-to-eat, chilled seafood, such as raw sushi, sashimi and oysters or pre-cooked prawns and smoked salmon
          pose a risk for pregnant women due to the risk of{' '}
          <em>
            <a href="/topics/pregnancy/articles/food_poisoning">
              Listeria
            </a>
          </em>{' '}
          and should be avoided.
        </strong>
      </p>
      <p>
        <strong>Canned Tuna &amp; Salmon</strong>
      </p>
      <p>
        It’s generally safe for all population groups, including pregnant women, to consume 2-3 serves of any type of
        tuna or salmon a week, canned or fresh. Canned tuna usually has lower mercury levels than other tuna because
        tuna used for canning are smaller species that are caught when less than 1 year old.
      </p>
      <p>
        <strong>Supplements</strong>
      </p>
      <p>
        Fish oil products and supplements are not a major source of dietary mercury and there is no recommendation to
        restrict them due to mercury.
      </p>
      <p>
        <strong>Crustacea &amp; Molluscs</strong>
      </p>
      <p>
        Crustacea (including prawns, lobster and crabs) and molluscs (including oysters and calamari) are not a concern
        because they generally contain lower levels of mercury and are usually consumed less often than finfish.
      </p>
      <p>
        <strong>
          In order to decrease the amount of mercury in your body, or to maintain healthy levels, you should follow
          these recommendations:
        </strong>
      </p>
      <Box my={2} style={{ textAlign: 'center' }}>
        <p>Eat 2-3 serves per week of any fish or seafood</p>
        <p>(excluding Catfish, Orange Roughy/Deep Sea Perch, Shark/Flake or</p>
        <p>Billfish/Swordfish/Merlin)</p>
        <p>
          <strong>OR</strong>
        </p>
        <p>Eat 1 serve per week of the following, and NO other fish that week:</p>
        <p>(Catfish or Orange Roughy/Deep Sea Perch)</p>
        <p>
          <strong>OR</strong>
        </p>
        <p>Eat 1 serve per fortnight of the following, and NO other fish for that fortnight:</p>
        <p>(Shark/Flake or Billfish/Swordfish/Merlin)</p>
      </Box>

      <p>
        Modified from: NSW Food Authority. Pregnancy and food safety [Internet]. Newington: NSW Health; 2016 [updated
        2018 Oct 11; cited 2019 Jun 24]. Available from:{' '}
        <a href="http://www.foodauthority.nsw.gov.au/" target="_blank" rel="noopener noreferrer">
          NSW Food Authority
        </a>
      </p>
    </ArticleContent>
  );
}
