import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import DrawerContent from './AppDrawer';
import { useLocation, useHistory } from 'react-router-dom';
import Alert from 'components/Alert';

export const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: '#EEE',
    },

    drawerPaper: {
      width: drawerWidth,
    },
  }),
);

export type PageProps = {
  children: React.ReactNode;
};

export default function HomePage(props: PageProps) {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation<{ alert: string }>();
  const alert = location.state?.alert;
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.root}>
      <Alert alertText={alert} onClose={() => history.replace('/')} severity="warning" />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        classes={{ paper: classes.drawerPaper }}
        open={drawerOpen}
        onClose={handleDrawerToggle}
        anchor="left"
        ModalProps={{ keepMounted: true }}>
        <DrawerContent />
      </Drawer>
      {props.children}
    </div>
  );
}
