import {
  Rate5,
  Approval4,
  Difficulty5,
  Engaging5,
  Likely5,
  Helpful5,
  Effort5,
  SurveyQuestions,
} from 'components/surveyForm';

export type EvaluationData = {
  ka1: Rate5;
  ka2: Rate5;
  ka3: Rate5;
  ka4: Rate5;
  kb1: Approval4;
  kb2: Approval4;
  kb3: Approval4;
  kb4: Approval4;
  kb5: Approval4;
  kb6: Approval4;
  kb7: Approval4;
  kb8: Approval4;
  kb9: Approval4;
  kc1: Difficulty5;
  kc2: Difficulty5;
  kc3: Difficulty5;
  kc4: Difficulty5;
  kc5: Difficulty5;
  kd1: Engaging5;
  kd2: string;
  kd3: string;

  a1: Likely5;
  b1: Helpful5;
  b2: Helpful5;
  b3: Helpful5;
  b4: Helpful5;
  b5: Helpful5;
  b6: Helpful5;
  b7: Helpful5;
  c1: string;
  c2: Effort5;
  c3: string;
  c4: string;
  c5: string;
  d1: string;
  d2: string;
  d3: string;
  e1: string;
  e2: string;
  e3: string;
  e4: string;
  e5: string;
  e6: string;
  e7: string;
  e8: string;
  e9: string;
  e10: string;
};

const questions: SurveyQuestions<EvaluationData> = {
  groups: [
    {
      title:
        'Congratulations on completing the pre-pregnancy module. Your interpretation of this module is very valuable, please fill in the following questions to help us improve OptimalMe and understand your experience.',
      variant: 'rating',
      options: ['Poor', 'Fair', 'Good', 'Very good', 'Excellent'],
      questions: [
        {
          id: 'ka1',
          label: 'How would you rate your knowledge of how to optimise your health in preparing for pregnancy?',
        },
        {
          id: 'ka2',
          label: 'How would you rate your confidence to make lifestyle changes?',
        },
        {
          id: 'ka3',
          label: 'How would you rate your motivation to make lifestyle changes?',
        },
        {
          id: 'ka4',
          label: 'How would you rate your intention to make lifestyle changes?',
        },
      ],
    },
    {
      title:
        'When thinking about your pre-pregnancy health how strongly do you disagree or agree with the following statements?',
      variant: 'toggle-group',
      options: ['Strongly agree', 'Agree', 'Disagree', 'Strongly disagree'],
      questions: [
        {
          id: 'kb1',
          label: 'I feel I have good information about health',
        },
        {
          id: 'kb2',
          label: 'I have enough information to help me deal with my health problems',
        },
        {
          id: 'kb3',
          label: 'I am sure I have all the information I need to manage my health effectively',
        },
        {
          id: 'kb4',
          label: 'I have all the information I need to look after my health',
        },
        {
          id: 'kb5',
          label: 'I can get access to several people who understand and support me',
        },
        {
          id: 'kb6',
          label: 'When I feel ill, the people around me really understand what I am going through',
        },
        {
          id: 'kb7',
          label: 'If I need help, I have plenty of people I can rely on',
        },
        {
          id: 'kb8',
          label: 'I have at least one person who can come to medical appointments with me',
        },
        {
          id: 'kb9',
          label: 'I have strong support from family or friends',
        },
      ],
    },
    {
      title:
        'When thinking about your pre-pregnancy health, how easy or difficult are the following tasks for you to do now?',
      variant: 'rating',
      options: ['Always difficult', 'Usually difficult', 'Sometimes difficult', 'Usually easy', 'Always easy'],
      questions: [
        {
          id: 'kc1',
          label: 'Confidently fill medical forms in the correct way',
        },
        {
          id: 'kc2',
          label: 'Accurately follow the instructions from healthcare providers',
        },
        {
          id: 'kc3',
          label: 'Read and understand written health information',
        },
        {
          id: 'kc4',
          label: 'Read and understand all the information on medication labels',
        },
        {
          id: 'kc5',
          label: 'Understand what healthcare providers are asking you to do',
        },
      ],
    },
    {
      questions: [
        {
          id: 'kd1',
          label: 'How engaging did you find the module?',
          options: ['Not engaging', 'Slightly engaging', 'Moderately engaging', 'Very engaging', 'Extremely engaging'],
          variant: 'rating',
        },
        {
          id: 'kd2',
          variant: 'selection-open',
          label: 'Did the module meet your information needs? (Yes, If No why?)',
          options: ['Yes', 'No'],
        },
        {
          id: 'kd3',
          variant: 'text',
          label: 'What were the three most important messages that stood out to you when completing the module?',
        },
      ],
    },
    {
      title: 'Would you recommend OptimalMe?',
      variant: 'rating',
      options: ['Not likely', 'Slightly likely', 'Moderately likely', 'Very likely', 'Extremely likely'],
      questions: [
        {
          id: 'a1',
          label: 'How likely are you to recommend OptimalMe to family and friends thinking about becoming pregnant?',
        },
      ],
    },
    {
      title: 'How helpful were the following?',
      variant: 'rating',
      options: ['Not helpful', 'Slightly helpful', 'Neutral', 'Helpful', 'Very Helpful'],
      questions: [
        {
          id: 'b1',
          label: 'The interactive web program and modules',
        },
        {
          id: 'b2',
          label: 'The information on physical activity, diet and weight',
        },
        {
          id: 'b3',
          label: 'The information on pre-pregnancy health',
        },
        {
          id: 'b4',
          label: 'Text messages',
        },
        {
          id: 'b5',
          label: 'Phone coaching',
        },
        {
          id: 'b6',
          label: 'Weighing yourself',
        },
        {
          id: 'b7',
          label: 'Accessibility to a health coach',
        },
      ],
    },
    {
      questions: [
        {
          id: 'c1',
          variant: 'selection',
          label: 'Were you satisfied with the level of support provided by the research team?',
          options: ['No, I wanted more support', 'Yes, I was satisfied'],
        },
        {
          id: 'c2',
          variant: 'rating',
          label: 'What effort did you put into following the advice given within the module or phone coaching?',
          options: ['No effort', 'Slight effort', 'Moderate effort', 'Quite a lot of effort', 'A great deal of effort'],
        },
        {
          id: 'c3',
          variant: 'selection-open',
          label: 'How could we have improved the program (please select all that apply)?',
          options: [
            'More phone calls and phone support',
            'More text messages',
            'Regular emails',
            'One-on-one sessions',
            'Addition of exercise plans',
            'Addition of meal plans',
            'Incorporate social media (Facebook groups) in order for program participants to keep in touch and provide encouragement to each other',
            'Other',
          ],
        },
        {
          id: 'c4',
          variant: 'text',
          label: 'What did you like about the program?',
        },
        {
          id: 'c5',
          variant: 'selection-open',
          label:
            'Of the following, what were the key messages you took from the pre-pregnancy module (please select all that apply)?',
          options: [
            'Improved health and lifestyle behaviours can lead to better health outcomes for the baby.',
            'Improved health and lifestyle behaviours can lead to better health outcomes for the mother.',
            'Improved health and lifestyle behaviours can improve fertility.',
            'Improved health and lifestyle behaviours can improve overall wellbeing.',
            'It is important to actively plan for good mental health before pregnancy.',
            'It is ideal for lifestyle changes to happen before you become pregnant.',
            'It is important to make time to focus on emotional wellbeing and relationships.',
            'Other',
          ],
        },
      ],
    },
    {
      title: 'Health assessment',
      questions: [
        {
          id: 'd1',
          variant: 'text',
          label: 'What is your current weight without shoes (kg)? If you are unsure, please estimate anyway.',
        },
        {
          id: 'd2',
          variant: 'toggle-group',
          label: 'How often do you weigh yourself?',
          options: ['Daily', 'Weekly', 'Monthly', 'Occasionally or Never'],
        },
        {
          id: 'd3',
          variant: 'selection-multi',
          label: 'Since starting the OptimalMe program have you (please select all that apply)?',
          options: [
            'Visited a GP in preparation for pregnancy',
            'Had a vaccine for any of the following (Measles, Mumps, Rubella (MMR), Hepatitis B, Tetanus/Diphtheria/Pertussis (whooping cough), Chicken pox, Influenza (flu))',
            'Had a cervical screening test',
            'Had a sexually transited infection (STI) screening test',
            'Had prenatal screening for genetic malformations (genetic testings)',
            'Taken a preconception supplement (folate (folic acid)/iodine or a pre-pregnancy multivitamin)',
            'Taken a Vitamin D supplement',
            'Smoked tobacco',
            'Consumed alcohol (any)',
            'Consumed four or more standard drinks in a single occasion',
            'Taken recreational drugs',
          ],
        },
      ],
    },
    {
      title: 'Program Impact',
      variant: 'toggle-group',
      options: ['Yes', 'No', 'Unsure', 'I did not need to'],
      questions: [
        {
          id: 'e1',
          label: 'Has OptimalMe helped you to improve your overall diet?',
        },
        {
          id: 'e2',
          label: 'Since completing OptimalMe, have you increased your vegetable intake?',
        },
        {
          id: 'e3',
          label: 'Since completing OptimalMe, have you increased your fruit intake?',
        },
        {
          id: 'e4',
          label: 'Since completing OptimalMe, have you decreased your discretionary foods and beverage intake?',
        },
        {
          id: 'e5',
          label: 'Has OptimalMe increased your knowledge about healthy food choices?',
          options: ['Yes', 'No', 'Neutral'],
        },
        {
          id: 'e6',
          label: 'Has OptimalMe increased your knowledge about unhealthy food choices?',
          options: ['Yes', 'No', 'Neutral'],
        },
        {
          id: 'e7',
          label: 'Since completing OptimalMe, have you increased your physical activity/exercise?',
        },
        {
          id: 'e8',
          label: 'Since completing OptimalMe, have you decreased your sedentary behaviour/time sitting?',
        },
        {
          id: 'e9',
          label: 'Has OptimalMe increased your knowledge about ways to be physically active?',
          options: ['Yes', 'No', 'Neutral'],
        },
        {
          id: 'e10',
          label:
            'If you chose another area to work on in your goals (e.g. sleep, stress levels, alcohol consumption etc.) have you made any improvement?',
          options: ['Yes', 'No', 'Neutral'],
        },
      ],
    },
  ],
};

export default questions;
