import preconception from './preconception';
import pregnancy from './pregnancy';
import postpartum from './postpartum';
import { ModuleId } from 'app/types';
import { TopicModule } from './types';

export default {
  preconception,
  pregnancy,
  postpartum,
} as Record<ModuleId, TopicModule>;
