import { UserInfo } from 'app/types';

export function getUserInfo(userInfo: UserInfo): { initial: string; name: string; email: string; groups?: string[] } {
  const email = userInfo.attributes.email;
  const name = userInfo.attributes.name || email.split('@')[0];

  return {
    name,
    email,
    initial: name.charAt(0).toUpperCase(),
    groups: userInfo.groups,
  };
}
