import React from 'react';
import { Box, Typography, Chip, ExpansionPanel, ExpansionPanelDetails } from '@material-ui/core';
import PanelSummary, { Status } from './PanelSummary';
import { isEmpty } from 'lodash';

export type Section = {
  title?: string;
  questions: Question[];
};

export type Question = {
  label: string;
  value: string | string[] | null;
};

export type QuestionListProps = {
  sections?: Section[];
};

export function QuestionList(props: QuestionListProps) {
  const { sections } = props;
  if (!sections) {
    return null;
  }

  return (
    <Box>
      {sections.map((section, sectionIndex) => (
        <Box key={sectionIndex} my={1}>
          {section.title && (
            <Typography variant="h6" gutterBottom>
              {section.title}
            </Typography>
          )}

          {section.questions.map((question, questionIndex) => {
            const value = question.value;
            return (
              <Box key={questionIndex} my={1}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                  {question.label}
                </Typography>
                {value ? <Chip label={value} color="primary" variant="outlined" /> : <Chip label="Empty" disabled />}
              </Box>
            );
          })}
        </Box>
      ))}
    </Box>
  );
}

export type QuestionListPanelProps = {
  primary: string;
  icon?: React.ReactNode;
} & QuestionListProps;

export default function QuestionListPanel(props: QuestionListPanelProps) {
  const { primary, sections, icon } = props;
  const complemeted = !isEmpty(sections);
  return (
    <ExpansionPanel disabled={!complemeted}>
      <PanelSummary icon={icon} primary={primary} secondary={<Status completed={complemeted} />} />
      <ExpansionPanelDetails>
        <QuestionList sections={sections} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
