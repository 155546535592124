import React from 'react';
import { ModuleId } from 'app/types';

type ArticleContextType = {
  articleId: string;
  moduleId: ModuleId;
};

export const ArticleContext = React.createContext<ArticleContextType>({
  articleId: '',
  moduleId: 'preconception',
});

export default ArticleContext;
