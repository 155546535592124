import React from 'react';
import get from 'lodash/get';
import merge from 'lodash/merge';
import ArticleContext from './ArticleContext';

import { TopicState } from 'app/types';
import useTopicState from 'app/topics/useTopicState';

export default function useContentState() {
  const { articleId, moduleId } = React.useContext(ArticleContext);
  const { topicState, updateTopicState } = useTopicState(moduleId);

  // getContentState
  const getContentState = (contentId: string, defaultValue: any = {}) => {
    return get(topicState, ['articles', articleId, contentId], defaultValue);
  };

  // setContentState
  const setContentState = (contentId: string, contentState: any): void => {
    const articles = { ...topicState.articles };
    const data: Partial<TopicState> = {
      articles: merge(articles, {
        [articleId]: {
          [contentId]: contentState,
        },
      }),
    };
    updateTopicState(data);
  };

  return {
    getContentState,
    setContentState,
  };
}
