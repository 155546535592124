import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogContent, Stepper, Step, StepLabel, StepContent, Box } from '@material-ui/core';
import { SurveyForm, SurveyQuestions } from 'components/surveyForm';
import { FormData } from 'components/form';
import { RedirectDialogTitle } from './RedirectDialogTitle';
import PregnancyCheckForm, { PregCheck } from './PregnancyCheckForm';
import { selectProfile } from 'app/store';
import { updateProfile } from 'app/profile/profileSlice';

export type PreEnterDialogProps<T extends FormData> = {
  title: string;
  open: boolean;
  questions: SurveyQuestions<T>;
  data: T;
  onSubmit: (data: Partial<T>) => void;
};

export default function PreEnterDialog<T = FormData>(props: PreEnterDialogProps<T>) {
  const { title, open, questions, data, onSubmit } = props;
  const dispatch = useDispatch();
  const { profile } = useSelector(selectProfile);

  const pregStatus = profile?.pregStatus || 'NO';
  const dueDate = profile?.dueDate || null;
  const [activeStep, setActiveStep] = React.useState<number>(pregStatus === 'YES' ? 1 : 0);
  const pregCheck: PregCheck = { pregStatus, dueDate };

  React.useEffect(() => {
    if (pregStatus === 'YES') {
      setActiveStep(1);
    }
  }, [pregStatus, setActiveStep]);

  const handleUpdatePofile = (data: PregCheck) => {
    dispatch(updateProfile(data));
  };

  return (
    <Dialog open={open} scroll="body">
      <RedirectDialogTitle>{title}</RedirectDialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="pregnancy">
            <StepLabel>Verify Pregnancy</StepLabel>
            <StepContent>
              <Box display="flex" flexDirection="column"></Box>
              <PregnancyCheckForm data={pregCheck} onSubmit={handleUpdatePofile} />
            </StepContent>
          </Step>
          <Step key="kpi">
            <StepLabel>Survey Questions</StepLabel>
            <StepContent>
              <SurveyForm<T> namePrefix="k" questions={questions} data={data} onSubmit={onSubmit} />
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
    </Dialog>
  );
}
