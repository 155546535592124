import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ScrollTriggerPage } from 'components/page';
import NavToolbar, { Nav } from 'components/nav/NavToolbar';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      backgroundColor: 'transparent',
      color: '#333',
    },
    filler: {
      backgroundImage: 'url(images/topics/pregnancy/essentials_banner.jpg)',
      backgroundSize: 'cover',
    },
    header: {
      height: 200,
      padding: theme.spacing(2, 0),
    },
  }),
);

const navs: Nav[] = [
  {
    label: 'Essential Information',
    path: '/topics/pregnancy/essentials',
    exact: false,
  },
  // Fix #141
  {
    label: 'Shaping My Lifestyle',
    path: '/topics/pregnancy/shaping_my_lifestyle',
    exact: false,
  },
  {
    label: 'My Goals',
    path: '/topics/pregnancy/goals',
    exact: false,
  },
];

export function EssentialsPage(props: React.HTMLProps<HTMLElement>) {
  const classes = useStyles();

  return (
    <ScrollTriggerPage
      title="Pregnancy Essentials"
      extraToolbar={<NavToolbar navs={navs} />}
      classes={{ appBar: classes.appBar, fillerBg: classes.filler }}>
      <div className={classes.header} />
      {props.children}
    </ScrollTriggerPage>
  );
}
