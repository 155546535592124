import React from 'react';
import { useSelector } from 'react-redux';
import { ScrollTriggerPage } from 'components/page';
import { Grid, Typography, Avatar, Box } from '@material-ui/core';
import { makeStyles, createStyles, styled } from '@material-ui/core/styles';

import WeightTrack from './widgets/WeightTrack';
import WeightBmi from './widgets/WeightBmi';
import MyProgress from './widgets/MyProgress';
import MyActions from './widgets/MyActions';
import MyGoals from './widgets/MyGoals';

import { selectProfile, selectAuth } from 'app/store';
import { getUserInfo, isPregnant } from 'app/helper';

const useStyles = makeStyles(theme =>
  createStyles({
    fillerBg: {
      backgroundImage: 'url(images/dashboard_banner.jpg)',
      backgroundSize: 'cover',
    },
    appBar: {
      backgroundColor: 'transparent',
      color: '#FFF',
    },
    heading: {
      color: '#FFF',
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

export default function Dashboard() {
  const classes = useStyles();
  const { userInfo } = useSelector(selectAuth);
  const { profile } = useSelector(selectProfile);

  if (!(profile && userInfo)) {
    throw new Error('profile and user info are not loaded.');
  }

  return (
    <ScrollTriggerPage title="OptimalMe" classes={{ appBar: classes.appBar, fillerBg: classes.fillerBg }}>
      <section className={classes.heading}>
        <Typography variant="h4" color="inherit" style={{ flex: 1 }}>
          My Dashboard
        </Typography>
        <UserInfo {...getUserInfo(userInfo!)} />
      </section>

      <MyProgress />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          {isPregnant(profile) ? <WeightTrack profile={profile} /> : <WeightBmi profile={profile} />}
        </Grid>
        <Grid item xs={12}>
          <MyActions profile={profile} />
        </Grid>
        <Grid item xs={12}>
          <MyGoals profile={profile} />
        </Grid>
      </Grid>
    </ScrollTriggerPage>
  );
}

const UserInfo = styled((props: any) => (
  <div className={props.className}>
    <Avatar className="avatar">{props.initial}</Avatar>
    <Box marginLeft={1}>
      <Typography variant="body1">{props.name}</Typography>
      <Typography variant="caption">{props.email}</Typography>
    </Box>
  </div>
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  '& > .avatar': {
    backgroundColor: theme.palette.info.main,
  },
}));
