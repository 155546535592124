import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function SleepDeprivation() {
  return (
    <ArticleContent>
      <Quiz
        question="Not having enough sleep can impact your physical and mental health."
        answer="True"
        feedback="This is true. Lack of sleep can have numerous negative impacts."
      />
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Sleep deprivation is very common for new parents, especially in the first six months.</li>
        <li>Your baby has different sleeping patterns to you and will wake more easily and regularly.</li>
        <li>Maintaining your own sleeping routine as best as possible is important.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        You probably already know that you don’t feel your best or function as well as you can after a bad night’s
        sleep, but you may not realise the impact constant sleep deprivation can have. Lack of sleep can impact your
        ability to concentrate, affect your ability to think and cope, and can eventually lead to poor mental and
        physical health.
      </p>
      <p>
        <strong>Why is lack of sleep so common?</strong>
      </p>
      <p>
        Your newborn’s sleep patterns are not the same as yours, not only do they need to rest more regularly, they also
        have shorter sleep cycles and spend more time in lighter versus deeper sleep. This means your baby will wake
        more regularly and easily. Most babies will sleep for no more than three to four hours in a row. Some babies may
        need help falling back asleep when they wake from a sleep cycle. When your baby is awake you will need to be
        awake, so this means you may be woken two to three times over the night. Sleep deprivation can be one of the
        most difficult aspects of early parenting. Sleep deprivation can aff ect your lifestyle habits, including diet
        and physical activity. It can trigger changes in hormones that regulate satiety and appetite, making you feel
        more hungry and causing you to crave carbohydrates and foods high in sugar. Hormones can also cause sleepless
        nights and may interfere with the time it takes you to fall asleep, or affect the quality of your sleep.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        It is important you try to maintain your own sleep routine. This will help your baby to develop a routine, and
        will also allow your body time to rest and increase your chances of relaxation. While it is expected that you
        will be woken regularly during the first weeks and months, it is important to go back to bed when you have
        settled your baby during the night and not be distracted by screens, snacks or be tempted to stay awake until
        your baby wakes again.
      </p>
      <p>
        Below are some tips to help you get enough sleep. Discussing some of these ideas with your partner before you
        baby arrives may be a good idea:
      </p>
      <ul>
        <li>
          <strong>Take advantage of weekends.</strong> Sleeping in for two to three hours on a weekend, when your
          partner or a support person is around to care for your baby can be very beneficial. Your body will make up for
          lack of sleep by spending a larger amount of time in deep sleep. Be sure not to sleep in for too long during
          the day as this can negatively impact your ability to fall asleep at night and interfere maintaining a
          day-night sleep routine.
          <br />
        </li>
        <li>
          <strong>Have a nap.</strong> A 20-30-minute nap can help you feel refreshed, and won’t leave you feeling
          groggy. Try to nap early in the day, before 2-3pm so this doesn’t impact your ability to sleep in the night.
          If your baby doesn’t have a predictable nap schedule calling on a friend or family member may be needed.
        </li>
        <li>
          <strong>Alternate night feeds.</strong> Depending on how you feed your baby, you may consider sharing the role
          of feeding your baby with your partner to alleviate sleep deprivation. For babies who are bottle fed, this may
          be easier, however breastfeeding mothers can consider expressing a bottle for overnight feeding also. Often
          partners may return to work and therefore, switching roles on weekends may be more realistic.
        </li>
        <li>
          <strong>Put your baby down while they are awake.</strong> This can help your baby learn to fall asleep on
          their own. By six months most babies should be capable of sleeping for seven to eight hours without needing
          you to settle them.
        </li>
        <li>
          <strong>Establish your own sleep ritual.</strong> Establishing a sleep routine will help your baby wind down
          and learn to expect when sleep is coming. This can be just as beneficial for you. Repeating the same
          activities each night such as reading a book or having a shower, can signal to your baby that it is time to
          for sleep.
        </li>
        <li>
          <strong>Help yourself.</strong> Don’t eat a heavy meal right before bed and set the mood in your room by
          keeping it dark and quiet. Turn your clock, phone and monitor light away from you, remove any light from
          screens and use a white-noise machine if needed. Use some of the strategies you implement for your baby’s
          sleep to help yourself too.
        </li>
      </ul>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about Sleep deprivation visit:</p>
      <ul>
        <li>
          <a
            href="https://raisingchildren.net.au/grown-ups/looking-after-yourself/healthy-lifestyle/sleep-for-parents"
            target="_blank"
            rel="noopener noreferrer">
            Raising Children
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/ConditionsAndTreatments/sleep-deprivation"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel - Sleep deprivation
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/ConditionsAndTreatments/sleep-hygiene"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Channel - Sleep hygiene
          </a>
        </li>
        <li>
          <a href="https://www.tresillian.org.au/" target="_blank" rel="noopener noreferrer">
            Tresillian - Baby Advice and Parenting Tips{' '}
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
