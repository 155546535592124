import React from 'react';
import { TopicState } from 'app/types';

export default function useVisited(
  articleId: string,
  visited: string[],
  updateTopicState: (topicState: Partial<TopicState>) => void,
) {
  React.useEffect(() => {
    if (visited.indexOf(articleId) < 0) {
      updateTopicState({ visited: [...visited, articleId] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId, visited]);
}
