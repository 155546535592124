import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';
import StepForm, { Step } from 'components/stepsForm';
import { notEmpty } from 'components/form/validators';
import useTopicState from 'app/topics/useTopicState';

type ScreeningData = {
  h1_weight: number;
  h2_weightFreq: string | null;
  h3_habit: string[];
};

const initialScreeningData: ScreeningData = {
  h1_weight: 0,
  h2_weightFreq: null,
  h3_habit: [],
};

const health = {
  title: 'Health Check-in',
  fields: [
    {
      name: 'h1_weight',
      type: 'text-field',
      desc: 'What is your current weight without shoes (kg)?',
      helperText: 'If you are unsure, please estimate anyway.',
      input: {
        label: 'Weight',
      },
      validator: notEmpty(),
    },
    {
      name: 'h2_weightFreq',
      type: 'radio-group',
      desc: 'How often do you weigh yourself?',
      input: {
        options: ['Daily', 'Weekly', 'Monthly', 'Occasionally or Never'],
      },
      validator: notEmpty(),
    },
    {
      name: 'h3_habit',
      type: 'checkbox-group',
      desc: 'Since starting the OptimalMe program have you (please select all that apply)?',
      input: {
        options: [
          'Visited a GP in preparation for pregnancy',
          'Had a vaccine for any of the following (Measles, Mumps, Rubella (MMR), Hepatitis B, Tetanus/Diphtheria/Pertussis (whooping cough), Chicken pox, Influenza (flu))',
          'Had a cervical screening test',
          'Had a sexually transited infection (STI) screening test',
          'Had prenatal screening for genetic malformations (genetic testings)',
          'Taken a preconception supplement (folate (folic acid)/iodine or a pre-pregnancy multivitamin)',
          'Taken a Vitamin D supplement',
          'Smoked tobacco',
          'Consumed alcohol (any)',
          'Consumed four or more standard drinks in a single occasion',
          'Taken recreational drugs',
        ],
      },
      validator: notEmpty(),
    },
  ],
} as Step;

export const steps = [health];

export default function Screening() {
  const history = useHistory();
  const { topicState, updateTopicState } = useTopicState('pregnancy');
  const handleSubmit = (screeningData: ScreeningData) => {
    const defaultArticleIds = [
      'morning_sickness',
      'pregnancy_stages',
      'pre_eclampsia',
      'prenatal_screening',
      'cord_blood',
      'anxiety',
    ];
    const plan = defaultArticleIds;
    updateTopicState({ screening: screeningData, plan }, () => history.replace('/topics/pregnancy/essentials'));
  };

  return (
    <Container>
      <StepForm<ScreeningData>
        steps={steps}
        initialData={(topicState.screening as ScreeningData) || initialScreeningData}
        onSubmit={handleSubmit}
        onStepChanged={() => {}}
      />
    </Container>
  );
}
