import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function SleepingRoutines() {
  return (
    <ArticleContent>
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Babies are born without a regular sleep-wake cycle and sleep during the day and night.</li>
        <li>
          It is important to try and rest when your baby sleeps and maintain your own sleep routine as best as possible
          to ensure adequate rest and relaxation.
        </li>
        <li>Sleep deprivation can be one of the hardest aspects of being a new mother.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Babies have different sleep patterns to adults. Newborn babies have short sleep-wake cycles, and typically sleep
        in cycles lasting between 30-50 minutes. During this time they have periods of light sleep and deep sleep.
        During light sleep they may twitch or jerk, while in deeper sleep they are more likely to be still with soft
        breathing. When newborns come out of a sleep cycle they may have difficulty falling asleep on their own and need
        repeated settling. By approximately 3 months babies begin to develop night and day sleep patterns and may being
        to sleep for longer stretches at night.
      </p>
      <p>
        This pattern of frequent waking in babies can be hard on you as the main carer and going without sleep (see
        sleep deprivation) can be one of the hardest aspects of caring for a new baby. If you understand the sleeping
        needs of your baby you may be better able to get an adequate amount of sleep.
      </p>
      <p>
        You are likely to receive a lot of advice from those around you and how long your baby should sleep and how to
        establish a routine. Whilst advice is often well meaning it may not fit you or your baby’s needs. Each baby will
        have the own sleep habits and learning to understand these will help you to know when you can sleep.
        Well-meaning friends and relatives may tell you to ignore your baby’s cries in the hope that this will establish
        quicker sleep routines (i.e. ‘cry it out’). Remember, however, that crying is a young baby’s only verbal means
        of telling her parents that she/he needs something and responding to your baby helps to establish a
        communication pattern between you.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Sleep when your baby sleeps, particularly in the first few weeks. Prioritise sleep over other tasks or, if
        possible, get someone else to take over household duties for you.
      </p>
      <p>
        You can help your newborn to learn to sleep more at night by establishing a feed-play-sleep routine. This
        includes exposing them to light when they wake, feeding them followed by playing with them and providing a dim
        and quiet environment when it is time for sleep.
      </p>
      <p>
        Try to remember when you are feeling sleep deprived that this is temporary and as babies grow and develop, sleep
        routines become more established and predictable.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about Sleep routines and safe sleeping visit:</p>
      <ul>
        <li>
          <a href="https://www.breastfeeding.asn.au/bf-info/sleep/wakeful" target="_blank" rel="noopener noreferrer">
            Australian Breast Feeding Association
          </a>
        </li>
        <li>
          <a
            href="https://raisingchildren.net.au/newborns/sleep/understanding-sleep/about-sleep"
            target="_blank"
            rel="noopener noreferrer">
            Raising Children
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
