import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogTitle, Box, IconButton, DialogTitleProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export function RedirectDialogTitle(props: DialogTitleProps) {
  const history = useHistory();
  return (
    <DialogTitle>
      <Box display="flex" alignItems="center">
        <Box flex={1}>{props.children}</Box>
        <IconButton color="primary" onClick={() => history.push('/dashboard')}>
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
  );
}
