import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function PrenatalScreening() {
  return (
    <ArticleContent>
      <Quiz
        question="Screening tests are used to diagnose genetic abnormalities."
        answer="False"
        feedback="This is false. Screening tests are used to determine increased risk for genetic abnormalities, they can not diagnose a condition."
      />
      <h4 id="key-points">Key points</h4>
      <ul>
        <li>
          There are different tests available to all Australian women that can indicate if your baby is at increased
          risk for genetic abnormalities.
        </li>
        <li>The most common test involves an ultrasound and blood test.</li>
        <li>
          The tests are voluntary, your doctor may recommend you have them if you have increased risks such as age or a
          family history.
        </li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Down syndrome (trisomy 21) is the most common chromosomal disorder. As women age, the chance of a her having a
        baby with Down syndrome increases. Prenatal screening for Down Syndrome is available to all Australian women.
      </p>
      <p>
        Other chromosomal conditions that may be screened for are Edwards syndrome (trisomy 18) and Patau syndrome
        (trisomy 13). These are serious conditions linked to pregnancy loss, disability or death of the newborn.
      </p>
      <p>
        It is recommended that all women are advised of the availability of prenatal screening tests at pre-pregnancy
        counselling or as early as possible in the pregnancy. Out-of-pocket costs for these tests vary.
      </p>
      <h4 id="types-of-prenatal-screening-tests">Types of Prenatal Screening Tests:</h4>
      <p>
        <strong>Combined first trimester screening</strong>
      </p>
      <ul>
        <li>Involves an ultrasound at 11 to 13 weeks and a blood test between 10 and 13 weeks.</li>
        <li>The ultrasound measurement of the back of the baby’s neck (nuchal translucency).</li>
        <li>
          The results are combined with the results of the blood test and your age to estimate the chance of the baby
          having Down syndrome.
        </li>
        <li>
          This test identifies approximately 85–90% of babies with Down syndrome, and may detect an increased risk of a
          range of other less-common chromosomal conditions.
        </li>
        <li>The ultrasound can also detect major structural conditions in the fetus.</li>
      </ul>
      <p>
        <strong>Cell-free DNA screening or non-invasive prenatal testing</strong>
      </p>
      <ul>
        <li>
          Uses a sample of your blood to estimate the chance of your baby having a chromosomal condition such as Down
          syndrome.
        </li>
        <li>
          This test can identify about 99% of babies with Down syndrome, and can also test for other chromosomal
          conditions.
        </li>
        <li>This test does not involve a 11-13-week ultrasound, but you can arrange to do this separately.</li>
      </ul>
      <p>
        <strong>Second trimester serum screening</strong>
      </p>
      <ul>
        <li>Involves a blood test.</li>
        <li>Can be performed between 15 and 20 weeks of pregnancy.</li>
        <li>
          Screens for Edwards syndrome and Downs syndrome, as well as neural tube defects, including spina bifida.
        </li>
        <li>It can detect approximately 75% of pregnancies with Down syndrome.</li>
      </ul>
      <p>
        Further to this, you can have a diagnostic test such as an amniocentesis or chorionic villus sampling (CVS),
        which will definitively tell you whether your baby has a genetic or chromosomal condition. These tests have a
        small risk of miscarriage, therefore having screening tests first is recommended.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Your doctor or midwife can discuss the available screening tests with you. They may refer you to a genetic
        counsellor for additional support or information. Participation in prenatal screening is voluntary and you
        should be supported in making an informed decision about doing so.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about Non-invasive Prenatal Testing (NIPT) visit:</p>
      <ul>
        <li>
          <a
            href="https://www.racgp.org.au/clinical-resources/clinical-guidelines/key-racgp-guidelines/view-all-racgp-guidelines/genomics-in-general-practice/prenatal-testing"
            target="_blank"
            rel="noopener noreferrer">
            The Royal Australian New Zealand College of General Practitioners
          </a>
        </li>
        <li>
          <a
            href="https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-prenatal-tests"
            target="_blank"
            rel="noopener noreferrer">
            Better Health Victoria
          </a>
        </li>
        <li>
          <a
            href="https://www.health.wa.gov.au/docreg/Education/Prevention/Genetics/HP3131_prenatal.pdf"
            target="_blank"
            rel="noopener noreferrer">
            Health Western Australia
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
