import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      '& h1, h2, h3, h4, h5, h6, blockquote': {
        fontFamily: "'Merriweather', serif",
      },
      '& h3': {
        fontSize: '2rem',
        margin: '1em 0',
      },
      '& h4': {
        fontSize: '1.5rem',
        margin: '1em 0',
        color: '#AEBC95',
      },
      '& h6': {
        fontSize: '1.2rem',
      },
      '& p': {
        letterSpacing: `${0.5 / 16}rem`,
        lineHeight: 1.5,
      },
      '& ul,ol > li': {
        letterSpacing: `${-0.15 / 16}rem`,
        lineHeight: 2,
      },
    },
  }),
);
export type ArticleContentProps = {
  children: React.ReactNode;
};

export default function ArticleContent(props: ArticleContentProps) {
  const classes = useStyles();
  return <Paper className={classes.root}>{props.children}</Paper>;
}
