import React from 'react';
import { useHistory } from 'react-router-dom';
import map from 'lodash/map';
import { Card, CardHeader, CardContent, Divider, Box, Grid, Button, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { ModuleId } from 'app/types';
import { GoalOptions } from 'app/topics/types';
import useTopicState from 'app/topics/useTopicState';

export type GoalSelectProps = {
  moduleId: ModuleId;
  goalOptions: GoalOptions;
};

export default function GoalSelect(props: GoalSelectProps) {
  const { moduleId, goalOptions } = props;
  const history = useHistory();
  const { topicState, updateTopicState } = useTopicState(moduleId);
  const [goals, setGoals] = React.useState<Set<string>>(new Set(topicState.goals));

  const toggleGoal = (goalId: string) => {
    if (goals.has(goalId)) {
      goals.delete(goalId);
    } else {
      goals.add(goalId);
    }
    setGoals(new Set(goals));
  };

  const handleSubmit = () => {
    const successPath = `/topics/${moduleId}/goals`;
    updateTopicState({ goals: Array.from(goals) }, () => history.push(successPath));
  };

  return (
    <Card>
      <CardHeader title="My Goals" />
      <CardContent>
        <Typography variant="subtitle1">One small step at a time:</Typography>
        <Typography color="textSecondary" gutterBottom>
          Our lives have become very busy and most of us struggle to cook healthy meals and achieve enough physical activity every day.
          The thought of making major changes to routines can be overwhelming. We recommend identifying a support person or group
          to assist you in your continued path to better and healthy living. Though challenging at first, we encourage you to take
          SMALL STEPS that are attainable, while consistently making SMALL CHANGES. Through the next sections you will learn ways to
          set goals and strategies and measure your progress.
        </Typography>

        <Typography variant="subtitle1">Choosing health habits to change:</Typography>
        <Typography color="textSecondary" gutterBottom>
          Changing health habits is an ongoing process and needs constant attention. When trying to set new goals or change habits, it is helpful to
          consider your current habits, what needs to change and how important it is for you to change them. It is advisable to make realistic
          changes to be able to develop sustainable behaviours. Listed below are a number of lifestyle areas that can affect your health and your weight.
          Some habits listed are those you may not have thought about before, others may be very familiar to you.
        </Typography>

        <Typography variant="subtitle1">Instructions:</Typography>
        <Typography color="textSecondary" gutterBottom>
          Consider the following lifestyle areas and select those that you feel you could improve upon. Select as many as you feel are relevant to you.
          You may re-visit these goals at any time to remind yourself of your strategies and planned steps to improve your health.
        </Typography>

        <Box my={2}>
          <Grid container spacing={3}>
            {map(goalOptions, (goal, key) => (
              <Grid item key={key} xs={12} sm={6} md={4} lg={3}>
                <GoalItem checked={goals.has(key)} onClick={() => toggleGoal(key)}>
                  {goal}
                </GoalItem>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="center" my={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

/**
 * GoalItem
 */
type GoalItemProps = {
  checked?: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
  children: React.ReactNode;
};

const useStyles = makeStyles(theme =>
  createStyles({
    goalItem: (props: GoalItemProps) => {
      const base = {
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 5,
        cursor: 'pointer',
        minHeight: '4rem',
      };
      const checkStyle = props.checked
        ? {
            color: '#FFF',
            backgroundColor: '#333',
            borderColor: '#666',
          }
        : {
            color: '#333',
            backgroundColor: '#FFF',
            borderColor: '#666',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,.2)',
            },
          };
      return { ...base, ...checkStyle };
    },
  }),
);

const GoalItem = (props: GoalItemProps) => {
  const { children, onClick } = props;
  const classes = useStyles(props);
  return (
    <Box p={2} className={classes.goalItem} onClick={onClick} component="div">
      {children}
    </Box>
  );
};
