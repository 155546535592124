import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ServesInput from './ServesInput';
import { DietValue } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
    },
    main: {
      flex: 1,
      [theme.breakpoints.up('sm')]: {
        flex: 0.6,
      },
      '& > .content > ul': {
        listStyle: 'none',
        padding: theme.spacing(2),
        backgroundColor: '#EEE',
        color: '#333',
        borderRadius: 5,
        margin: theme.spacing(2, 0),
        '& > li': {
          marginBottom: theme.spacing(1),
        },
      },
    },
    cover: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        flex: 0.4,
      },
    },
  }),
);

export type DietCardProps = {
  name: string;
  value: DietValue;
  onChange: (value: DietValue) => void;
  children: React.ReactNode;
  image: string;
  title: string;
  subheader: string;
};

export default function DietCard(props: DietCardProps) {
  const { image, title, subheader, value, children, onChange } = props;
  const classes = useStyles();
  return (
    <Card>
      <div className={classes.container}>
        <CardContent className={classes.main}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle1" gutterBottom color="textSecondary">
            {subheader}
          </Typography>
          <div className="content">{children}</div>
          <ServesInput value={value} onChange={onChange} />
        </CardContent>
        <CardMedia className={classes.cover} image={image} title="Live from space album cover" />
      </div>
    </Card>
  );
}
