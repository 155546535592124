import React from 'react';
import { useHistory } from 'react-router-dom';
import InfoList from 'components/InfoList';
import { Card, CardHeader, CardContent, Grid, Box, Paper, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import WeightLogsView from 'components/WeightLogsView';
import WeightGainChart from 'components/WeightGainChart';
import { WeightLog, Profile } from 'app/types';
import { calcBmi, bmiLegends } from 'app/helper';
import { useDispatch } from 'react-redux';
import { updateProfile } from 'app/profile/profileSlice';

export type WeightTrackProps = {
  profile: Profile;
};

export default function WeightTrack(props: WeightTrackProps) {
  const { profile } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const { weight, height, dueDate, weightLogs } = profile;
  const [bmi, category] = calcBmi(weight, height);
  const handleChange = (weightLogs: WeightLog[]) => {
    dispatch(updateProfile({ weightLogs }));
  };

  return (
    <Card>
      <CardHeader
        title="Pregnancy weight gain calculator"
        subheader="Use this calculator to monitor if your weight gain is on a healthy track."
        action={
          <IconButton onClick={() => history.push('/profile/edit')}>
            <EditIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <WeightGainChart normalWeight={weight} height={height} dueDate={dueDate} weightLogs={weightLogs} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mt={4}>
              <Paper>
                <WeightLogsView weightLogs={weightLogs || []} onChange={handleChange} />
              </Paper>
            </Box>
          </Grid>
        </Grid>

        <InfoList
          items={[
            {
              label: 'Due Date',
              value: `${dueDate}`,
            },
            {
              label: 'Pre-pregnancy Weight',
              value: `${weight} kg`,
            },
            {
              label: 'Height',
              value: `${height} cm`,
            },
            {
              label: `BMI (${bmiLegends[category].label})`,
              value: bmi.toFixed(1),
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
