import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ScrollTriggerPage } from 'components/page';
import NavToolbar, { Nav } from 'components/nav/NavToolbar';
import Videos from 'components/Videos';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      backgroundColor: 'transparent',
      color: '#333',
    },
    filler: {
      backgroundImage: 'url(images/topics/preconception/essentials_banner.jpg)',
      backgroundSize: 'cover',
    },
    header: {
      padding: theme.spacing(2, 0),
    },
  }),
);

const navs: Nav[] = [
  {
    label: 'Essential Information',
    path: '/topics/preconception/essentials',
    exact: false,
  },
  {
    // Fix # 141
    label: 'Shaping My Lifestyle',
    path: '/topics/preconception/shaping_my_lifestyle',
    exact: false,
  },
  {
    label: 'My Goals',
    path: '/topics/preconception/goals',
    exact: false,
  },
];


export function EssentialsPage(props: React.HTMLProps<HTMLElement>) {
  const classes = useStyles();

  return (
    <ScrollTriggerPage
      title="Pre-Pregnancy Essentials"
      extraToolbar={<NavToolbar navs={navs} />}
      classes={{ appBar: classes.appBar, fillerBg: classes.filler }}>
      <div className={classes.header}>
        <Videos
          videos={[
            {
              title: 'Once you have completed this module you will have a tailor made plan.',
              id: '0lR0jxbpYPI',
            },
            {
              title: 'Pre-pregnancy health is more than just folic acid and cutting back on drinking.',
              id: 'w_WLmjvITOc',
            },
            {
              title: 'This program has everything that you would need for a happy and healthy pregnancy journey.',
              id: 'RKiIA9WTdvs',
            },
          ]}
        />
      </div>
      {props.children}
    </ScrollTriggerPage>
  );
}
