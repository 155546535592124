export type BmiCategory = 'underWeight' | 'normal' | 'overWeight' | 'obese' | 'extremlyObese';

export const bmiLegends: {
  [key in BmiCategory]: {
    color: string;
    label: string;
    gainRange: [number, number];
  };
} = {
  underWeight: {
    color: '#939183',
    label: 'Underweight',
    gainRange: [12.5, 18],
  },
  normal: {
    color: '#228B22',
    label: 'Healthy',
    gainRange: [11.5, 16],
  },
  overWeight: {
    color: '#939183',
    label: 'Overweight',
    gainRange: [7, 11.5],
  },
  obese: {
    color: '#805D49',
    label: 'Obese',
    gainRange: [5, 9],
  },
  extremlyObese: {
    color: '#A0413B',
    label: 'Extremly Obese',
    gainRange: [5, 9],
  },
};

export function calcBmi(weight: number, height: number): [number, BmiCategory] {
  const bmi = weight / Math.pow(height / 100, 2);
  const categorical = (): BmiCategory => {
    if (bmi < 18.5) return 'underWeight';
    if (bmi < 25) return 'normal';
    if (bmi < 30) return 'overWeight';
    if (bmi < 40) return 'obese';
    return 'extremlyObese';
  };
  return [bmi, categorical()];
}
