import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Link from 'components/Link';
import { Card, Grid, CardContent } from '@material-ui/core';

export default function WeightGain() {
  return (
    <ArticleContent>
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Gaining weight during pregnancy is normal and vital to a baby’s healthy development</li>
        <li>
          Gaining too much weight during pregnancy can increase the risk of negative health outcomes for mother and baby
        </li>
        <li>Regular weighing is the best way to keep recommended weight gain on track</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Gaining weight is a normal, healthy part of pregnancy. However, it is not always easy to know how much weight
        gain is healthy during pregnancy. Gaining too much or not enough weight can put you and your baby at risk of
        complications.
      </p>
      <h4 id="how-much-weight-should-i-gain">How much weight should I gain?</h4>
      <p>
        Recommended weight gain during pregnancy varies, depending on your body mass index (BMI) before pregnancy.
        Calculate your BMI using this <Link to="/dashboard">BMI Calculator</Link>.
      </p>
      <p>The recommendations for weight gain during pregnancy are as follows:</p>
      <ul>
        <li>
          During the first twelve weeks of pregnancy, the guidelines recommend women gain 0.5 – 2kgs, depending on their
          weight at conception.
        </li>
        <li>
          During the second and third trimester, you should gain weight at a steady rate. Depending on your
          pre-pregnancy weight, the recommended weekly weight gain is between 200 – 500grams per week.
        </li>
        <li>
          Keep in mind, it’s normal for weight gain to fluctuate from time-to-time. If you’re concerned, speak to your
          doctor.
        </li>
      </ul>
      <h4 id="what-is-a-pregnancy-made-up-of">What is a pregnancy made up of?</h4>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
            <p>Baby: 3.3kg</p>
            <p>Placenta: 0.6kg</p>
            <p>Extra fluid: 1-1.5kg</p>
            <p>Uterus enlargement &amp; breast tissue: 2kg</p>
            <p>Maternal blood volume: 1.8kg</p>
            <p>Maternal fat stores: 1-2kg</p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <h4 id="what-can-i-do">What can I do?</h4>
      <ul>
        <li>
          Self-monitor regularly: Weighing yourself regularly during pregnancy is the most effective way to keep on
          track. Start by weighing yourself once per week and keep track of weight gain by writing or logging your
          weight each time you weigh.
        </li>
        <li>
          Awareness of weight gain: Accounting for up to 2kg of weight gain during the first trimester, Gestational
          weight gain (GWG) should accrue slowly, but steadily at a rate of about 0.5kg per week for healthy and
          overweight women and 0.3kg per week for obese women in the second and third trimester. If you consistently
          experience weekly weight gain above these limits, making small adjustments to diet and physical activity will
          help.
        </li>
        <li>
          Beware of common myths: It’s important to remember that during pregnancy energy intake changes are minimal –
          equivalent to about one glass extra of milk per day and regular moderate physical activity such as walking is
          encouraged in the absence of complications. The need to “eat for two” or increased “cravings” for high sugar,
          salt or fat foods contribute to excessive weight gain, especially when physical activity is stopped or
          reduced.
        </li>
      </ul>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about gestational weight gain visit:</p>
      <ul>
        <li>
          <a
            href="https://www.wslhd.health.nsw.gov.au/WNH/Health-Information/Healthy-lifestyle/Gestational-weight-gain"
            target="_blank"
            rel="noopener noreferrer">
            Women’s and Newborn Health
          </a>
        </li>
        <li>
          <a
            href="https://www.diabetesaustralia.com.au/news/11295?type=articles"
            target="_blank"
            rel="noopener noreferrer">
            Diabetes Australia
          </a>
        </li>
        <li>
          <a
            href="https://www.thewomens.org.au/health-information/pregnancy-and-birth/a-healthy-pregnancy/weight-pregnancy"
            target="_blank"
            rel="noopener noreferrer">
            Royal Women’s Hospital
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
