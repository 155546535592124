import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    videoContainer: {
      position: 'relative',
      overflow: 'hidden',
      paddingTop: '56.25%',
      marginBottom: theme.spacing(1),
    },
    videoInner: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    caption: {
      textAlign: 'center',
    },
  }),
);

type VideoProps = {
  id: string;
  title?: string;
};

/**
 * Video Component wrap around react-youtube
 *
 * @param {object} props React props
 */
export default function Video(props: VideoProps) {
  const { id, title } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.videoContainer}>
        <iframe
          className={classes.videoInner}
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          title="Youtube"
        />
      </div>
      {title && (
        <Typography className={classes.caption} variant="caption" gutterBottom>
          {title}
        </Typography>
      )}
    </div>
  );
}
