import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NavPage, AppBar } from 'components/nav';
import AppDrawer from './AppDrawer';

const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingBottom: theme.spacing(4),
      overflow: 'auto',
    },
    gutter: theme.mixins.toolbar,
  }),
);

export type SimplePageProps = {
  title: string;
  drawerContent: React.ReactElement;
  extraToolbar?: React.ReactNode;
} & Pick<React.HTMLProps<HTMLElement>, 'children'>;

export default function SimplePage(props: SimplePageProps) {
  const { title, drawerContent, extraToolbar, children } = props;
  const classes = useStyles();
  return (
    <NavPage drawerContent={drawerContent}>
      <AppBar position="fixed" title={title} extraToolbar={extraToolbar} />
      <main className={classes.content}>
        <div className={classes.gutter} />
        {children}
      </main>
    </NavPage>
  );
}

export const AppPage = (props: Omit<SimplePageProps, 'drawerContent'>) => (
  <SimplePage {...props} drawerContent={<AppDrawer />} />
);
