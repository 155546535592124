import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import Link from 'components/Link';
import Form from 'components/form';
import Button from 'components/ActionStatusButton';
import { Card, CardActions } from './AuthPage';
import { mustBeEmail, withinLength } from 'components/form/validators';
import { Credential } from 'app/types';
import { ActionStatus } from 'app/helper';

const initialData = {
  email: '',
  password: '',
};

const validators = {
  email: mustBeEmail(),
  password: withinLength(8, 16),
};

export type SigninCardProps = {
  status: ActionStatus | null;
  onSignin: (data: Credential) => void;
};

export default function SigninCard(props: SigninCardProps) {
  const { onSignin, status } = props;
  return (
    <Card title="Sign in">
      <Form<Credential> data={initialData} validators={validators} onSubmit={onSignin}>
        {({ values, errors, updateField }) => (
          <>
            <TextField
              name="email"
              label="Email or Username"
              margin="normal"
              variant="outlined"
              value={values.email}
              onChange={e => updateField('email', e.target.value)}
              error={Boolean(errors.email)}
              helperText={errors.email || 'Please note that this field is case sensitive.'}
              fullWidth
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              margin="normal"
              variant="outlined"
              value={values.password}
              onChange={e => updateField('password', e.target.value)}
              error={Boolean(errors.password)}
              helperText={errors.password}
              fullWidth
            />
            <CardActions>
              <Button status={status} type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </CardActions>

            <Grid container>
              <Grid item xs>
                <Link variant="body2" to="/auth/forgotPassword">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body2" to="/auth/register">
                  Don't have an account? Register
                </Link>
              </Grid>
            </Grid>
          </>
        )}
      </Form>
    </Card>
  );
}
