import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import ActionStatusProgress from 'components/ActionStatusProgress';
import Alert from 'components/Alert';
import AdminPage from 'app/admin/AdminPage';
import { selectProfiles } from 'app/store';
import { loadProfiles, actions } from '../profileSlice';
import { ProfileCard } from './ProfileCard';
import Filter from "./Filter";
import moment from 'moment'

export function Profiles() {
  const dispatch = useDispatch();
  const { cursor, profiles, entities, query, status } = useSelector(selectProfiles);

  React.useEffect(() => {
    if (profiles === null) {
      dispatch(loadProfiles(query));
    }
    // eslint-disable-next-line
  }, []);

  const handleTabChange = (value: string) => {
    switch (value) {
      case 'ALL':
        return dispatch(loadProfiles({}));
      default:
        return dispatch(loadProfiles({ pregStatus: value as 'YES' | 'NO' }));
    }
  };

  const handleLoadMore = () => {
    dispatch(loadProfiles(query, cursor));
  };


  const [filterValues, setFilterValues] = useState<{
    dueDate : any,
    enableFilter : boolean
  }>({
    dueDate : moment(),
    enableFilter : false
  })

  const onChange = (id : string , value : any) => setFilterValues({
    ...filterValues,
    [id] : value
  })


  return (
    <AdminPage title="Profiles">
      <Alert alertText={status?.error} onClose={() => dispatch(actions.idle())} variant="filled" severity="error" />
      <ActionStatusProgress status={status} />

      <Box mb={2}>
        <Paper>
          <Tabs
            value={query.pregStatus || 'ALL'}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => handleTabChange(value)}>
            <Tab label="All" value="ALL" />
            <Tab label="Pregnant" value="YES" />
            <Tab label="Not pregnant" value="NO" />
          </Tabs>
        </Paper>
      </Box>

      {profiles && (
        <>
          <Box mb={2}>
            <Filter onChange={onChange} value={filterValues}/>
            <Grid container spacing={2}>
              {profiles
                .filter(id => {
                  const item = entities[id]
                  if(!filterValues.enableFilter) return true
                  if(!filterValues.dueDate) return false
                  return moment(filterValues.dueDate) >= moment(item.dueDate, 'DD/MM/YYYY')
                })
                .sort((firstId, secondId) => {
                  const firstEl = entities[firstId]
                  const secondEl = entities[secondId]
                  if(!firstEl.dueDate) return -1
                  if(!secondEl.dueDate) return -1
                  const commonDateFormat = 'DD/MM/YYYY'
                  const firstDate = moment(firstEl.dueDate, commonDateFormat)
                  const secondDate = moment(secondEl.dueDate, commonDateFormat)
                  if (firstDate < secondDate) return 1
                  else return -1
                })
                .map(id => {
                const profile = entities[id];
                return (
                  <Grid item key={profile.id} xs={12} sm={6} md={4}>
                    <ProfileCard profile={profile} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          {cursor && (
            <>
              <Divider />
              <Box p={2} display="flex" justifyContent="center">
                <Button onClick={handleLoadMore}>Load More</Button>
              </Box>
            </>
          )}
        </>
      )}
    </AdminPage>
  );
}
