export default {
  focus: 'Focus on self-care',
  coffee: 'Caffeine intake',
  softdrinks: 'Drinking soft drink or sugary drinks',
  water: 'Drinking water',
  fruit: 'Eating fruit',
  junkfood: 'Eating junk food',
  meals: 'Eating regular meals',
  veg: 'Eating vegetables',
  exercise: 'Exercise',
  highfat: 'Foods high in fat content',
  highsugar: 'Foods high in sugar content',
  homecooked: 'Home cooked meals',
  priority: 'Making my health a priority',
  weight: 'Maintaining healthy weight',
  worklife: 'My work/life balance',
  ask: 'Ask for help (support network)',
  pelvic: 'Pelvic floor exercises',
  foodlabels: 'Reading food labels when buying',
  sleep: 'Sleep routine',
  snacking: 'Snacking',
  mindfullness: 'Practising mindfulness',
  relaxing: 'Time spent relaxing',
};
