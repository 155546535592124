import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from '@material-ui/core';
import StepForm, { Step } from 'components/stepsForm';
import { notEmpty } from 'components/form/validators';
import useTopicState from 'app/topics/useTopicState';

type ScreeningData = {
  h1_weight: number;
};

const initialScreeningData: ScreeningData = {
  h1_weight: 0,
};

const health = {
  title: 'Health Check-in',
  fields: [
    {
      name: 'h1_weight',
      type: 'text-field',
      desc: 'What is your current weight without shoes (kg)?',
      helperText: 'If you are unsure, please estimate anyway.',
      input: {
        label: 'Weight',
      },
      validator: notEmpty(),
    },
  ],
} as Step;

export const steps = [health];

export default function Screening() {
  const { topicState, updateTopicState } = useTopicState('postpartum');
  const history = useHistory();
  const handleSubmit = (screeningData: ScreeningData) => {
    const defaultArticleIds = [
      'first_hours',
      'baby_blues',
      'sleep_deprivation',
      'feeding_baby',
      'physical_activity',
      'relationships',
    ];
    const plan = defaultArticleIds;
    updateTopicState({ screening: screeningData, plan }, () => history.replace('/topics/postpartum/essentials'));
  };

  return (
    <Container>
      <StepForm<ScreeningData>
        steps={steps}
        initialData={(topicState.screening as ScreeningData) || initialScreeningData}
        onSubmit={handleSubmit}
        onStepChanged={() => {}}
      />
    </Container>
  );
}
