import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthPage from './AuthPage';
import SigninCard from './SigninCard';
import ChallengeCard from './ChallengeCard';
import { signin, completeNewPassword, actions } from './authSlice';
import { Credential } from 'app/types';
import { selectAuth } from 'app/store';
import { useHistory } from 'react-router-dom';

export default function Signin() {
  const dispatch = useDispatch();
  const { status } = useSelector(selectAuth);
  const [challenge, setChallenge] = React.useState<any>(null);
  const [failureCount, setFailureCount] = React.useState(0);
  const history = useHistory();

  React.useEffect(() => {
    if (failureCount > 5) {
      dispatch(actions.idle());
      history.replace({ pathname: '/', state: { alert: 'Too many failed login attempts.' } });
    }
    //eslint-disable-next-line
  }, [failureCount]);

  const handleSignin = (credential: Credential) => {
    dispatch(
      signin(
        credential,
        (challenge: any) => {
          setChallenge(challenge);
        },
        () => {
          setFailureCount(failureCount + 1);
        },
      ),
    );
  };

  const handleChallenge = (password: string) => {
    if (!challenge) {
      throw new Error('Challenge did not exist...');
    }
    dispatch(completeNewPassword(challenge, password));
  };

  return (
    <AuthPage>
      {challenge === null ? (
        <SigninCard onSignin={handleSignin} status={status} />
      ) : (
        <ChallengeCard onChallenge={handleChallenge} status={status} />
      )}
    </AuthPage>
  );
}
