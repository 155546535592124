import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { RegisterStatus, ListRegistersQueryVariables } from 'API';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';

import { AppThunk } from 'app/store';
import { ActionStatus, request, failure, idle } from 'app/helper';
import { RegisterData } from 'app/admin/types';
import * as adminApi from 'app/admin/api';

export type RegistersQuery = {
  email: string | null;
  status: RegisterStatus | null;
  nextToken: string | null;
};

export type RegistersState = {
  query: RegistersQuery;
  items: RegisterData[] | null;
  cursor: string | null;
  status: ActionStatus | null;
};

export const initialState: RegistersState = {
  query: {
    email: null,
    status: null,
    nextToken: null,
  },
  items: null,
  cursor: null,
  status: null,
};

type RegistersInput = {
  items: RegisterData[];
  cursor: string | null;
};

const { actions, reducer } = createSlice({
  name: 'registers',
  initialState,
  reducers: {
    setQuery(state, action: PayloadAction<RegistersQuery>) {
      const query = action.payload;
      state.query = query;
    },

    setRegisters(state, action: PayloadAction<RegistersInput>) {
      const { items, cursor } = action.payload;
      state.items = items;
      state.cursor = cursor;
      state.status = null;
    },

    appendRegisters(state, action: PayloadAction<RegistersInput>) {
      const { items, cursor } = action.payload;
      state.items = [...(state.items || []), ...items];
      state.cursor = cursor;
      state.status = null;
    },

    updateRegister(state, action: PayloadAction<{ id: string; register: RegisterData }>) {
      const { id, register } = action.payload;
      if (state.items) {
        state.items = state.items.map(r => (r.email === id ? { ...r, ...register } : r));
      }
      state.status = null;
    },

    removeRegister(state, action: PayloadAction<string>) {
      const email = action.payload;
      if (state.items) {
        state.items = state.items.filter(r => r.email !== email);
      }
      state.status = null;
    },

    request: request('status'),

    failure: failure('status'),

    idle: idle('status'),
  },
});

export default reducer;

export { actions };

const toListQueryVariables = (query: RegistersQuery): ListRegistersQueryVariables => {
  let variables: ListRegistersQueryVariables = {
    nextToken: query.nextToken,
  };
  if (query.email) {
    variables = {
      ...variables,
      email: query.email,
    };
  }
  if (query.status) {
    variables = {
      ...variables,
      filter: {
        status: { eq: query.status },
      },
    };
  }
  return variables;
};

export const fetchRegisters = (query: RegistersQuery): AppThunk => async (dispatch, getState) => {
  dispatch(actions.request());
  try {
    dispatch(actions.setQuery(query));
    const {
      data: {
        listRegisters: { items, nextToken },
      },
    } = await API.graphql({ query: queries.listRegisters, variables: toListQueryVariables(query) });

    if (query.nextToken) {
      dispatch(actions.appendRegisters({ items, cursor: nextToken }));
    } else {
      dispatch(actions.setRegisters({ items, cursor: nextToken }));
    }
  } catch (err) {
    console.error(err);
    dispatch(actions.failure(err.message || 'Failed to load data'));
  }
};

export const approveRegister = (data: RegisterData): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    const { email, name, dob, postcode, medibank_number } = data;
    const user = await adminApi.createUser({ email, name, dob, postcode, medibank_number });
    const input = { email, status: RegisterStatus.APPROVED };
    const result = await API.graphql({ query: mutations.updateRegister, variables: { input } });
    const register = result.data.updateRegister;

    dispatch(
      actions.updateRegister({
        id: email,
        register: {
          ...register,
          status: Boolean(user) ? register.status : RegisterStatus.PENDING,
        },
      }),
    );
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to approve'));
  }
};

export const rejectRegister = (email: string): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    const input = { email, status: RegisterStatus.REJECTED };
    const result = await API.graphql({ query: mutations.updateRegister, variables: { input } });
    const register = result.data.updateRegister;
    dispatch(actions.updateRegister({ id: email, register }));
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to reject'));
  }
};

export const deleteRegister = (email: string): AppThunk => async dispatch => {
  dispatch(actions.request());
  try {
    const input = { email };
    await API.graphql({ query: mutations.deleteRegister, variables: { input } });
    dispatch(actions.removeRegister(email));
  } catch (err) {
    dispatch(actions.failure(err.message || 'Failed to delete'));
  }
};
