import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { FormData } from 'components/form';
import { SurveyForm, SurveyQuestions } from 'components/surveyForm';
import { SimplePage } from 'components/page';
import AppDrawer from 'components/page/AppDrawer';

import { ModuleId } from 'app/types';
import useTopicState from 'app/topics/useTopicState';

export type EvaluationPageProps<T extends FormData> = {
  title: string;
  questions: SurveyQuestions<T>;
};

export default function EvaluationPage<T = FormData>(props: EvaluationPageProps<T>) {
  const { questions, title } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { moduleId } = useParams<{ moduleId: ModuleId }>();
  const { topicState, updateTopicState } = useTopicState(moduleId);
  const history = useHistory();
  const data = topicState.evaluation || {};

  const handleSubmit = (data: Partial<T>) => {
    updateTopicState({ evaluation: data }, () => {
      setDialogOpen(true);
    });
  };

  return (
    <SimplePage title="Evaluation" drawerContent={<AppDrawer />}>
      <Container maxWidth="md">
        <Box py={2}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <SurveyForm<T> namePrefix="e" questions={questions} data={data} onSubmit={handleSubmit} />
      </Container>
      <Dialog open={dialogOpen}>
        <DialogTitle>Thank you</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Thank you for your participation in the program evaluation, which greatly helped us.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.replace('/dashboard')} color="primary">
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </SimplePage>
  );
}
