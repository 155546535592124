// include plan and article results
import React from 'react';
import { ExpansionPanelDetails, ExpansionPanel } from '@material-ui/core';
import PanelSummary, { Attrs } from './PanelSummary';
import GpsFixed from '@material-ui/icons/GpsFixed';

import preconceptionGoals from 'app/topics/preconception/goals';
import pregnancyGoals from 'app/topics/pregnancy/goals';
import postpartumGoals from 'app/topics/postpartum/goals';
import { ModuleId, TopicState } from 'app/types';
import { Section, QuestionList } from './QuestionListPanel';
import { get } from 'lodash';

const allTopicGoals: Record<ModuleId, Record<string, string>> = {
  preconception: preconceptionGoals,
  pregnancy: pregnancyGoals,
  postpartum: postpartumGoals,
};

export type GoalsProps = {
  mod: ModuleId;
  topicState: TopicState;
};

export default function Goals(props: GoalsProps) {
  const { mod, topicState } = props;
  const { goals, goalSettings } = topicState;
  const topicGoals = allTopicGoals[mod];

  const attrs = toSummaryAttrs(goals || [], goalSettings || {}, topicGoals);
  const sections = toSections(goals || [], goalSettings || {}, topicGoals);
  return (
    <ExpansionPanel>
      <PanelSummary icon={<GpsFixed />} primary="Goals" secondary={<Attrs attrs={attrs} />} />
      <ExpansionPanelDetails>
        <QuestionList sections={sections} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

const toSummaryAttrs = (
  goals: string[],
  goalSettings: object,
  topicGoals: Record<string, string>,
): Array<{ label: string; value: string }> => {
  const goalsCount = goals.length;
  const goalSettingCount = Object.keys(goalSettings).length;
  return [
    {
      label: 'Selected',
      value: `${goalsCount}/${Object.keys(topicGoals).length}`,
    },
    {
      label: 'Filled',
      value: `${goalSettingCount}/${goalsCount}`,
    },
  ];
};

const goalQuestions = [
  { title: 'I want to...', id: 'goal' },
  { title: 'List what motivates you to change...', id: 'why' },
  { title: 'List your obstacles...', id: 'obstacles' },
  { title: 'List your strategies...', id: 'strategies' },
  { title: 'List your aim...', id: 'aim' },
  { title: 'Review my progress...', id: 'review_progress' },
];

const toSections = (goals: string[], goalSettings: object, topicGoals: Record<string, string>): Section[] => {
  return goals.map<Section>(goal => {
    const result = get(goalSettings, goal);
    return {
      title: topicGoals[goal] || goal,
      questions: result
        ? goalQuestions.map(goalQuestion => ({
            label: goalQuestion.title,
            value: result[goalQuestion.id],
          }))
        : [],
    };
  });
};
