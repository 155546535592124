import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import Link from 'components/Link';

export default function PCOS() {
   return (
    <ArticleContent>
      <Quiz
        question="Women with PCOS cannot have children."
        answer="False"
        feedback="Women with PCOS usually have the same family size as women without PCOS but may take longer to achieve if medical care is required."
      />

      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Polycystic Ovary Syndrome (PCOS) is the most common cause of infertility.</li>
        <li>The most effective way to improve fertility is with a healthy lifestyle.</li>
        <li>Women with PCOS usually have the same family size as women without PCOS but may take longer to achieve if medical care is required.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        PCOS is common, affecting around one in ten women. If you are thinking of starting a family and have PCOS it is important to know how you can optimise your fertility.
	</p>
	<p>About 60% of women with PCOS experience difficulties getting pregnant. This is due to hormonal changes that prevent regular ovulation (release of an egg). Without ovulation, which involves an egg moving from the ovary into the fallopian tubes where the sperm can fertilise it, pregnancy is not possible. The focus of improving fertility in PCOS management is correcting hormone levels (androgens and insulin) so that the ovary will release an egg. A healthy lifestyle helps this because it lowers hormone levels that prevent egg release. There are also medications that can assist this process.
	</p>

      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Lifestyle changes are the most effective way to improve your fertility. (Download the <a
            href="https://www.monash.edu/medicine/sphpm/mchri/pcos/resources/askpcos-app"
            target="_blank"
            rel="noopener noreferrer">PCOS app</a> and/or view fact sheets in the links below.)</p>
      <ul>
        <li>If you are overweight, losing 5 to 10% of your total body weight will greatly improve your chances of becoming pregnant</li>
        <li>Being active is very important in optimising the way insulin works in your body  - 30 mins of moderate to intense exercise on most days is recommended (see <Link to="/topics/preconception/articles/key_message">lifestyle messages</Link>). Addressing this hormonal imbalance, will in turn, improve fertility</li>
        <li>Eating a healthy diet is very important to improving your chances of getting pregnant. There is no particular diet recommended just a healthy, balanced diet.</li>
	<li>Working with health professionals such as your GP, dietitian and/or exercise physiologist, will assist your chances of getting pregnant</li>
	<li>Some women with PCOS will need medical assistance in addition to healthy lifestyle changes</li>
	<li>It is very important that you allow time for medical assistance when planning a family. Fertility starts to decline around the age of 33 years so planning to start your family earlier is important</li>
      </ul>

      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about PCOS visit these </p>
      <ul>
        <li>
          <a
            href="https://www.monash.edu/medicine/sphpm/mchri/pcos/resources/resources-for-women-with-pcos"
            target="_blank"
            rel="noopener noreferrer">
            Resources for Women with PCOS</a>
        </li>
        <li>Hear about <a
            href="https://vimeo.com/271184866/f47f34563c"
            target="_blank"
            rel="noopener noreferrer">Melanie’s journey to motherhood with PCOS</a>
        </li>
      </ul>
    </ArticleContent>
  );
}
