import { UpdateTopicStateInput, CreateProfileInput, UpdateProfileInput } from 'API';
import { Profile, TopicState, WeightLog } from 'app/types';

export type MutateProfileInput = CreateProfileInput | UpdateProfileInput;

export const decode = {
  profile(profile: any): Profile {
    return {
      ...profile,
      weightLogs: profile.weightLogs && JSON.parse(profile.weightLogs),
      topicStates: {
        items: (profile.topicStates?.items || []).map(this.topicState),
      },
    };
  },

  topicState(topicState: any): TopicState {
    return [
      'kpiPre',
      'kpiAfter',
      'evaluation',
      'screening',
      'lifestyleData',
      'goalSettings',
      'checklist',
      'articles',
    ].reduce((data, key) => {
      return Boolean(data[key]) ? { ...data, [key]: JSON.parse(data[key]) } : data;
    }, topicState);
  },
};

export const encode = {
  updateTopicStateInput(topicState: Partial<TopicState>): UpdateTopicStateInput {
    const keys: Array<keyof TopicState> = [
      'kpiPre',
      'evaluation',
      'screening',
      'lifestyleData',
      'goalSettings',
      'checklist',
      'articles',
    ];
    return keys.reduce(
      (input, key) => (Boolean(topicState[key]) ? { ...input, [key]: JSON.stringify(topicState[key]) } : input),
      topicState,
    ) as UpdateTopicStateInput;
  },

  mutateProfileInput(data: Partial<Profile>): MutateProfileInput {
    const converts: Array<[keyof Profile, (value: any) => string]> = [
      ['weightLogs', (value: WeightLog[]) => JSON.stringify(value)],
    ];
    return converts.reduce(
      (result, [key, convert]) => (result[key] ? { ...result, [key]: convert(result[key]) } : result),
      data,
    ) as MutateProfileInput;
  },
};
