import React from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Grid,
  InputAdornment,
  Box,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import greys from '@material-ui/core/colors/grey';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { WeightLog } from 'app/types';

const grey = greys[200];

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      flexGrow: 1,
    },
    toolbar: {
      backgroundColor: grey,
      color: theme.palette.getContrastText(grey),
    },
    mute: {
      color: theme.palette.text.secondary,
      marginLeft: 2,
    },
    paper: {},
    table: {},
  }),
);

type WeightLogEntry = {
  date: any;
  weight: number;
};

export type WeightLogsViewProps = {
  weightLogs: WeightLog[];
  onChange: (data: WeightLog[]) => void;
};

export default function WeightLogsView(props: WeightLogsViewProps) {
  const { weightLogs: value, onChange } = props;
  const classes = useStyles();
  const [entry, setEntry] = React.useState<WeightLogEntry | null>(null);
  const weightLogs = value;

  const updateEntry = (field: string, value: any) => {
    entry && setEntry({ ...entry, [field]: value });
  };

  const newEntry = () => {
    setEntry({
      date: moment(),
      weight: 50,
    });
  };

  const cancelEntry = () => {
    setEntry(null);
  };

  const submitEntry = () => {
    if (!entry) {
      return;
    }
    const weightLog = {
      date: moment(entry.date).format('DD/MM/YYYY'),
      weight: entry.weight,
    };
    onChange([...weightLogs, weightLog]);
    setEntry(null);
  };

  const deleteWeightLog = (index: number) => {
    const weightLogs = props.weightLogs.filter((item, i) => i !== index);
    onChange(weightLogs);
  };

  const isEntryValid = (entry: WeightLogEntry | null) => {
    return entry && entry.date && entry.weight;
  };

  return (
    <React.Fragment>
      <Toolbar variant="dense" className={classes.toolbar}>
        <Typography className={classes.title} variant="h6">
          My weight during pregnancy
        </Typography>
        <IconButton color="inherit" onClick={() => newEntry()}>
          <AddIcon />
        </IconButton>
      </Toolbar>

      {weightLogs.length > 0 ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell style={{ width: 64 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {weightLogs.map((weightLog, i) => (
                <TableRow key={i}>
                  <TableCell>{weightLog.date}</TableCell>
                  <TableCell>
                    {weightLog.weight}
                    <span className={classes.mute}>kg</span>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => deleteWeightLog(i)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box p={4} display="flex" flexDirection="column" alignItems="center">
          <Typography gutterBottom>Please create at least one weight log entry.</Typography>
          <Button color="primary" onClick={() => newEntry()}>
            Create a weight log entry
          </Button>
        </Box>
      )}

      <Dialog open={Boolean(entry)} onClose={cancelEntry} keepMounted>
        <DialogTitle id="form-dialog-title">Create weight log</DialogTitle>
        <DialogContent>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DialogContentText>
              To add a weight log entry, please enter the date and your weight around that date.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="DD/MM/YYYY"
                  label="Date"
                  value={entry?.date}
                  placeholder="DD/MM/YYYY"
                  onChange={(date: any) => {
                    updateEntry('date', date);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="number"
                  label="Weight"
                  variant="outlined"
                  InputProps={{ endAdornment: <InputAdornment position="start">kg</InputAdornment> }}
                  value={entry?.weight || 50}
                  onChange={e => {
                    updateEntry('weight', e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelEntry} color="primary">
            Cancel
          </Button>
          <Button onClick={submitEntry} color="primary" disabled={!isEntryValid(entry)}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
