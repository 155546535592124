import { Rate5, Approval5, Approval4, Difficulty5, SurveyQuestions } from 'components/surveyForm';

export type KpiPreData = {
  a1: Rate5;
  a2: Rate5;
  a3: Rate5;
  a4: Rate5;
  a5: Approval5;
  b1: Approval4;
  b2: Approval4;
  b3: Approval4;
  b4: Approval4;
  b5: Approval4;
  b6: Approval4;
  b7: Approval4;
  b8: Approval4;
  b9: Approval4;
  c1: Difficulty5;
  c2: Difficulty5;
  c3: Difficulty5;
  c4: Difficulty5;
  c5: Difficulty5;
};

const questions: SurveyQuestions<KpiPreData> = {
  groups: [
    {
      title:
        'Congratulations! Pregnancy is an exciting time for many women. Before we get started on this module, we would like to ask you a few questions to create the most optimised experience for you.',
      variant: 'rating',
      options: ['Poor', 'Fair', 'Good', 'Very good', 'Excellent'],
      questions: [
        {
          id: 'a1',
          label: 'How would you rate your knowledge of how to optimise your health during pregnancy?',
        },
        {
          id: 'a2',
          label: 'How would you rate your confidence to make lifestyle changes?',
        },
        {
          id: 'a3',
          label: 'How would you rate your motivation to make lifestyle changes?',
        },
        {
          id: 'a4',
          label: 'How would you rate your intention to make lifestyle changes?',
        },
        {
          id: 'a5',
          variant: 'toggle-group',
          label: 'I believe I can improve my health and lifestyle during pregnancy.',
          options: ['Strongly agree', 'Agree', 'Neutral', 'Disagree', 'Strongly disagree'],
        },
      ],
    },

    {
      title:
        'When thinking about your health during pregnancy, how strongly do you disagree or agree with the following statements?',
      variant: 'toggle-group',
      options: ['Strongly agree', 'Agree', 'Disagree', 'Strongly disagree'],
      questions: [
        {
          id: 'b1',
          label: 'I feel I have good information about health',
        },
        {
          id: 'b2',
          label: 'I have enough information to help me deal with my health problems',
        },
        {
          id: 'b3',
          label: 'I am sure I have all the information I need to manage my health effectively',
        },
        {
          id: 'b4',
          label: 'I have all the information I need to look after my health',
        },
        {
          id: 'b5',
          label: 'I can get access to several people who understand and support me',
        },
        {
          id: 'b6',
          label: 'When I feel ill, the people around me really understand what I am going through',
        },
        {
          id: 'b7',
          label: ' If I need help, I have plenty of people I can rely on',
        },
        {
          id: 'b8',
          label: ' I have at least one person who can come to medical appointments with me',
        },
        {
          id: 'b9',
          label: 'I have strong support from family or friends',
        },
      ],
    },
    {
      title:
        'When thinking about your health during pregnancy, how easy or difficult are the following tasks for you to do now?',
      variant: 'rating',
      options: ['Always difficult', 'Usually difficult', 'Sometimes difficult', 'Usually easy', 'Always easy'],
      questions: [
        {
          id: 'c1',
          label: 'Confidently fill medical forms in the correct way',
        },
        {
          id: 'c2',
          label: 'Accurately follow the instructions from healthcare providers',
        },
        {
          id: 'c3',
          label: 'Read and understand written health information',
        },
        {
          id: 'c4',
          label: 'Read and understand all the information on medication labels',
        },
        {
          id: 'c5',
          label: 'Understand what healthcare providers are asking you to do',
        },
      ],
    },
  ],
};

export default questions;
