import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Rate5, Difficulty5, Engaging5, SurveyQuestions } from 'components/surveyForm';

export type EvaluationData = {
  ka1: Rate5;
  ka2: Rate5;
  ka3: Rate5;
  ka4: Rate5;
  kb1: Rate5;
  kb2: Rate5;
  kb3: Rate5;
  kb4: Rate5;
  kb5: Rate5;
  kb6: Rate5;
  kb7: Rate5;
  kb8: Rate5;
  kc1: Difficulty5;
  kc2: Difficulty5;
  kc3: Difficulty5;
  kc4: Difficulty5;
  kc5: Difficulty5;
  kc6: Difficulty5;
  kc7: Difficulty5;
  kc8: Difficulty5;
  kc9: Difficulty5;
  kc10: Difficulty5;
  kc11: Difficulty5;
  kd1: Engaging5;
  kd2: string;
  kd3: string;
  kd4: string;
  kd5: string;
  kd6: string;
  kd7: string;
  kd8: string;
  kd9: string;
  kd10: string;
  kd11: string;
};

const questions: SurveyQuestions<EvaluationData> = {
  post: (
    <Box my={2}>
      <Typography>
        Thank you for taking the time to complete these questions. We wish you luck in your motherhood journey and hope
        that this has made a small difference towards an optimised healthy living.
      </Typography>
    </Box>
  ),
  groups: [
    {
      title:
        'Congratulations on completing the pregnancy and post birth module. Your interpretation of this module is very valuable, please fill in the following questions to help us improve OptimalMe and understand your experience.',
      variant: 'rating',
      questions: [],
    },
    {
      title: 'How likely are you to recommend OptimalMe to family and friends who are pregnant?',
      variant: 'rating',
      options: ['Not likely', 'Slightly likely', 'Moderate likely', 'Very likely', 'Extremely likely'],
      questions: [
        {
          id: 'ka1',
          label: '',
        },
      ],
    },
    {
      title: 'How helpful were the following?',
      variant: 'rating',
      options: ['Not helpful', 'Slightly helpful', 'Neutral', 'Helpful', 'Very Helpful'],
      questions: [
        {
          id: 'kb1',
          label: 'The interactive overall web program and modules ',
        },
        {
          id: 'kb2',
          label: 'The information on pregnancy health',
        },
        {
          id: 'kb3',
          label: 'The information on physical activity, diet/food safety and weight during pregnancy',
        },
        {
          id: 'kb4',
          label: 'The information on post-birth health and lifestyle changes',
        },
        {
          id: 'kb5',
          label: 'The post-birth diet and physical activity information',
        },
        {
          id: 'kb6',
          label: 'Text messages',
        },
        {
          id: 'kb7',
          label: 'The gestational weight tracker',
        },
        {
          id: 'kb8',
          label: 'Speaking with a health professional ',
        },
      ],
    },
    {
      title: 'Were you satisfied with the level of support provided by the program? ',
      questions: [
        {
          id: 'kc1',
          label: '',
          variant: 'selection-open',
          options: [
            'No, I wanted some more support',
            'No, i wanted a lot more support',
            'Neutral',
            'Yes, I was happy with the support provided',
          ],
        },
        {
          id: 'kc2',
          variant: 'rating',
          label: 'What effort did you put into following the advice given within the online program?',
          options: [
            'No effort',
            'Slight effort',
            'Moderately effort',
            'Quite a lot of effort',
            'A great deal of effort',
          ],
        },
        {
          id: 'kc3',
          variant: 'rating',
          label:
            'What effort did you put into following the advice given within the or one-on-one session with your health coach?',
          options: [
            'No effort',
            'Slight effort',
            'Moderately effort',
            'Quite a lot of effort',
            'A great deal of effort',
          ],
        },
        {
          id: 'kc4',
          variant: 'selection-multi',
          label: 'How could we have improved the program (please select all that apply)?',
          options: [
            'More one-on-one support',
            'More text messages',
            'Week-by-week pregnancy tracker ',
            'Program newsletter',
            'Regular healthy lifestyle emails',
            'Online group sessions',
            'Video uploads with information',
            'Addition of exercise plans',
            'Addition of meal plans',
            'Incorporate social media (Facebook groups) in order for program participants to keep in touch and provide encouragement to each other',
            'Chat box on website to ask questions between sessions',
          ],
        },
        {
          id: 'kc4',
          variant: 'selection-open',
          label: '',
          options: ['Other (please specify):'],
        },
        {
          id: 'kc5',
          variant: 'text',
          label: 'What did you like about the program?',
        },
        {
          id: 'kc6',
          variant: 'text',
          label: 'What did you dislike about the program?',
        },
        {
          id: 'kc7',
          variant: 'selection-multi',
          label:
            'Of the following, what were the key messages you took from the pregnancy and post-birth module (please select all that apply)?',
          options: [
            'Improved health and lifestyle behaviours can lead to better health outcomes for the baby.',
            'Improved health and lifestyle behaviours can lead to better health outcomes for the mother.',
            'Improved health and lifestyle behaviours can improve pregnancy and birth outcomes.',
            'Program newsletter',
            'Improved health and lifestyle behaviours can improve overall wellbeing.',
            'It is important to make time to focus on emotional wellbeing and relationships.',
            'It’s important to make lifestyle goals.',
            'It’s important to regularly assess progress with goals and revaluate obstacles and strategies. ',
          ],
        },
        {
          id: 'kc10',
          variant: 'selection-open',
          label: '',
          options: ['Other (please specify):'],
        },
      ],
    },
    {
      title: 'Health Check-in',
      options: [''],
      questions: [
        {
          id: 'kc8',
          variant: 'text',
          label: 'What is your current weight without shoes (kg)? If you are unsure, please estimate anyway.',
        },
        {
          id: 'kc9',
          variant: 'selection-open',
          label: 'How often do you weigh yourself?)',
          options: ['Daily', 'Weekly', 'Monthly', 'Ocassionally or Never'],
        },
      ],
    },
    {
      title: 'Program Impact',
      variant: 'toggle-group',
      options: ['Yes', 'No', 'Unsure', 'I did not need to'],
      questions: [
        {
          id: 'kd1',
          label: 'Has OptimalMe helped you to improve your overall diet?',
        },
        {
          id: 'kd2',
          label: 'Since completing OptimalMe, have you increased your vegetable intake?',
        },
        {
          id: 'kd3',
          label: 'Since completing OptimalMe, have you increased your fruit intake?',
        },
        {
          id: 'kd4',
          label: 'Since completing OptimalMe, have you decreased your discretionary foods and beverage intake?',
        },
        {
          id: 'kd5',
          label: 'Has OptimalMe increased your knowledge about healthy food choices?',
          options: ['Yes', 'No', 'Neutral'],
        },
        {
          id: 'kd6',
          label: 'Has OptimalMe increased your knowledge about unhealthy food choices?',
          options: ['Yes', 'No', 'Neutral'],
        },
        {
          id: 'kd7',
          label: 'Since completing OptimalMe, have you increased your physical activity/exercise?',
        },
        {
          id: 'kd8',
          label: 'Since completing OptimalMe, have you decreased your sedentary behaviour/time sitting?',
        },
        {
          id: 'kd9',
          label: 'Has OptimalMe increased your knowledge about ways to be physically active?',
          options: ['Yes', 'No', 'Neutral'],
        },
        {
          id: 'kd10',
          label:
            'If you chose another area to work on in your goals (e.g. sleep, stress levels, alcohol consumption etc.) have you made any improvement?',
          options: ['Yes', 'No', 'Neutral'],
        },
        {
          id: 'kd11',
          variant: 'text',
          label: 'Do you have any further comments or feedback?',
        },
      ],
    },
  ],
};

export default questions;
