import MorningSickness from './MorningSickness';
import PelvicFloor from './PelvicFloor';
import DiscussWithDoctor from './DiscussWithDoctor';
import PregnancyStages from './PregnancyStages';
import LabourStages from './LabourStages';
import WeightGain from './WeightGain';
import Diabetes from './Diabetes';
import PreEclampsia from './PreEclampsia';
import PrenatalScreening from './PrenatalScreening';
import CordBlood from './CordBlood';
import Anxiety from './Anxiety';
import SexAndPregnancy from './SexAndPregnancy';
import Relationships from './Relationships';
import MaternityLeave from './MaternityLeave';
import { Content } from 'app/topics/types';

export default [
  {
    id: 'morning_sickness',
    tag: 'Health',
    title: 'Morning Sickness',
    desc: 'Morning sickness is a common symptom of early pregnancy.',
    cover: 'images/articles/pregnancy/essentials/morning_sickness.jpg',
    banner: 'images/articles/pregnancy/essentials/morning_sickness@2x.jpg',
    ArticleContent: MorningSickness,
    dataKeys: ['quiz'],
  },
  {
    id: 'pelvic_floor_exercises',
    tag: 'Health',
    title: 'The Pelvic Floor',
    desc: 'You should not wait until after pregnancy to strengthen your pelvic floor.',
    cover: 'images/articles/pregnancy/essentials/pelvic_floor_exercises.jpg',
    banner: 'images/articles/pregnancy/essentials/pelvic_floor_exercises@2x.jpg',
    ArticleContent: PelvicFloor,
    dataKeys: ['quiz'],
  },
  {
    id: 'discuss_with_doctor',
    tag: 'Health',
    title: 'Things to Discuss with your Doctor',
    desc: 'Things to Discuss with your Doctor',
    cover: 'images/articles/pregnancy/essentials/discuss_with_doctor.jpg',
    banner: 'images/articles/pregnancy/essentials/discuss_with_doctor@2x.jpg',
    ArticleContent: DiscussWithDoctor,
    dataKeys: ['quiz'],
  },
  {
    id: 'pregnancy_stages',
    tag: 'Essentials',
    title: 'The development of your baby during pregnancy',
    desc: 'The development of your baby during pregnancy',
    cover: 'images/articles/pregnancy/essentials/pregnancy_stages.jpg',
    banner: 'images/articles/pregnancy/essentials/pregnancy_stages@2x.jpg',
    ArticleContent: PregnancyStages,
    dataKeys: ['quiz'],
  },
  {
    id: 'labour_stages',
    tag: 'Essentials',
    title: 'Stages of Labour',
    desc: 'Stages of Labour',
    cover: 'images/articles/pregnancy/essentials/labour_stages.jpg',
    banner: 'images/articles/pregnancy/essentials/labour_stages@2x.jpg',
    ArticleContent: LabourStages,
  },
  {
    id: 'gwg',
    tag: 'Health',
    title: 'Gestational Weight-gain',
    desc: 'Gestational Weight-gain',
    cover: 'images/articles/pregnancy/essentials/gwg.jpg',
    banner: 'images/articles/pregnancy/essentials/gwg@2x.jpg',
    ArticleContent: WeightGain,
  },
  {
    id: 'gdm',
    tag: 'Health',
    title: 'Gestational Diabetes',
    desc: 'Gestational Diabetes',
    cover: 'images/articles/pregnancy/essentials/gdm.jpg',
    banner: 'images/articles/pregnancy/essentials/gdm@2x.jpg',
    ArticleContent: Diabetes,
  },
  {
    id: 'pre_eclampsia',
    tag: 'Health',
    title: 'Hypertension (pre-eclampsia)',
    desc: 'Hypertension (pre-eclampsia)',
    cover: 'images/articles/pregnancy/essentials/pre_eclampsia.jpg',
    banner: 'images/articles/pregnancy/essentials/pre_eclampsia@2x.jpg',
    ArticleContent: PreEclampsia,
  },
  {
    id: 'prenatal_screening',
    tag: 'Health',
    title: 'Non-invasive Prenatal Testing',
    desc: 'Will I need a NIPT test?',
    cover: 'images/articles/pregnancy/essentials/prenatal_screening.jpg',
    banner: 'images/articles/pregnancy/essentials/prenatal_screening@2x.jpg',
    ArticleContent: PrenatalScreening,
    dataKeys: ['quiz'],
  },
  {
    id: 'cord_blood',
    tag: 'Health',
    title: 'Cord blood',
    desc: 'What is cord blood and what is involved in collecting it?',
    cover: 'images/articles/pregnancy/essentials/cord_blood.jpg',
    banner: 'images/articles/pregnancy/essentials/cord_blood@2x.jpg',
    ArticleContent: CordBlood,
    dataKeys: ['quiz'],
  },
  {
    id: 'anxiety',
    tag: 'Wellbeing',
    title: 'Anxiety During Pregnancy',
    desc: 'Anxiety and depression during pregnancy',
    cover: 'images/articles/pregnancy/essentials/anxiety.jpg',
    banner: 'images/articles/pregnancy/essentials/anxiety@2x.jpg',
    ArticleContent: Anxiety,
    dataKeys: ['quiz'],
  },
  {
    id: 'sex_and_pregnancy',
    tag: 'Lifestyle',
    title: 'Sex During Pregnancy',
    desc: 'Is the way I am feeling about having sex unusual?',
    cover: 'images/articles/pregnancy/essentials/sex_and_pregnancy.jpg',
    banner: 'images/articles/pregnancy/essentials/sex_and_pregnancy@2x.jpg',
    ArticleContent: SexAndPregnancy,
    dataKeys: ['quiz'],
  },
  {
    id: 'relationships',
    tag: 'Wellbeing',
    title: 'Relationships and Pregnancy',
    desc: 'Relationships and Pregnancy',
    cover: 'images/articles/pregnancy/essentials/relationships.jpg',
    banner: 'images/articles/pregnancy/essentials/relationships@2x.jpg',
    ArticleContent: Relationships,
    dataKeys: ['quiz'],
  },
  {
    id: 'maternity_leave',
    tag: 'Lifestyle',
    title: 'Maternity Leave',
    desc: 'What am I entitled to and how do I organise things?',
    cover: 'images/articles/pregnancy/essentials/maternity_leave.jpg',
    banner: 'images/articles/pregnancy/essentials/maternity_leave@2x.jpg',
    ArticleContent: MaternityLeave,
  },
] as Array<Content>;
