import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Button, Box } from '@material-ui/core';
import ActionStatusProgress from 'components/ActionStatusProgress';
import Alert from 'components/Alert';
import { Toolbar, SearchField } from 'components/toolbars';
import { UserPanel } from './UserPanel';
import { fetchUsers, actions } from '../userSlice';
import AdminPage from 'app/admin/AdminPage';
import { selectUsers } from 'app/store';
import { AWSUser } from 'app/admin/types';

export type UsersProps = {
  users?: AWSUser[];
  fetchUsers: () => void;
};

export function Users() {
  const { users: userIds, entities, query, status, cursor } = useSelector(selectUsers);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchUsers(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (value?: string) => {
    const email = isEmpty(value) ? null : value!;
    dispatch(fetchUsers({ ...query, email, nextToken: null }));
  };

  const handleLoadMore = () => {
    dispatch(fetchUsers({ ...query, nextToken: cursor }));
  };

  const users = userIds ? userIds.map(id => entities.users[id]) : [];

  return (
    <AdminPage title="Users">
      <Alert alertText={status?.error} onClose={() => dispatch(actions.idle())} variant="filled" severity="error" />
      <ActionStatusProgress status={status} />
      <Toolbar>
        <Box flex={1} />
        <SearchField value={query.email} onSearch={handleSearch} />
      </Toolbar>
      {users.map(user => (
        <UserPanel key={user.Username} user={user} />
      ))}

      {cursor && (
        <Box my={4}>
          <Button onClick={() => handleLoadMore()}>Load More</Button>
        </Box>
      )}
    </AdminPage>
  );
}
