import { notEmpty } from 'components/form/validators';
import { Step } from 'components/stepsForm';

export default {
  title: 'Demographic',
  fields: [
    {
      name: 'd1_country',
      prefix: 'D1',
      type: 'radio-group',
      desc: 'What is your country of birth?',
      validator: notEmpty(),
      input: {
        options: ['Australia', 'New Zealand', 'United Kingdom', 'India', 'China', 'Other'],
      },
    },
    {
      name: 'd2_ethnicity',
      prefix: 'D2',
      type: 'radio-group',
      desc: 'What ethnicity do you identify with?',
      validator: notEmpty(),
      input: {
        options: [
          'Oceanian (Australian Peoples, New Zealand Peoples, Polynesia, Micronesia, Melanesian and Papuan)',
          'Indigenous Australian',
          'European',
          'North African / Middle Eastern',
          'South-East Asian',
          'North-East Asian',
          'Southern and Central Asian',
          'American',
          'Sub-Saharan African',
          'Other',
        ],
      },
    },
    {
      name: 'd3_schooling',
      prefix: 'D3',
      type: 'radio-group',
      desc: 'What is the highest level of education you have completed?',
      validator: notEmpty(),
      input: {
        options: [
          'Year 10 or below (Middle High)',
          'Year 11 or equivalent',
          'Year 12 or equivalent (Senior High)',
          'Certificate',
          'Diploma',
          'Bachelor degree and above',
        ],
      },
    },
    {
      name: 'd5_post_income',
      prefix: 'D5',
      type: 'radio-group',
      desc:
        'What is the average gross (before tax) total income that your household receives each week, including pensions, allowances, other income sources (rental income, interest, dividends)?',
      helperText: 'Household = Add salary of all major income earners including self, partner and others',
      validator: notEmpty(),
      input: {
        options: [
          'Less than $999 per week ($51,999 or less per year)',
          '$1,000 – 1,499 per week ($52,000 – 77,999 per year)',
          '$1,500 – 1,999 per week ($78,000 – 103,999 per year)',
          '$2,000 – 2,999 per week ($104, 155,999 per year)',
          '$3,000 or more per week ($156,000 or more per year)',
          'I prefer not to answer',
        ],
      },
    },
    {
      name: 'd6_working',
      prefix: 'D6',
      type: 'radio-group',
      desc: 'Are you currently working?',
      validator: notEmpty(),
      input: {
        options: ['Full time paid work', 'Part time paid work', 'Casual/ temporary work', 'No paid work'],
      },
    },
    {
      name: 'd7_marital_status',
      prefix: 'D7',
      type: 'radio-group',
      desc: 'Which best describes your marital status:',
      validator: notEmpty(),
      input: {
        options: ['Never married or single', 'Married or de facto', 'Separated or divorced', 'Widowed'],
      },
    },
    {
      name: 'd8_children',
      prefix: 'D8',
      type: 'radio-group',
      desc: 'How many children do you have?',
      validator: notEmpty(),
      input: {
        options: ['None', 'One', 'Two', 'Three or more'],
      },
    },
  ],
} as Step;
