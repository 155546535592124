import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will make an appointment with my GP to discuss having a cervical screen (pap test).';

export default function CervicalScreening() {
  return (
    <ArticleContent>
      <Quiz
        question="I have been with the same partner for a long time so I'm not at risk and don’t need a pap smear."
        answer="False"
        feedback="This is false. Regular screening is recommended for all women over 25 yrs of current or past sexual history."
      />

      <h4>Key points</h4>
      <ul>
        <li>The cervical screening test detects the human papillomavirus (HPV)</li>
        <li>Ideally your cervical screening is up-to-date prior to pregnancy</li>
        <li>If pre-cancerous cells are detected it is important to have treatment prior to pregnancy</li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        It is recommended you have cervical screening before conceiving. Whilst screening can be performed during
        pregnancy this may cause spotting. Also, If there are any abnormalities detected it is advisable that this is
        treated before pregnancy.
      </p>
      <p>
        Cervical screening (previously known as the ‘pap smear’) detects the human papillomavirus (HPV) which is an
        infection that can cell cause changes within the cervix that may cause cervical cancer. 99% of cervical cancer
        is caused by the HPV. The two yearly Pap test for people aged 18 to 69 has been replaced by a five yearly human
        papillomavirus (HPV) test for people aged 25 to 74. People are due for their first Cervical Screening Test at
        the age of 25 or two years after their last Pap test.
      </p>
      <p>
        The guidelines for cervical screening changed in December 2017. For women aged over 25, the two yearly pap smear
        has been replaced by a five yearly cervical screen. You are recommended to have your first cervical screening
        test two years after your last pap test. If results are normal, a test is then recommended every five years.
      </p>

      <h4>What can I do?</h4>
      <p>Discuss with your GP about when your next cervical screening test is due.</p>

      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information about cervical screening visit:</p>
      <ul>
        <li>
          <a
            href="http://cancerscreening.gov.au/internet/screening/publishing.nsf/Content/cervical-screening-1"
            target="_blank"
            rel="noopener noreferrer">
            Australian Government Department of Health
          </a>
        </li>
        <li>
          <a href="https://www.fpnsw.org.au" target="_blank" rel="noopener noreferrer">
            Family Planning NSW
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
