import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import ArticleContext from './ArticleContext';
import { AppBar, Toolbar, IconButton, Typography, Container } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BackIcon from '@material-ui/icons/ArrowBack';
import NextIcon from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';

import { ModuleId } from 'app/types';
import useVisited from 'app/topics/useVisited';
import useTopicState from 'app/topics/useTopicState';
import topicModules from 'app/topics/topicModules';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
    },

    title: {
      flexGrow: 1,
    },

    main: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingBottom: theme.spacing(4),
      overflow: 'auto',
    },

    banner: {
      position: 'fixed',
      height: 400,
      top: 56,
      left: 0,
      right: 0,
      backgroundSize: 'cover',
    },

    container: {
      zIndex: 10,
      paddingTop: 200,
      paddingBottom: theme.spacing(4),
    },

    heading: {
      color: '#FFF',
      textShadow: '2px 2px 3px #666',
    },
  }),
);

type ArticleProps = RouteComponentProps<{
  moduleId: ModuleId;
  articleId: string;
}>;

export default function Article(props: ArticleProps) {
  const { match, history } = props;
  const classes = useStyles();
  const { moduleId, articleId } = match.params;
  const { topicState, updateTopicState } = useTopicState(moduleId);
  useVisited(articleId, topicState.visited || [], updateTopicState);

  const topicModule = topicModules[moduleId];
  const allArticles = topicModule.content.all;

  const articleIndex = allArticles.findIndex(article => article.id === articleId);

  if (articleIndex < 0) {
    return <Redirect to={`/topics/${moduleId}/essentials`} />;
  }

  const article = allArticles[articleIndex];
  const canBack = history.length > 0;
  const next =
    articleIndex < allArticles.length - 1 ? `/topics/${moduleId}/articles/${allArticles[articleIndex + 1].id}` : null;

  return (
    <ArticleContext.Provider value={{ moduleId, articleId }}>
      <div className={classes.root}>
        <AppBar>
          <Toolbar>
            {canBack && (
              <IconButton color="inherit" edge="start" onClick={() => history.goBack()}>
                <BackIcon />
              </IconButton>
            )}
            <Typography variant="h6" className={classes.title} noWrap>
              {article.title}
            </Typography>
            <IconButton color="inherit" edge="start" onClick={() => history.push('/dashboard')}>
              <HomeIcon />
            </IconButton>
            {next !== null && (
              <IconButton edge="end" color="inherit" onClick={() => history.push(next)}>
                <NextIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <main className={classes.main}>
          <div className={classes.banner} style={{ backgroundImage: `url(${article.banner})` }} />
          <Container className={classes.container} maxWidth="lg">
            <Typography variant="h4" paragraph className={classes.heading}>
              {article.desc}
            </Typography>
            <article.ArticleContent />
          </Container>
        </main>
      </div>
    </ArticleContext.Provider>
  );
}
