import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { AppPage } from 'components/page';
import ArticleContent from 'components/topics/Article/ArticleContent';

const Image = styled('img')({
  width: '100%',
  margin: '1rem 0',
});

export default function About() {
  return (
    <AppPage title="About">
      <ArticleContent>
        <Container fixed>
          <Typography variant="h4" gutterBottom>
            Vision
          </Typography>
          <p>
            Women will have access to the best available health and lifestyle information on how to live optimal lives
            before, during and after pregnancy.
          </p>

          <Typography variant="h4" gutterBottom>
            Who are we?
          </Typography>
          <p>
            We are a group of researchers who work at the Monash Centre for Health Research and Implementation (MCHRI)
            within the School of Public Health and Preventive Medicine, Monash University. MCHRI aims to create and
            deliver the best available knowledge to enable greater opportunities for women to live optimal lives. For
            more information please go to the <a href="https://www.monash.edu/medicine/sphpm/mchri">MCHRI website</a>.
          </p>

          <Image src="images/GroupPhoto.jpg" />

          <Typography variant="h4" gutterBottom>
            What do we do?
          </Typography>
          <p>
            Over the past decade a major area of research focus has been on the prevention of weight gain and risk
            factors for chronic disease in women. The health of women is important to us with evidence showing that
            younger women of reproductive age are at high-risk compared to middle-aged women and men. Weight gain at a
            younger age increases the risk of obesity related chronic disease development and also impacts on fertility
            and pregnancy.
          </p>

          <p>
            The OptimalMe program is designed to encourage women to follow a healthy lifestyle in order to optimise
            health, wellness and prevent excessive weight gain.
          </p>

          <p>
            The OptimalMe program sits within a larger body of research known as HiPPP (Health in Pre-conception,
            Pregnancy and Postpartum).
          </p>

          <Typography variant="h4" gutterBottom>
            Health in Preconception, Pregnancy &amp; Postpartum (HIPPP)
          </Typography>
          <p>
            The HiPPP program of research is funded by the Medical Research Future Fund (MRFF) Boosting Prevention
            program, through The Australian Prevention Partnership Centre (TAPPC).
          </p>

          <p>
            The primary aim of HiPPP is to provide healthcare that supports women in healthy lifestyles, optimising
            weight and health across the life-course from pre-conception, through pregnancy and post-partum. This
            improves women’s own health, that of their children and has intergenerational impact.
          </p>

          <p>
            For more information please see the <a href="https://www.monash.edu/medicine/sphpm/mchri/hippp">HIPPP</a>{' '}
            website.
          </p>

          <img src="images/hippp.png" alt="hippp" />

          <Typography variant="h5" gutterBottom>
            What is OptimalMe?
          </Typography>
          <p>
            OptimalMe is a lifestyle program that aims to educate and motivate women to achieve their optimal level of
            health when considering pregnancy, during pregnancy and after birth.
          </p>

          <p>
            OptimalMe is based on a tested and proven program, using the best available research that has helped
            thousands of women achieve a healthier lifestyle. It has been delivered nationally and internationally, in
            various locations and in different populations of women before, during and after pregnancy.
          </p>

          <Typography variant="h4" gutterBottom>
            Who is involved with OptimalMe?
          </Typography>
          <p>
            OptimalMe is a partnership project between MCHRI and the Medibank Better Health Foundation. Medibank is an
            integrated healthcare company providing private health insurance to more than 3.7 million people and health
            services across Australia. Medibank is also committed to supporting the health research sector and fostering
            healthy communities. In this capacity, Medibank invests in the Medibank Better Health Foundation, which
            funds independent research. MCHRI wish to acknowledge Medibank for supporting this research by providing
            funding and in-kind support to enable study recruitment, which would not have otherwise been possible.
          </p>
        </Container>
      </ArticleContent>
    </AppPage>
  );
}
