import { createMuiTheme } from '@material-ui/core/styles';

export const themeColors = {
  me: '#AEBC95',
  me2: '#8D9669',
  optimal: '#464B2F',
  optimal2: '#5B5F53',
  optimal3: '#31371E',
  calm: '#B7B3A8',
  calm2: '#939183',
  ocean: '#95AEBD',
  bluegreen: '#00505E',
  night: '#103444',
  sunset: '#A0413B',
  autumn: '#805D49',
};

export default createMuiTheme({
  palette: {
    info: {
      main: themeColors.me,
    },

    primary: {
      main: themeColors.me2,
    },

    secondary: {
      main: themeColors.sunset,
    },
  },
});
