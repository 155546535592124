import React from 'react';
import { RouteComponentProps, useHistory, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { selectAuth } from './store';

export default function withAuth<T>(
  Comp: React.ComponentType<T>,
  requireAdmin?: boolean,
): React.ComponentType<T & RouteComponentProps> {
  return (props: T & RouteComponentProps) => {
    const { location } = props;
    const history = useHistory();
    const { authenticated, userInfo } = useSelector(selectAuth);

    React.useEffect(() => {
      if (!authenticated) {
        const continuePath = location?.pathname;
        history.replace({ pathname: '/auth/signin', state: { continuePath } });
      }
    }, [location, history, authenticated]);

    if (!authenticated) {
      return null;
    }

    if (requireAdmin) {
      const adminRoleIndex = userInfo?.groups?.indexOf('Admin');
      if (adminRoleIndex === undefined || adminRoleIndex < 0) {
        return <Redirect to="/404" />;
      }
    }

    return <Comp {...props} />;
  };
}
