import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { SurveyForm, SurveyQuestions } from 'components/surveyForm';
import { FormData } from 'components/form';
import { RedirectDialogTitle } from './RedirectDialogTitle';

export type KpiDialogProps<T extends FormData> = {
  open: boolean;
  questions: SurveyQuestions<T>;
  title: string;
  data: T;
  onSubmit: (data: Partial<T>) => void;
};

export default function KpiDialog<T = FormData>(props: KpiDialogProps<T>) {
  const { open, questions, title, data, onSubmit } = props;
  return (
    <Dialog open={open} scroll="body">
      <RedirectDialogTitle>{title}</RedirectDialogTitle>
      <DialogContent>
        <SurveyForm<T> namePrefix="k" questions={questions} data={data} onSubmit={onSubmit} />
      </DialogContent>
    </Dialog>
  );
}
