import { useSelector, useDispatch } from 'react-redux';
import { TopicState, ModuleId } from 'app/types';
import { selectProfile } from 'app/store';
import { updateTopicState as updateTopicStateAction } from 'app/profile/profileSlice';

export default function useTopicState(id: ModuleId) {
  const dispatch = useDispatch();

  const { profile } = useSelector(selectProfile);
  if (!profile) {
    throw new Error('Profile is not loaded!');
  }

  const topicState = profile.topicStates?.items.find(topicState => topicState.mod === id);
  if (!topicState) {
    throw new Error('Topic state not found');
  }

  return {
    topicState,
    updateTopicState: (data: Partial<TopicState>, onSuccess?: () => void) => {
      const topicId = topicState.id;
      dispatch(updateTopicStateAction(topicId, data, onSuccess));
    },
  };
}
