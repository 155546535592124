import React from 'react';

import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { isEmpty } from 'lodash';

export type SelectionMultiProps = {
  options: string[];
  value: string | null;
  onChange: (value: string | string[] | null) => void;
};

export default function SelectionMulti(props: SelectionMultiProps) {
  const { options, value, onChange } = props;
  const values = value?.split('|') || [];

  const isChecked = (option: string) => {
    return values.indexOf(option) >= 0;
  };

  const handleChange = (option: string) => (e: React.ChangeEvent, checked: boolean) => {
    const next = checked ? [...values, option] : values.filter(v => v !== option);
    onChange(isEmpty(next) ? null : next.join('|'));
  };

  return (
    <FormGroup>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          label={option}
          control={
            <Checkbox color="primary" checked={isChecked(option)} onChange={handleChange(option)} value={option} />
          }
        />
      ))}
    </FormGroup>
  );
}
