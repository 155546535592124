import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function Relationships() {
  return (
    <ArticleContent>
      <Quiz
        question="Being pregnant is a major life change and can change your relationship with your partner."
        answer="True"
        feedback="This is true. Pregnancy can bring on intense emotions that may swing from joy to anxiety. Your relationship with your partner may face challenges you may not have anticipated."
      />
      <h4 id="key-points">Key points</h4>
      <ul>
        <li>Being pregnant is a major life change and may change your relationship with your partner.</li>
        <li>Pregnancy does not happen in isolation from all the other things happening in our life.</li>
        <li>Feelings can be intense, including happiness mixed with anxiety.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>You may find challenges in your relationship that you may not have anticipated.</p>
      <p>
        <strong>Other people’s reactions</strong>
      </p>
      <p>
        You may have expectations that other people will respond positively to your pregnancy news but sometimes they’re
        not quite as positive as you had hoped.
      </p>
      <p>
        Sometimes even the enthusiastic responses can arouse mixed or negative feelings. As a couple you might be
        dealing with a range of complex feelings and thoughts about the pregnancy, from joy through to anxiety. When
        others suggest either through their reactions or their words that any feelings that are not overwhelmingly
        positive are wrong it can add another layer of complexity to what you are feeling.
      </p>
      <p>
        However, you might be feeling scared. You might be experiencing doubts alongside your joy and anticipation. You
        might be struggling with the loss of your former life. If other people suggest these feelings are wrong or
        shameful, you might try to suppress or ignore them. But this can actually make things worse.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Initiate open discussions with your partner about how both of you are feeling. Seek professional counselling if
        you are unable to resolve problems. Being pregnant is a major life change and can change your relationship with
        your partner. Feelings can be intense, and move from happiness to anxiety. Pregnancy does not happen in
        isolation from all the other things happening in your life such as; financial issues, issues at work, social or
        physical isolation, or issues with extended family, relationship concerns etc. These issues can compound stress.
      </p>
      <p>
        Whether planned or unplanned becoming pregnant can come as a big life change stimulating thoughts and
        discussions with your partner about what it means to be bringing a baby into your life. For many couples, this
        can be more difficult than expected.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about relationships and pregnancy visit:</p>
      <ul>
        <li>
          <a
            href="https://www.panda.org.au/info-support/during-pregnancy/adjusting-to-change/relationships"
            target="_blank"
            rel="noopener noreferrer">
            Perinatal Anxiety &amp; Depression Australia, PANDA
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
