import React from 'react';
import { ArticleContent, Quiz } from 'components/topics/Article';

export default function FeedingBaby() {
  return (
    <ArticleContent>
      <Quiz
        question="Breast feeding comes naturally, you will know what to do when the time comes."
        answer="False"
        feedback="This is not true. Breast feeding is a learnt skill like any other mothering skill. We learn from those around us and from health professionals."
      />
      <h4 id="key-points">Key Points</h4>
      <ul>
        <li>Breast feeding provides many benefits to both the mother and the baby</li>
        <li>
          Breast feeding requires the support of experienced and knowledgeable people around you and is a learned skill
        </li>
        <li>Having good support may improve your experience of breast feeding.</li>
      </ul>
      <h4 id="why-is-this-important-to-me">Why is this important to me?</h4>
      <p>
        Breast feeding your child can be a rich and rewarding experience and provides many health benefits to you and
        your baby. Benefits to your baby include reducing stomach problems such as diarrhoea and constipation, protects
        against allergies, reduces infections, makes vaccines more effective, protects against a range of diseases, may
        improves brain performance and reduces the risk of obesity later in life.
      </p>
      <p>
        Benefits to you include creating a closeness to your baby and helping your body recover from the pregnancy and
        birth.
      </p>
      <h4 id="what-can-i-do">What can I do?</h4>
      <p>
        Without the right support breast feeding can be difficult. Make sure you have a family member and/or health
        professional with experience in breast feeding to help you.
      </p>
      <p>
        Learning how to attach your baby to the breast is very important. If the baby is not attaching properly you can
        develop sore breasts that can make feeding uncomfortable. Also, the baby will be upset because she/he may not be
        feeding correctly. Get an experienced person to show you how this is done.
      </p>
      <p>
        View breast feeding as a learning journey. Along the way many problems can arise such as sore breasts, blocked
        ducts, milky supply and/or concern that you baby is not getting enough milk. As these problems arise seek advice
        from your doctor or other supports. The Australian Breast Feeding Organisations website is a rich source of
        credible information. Also, your maternal and health nurse will be a good source of support and information.
      </p>
      <p>
        <strong>When breast feeding doesn’t work out</strong>
      </p>
      <p>
        Most women want to breastfeed but unfortunately, despite best set wishes, hopes and efforts, sometimes
        breastfeeding doesn’t work out for many reasons.
      </p>
      <p>
        Not being able to breast feed can make mothers feel a deep sense of loss. It is normal to feel sad, and to feel
        some guilt and remorse. Allow yourself to feel these emotions. You may not be satisfied with your experience and
        things may not have gone as planned. Talking with a health professional can help you to feel reassured.
      </p>
      <h4 id="useful-links">Useful Links</h4>
      <p>For more information about breastfeeding visit:</p>
      <ul>
        <li>
          <a href="https://www.breastfeeding.asn.au/bfinfo/index.html" target="_blank" rel="noopener noreferrer">
            Australian Breast Feeding Association
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
