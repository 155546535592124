import React from 'react'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Box, Grid, FormControlLabel, Checkbox} from "@material-ui/core";
import MomentUtils from "@date-io/moment";

interface iFilter {
  onChange : (id : string, value : any) => void
  value : {[id : string] : any}
}


const Filter : React.FC<iFilter> = ({
  onChange,
  value
}) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              variant="inline"
              format="DD/MM/YYYY"
              label="Due date"
              placeholder="DD/MM/YYYY"
              value={value.dueDate}
              onChange={(value: any) => onChange('dueDate', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value.enableFilter}
                  onChange={(value : any) => onChange('enableFilter', value.target.checked)}
                  name="Enable Filter"
                  color="primary"
                />
              }
              label="Enable Filter"
            />
          </Grid>
        </Grid>
      </Box>
    </MuiPickersUtilsProvider>
  )
}

export default Filter
