import { PayloadAction } from '@reduxjs/toolkit';

export type ActionStatusType = 'request' | 'failure' | 'success';

export type ActionStatus = {
  type: ActionStatusType;
  error?: string;
  redirect?: string;
};

export const request = <T>(key: keyof T) => (state: T): T => {
  const status: ActionStatus = { type: 'request' };
  return { ...state, [key]: status } as T;
};

export const failure = <T>(key: keyof T) => (state: T, action: PayloadAction<string>): T => {
  const status: ActionStatus = { type: 'failure', error: action.payload };
  return { ...state, [key]: status } as T;
};

export const success = <T>(key: keyof T) => (state: T): T => {
  const status: ActionStatus = { type: 'success' };
  return { ...state, [key]: status } as T;
};

export const idle = <T>(key: keyof T) => (state: T): T => {
  return { ...state, [key]: null } as T;
};
