import React from 'react';
import ArticleContent from 'components/topics/Article/ArticleContent';
import Quiz from 'components/topics/Article/Quiz';
import AddToActions from 'components/topics/Article/AddToActions';

export const action = 'I will discuss my emotional health challenges that may arise during pregnancy.';

export default function EmotionalWellbeing() {
  return (
    <ArticleContent>
      <Quiz
        question="Being anxious about things at this point in your life is normal and healthy."
        answer="True"
        feedback="This is true. It is normal to feel worried, anxious and/or depressed sometimes, however these feelings should not be ongoing or affect your daily life."
      />

      <h4>Key points</h4>
      <ul>
        <li>Contemplating pregnancy can be a very exciting time</li>
        <li>It can also be daunting and may affect you emotional wellbeing</li>
        <li>
          Being anxious about things at this point in your life is normal and healthy, unless the periods of worry and
          stress don’t go away
        </li>
      </ul>

      <h4>Why is this important to me?</h4>
      <p>
        Emotional health is a state of wellbeing. Just as there are many benefits from being physically healthy, you,
        your partner and your baby can all benefit from being emotionally healthy.’ (Beyond Blue)
      </p>
      <p>
        Planning to have a baby can be very exciting but also daunting. You’re making plans for a huge change in your
        life and the following are things you may consider:
      </p>
      <ul>
        <li>Your health and ways to be as healthy as possible</li>
        <li>How you will finance your time away from work</li>
        <li>Discuss with your partner what kind of parents you would like to be.</li>
      </ul>
      <p>
        Being anxious about things at this point in your life is normal and healthy, unless the periods of worry and
        stress don’t go away. Look out for changes in your mood that are lasting much longer than is normal for you.
        Mental health problems such as anxiety and depression are not unusual in pregnancy so it’s important to look
        after your mental wellbeing before and after you conceive.
      </p>
      <p>
        If you have experienced emotion challenges such as depression and/or anxiety you may be at greater risk so it is
        good to discuss with your doctor strategies to manage these once you become pregnant. It is good to have a plan
        of action if you find you are challenged and know how to act.
      </p>

      <h4>What can I do?</h4>
      <p>Discuss ways to manage your emotional health challenges that may arise during pregnancy.</p>

      <AddToActions action={action} />

      <h4>Useful Links</h4>
      <p>For more information about emotional wellbeing visit:</p>
      <ul>
        <li>
          <a
            href="https://healthyfamilies.beyondblue.org.au/pregnancy-and-new-parents/becoming-a-parent-what-to-expect/emotional-health-and-wellbeing"
            target="_blank"
            rel="noopener noreferrer">
            Beyond Blue
          </a>
        </li>
        <li>
          <a href="https://www.panda.org.au/info-support" target="_blank" rel="noopener noreferrer">
            Panda
          </a>
        </li>
      </ul>
    </ArticleContent>
  );
}
