import React from 'react';
import { ArticleContent } from 'components/topics/Article';

export default function HowtoReadFoodLabel() {
  return (
    <ArticleContent>
      <h4>How to Read a Food Label</h4>
      <p>
        Often the information on the front of food packages can be false or misleading. Knowing how to read the
        Nutrition Information Panel is an important skill as it assists you in knowing what nutrients and energy you are
        consuming, and empowers you to make more informed, healthy choices.
      </p>

      <figure>
        <img src="images/articles/preconception/resources/howto_read_food_label/starthere.png" alt="starthere" />
      </figure>

      <figure>
        <img src="images/articles/preconception/resources/howto_read_food_label/salt100g.png" alt="salt100g" />
      </figure>

      <figure>
        <img src="images/articles/preconception/resources/howto_read_food_label/sodium.png" alt="sodium" />
      </figure>
      <p>
        Salt may be hidden on the ingredients list under a different name. Watch out for these sneaky names for salt.
      </p>
      <figure>
        <img
          src="images/articles/preconception/resources/howto_read_food_label/commonnamesalt.jpg"
          alt="commonnamesalt"
        />
      </figure>
      <p>
        Too much sugar can lead to weight gain, poor dentil health and other health concerns. Aim to have no more than 6
        teaspoons of added sugar each day.
      </p>
      <p>
        Natural sugars are found in fruit and dairy – these foods also contain important nutrients like protein, fibre,
        vitamins and minerals and should be eaten every day.
      </p>
      <p>
        However, sugar is often added to foods, often referred to as ultra-processed or processed foods. The are high in
        energy and don’t have the nutrients the body needs, therefor you should minimise the amount you eat and drink.
      </p>
      <p>To choose healthier package foods, read the information panel and check the amount of sugar.</p>
      <figure>
        <img
          src="images/articles/preconception/resources/howto_read_food_label/checksugar100g.jpg"
          alt="checksugar100g"
        />
      </figure>
      <p>
        Food labels in Australia don’t list the amount of naturally occurring sugar and added sugar separately so it is
        important that you check the ingredients as well as the amount of sugar. Some healthy foods like breakfast
        cereals and yoghurt contain natural sugars. However, some may be sweetened with added sugar. Read the
        ingredients list and choose products with no added sugar. The natural sugar from fruit and milk is okay. But
        check for the sneaky names for added sugar listed below.
      </p>
      <figure>
        <img
          src="images/articles/preconception/resources/howto_read_food_label/commonnamessugar.jpg"
          alt="commonnamessugar"
        />
      </figure>
    </ArticleContent>
  );
}
